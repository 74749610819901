import React, { useState, useRef } from 'react'
import { FiXCircle } from "react-icons/fi";
import helper from '../../../helper/index';
import { toast } from 'react-hot-toast';
import Backdrop from '../../../component/Backdrop';
import websitesetting from '../../../controller/websitesetting';

export default function AddCategory({ onClose, cb }) {
    const [load, setLoad] = useState(false)
    const category = useRef()
    const priority = useRef()
    const role = useRef()

    const submit = async () => {
        const req = {
            "category_name": category.current.value,
            "admin_id": helper.getid(),
            "priority_level": priority.current.value,
            "role": role.current.value
        }
        if (!req.category_name) {
            toast.error("Please enter category name")
            return
        }
        else if (!req.priority_level) {
            toast.error("Please select priority level")
            return
        }
        else if (!req.role) {
            toast.error("Please select role")
            return
        }
        setLoad(true)
       await websitesetting.addSupportCategory(req).then((res) => {
            if (res.data.status) {
                cb(res.data.result)
            }
            setLoad(false)
        })
    }

    return (
        <>
            <div className="all-center popup-modal">
                <div className="right-popup notify-detail p-fix tp-0 rt-0  sm" >
                    <div className="row gx-0 mx-0">
                        <div className="col-10">
                            <p className="f-fred fs-24">Add <span style={{ color: "#089D12" }}> category</span></p>
                            <p className="sub-heading">Support category</p>
                        </div>
                        <div className="col-2">
                            <FiXCircle className="pointer float-right " size="2rem" onClick={onClose} style={{ color: "lightgray" }} />

                        </div>
                    </div>


                    <div className="row gx-0 mx-0 edit-profile f-fred">
                        <div className="col-12 edit-input">
                            <input type="text" placeholder='Category name' ref={category} style={{ border: "1px solid lightgray" }} />
                        </div>
                        <div className="col-12 edit-input">
                            <select ref={priority}>
                                <option value="">Select priority</option>
                                <option value="High">High</option>
                                <option value="Low">Low</option>
                                <option value="Medium">Medium</option>
                            </select>
                        </div>
                        <div className="col-12 edit-input">
                            <select ref={role}>
                                <option value="">Select role</option>
                                <option value={3}>Tutor</option>
                                <option value={2}>Student</option>

                            </select>
                        </div>

                        <div className="col-12">
                            <button className="btn btn-update px-2 py-2  col-xs-2" onClick={submit}>Add Category</button>
                        </div>

                    </div>
                </div>
            </div>
            {load && <Backdrop />}
        </>
    )
}
