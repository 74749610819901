import React, { useState, useRef } from 'react'
import { FiXCircle } from "react-icons/fi";
import admin from '../../controller/admin';
import helper from '../../helper/index';
import { Toaster, toast } from 'react-hot-toast';
import Backdrop from '../../component/Backdrop';
export default function EditTutorRate({ CallClose, OnEdit, id, data }) {
    const rate = useRef()
    const [load, setLoad] = useState(false)
    const Edit = async () => {
        const reqData = {
            "tutor_id": id,
            "admin_id": helper.getid(),
            "rate": rate.current.value
        }
        if (!reqData.rate || reqData.rate <= 0) {
            toast("Please add valid rate")
            return
        }
        if (isNaN(reqData.rate)) {
            toast("Please add valid number")
            return
        }
        setLoad(true)
        await admin.editRate(reqData, id)
            .then((res) => {

                if (res.data.status) {
                    OnEdit()
                }
                setLoad(false)
            })
    }
    return (
        <>
             
            <div className="all-center popup-modal">
                <div className="right-popup notify-detail p-fix tp-0 rt-0  sm" >
                    <div className="row gx-0 mx-0">
                        <div className="col-10">
                            <p className="f-fred" style={{ fontSize: "24px" }}>Edit<span style={{ color: "#089D12" }}> rate</span></p>
                            <p className="sub-heading">Calls rate per minute</p>
                        </div>
                        <div className="col-2">
                            <FiXCircle className="pointer float-right " size="2rem" onClick={CallClose} style={{ color: "lightgray" }} />

                        </div>
                    </div>


                    <div className="row gx-0 mx-0 edit-profile f-fred">

                        <div className="col-lg-12 edit-input">
                            <input type="text" ref={rate} style={{ border: "1px solid lightgray" }} defaultValue={data && data.rate} />
                        </div>

                        <div className="col-lg-12">
                            <button className="btn btn-update px-2 py-2 f-fred  btn-sm col-xs-2" onClick={Edit}>UPDATE RATE</button>
                        </div>

                    </div>
                </div>
            </div>
            {load && <Backdrop />}

        </>
    )
}
