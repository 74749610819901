import React, { useState, useRef } from 'react'
import { FiXCircle } from "react-icons/fi";
import admin from '../../controller/admin';
import helper from '../../helper/index';
import { Toaster, toast } from 'react-hot-toast';
import Backdrop from '../../component/Backdrop';
export default function AddNewPlan({ CallClose, OnAddPlan }) {
    const [planData, setplanData] = useState({ price: "", is_active: false, plan_types: "", minutes: "", week: '' })
    const [pageload, setPageload] = useState(false)
    const addPlan = async () => {
        const reqData = {
            "admin_id": helper.getid(),
            "amount": planData.price,
            "is_active": planData.is_active,
            "commitment_level": planData.plan_types,
            "minutes_per_day": planData.minutes,
            "days_per_week": planData.week
        }

        if (!planData.plan_types) {
            toast("Please add commitment level")
            return
        }
        if (!reqData.amount || isNaN(reqData.amount)) {
            toast("Please add valid price")
            return
        }
        if (!reqData.minutes_per_day || isNaN(reqData.minutes_per_day)) {
            toast("Please add valid minutes")
            return
        }
        if (!reqData.days_per_week || isNaN(reqData.days_per_week) || reqData.days_per_week > 7) {
            toast("Please add valid days per week")
            return
        }
        setPageload(true)
        await admin.addPlan(reqData)
            .then((res) => {
                if (res.data.status) {
                    toast("Plan successfully added")
                    setTimeout(() => {
                        CallClose()
                        OnAddPlan(res.data.result)
                    }, 1500)
                } else {
                    toast(res.data.error)
                }
                setPageload(false)
            })
    }

    return (
        <>
             
            <div className="all-center popup-modal">
                <div className="right-popup notify-detail ms-500 p-fix tp-0 rt-0  px-3 py-3 sm" >
                    <div className="row gx-0 mx-0">
                        <div className="col-10">
                            <p className="f-fred" style={{ fontSize: "22px" }}>Add<span style={{ color: "#089D12" }}> commitment plan</span></p>
                            <p className="sub-heading" style={{ color: "#434943", fontSize: "14px", fontWeight: "700" }}>SayDhad cost manager</p>
                        </div>
                        <div className="col-2">
                            <FiXCircle className="pointer float-right " size="2rem" onClick={CallClose} style={{ color: "lightgray" }} />
                        </div>
                    </div>


                    <div className="row gx-0 mx-0 ms-700">
                        <div className="col-lg-12 col-3 py-2 my-2">
                            <label class="switch">
                                <input type="checkbox" onChange={(e) => { setplanData({ ...planData, is_active: e.target.checked }) }} />
                                <span class="slider round"></span>
                            </label>
                            <span className="pl-2 " >{planData.is_active ? 'Active' : 'Inactive'}</span>
                        </div>
                    </div>

                    <div className="row gx-0 mx-0 mb-3 ">
                        <div className="col-lg-12 col-3 mb-0 add-course">
                            <select className="add-course-select ms-700 fs-14" placeholder="Plan type" name="" id="" onChange={(e) => { setplanData({ ...planData, plan_types: e.target.value }) }} >
                                <option value="">Select commitment level</option>
                                <option value="Month">Monthly</option>
                                <option value="Quarter">Quarterly</option>
                                <option value="Semester">Half yearly</option>
                                <option value="Annual">Annually</option>
                            </select>
                        </div>

                    </div>
                    <div className="row gx-0 mx-0 ">
                        <div className="col-lg-12 col-3 add-course">
                            <select className="add-course-select ms-700 fs-14" placeholder="Minutes" name="" id="" onChange={(e) => { setplanData({ ...planData, minutes: e.target.value }) }}>
                                <option value="">Select minutes per day</option>
                                <option value="15">15 minutes</option>
                                <option value="30">30 minutes</option>
                                <option value="60">60 minutes</option>

                            </select>
                        </div>
                    </div>
                    <div className="row  gx-0 mx-0 edit-profile">
                        <div className="col-lg-12 edit-input ms-700 fs-14">
                            <input placeholder="Plan price per month" type="number" style={{ border: "1px solid lightgray" }} onChange={(e) => { setplanData({ ...planData, price: e.target.value }) }} required />
                        </div>
                        <div className="col-lg-12 edit-input ms-700 fs-14">
                            <input placeholder="days per week" type="number" style={{ border: "1px solid lightgray" }} onChange={(e) => { setplanData({ ...planData, week: e.target.value }) }} required />
                        </div>
                    </div>

                    <div className="row  gx-0 mx-0 py-2 my-2">
                        <div className="col-lg-12">
                            <button className="btn btn-update px-2 py-2 f-fred  btn-sm col-xs-2" onClick={addPlan}>ADD COMMITMENT PLAN</button>
                        </div>
                    </div>


                </div>
            </div>
            {
                pageload && <Backdrop />
            }

        </>
    )
}
