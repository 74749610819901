import axios from "axios";
import helper from "../helper/index";
import { constant } from "../config/Constant";
const URL = constant.ADMIN_URL
const BASE_URL = constant.BASE_URL

const supportVdo = async (data) => {

    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'put',
            url: `${URL}/edit-support-videos`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}

const supportArticle = async (data) => {

    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'put',
            url: `${URL}/edit-support-articles`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}

const getTutorArticle = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/support-article-list?id=${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err
    }
}
const getTutorVdo = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/support-videos-list?id=${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err
    }
}

const getAdminProfile = async ({ id }) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/profile/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res

    } catch (err) {
        return err

    }
}


const getCourse = async () => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/course/course-list`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res

    } catch (err) {
        return err

    }
}
const getComPlan = async (page,limit,query) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/commitment-plans-list?search=${query}&page=${page}&limit=${limit}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}
const getRefMin = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/referral-minutes-list?id=0`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}
const getCouponList = async (page,limit,query) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/coupon-list?search=${query}&page=${page}&limit=${limit}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}
const getCouponByCode = async (page,limit,code) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/coupon-code-details-list-by-id?page=${page}&limit=${limit}&coupon_code=${code}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}
const getStats = async () => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/find-stats-analytics`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}
const getCatList = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/course-categories-list`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}
const addCoupon = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios.post(`${URL}/add-coupon`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        return error
    }
}
const addRefMin = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios.post(`${URL}/referral-minutes`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        return error
    }
}

const addPlan = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios.post(`${URL}/commitment-plans`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        return error
    }
}
const getStdntPro = async (page, limit, name) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/users-list?search=${name}&page=${page}&limit=${limit}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}

export const getUnverifiedStdnt = async (page, limit, name) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/non-verified/user-list?search=${name}&page=${page}&limit=${limit}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}

const addCourseCat = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios.post(`${URL}/add-course-categories`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        return error
    }
}

const editComPlan = async (data, id) => {
    try {
        const token = helper.getToken()
        const res = await axios.put(`${URL}/edit-commitment-plans`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err
    }
}

const editRefMin = async (data, id) => {
    try {
        const token = helper.getToken()
        const res = await axios.put(`${URL}/edit-referral-minutes`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err
    }
}

const addRefund = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios.post(`${URL}/refund-policy`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}
const deleteCourseCategory = async (data) => {

    try {
        
        const token = helper.getToken()
       
        const res = await axios({
            method: "delete", url: `${URL}/remove-course-categories`, data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        )
        return res
    }
    catch (err) {
        return err
    }
}

const editCourseCat = async (data, id) => {
    try {
        const token = helper.getToken()
        const res = await axios.put(`${URL}/edit-course-categories`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err
    }
}

const getKB = async () => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/knowledgebase-categories-list`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}
const addKB = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios.post(`${URL}/add-knowledgebase-categories`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}
const editKB = async (data, id) => {
    try {
        const token = helper.getToken()
        const res = await axios.put(`${URL}/edit-knowledgebase-categories`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}
const deleteKB = async (data) => {

    try {
        const token = helper.getToken()
        const res = await axios({
            method: "delete", url: `${URL}/remove-knowledgebase-categories`, data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err
    }
}

const updateAdminProfile = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios.put(`${BASE_URL}/student/profile`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}
const editProImg = async (data, id) => {
    try {
        const token = helper.getToken()
        const res = await axios.put(`${BASE_URL}/student/profile-picture`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}

const getFAQById = async () => {
    try {
        const token = helper.getToken()
        // const res = await axios.get(`${URL}/find-support-articles?id=${id}`, {
        const res = await axios.get(`${BASE_URL}/tutor/faq-category`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res

    } catch (err) {
        return err
    }
}
const addFAQ = async (data) => {
    try {
        const token = helper.getToken()

        const res = await axios.post(`${URL}/add-faq-category`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res

    } catch (err) {
        return err
    }
}

const editFAQById = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios.put(`${URL}/edit-faq-categories`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}

const delFAQById = async (data) => {
 
    try {
        const token = helper.getToken()
        const res = await axios({
            method: "delete", url: `${URL}/remove-faq-categories`, data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err
    }
}
const delArticle = async (data) => {
   
    try {
        const token = helper.getToken()
        const res = await axios({
            method: "delete", url: `${URL}/remove-support-article`, data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err
    }
}
const getPolicyPages = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/policy-pages`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res

    } catch (err) {
        return err
    }
}

const editRefundPolicy = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios.put(`${URL}/refund-policy`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}
const editDisclaimer = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios.put(`${URL}/disclaimer`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}
const editPrivacyPolicy = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios.put(`${URL}/privacy-policy`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}
const editTermCond = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios.put(`${URL}/terms-conditions`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}
const deleteArticle = async (data) => {

    try {
       
        const token = helper.getToken()
        const res = await axios({
            method: "delete", url: `${URL}/remove-support-article`, data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        )
        return res
    }
    catch (err) {
        return err
    }
}
const deleteSupportVdo = async (data) => {

    try {
      
        const token = helper.getToken()
        const res = await axios({
            method: "delete", url: `${URL}/remove-support-videos`, data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        )
        return res
    }
    catch (err) {
        return err
    }
}
const editSupportArticle = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'put',
            url: `${URL}/edit-support-articles`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}

const addCourse = async (data) => {

    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'post',
            url: `${BASE_URL}/course/add`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}


const updateCourse = async (data) => {

    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'put',
            url: `${BASE_URL}/admin/course/course-details`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}

const getRate = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/rate-per-minutes?tutor_id=${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res

    } catch (err) {
        return err
    }
}

const editRate = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios.put(`${URL}/edit-rate-per-minute`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res

    } catch (err) {
        return err
    }
}

const getTutorList = async (page, limit, name) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/tutor-list?name=${name}&page=${page}&limit=${limit}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res

    } catch (err) {
        return err
    }
}

const getTutorDetail = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/tutor-details-by-Id?id=${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res

    } catch (err) {
        return err
    }
}

export const getUnVerifiedTutorList = async (page, limit, name) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/non-verified/tutor-list?name=${name}&page=${page}&limit=${limit}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res

    } catch (err) {
        return err
    }
}

const getTutorAnalytics = (tutor_id, startDate, endDate) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/tutor/find-stats-and-analytics?tutor_id=${tutor_id}&start_date=${startDate}&end_date=${endDate}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const approveTutor = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios.post(`${URL}/approve-tutor-profile`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}

const getPayoutSum = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/tutors-payout-list?page=1&limit=10`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res

    } catch (err) {
        return err
    }
}
const getUserSupportTicket = async (qry, page, limit) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/user-support-ticket-list?search=${qry}&page=${page}&limit=${limit}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res

    } catch (err) {
        return err
    }
}
const getTutorSupportTicket = async (qry, page, limit) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/tutor-support-ticket-list?search=${qry}&page=${page}&limit=${limit}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res

    } catch (err) {
        return err
    }
}
const getUserList = async (qry) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/manager-list?search=${qry}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}

const editUserDetail = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios.put(`${URL}/edit-user-details-by-Id`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res

    } catch (err) {
        return err
    }
}
const getUserSupTicDet = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/user-support-ticket-details-by-id?id=${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}
const getTutorSupTicDet = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/tutor-support-ticket-details-by-id?id=${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}

const getTicketDetail = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/support-ticket-details-by-id?id=${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}
const getEarning = async (startDate, endDate, page, limit) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/user-earning-list?start_date=${startDate}&end_date=${endDate}&page=${page}&limit=${limit}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}
const getEarningDet = async (userId) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/user-earning-details-by-id?user_id=${userId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}

const cancelSubscription = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios.post(`${URL}/cancel-user-subscription`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}

const getBillingSum = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/users-billing-summary?user_id=${id}&page=1&limit=10`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}
const getUserDetById = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/user-details-by-Id?user_id=${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}

const getNotification = async (page, limit, qry) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/notifications-list?title=${qry}&page=${page}&limit=${limit}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}

const getNotificationDeailById = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/notification-details-by-Id?id=${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}

const updateUTStatus = async (data, id) => {
    try {
        const token = helper.getToken()
        const res = await axios.put(`${URL}/edit-status-user-support-ticket`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err
    }
}

const updateTTStatus = async (data, id) => {
    try {
        const token = helper.getToken()
        const res = await axios.put(`${URL}/edit-status-tutor-support-ticket`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err
    }
}
const addSyllabus = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios.post(`${BASE_URL}/syllabus/add`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
        // const token = helper.getToken()
        // const res = await axios.post(`http://localhost:5000/v2/agora/multiple-file`, data, {
        //     headers: {
        //         'Authorization': `Bearer ${token}`
        //     }
        // })
        return res
    } catch (error) {
        return error
    }
}

const getCourseById = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${BASE_URL}/admin/course/find-course-detail-by-id?courseId=${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}

const getRatingReview = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${BASE_URL}/student/testimonial-details?studentId=${id}&page=1&limit=5`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}

const deleteCourse = async (data) => {

    try {
       
        const token = helper.getToken()
        const res = await axios({
            method: "delete", url: `${BASE_URL}/admin/course-delete-by-id`, data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        )
        return res
    }
    catch (err) {
        return err
    }
}

const getWebContent = async () => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/website-content`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}

const editAboutUs = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios.put(`${URL}/edit-about-us`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err
    }
}
const rejectTutor = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios.post(`${BASE_URL}/reject-tutor-profile`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        return error
    }
}
const blockTutor = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios.post(`${URL}/block-tutor-profile`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        return error
    }
}


const getCouponCode = async () => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${URL}/generate-coupon-code`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (error) {
        return error
    }
}

const searchSupportVideo = async (qry) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${BASE_URL}/admin/find-support-videos?title=${qry}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res

    } catch (err) {
        return err
    }
}


const searchSupportArticle = async (qry) => {
    try {
        const token = helper.getToken()
        const res = await axios.get(`${BASE_URL}/admin/find-support-articles?title=${qry}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res

    } catch (err) {
        return err
    }
}


const markAsRead = async (id, notification_id) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `put`,
            url: `${BASE_URL}/admin/user/notification/mark-as-read`,
            data: { user_id: id, id: notification_id },
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res

    } catch (err) {
        return err
    }
}
export default {
    supportVdo, supportArticle, getTutorArticle, getAdminProfile, getCourse,
    getTutorVdo, getComPlan, getRefMin, getCouponList,getCouponByCode, getStats, getCatList, addCoupon, addRefMin, addPlan, getStdntPro,
    addCourseCat, editComPlan, editRefMin, addRefund, deleteCourseCategory, editCourseCat, getKB, addKB, editKB, deleteKB,
    updateAdminProfile, editProImg, getFAQById, editFAQById, delFAQById, delArticle, getPolicyPages, editRefundPolicy, editDisclaimer,
    editTermCond, editPrivacyPolicy, deleteArticle, deleteSupportVdo, editSupportArticle, addCourse, getRate, editRate, getTutorList,
    getTutorDetail,getTutorAnalytics, approveTutor, getPayoutSum, getUserSupportTicket, getTutorSupportTicket, getUserList, editUserDetail, getUserSupTicDet,
    getTutorSupTicDet, getEarning, getEarningDet, cancelSubscription, getBillingSum, getUserDetById, getNotification, getNotificationDeailById,
    updateUTStatus, updateTTStatus, addSyllabus, getCourseById, addFAQ, getRatingReview, deleteCourse, getWebContent, editAboutUs, rejectTutor,
    blockTutor, getCouponCode, searchSupportArticle, searchSupportVideo,markAsRead,getTicketDetail,updateCourse
}