import axios from "axios";
import helper from "../helper/index";
import { constant } from "../config/Constant";
const BASE_URL = constant.BASE_URL
const getAttendaceRecord = ({tid,startDate,endDate}) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/admin/tutor/attendance-history?tutor_id=${tid}&start_date=${startDate}&end_date=${endDate}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getFilteredTutor = ({rating,country,status,page,limit}) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/admin/filter-tutor-list?ratings=${rating}&country=${country}&status=${status}&page=${page}&limit=${limit}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const activeInactiveTutor = (data) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `post`,
            url: `${BASE_URL}/admin/block-tutor-profile`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

export const payoutListing = async (tid,startDate,endDate) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'get',
            url: `${BASE_URL}/admin/tutor/payout-list?tutor_id=${tid}&start_date=${startDate}&end_date=${endDate}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}

export const getChatUserListing = async (tid)=>{
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'get',
            url: `${BASE_URL}/admin/tutor/chat-student-list/${tid}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
        return res;
    }
    catch (err) {
        return err
    }   
}



export default {
    getAttendaceRecord,
    getFilteredTutor,
    activeInactiveTutor
}