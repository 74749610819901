import React, { useState } from 'react'
import '../../static/css/modal.css'
import { FiXCircle } from "react-icons/fi";
import { useEffect } from 'react';
import admin from '../../controller/admin';
import Backdrop from '../../component/Backdrop';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import helper from '../../helper';

export default function NotificationDetail({ CallClose, id }) {
  const [detail, setdetail] = useState()
  const [pageLoad, setpageLoad] = useState(true)
  useEffect(() => {
    apiCall()
    return () => { }
  }, [])

  const apiCall = async () => {
    await admin.getNotificationDeailById(id)
      .then((res) => {
        if (res.data.status) {
          setdetail(res.data.result)
        }
        setpageLoad(false)
      })
  }

  return (

    <>
      {!pageLoad && <>
        <div className=" all-center popup-modal">
          <div className="right-popup notify-detail p-fix tp-0 rt-0 sm">
            <div className="row gx-0 mx-0 px-3">
              <div className="col-10 px-1">
                <p className="f-fred fs-24">Notification <span style={{ color: "#089D12" }}> details</span></p>
                <p className="sub-heading" style={{ color: "#434943" }}>{moment().format('ddd, DD MMM YYYY hh:mm A')}</p>
              </div>
              <div className="col-2 px-1">
                <FiXCircle className="pointer float-right " size="2rem" onClick={() => CallClose()} style={{ color: "lightgray" }} />
              </div>
            </div>

            <div className="row gx-0 mx-0 mt-3 px-3">
              <div className="col-lg-12 px-1">
                <p className="f-fred fs-18" style={{ color: "#434943" }}>{detail && detail.type}</p>
                <p className="ms-500 my-3  text-justify" style={{ color: "#434943" }}>{detail && detail.description}</p>

                {detail && detail.type === "Create ticket" && <NavLink to={`/${helper.getRole()}/dashboard/user-support/${detail.type_id}`} className={"link"}>View</NavLink>}
              </div>
            </div>

          </div>
        </div>
      </>}
      {pageLoad && <Backdrop />}



    </>

  )
}


