import React, { useState, useEffect } from 'react'
import store from '../../redux/AppState'
import admin from '../../controller/admin';
import Backdrop from '../../component/Backdrop';
import { useHistory, useParams } from 'react-router-dom';
import ProfileIcon from '../../components/ProfileIcon';
import EarningDetailCard from './EarningDetailCard';
import { FaBell } from 'react-icons/fa';
import helper from '../../helper';
export default function EarningDetail() {
    const history = useHistory()
    const { userId } = useParams()
    const [detail, setdetail] = useState([])
    const [pageLoad, setpageLoad] = useState(true)

    useEffect(() => {
        apiCall()
        return () => { }
    }, [])

    const apiCall = async () => {
        await admin.getEarningDet(userId)
            .then((res) => {
                if (res.data.status) {
                    setdetail(res.data.result)

                }

                setpageLoad(false)
            })
    }
    return (
        <>
            <div className="mx-1">
                <header className='row gx-0 mx-0 mb-3 py-1'>
                    <div className="col-lg-4 col-md-5 col-6 px-1 d-flex align-items-center ">
                        <button className="btn btn-back px-2 py-1 ms-700 mr-2 fs-14" onClick={() => history.goBack()}>BACK</button>
                        <div>
                            <p className="mb-1 fs-24 f-fred"> <i class="fas fa-bars mr-1 fs-20 pointer d-lg-none  d-md-none d-inline-block" onClick={() => store.setMenu(true)}></i> Earning <span className="tx-green">sales</span></p>
                            <p className="lh-15 sub-heading">SayDhad earning/sales</p>

                        </div>
                    </div>
                    <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                        <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                            <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                        </p>
                        <ProfileIcon />
                    </div>
                </header>

                <div className="mx-1">
                    {!pageLoad && <>
                        {detail.map((data, index) =>
                            <EarningDetailCard
                                key={`earning${index}`}
                                pic={data.profilePicture}
                                name={data.name}
                                country={data.country}
                                amount={data.amount}
                                cycle={data.commitment_level}
                                date={data.created_at}
                                subscription={data.subscription}
                                week={data.days_per_week}
                                min={data.minutes_per_day}
                                onClick={() => history.push(`/${helper.getRole()}/dashboard/student/${data.user_id}`)}

                            />
                        )}


                    </>}

                    {pageLoad && <Backdrop />}
                </div>


            </div>
        </>
    )
}
