import React, { useState } from 'react'
import { FiXCircle } from "react-icons/fi";
import admin from '../../../controller/admin';
import helper from '../../../helper';
import { toast } from 'react-hot-toast';
import Backdrop from '../../../component/Backdrop';
import TextEditor from '../../../components/TextEditor';

export default function EditFaq({ CallClose, data, id, OnEdit }) {
    const [editData, seteditData] = useState({ ques: data.question, ans: data.answer, arabic_que: data.arabic_question, arabic_ans: data.arabic_answer })
    const [pageload, setPageload] = useState(false)
    const EditData = async () => {
        const reqData = {
            "question": editData.ques,
            "answer": editData.ans,
            "adminId": helper.getid(),
            arabic_answer: editData.arabic_ans,
            arabic_question: editData.arabic_que,
            "id": id,
            role: data.role
        }
        if (!reqData.question) {
            toast("Please put question")
            return
        }
        if (!reqData.answer) {
            toast("Please put answer")
            return
        }
        else if (!reqData.arabic_question) {
            toast("Please enter arabic question")
            return
        }
        else if (!reqData.arabic_answer) {
            toast("Please enter arabic answer")
            return
        }

        setPageload(true)
        await admin.editFAQById(reqData, id)
            .then((res) => {
                if (res.data.status) {
                    toast("Successfully updated")
                    setTimeout(() => {
                        CallClose()
                        OnEdit()
                    }, 1000)
                } else {
                    toast(res.data.error)
                }
                setPageload(false)

            })
    }

    const DeleteData = async () => {
        await admin.delFAQById({ adminId: helper.getid(), id: id })
            .then((res) => {
                if (res.data.status) {
                    toast("Successfully deleted")
                    setTimeout(() => {
                        CallClose()
                        OnEdit()
                    }, 1000)
                } else {
                    toast(res.data.error)
                }

            })
    }

    return (
        <>
            <div className="  all-center popup-modal">
                <div className="right-popup notify-detail p-fix tp-0 rt-0  px-3 py-3 sm" style={{ width: '60%' }}>
                    <div className="row gx-0 mx-0">
                        <div className="col-10">
                            <p className="f-fred" style={{ fontSize: "24px" }}>Edit<span style={{ color: "#089D12" }}> FAQ</span></p>
                            <p className="sub-heading" style={{ color: "#434943" }}>SayDhad course category</p>
                        </div>
                        <div className="col-2">
                            <FiXCircle className="pointer float-right " size="2rem" onClick={CallClose} style={{ color: "lightgray" }} />
                        </div>
                    </div>

                    <div className="row gx-0 mx-0 edit-profile f-fred fs-14 mt-3">
                        <div className="col-lg-12 edit-input">
                            <input placeholder="Frequently asked question here" type="text" style={{ border: "1px solid lightgray" }} defaultValue={data.question} onChange={(e) => { seteditData({ ...editData, ques: e.target.value }) }} />
                        </div>

                        <div className="col-lg-12">
                            <section className='my-3'>
                                {
                                    <TextEditor
                                        onChange={(data) => { seteditData({ ...editData, ans: data }) }}
                                        data={data.answer ? data.answer : '<p>Answer here (English)</p>'} />
                                }
                            </section>
                        </div>

                        <div className="col-lg-12 edit-input">
                            <input placeholder="Frequently asked question here" type="text" style={{ border: "1px solid lightgray" }} defaultValue={data.arabic_question} onChange={(e) => { seteditData({ ...editData, arabic_que: e.target.value }) }} />
                        </div>

                        <div className="col-lg-12">
                            <section className='my-3'>
                                {
                                    <TextEditor
                                        onChange={(data) => { seteditData({ ...editData, arabic_ans: data }) }}
                                        data={data.arabic_answer ? data.arabic_answer : '<p>Answer here (English)</p>'} />
                                }
                            </section>
                        </div>

                        {/* <div className="col-lg-12 edit-input mt-2">
                            <textarea placeholder="Frequently answer here " rows={'8'} className='f-fred fs-14 form-control' type="text" style={{ border: "1px solid lightgray" }} defaultValue={data.answer} onChange={(e) => { seteditData({ ...editData, ans: e.target.value }) }} />
                        </div> */}

                        <div className="col-lg-6 mb-2 col-12">
                            <button className="btn btn-update px-2 mr-1 py-2 w-100  btn-sm " onClick={EditData}>UPDATE FAQ</button>
                        </div>

                        <div className="col-lg-6 mb-2 col-12">
                            <button className="btn btn-clear px-2 py-2 w-100  btn-sm " onClick={DeleteData} >DELETE FAQ</button>
                        </div>


                    </div>
                </div>
            </div>
            {pageload && <Backdrop />}

        </>
    )
}
