import React, { useState, useEffect, useRef } from 'react'
import helper from '../../../helper';
import ProfileIcon from '../../../components/ProfileIcon';
import { useHistory } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { FaBell } from 'react-icons/fa';
import store from '../../../redux/AppState'
import { FaTrashAlt, FaEdit } from "react-icons/fa";
import Backdrop from '../../../component/Backdrop';
import AddDialects from './AddDialects';
import websitesetting from '../../../controller/websitesetting';
import searchIcon from '../../../static/images/search.svg'
export default function Dialects() {
  const history = useHistory()
  const query = useRef()
  const [load, setLoad] = useState(true)
  const [state, setState] = useState({
    addDialect: false,
    data: [],
    copyData: []
  })


  useEffect(() => {
    async function getDialect() {
      await websitesetting.getLanguage().then((res) => {
        if (res.data.status) {
          setState({ ...state, data: res.data.result.dialects, copyData: res.data.result.dialects })
        }
      })
      setLoad(false)
    }
    getDialect()
  }, [])


  const remove = async (id, index) => {
    setLoad(true)
    await websitesetting.removeDialect(id).then((res) => {
      if (res.data.status) {
        let copy = state.data
        copy.splice(index, 1)
        setState({ ...state, data: copy, copyData: copy })
      }
      setLoad(false)
    })
  }
  const search = () => {
    if (query.current.value) {
      let regEx = new RegExp(query.current.value, 'si')
      const res = state.data.filter((data) => {
        return (data.name.toString()).match(regEx)
      })
      setState({ ...state, copyData: res, data: state.data })
    }
    else {
      setState({ ...state, copyData: state.data, data: state.data })
    }
  }
  return (
    <div className="tutor-dialects mx-1">
      <Toaster
        position="top-center"
        reverseOrder={false}
        containerClassName="ms-700"
      />
      <header className='row gx-0 mx-0 mb-3 py-1'>
        <div className="col-lg-4 col-md-5 col-6 d-flex align-items-center px-1">
          <button className="btn btn-back px-2 py-1 fs-14 ms-700 mr-2" style={{ fontWeight: "900" }} onClick={() => history.goBack()}>BACK</button>
          <div>
            <p className="mb-1 fs-24 f-fred"> <i class="fas fa-bars mr-1 fs-20 pointer d-lg-none  d-md-none d-inline-block" onClick={() => store.setMenu(true)}></i>Tutor <span className="tx-green">Dialects</span></p>
            <p className="lh-15 sub-heading">About us</p>

          </div>
        </div>
        <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
          <button className="btn btn-update mx-2 d-lg-block d-md-block d-none" onClick={() => { setState({ ...state, addDialect: true }) }}>ADD Dialects</button>

          <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

            <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
          </p>
          <ProfileIcon />
        </div>
      </header>


      <form className='tutor-library' onSubmit={(e) => { e.preventDefault(); search() }}>
        <div className="row gx-0 mx-0">
          <div className="col-lg-3 col-md-4 col-12 px-1 mb-2">
            <div className='d-flex w-100 align-items-center'>
              <input type="search" ref={query} placeholder="Search dialects" className="w-100 pl-5 h-100 input-box px-2 py-2 fs-14 ms-700" />
              <img src={searchIcon} className="p-abs lt-0 pl-3" />
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6 px-1 mb-2">
            <button type='submit' className="btn btn-login w-100 h-100 wrap">Search</button>
          </div>

        </div>
      </form>

      <section>
        {
          state.copyData.map((data, index) =>
            <div key={`category${index}`} className="quote-card cur-pointer bg-smoke border-radius-1 py-2 my-2">
              <div className="row gx-0 mx-0">
                <div className="col-lg-9  px-4 my-1">
                  <p className="f-fred fs-14">{data.name}</p>
                  <p className="ms-500 fs-12">Name</p>
                </div>
                <div className="col-lg-3  my-1 d-flex justify-content-end align-items-center">
                  <FaTrashAlt className="pointer float-right tx-red mx-3" size={20} onClick={() => remove(data.id, index)} />

                </div>
              </div>
            </div>

          )
        }
        {state.data.length === 0 && <p className='text-center my-3 ms-700'>No Record Found</p>}
      </section>
      {/* popups */}

      {state.addDialect && <AddDialects
        onAdd={(data) => {
          let copy = state.data
          copy.push(data)
          setState({ ...state, data: copy, copyData: copy })
        }}
        onClose={() => setState({ ...state, addDialect: false })} />}

      {load && <Backdrop />}
    </div>
  )
}

