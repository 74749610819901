import React, { useState, useRef, useEffect } from 'react'
import store from '../../../redux/AppState'
import { useSelector } from 'react-redux'
import TextEditor from '../../../components/TextEditor'
import admin from '../../../controller/admin';
import { useParams } from 'react-router-dom';
import helper from '../../../helper/index';
import ProfileIcon from '../../../components/ProfileIcon';
import { useHistory } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';
import Backdrop from '../../../component/Backdrop';
import { FaBell } from 'react-icons/fa';
import { fileUploadToS3 } from '../../../helper/fileupload';
export default function EditArticle() {
    const { userId } = useParams()
    const history = useHistory()
    const [pageLoad, setPageLoad] = useState(true)
    const [resData, setResData] = useState()
    const [categoryList, setCategoryList] = useState([])
    const [editContent, setEditContent] = useState()

    const [Backpopup, setBackpopup] = useState(false);


    useEffect(() => { apiCall(); getCategory() }, [])

    const getCategory = async () => {
        await admin.getKB().then((res) => {
            if (res.data.status) {
                setCategoryList(res.data.result)

            }
        })

    }

    const apiCall = async () => {
        await admin.getTutorArticle(userId)
            .then((res) => {
                if (res.data.status) {
                    setEditContent(res.data.result[0].description)
                    setResData(res.data.result[0])
                    setPageLoad(false)
                }
            })


    }

    const title = useRef()
    const category = useRef()

    const [selectedImage, setSelectedImage] = useState(null);

    const submit = async () => {
        const formData = new FormData()

        formData.append('title', title.current.value)
        formData.append('category', category.current.value)
        formData.append('description', editContent)
        // formData.append('image_file', selectedImage)
        formData.append('admin_id', helper.getid())
        formData.append('id', userId)


        if (!formData.get('title')) {
            toast("please enter article title")
            return
        }
        if (!formData.get('category')) {
            toast("please select category")
            return
        }
        if (selectedImage && ((selectedImage.size / 1024) / 1024) > 5) {
            toast("Image size should not be greater than 5 MB")
            return
        }

        setPageLoad(true)
        if (selectedImage) {
            let fileURL = await fileUploadToS3("support_articles/", selectedImage)
            formData.append('image_file', fileURL)
        }
        await admin.editSupportArticle(formData)
            .then((res) => {
                if (res.data.status) {
                    toast('successfully updated')


                }
                else {
                    toast(res.data.error)

                }
                setPageLoad(false);

            })


    }



    return (
        <>
            <div className="mx-1">
                {!Backpopup && <>
                    <Toaster
                        position="top-center"
                        reverseOrder={false}
                        containerClassName="ms-700"
                    />
                    <header className='row gx-0 mx-0 mb-3 py-1'>
                        <div className="col-lg-4 col-md-5 col-6 d-flex align-items-center px-1">
                            <button className="btn btn-back  mr-2" style={{ fontWeight: "900" }} onClick={() => history.goBack()}>BACK</button>
                            <div>
                                <p className="mb-1 fs-24 f-fred"> <i class="fas fa-bars mr-1 fs-24 pointer d-lg-none  d-md-none d-inline-block" onClick={() => store.setMenu(true)}></i>Edit <span className="tx-green">article</span></p>
                                <p className="fs-14 ms-700 lh-15 sub-heading">Support</p>

                            </div>
                        </div>
                        <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                            <button className="btn btn-update mx-2 d-lg-block d-md-block d-none" onClick={submit}>PUBLISH</button>
                            <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                                <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                            </p>    <ProfileIcon />
                        </div>
                    </header>

                    <div className="mx-1">
                        {!pageLoad && <div className="row  gx-0 mx-0  ">
                            <div className="col-lg-8 px-1 py-2 ">
                                <div className="row gx-0 mx-0  ">
                                    <div className='col-lg-12 px-0 fs-14 edit-profile f-fred pb-4 '>
                                        <div className="edit-input">
                                            <input type="text" placeholder="Enter article title" defaultValue={resData.title} style={{ border: "1px solid #EAEAEA" }} ref={title} required />
                                        </div>
                                    </div>
                                    <section className='m-0'>
                                        <TextEditor data={resData.description} onChange={(data) => { setEditContent(data) }} />
                                    </section>
                                </div>
                            </div>

                            <div className="col-lg-4 px-2 ">
                                <div className="quote-card cur-pointer bg-smoke border-radius-1 py-4 my-4 px-4  filter-cb">
                                    <p className="f-fred mb-3 fs-24" >Select<span style={{ color: "#089D12" }}> category</span></p>
                                    <select className='ms-700 py-2 px-2' ref={category} defaultValue={resData.category} style={{ width: "100%", borderRadius: "5px", border: "1px solid #EAEAEA" }}>
                                        {categoryList.map((data) => <option value={data.category_name}>{data.category_name}</option>)}
                                    </select>
                                </div>

                                <div className="quote-card cur-pointer bg-smoke border-radius-1 py-4 my-2 px-4  filter-cb">
                                    <p className="f-fred" style={{ fontSize: "24px" }}>Featured<span style={{ color: "#089D12" }}> image</span></p>

                                    {selectedImage ? (
                                        <div>
                                            <img className="my-2" alt="not found" accept=".png, .gif, .jpeg" style={{ maxWidth: '100%' }} height={"160px"} src={URL.createObjectURL(selectedImage)} required />
                                            <br />
                                            <div className="my-2">
                                                <button className="btn btn-dark px-1 py-1 ms-900 mr-1 fs-14" onClick={() => setSelectedImage(null)}>Replace image</button>

                                            </div>
                                        </div>
                                    ) : <div>
                                        <img className="my-2" alt="not found" accept=".png, .gif, .jpeg" style={{ maxWidth: '100%' }} height={"160px"} src={helper.replaceS3Url(resData.featured_image_link)} required />
                                        <br />
                                        <div className="my-2">
                                            <button className="btn btn-dark px-1 py-1 ms-900 mr-1 fs-14" onClick={() => setSelectedImage(null)}>Replace image</button>

                                        </div>
                                    </div>
                                    }

                                    <input
                                        type="file" name="myImage" onChange={(event) => {
                                            setSelectedImage(event.target.files[0]);
                                        }}
                                    />
                                    <p className="py-2" style={{ fontSize: "14px", color: "#434943", fontWeight: "700", lineHeight: "12px" }}>Width: 400px; Height: 160px min</p>
                                </div>
                            </div>

                        </div>}


                    </div>
                </>}
                {pageLoad && <Backdrop />}
            </div>

        </>
    )
}
