import React,{useState} from 'react'
import ReactApexChart from 'react-apexcharts'
export default function ApexChart({month,amount,year}) {
    const [chartData, setchartData] = useState(
       {
          
            series: [{
              name: 'earning/month',
              data: amount
            }],
            options: {
              chart: {
                height: 250,
                type: 'bar'
              },
              dataLabels: {
                enabled: false
              },
              stroke: {
                curve: 'smooth'
              },
              yaxis: {
                title: {
                  text: 'Price(in SAR)'
                },
              },
              xaxis: {
              title:{
                // text:year
              },
                categories: month
              },
              tooltip: {
                x: {
                  format: 'dd/MM/yy HH:mm'
                },
              },
            },
          }  
    )
    return (
        <div >
             <ReactApexChart options={chartData.options} series={chartData.series} type="area" height={250} />
        </div>
    )
}
