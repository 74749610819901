import React, { useState, useEffect, useRef } from 'react'
import store from '../../redux/AppState'
import admin, { getUnVerifiedTutorList } from '../../controller/admin';
import Backdrop from '../../component/Backdrop';
import { useHistory } from 'react-router-dom';
import ProfileIcon from '../../components/ProfileIcon';
import TutorCard from './TutorCard';
import moment from 'moment';
import Filter from './Filter';
import tutor from '../../controller/tutor';
import { useSelector } from 'react-redux'
import helper from '../../helper';
import { FaBell } from 'react-icons/fa'
import searchIcon from '../../static/images/search.svg'
import ReactPaginate from 'react-paginate';
import IncompleteRegisterCard from './IncompleteRegisterCard';
export default function IncompleteRegistration() {
    const storeData = useSelector(state => state)
    const history = useHistory()
    const [pageLoad, setpageLoad] = useState(true)
    const [pageNo, setPageNo] = useState(1)
    const query = useRef()
    const [queryType, setQueryType] = useState('')
    const [state, setState] = useState({ list: [], total: 0 })

    useEffect(() => {
        apiCall(pageNo)
        return () => { }
    }, [])

    const apiCall = async (page) => {
        setpageLoad(true)
        await getUnVerifiedTutorList(page, 10, '')
            .then((res) => {
                if (res.data.status) {
                    setState({ ...state, list: res.data.result.tutor_list, total: res.data.result.total_tutor })
                }
                setpageLoad(false)
            })
    }

    const searchTutor = async (page) => {
        setQueryType('search')
        if (!query.current.value) {
            apiCall(1)
            setQueryType('')
            return
        }
        setpageLoad(true)
        await admin.getUnVerifiedTutorList(page, 10, query.current.value)
            .then((res) => {
                if (res.data.status) {
                    setState({ ...state, list: res.data.result.tutor_list, total: res.data.result.total_tutor })
                }
                setpageLoad(false)
            })
    }


    return (
        <>
            <div className='mx-1'>
                <header className='row gx-0 mx-0 py-1 mb-3'>
                    <div className="col-lg-4 col-md-5 col-6 d-flex flex-column justify-content-center px-1 ">
                        <p className="mb-1 fs-24 f-fred">
                            <span>
                                <i className="fas fa-bars mr-2 fs-20 hover-shadow pointer d-lg-inline-block d-md-inline-block d-none" onClick={() => store.setShowLeftBar(!storeData.showLeftBar)}></i>
                            </span>
                            <i class="fas fa-bars mr-1 fs-20 pointer d-lg-none  d-md-none d-inline-block px-1" onClick={() => store.setMenu(true)}></i> SayDhad <span className="tx-green">tutors</span></p>
                        <p className="fs-14 ms-700 lh-15 sub-heading ml-2">{moment(new Date).format('ddd, DD MMM YYYY hh:mm A')}</p>
                    </div>
                    <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center">
                                <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >
                            <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                        </p>
                        <ProfileIcon />
                    </div>
                </header>

                <div className="tutor-library">
                    <form onSubmit={(e) => { e.preventDefault(); searchTutor(1); setPageNo(1) }}>
                        <div className="row gx-0 mx-0">
                            <div className="col-lg-3 col-md-4 col-12 px-1 mb-2">
                                <div className='d-flex w-100 align-items-center'>
                                    <input type="search" ref={query} placeholder="Search tutor..." className="w-100 pl-5 h-100 input-box px-2 py-2 fs-14 ms-700" />
                                    <img src={searchIcon} className="p-abs lt-0 pl-3" />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-6 px-1 mb-2">
                                <button type='submit' className="btn btn-login w-100 h-100 wrap">Search</button>
                            </div>
                           
                        </div>
                    </form>
                    <div className="grid-view">
                        <div className="grid-card">
                            {
                                state.list.map((tut) => <div key={tut.id} className="col-lg-12 col-md-12 col-12 px-1">
                                    <IncompleteRegisterCard
                                        tut={tut}
                                        onClick={(id) => history.push(`/${helper.getRole()}/dashboard/tutor/${id}`)}
                                    />
                                </div>
                                )}
                            {state.list.length === 0 && <p className='my-3 text-center'>No Record Found</p>}

                        </div>
                    </div>
                </div>

                {/* Pagination  */}
                {state.total !== 0 &&
                    <div className="col-12 px-2 c-pagination">
                        <ReactPaginate
                            previousLabel={<i class="fas fa-arrow-left"></i>}
                            nextLabel={<i class="fas fa-arrow-right"></i>}
                            pageCount={Math.ceil(state.total / 10)}
                            onPageChange={({ selected }) => {
                                if (queryType === 'search') {
                                    searchTutor(selected + 1)
                                }
                                else {
                                    apiCall(selected + 1);
                                }
                                setPageNo(selected + 1)
                            }}
                            containerClassName={"paginationBttns"}
                            previousClassName={"previousBttn"}
                            nextClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                            pageClassName={"eachpage"}
                        />
                    </div>
                }

                
                {pageLoad && <Backdrop />}
            </div>

        </>
    )
}
