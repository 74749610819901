import React, { useEffect, useState, useRef } from 'react'
import store from '../../../redux/AppState'
import admin from '../../../controller/admin';
import Backdrop from '../../../component/Backdrop';
import ProfileIcon from '../../../components/ProfileIcon';
import { useHistory } from 'react-router-dom';
import ArticleCard from '../../../components/ArticleCard';
import ArticleDetail from './ArticleDetail';
import { FaBell } from 'react-icons/fa';
import helper from '../../../helper';
export default function StudentArticles() {
    const history = useHistory()
    const [categoryList, setcategoryList] = useState([])
    const qry = useRef()
    const [pageLoad, setpageLoad] = useState(true);
    const [openDetail, setopenDetail] = useState({ open: false, data: '', id: 0, category: '' })
    const [resData, setresData] = useState([])

    useEffect(() => {

        apiCall()

        return () => {

        }
    }, [])

    const apiCall = async () => {

        await admin.getTutorArticle()
            .then((res) => {

                if (res.data.status) {
                    let filtered = []
                    res.data.result.forEach((data) => {
                        if (data.role == 2) {
                            filtered.push(data)
                        }
                    })
                    const list = []

                    for (let i = 0; i < filtered.length; i++) {
                        if (!list.includes(filtered[i].category)) {
                            list.push(filtered[i].category)
                        }
                    }


                    setcategoryList(list)
                    setresData(filtered)
                    setpageLoad(false)


                }

            })


    }

    const search = async () => {

        await admin.searchSupportArticle(qry.current.value)
            .then((res) => {

                if (res.data.status) {
                    let filtered = []
                    res.data.result.forEach((data) => {
                        if (data.role == 2) {
                            filtered.push(data)
                        }
                    })
                    const list = []

                    for (let i = 0; i < filtered.length; i++) {
                        if (!list.includes(filtered[i].category)) {
                            list.push(filtered[i].category)
                        }
                    }


                    setcategoryList(list)
                    setresData(filtered)
                    setpageLoad(false)


                }

            })


    }

    return (
        <>

            <div className="mx-1">

                {!openDetail.open && <>


                    <header className='row gx-0 mx-0 mb-3 py-1'>
                        <div className="col-lg-5 col-md-5 col-6 d-flex align-items-center px-1">
                            <button className="btn btn-back mr-2" style={{ fontWeight: "900" }} onClick={() => history.goBack()}>BACK</button>
                            <div>
                                <p className="mb-1 fs-24 f-fred"> <i class="fas fa-bars mr-1 fs-24 pointer d-lg-none  d-md-none d-inline-block" onClick={() => store.setMenu(true)}></i>Student <span className="tx-green">knowledgebase</span></p>
                                <p className="lh-15 sub-heading">SayDhad tutor support</p>

                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                            <button className="btn btn-edit fs-12 mx-2 d-lg-block d-md-block d-none" onClick={() => history.push(`/${helper.getRole()}/dashboard/add-user-support-article`)}>ADD NEW ARTICLE</button>
                            <button className="btn btn-update mx-2 d-lg-block d-md-block d-none" onClick={() => history.push(`/${helper.getRole()}/dashboard/support-category`)}>VIEW CATEGORIES</button>
                            <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                                <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                            </p>
                            <ProfileIcon />
                        </div>
                    </header>



                    <div className="mx-2">


                        <form onSubmit={(e) => { e.preventDefault(); search() }}>

                            <div className="row gx-0 mx-0 tutor-library">
                                <div className="col-lg-3 col-md-4 col-12 px-1 mb-2 input-icons">
                                    <div className='d-flex align-items-center'>
                                        <i className="fa fa-search ml-2" aria-hidden="true"></i>
                                        <input ref={qry} type="search" placeholder="Type category/id" className="ms-700 w-100 h-100 input-box px-2 pl-4 py-2 fs-14" />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4 col-6 px-1 mb-2">
                                    <button type="submit" className="btn btn-login w-100 h-100 fs-12 wrap f-fred">Search</button>
                                </div>
                            </div>
                        </form>


                        {!pageLoad && <>
                            {categoryList.map((cat, index) => {

                                return (
                                    <>
                                        <p className="plan my-2 text-capitalize">{cat}</p>
                                        <div className="row gx-0 mx-0">
                                            {resData.map((result, index) => {
                                                if (cat == result.category) {
                                                    return (

                                                        <div class="col-lg-3 col-md-4 col-12 card-carousel mb-3 px-1">
                                                            <ArticleCard
                                                                img={result.featured_image_link}
                                                                title={result.title}
                                                                onChange={() => setopenDetail({ ...openDetail, open: true, data: result, id: result.id, category: cat })} />

                                                        </div>

                                                    )
                                                }
                                            })}
                                        </div>
                                    </>
                                )
                            })}
                        </>}




                    </div>

                </>}


                {openDetail.open &&

                    <ArticleDetail
                        data={openDetail.data}
                        id={openDetail.id}
                        category={openDetail.category}
                        resData={resData}
                        CallClose={() => { setopenDetail({ ...openDetail, open: false }) }}
                    />}


                {pageLoad && <Backdrop />}
            </div>

        </>
    )
}
