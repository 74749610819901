import axios from "axios";
import helper from "../helper/index";
import { constant } from "../config/Constant";
const BASE_URL = constant.BASE_URL
const getFilteredStudent = ({ country, planType, page, limit }) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/admin/filter-users-list?country=${country}&commitment_level=${planType}&page=${page}&limit=${limit}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const getStudentWeeklyClasses = (id, date) => {
    try {

        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/admin/student/total-subscription-minutes-left?student_id=${id}&date=${date}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getEnrolledCourses = (id) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/admin/student/student-enrolled-course-list/${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getEnrolledCoursesDeatails = ({ cid, sid }) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/admin/student/course/find-course-detail-by-id?courseId=${cid}&user_id=${sid}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getEngagedTutor = ({ id, name }) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/admin/student/engaged-tutor-list?name=${name}&student_id=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}


const getRatings = (id, page, limit) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/student/testimonial-details?studentId=${id}&page=${page}&limit=${limit}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const activeInactiveStudent = (data) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `put`,
            url: `${BASE_URL}/admin/block-student-profile`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

export default {
    getFilteredStudent,
    getStudentWeeklyClasses,
    getEnrolledCourses,
    getEngagedTutor,
    getEnrolledCoursesDeatails,
    getRatings,
    activeInactiveStudent
}