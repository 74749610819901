import React, { useState ,useEffect } from 'react';
import FullStatPop from './FullStatPop';

export default function GlobalUserCard({ data, type }) {
    const [openStat, setOpenStat] = useState(false)
   
    const getCount = ()=>{
        let count = 0
        if(type === "tutor"){
            data.forEach((result)=>{
                count = count + result.totalTutors
            })
        }
        else{
            data.forEach((result)=>{
                count = count + result.totalStudents
            })
        }
    
        return count
    }
    return (
        <>
            <section className='global-tutor '>
                <div className="quote-card cur-pointer boxShadow bg-smoke border-radius-1 my-2">
                    {type === "tutor" ?
                        <>
                            <div className='mx-5 mb-2 br-2 ' style={{ height: '8px' ,backgroundColor:'#159D18'}}></div>
                            <div className="row gx-0 mx-0">
                                <div className="col-lg-12 ms-500 px-3 my-1 pb-2">
                                    <p  className="tx-lt-black" style={{ fontSize: "38px" }}>{getCount()}</p>
                                    <p className="tx-lt-black fs-14">Tutor's globality</p>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className='mx-5 mb-2 br-2' style={{ height: '8px',backgroundColor:'#FF5757' }}></div>
                            <div className="row gx-0 mx-0">
                                <div className="col-lg-12 ms-500 px-3 my-1 pb-2">
                                    <h2 className="tx-lt-black" style={{ fontSize: "38px" }}>{getCount()}</h2>
                                    <p className="tx-lt-black fs-14">Student globality</p>
                                </div>
                            </div>
                        </>
                    }

                    {data.slice(0,5).map((result, index) => {
                        return (
                            <section key={`user${index}`} className=''>
                                <div className='break-line my-2 mx-3'></div>
                                <div className="row  gx-0 mx-0 pb-2">
                                    <div className="col-lg-8 col-md-2 col-3 px-3 d-flex align-items-center">
                                        <span className='mr-2'>
                                            <img width="24px" height="16px" className="" src={result.flag_uri} alt="flag" /></span>
                                            <p className="ms-500 " style={{ fontSize: "14px" }}>{result.country}</p>
                                
                                    </div>
                                   
                                    <div className="col-4 px-3 d-flex align-items-center justify-content-end">
                                        <p className="ms-700 fs-14 text-right" style={{ fontSize: "12px" }}>{type === "tutor" ? result.totalTutors : result.totalStudents}</p>
                                    </div>
                                </div>
                            </section>
                        )
                    })}


                    <div className="col-12 px-3 my-1">
                    <div className='break-line my-2 mx-3'></div>
                        <p style={{color : `${type == "tutor" ? "#089D12" : "#FF5757"}`}} className={`mb-2 ms-900 fs-14 pointer text-center `} onClick={()=>setOpenStat(true)}>View full stats</p>
                    </div>
                </div>
                <FullStatPop
                    open={openStat}
                    data={data}
                    type={type}
                    close={()=>setOpenStat(false)}
                />
            </section>
        </>);
}
