import React, { useState, useEffect } from 'react'
import logo from '../static/images/Logo.svg'
import { useHistory } from 'react-router'
import helper from '../helper/index'
import auth from '../controller/auth'
import Backdrop from '../component/Backdrop'
import toast, { Toaster } from 'react-hot-toast'
export default function ManagerResetPassword() {

    const history = useHistory()
    const [password, setPassword] = useState({
        password: '',
        confirmPassword: ''
    })
    const [open, setOpen] = useState(false)
  
    useEffect(() => {
        const url = new URLSearchParams(window.location.search)
        if (!url.get('token')) {
            history.push('/')
        }
    }, [])
    const submit = async () => {
        const url = new URLSearchParams(window.location.search)
        const data = {
            password: password.password,
            token: url.get('token')
        }
        const res = helper.validate("password", password.password)
        if (!res.isValid) {
            toast(res.msg)
            return
        }
        if(password.password !== password.confirmPassword){
            toast("Password mismatch")
            return
        }
        setOpen(true)
        await auth.managerResetPassword(data)
            .then((res) => {
                if (res.data.status) {
                    toast(res.data.result)
                    setTimeout(() => {
                        history.push('/')
                    }, 1500)
                } else {
                    toast(res.data.error)
                }
                setOpen(false)
            })

    }

    return (
        <div className="user-little-more tutor-login-home all-center flex-column" >
            <div className="row gx-0 mx-0 bg-white br-1 p-4 box-shadow-grey ">
                <div className="col-lg-12 col-md-8 col-12 px-3 mx-auto all-center flex-column">
                    <div className="all-center mb-3">
                    <img width="220px" src={logo} alt="" />
                    </div>
                    <div className="f-fred mt-4" style={{ minWidth: '300px' }}>
                        <form onSubmit={(e) => { e.preventDefault(); submit() }}>
                            <h4 className="f-fred my-2">Reset <span className="tx-green">password</span></h4>

                            <div className="input-box mb-2 mt-3">
                                <input type="password" placeholder="new password" className="px-2 py-2 w-100 ms-700 " onChange={(e) => { setPassword({ ...password, password: e.target.value }); }} />
                            </div>
                            <div className="input-box mb-2 mt-3 mb-3">
                                <input type="password" placeholder="confirm password" className="px-2 py-2 w-100 ms-700 " onChange={(e) => { setPassword({ ...password, confirmPassword: e.target.value }); }} />
                            </div>

                                 <button type="submit" className="btn btn-login fs-12 mt-2 d-block  mx-auto w-100"  >PROCEED</button>

                        </form>
                    </div>

                </div>
            </div>
            {open && <Backdrop />}
        </div>
    )
}
