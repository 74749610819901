import React, { useState, useEffect } from 'react'
import admin from '../../controller/admin';
import Backdrop from '../../component/Backdrop';
import SyllabusCard from '../../component/SyllabusCard'
import helper from '../../helper/index'
import ProfileIcon from '../../components/ProfileIcon';
import { useHistory, useParams } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';
import ConfirmPopup from '../../components/ConfirmPopup';
import { FaBell } from 'react-icons/fa';
import ReadMore from '../../component/ReadMore';
export default function CourseDetails() {
    const history = useHistory()
    const { userId } = useParams()
    const [pageLoad, setpageLoad] = useState(true)
    const [detail, setdetail] = useState([])
    const [state, setState] = useState({
        openPop: false,
        msg: '',
        load: false
    })
    useEffect(() => {
        apiCall()
        return () => { }
    }, [])

    const apiCall = async () => {
        await admin.getCourseById(userId)
            .then((res) => {
                if (res.data.status) {
                    setdetail(res.data.result.length > 0 ? res.data.result[0].course : null)
                }
                console.log(res.data)
                setpageLoad(false)
            })
    }

    const updateCourse = async (isActive) => {
        await admin.deleteCourse({ adminId: helper.getid(), courseId: userId, isActive: isActive })
            .then((res) => {
                if (res.data.status) {
                    detail[0].isActive = isActive
                    const temp = [...detail]
                    setdetail(temp)
                } else {
                    toast.error(res.data.error)
                }
            })
    }


    return (
        <>

            <header className='row gx-0 mx-0 my-2'>
                <div className="col-lg-4 col-md-5 col-6 d-flex align-items-center pl-1">
                    <button className='btn btn-back mr-2' onClick={() => history.goBack()}>BACK</button>
                    <div>
                        <p className="mb-1 fs-24 f-fred"> <i class="fas fa-bars mr-1 fs-20 pointer d-lg-none  d-md-none d-inline-block"></i>Course <span className='tx-green'>details</span></p>
                        <p className="lh-15 sub-heading">{!pageLoad && detail[0].courseCategory}</p>
                    </div>
                </div>

                <div className="col-lg-8 col-md-7 col-6 pr-1 d-flex justify-content-end align-items-center">
                    {detail[0]?.isActive ? <button className="btn btn-remove mx-2 d-lg-block ms-700 fs-14 d-md-block d-none" onClick={() => updateCourse(false)}>INACTIVE COURSE</button>
                        : <button className="btn btn-tutor-profile mx-2 d-lg-block ms-700 fs-14 d-md-block d-none" onClick={() => updateCourse(true)}>ACTIVE COURSE</button>
                    }
                    <button className="btn btn-update mx-2 d-lg-block d-md-block d-none " onClick={() => { history.push(`/${helper.getRole()}/dashboard/edit-course/${userId}`) }}>EDIT COURSE</button>

                    <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                        <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                    </p>
                    <ProfileIcon />
                </div>
            </header>

            {!pageLoad && <>
                <div className="">
                    {detail.map((det) => {
                        return (<>
                            <div className="" key={det.id}>
                                <div className="row gx-0 mx-0 px-1 tx-lt-black">
                                    <div className="col-lg-4 pay" >
                                        <div className="row payout gx-0 mx-0">
                                            <div className="col-lg-6 px-1">
                                                <div style={{ order: "1" }}>
                                                    <p className="f-fred fs-18">Total enrolled</p>
                                                    <p className="ms-700 fs-14 sub-heading" >Students</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 px-1">
                                                <h2 className="ms-500 fs-40 text-right" style={{ color: "#035D0A" }}>{det.totEnrolledStudent}</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 pay " >
                                        <div className="row payout gx-0 mx-0">
                                            <div className="col-lg-6 px-1">
                                                <div style={{ order: "1" }}>
                                                    <p className="f-fred fs-18">Total</p>
                                                    <p className="ms-700 fs-14 sub-heading">Lessons</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 px-1">
                                                <h2 className="ms-500 fs-40 text-right" style={{ color: "#035D0A" }}>{det.totalLessons}</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 pay " >
                                        <div className="row payout gx-0 mx-0">
                                            <div className="col-lg-6 px-1">
                                                <div style={{ order: "1" }}>
                                                    <p className="f-fred fs-18" >Total</p>
                                                    <p className="ms-700 fs-14 sub-heading">Slides</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 px-1">
                                                <h2 className="ms-500 fs-40 text-right" style={{ color: "#035D0A" }}>{det.totalSlide}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row gx-0 mx-0 my-2">
                                    <div className="col-lg-12">
                                        <p className="f-fred text-capitalize" style={{ fontSize: "24px", color: "#434943" }}>Intermediate Conversation topics part 2</p>
                                        <p className="ms-500 fs-14" style={{ color: "#434943" }} >More practise expressing your ideas and opinions in English</p>
                                        <hr className='my-2' />
                                    </div>
                                </div>

                                <div className="row gx-0 mx-0">
                                    <div className="col-lg-2">
                                        <p className="ms-900 " style={{ fontSize: "14px" }}>Experimental level</p>
                                        <span className="boxes tx-lt-black mt-2 d-inline-block ms-500">{det.experienceLevel}</span>
                                    </div>
                                    <div className="col-lg-2">
                                        <p className="ms-900" style={{ fontSize: "14px" }} >Course length</p>
                                        <span className="boxes tx-lt-black mt-2 d-inline-block ms-500">{det.totalLessons} lessons</span>

                                    </div>
                                    <div className="col-lg-2">
                                        <p className="ms-900" style={{ fontSize: "14px" }}>Slides</p>
                                        <span className="boxes tx-lt-black mt-2 d-inline-block ms-500">{det.totalSlide} slides</span>

                                    </div>
                                </div>
                                <br />

                                <div className="row gx-0 mx-0 my-2">
                                    <div className="col-lg-12 f-fred">
                                        <p style={{ fontSize: "24px", color: "#C3C3C3" }}>Overview</p>
                                    </div>
                                </div>

                                <div className="row gx-0 ms-500 mx-0 hrtag">
                                    <div className="col-lg-4">
                                        <p className="ms-900 fs-16 text-capitalize">{det.title}</p>
                                        <ReadMore
                                            text={det.description}
                                            initialNoOfWords={30}
                                        />

                                    </div>

                                    <div className="col-lg-4 px-2">
                                        <p className="ms-900 fs-16 text-capitalize">{det.subTitle}</p>
                                        <div className="read-more-container" id="el">
                                            <div className="ms-500">
                                                <ReadMore
                                                    text={det.descriptionCourse}
                                                    initialNoOfWords={30}
                                                />

                                            </div>

                                        </div>



                                    </div>
                                    <hr className='my-2' />

                                </div>


                                <div className="row gx-0 mx-0 mt-3 mb-2">
                                    <div className="col-lg-12 f-fred">
                                        <p style={{ fontSize: "24px", color: "#C3C3C3" }}>Syllabus</p>
                                    </div>
                                </div>


                                <div className="row gx-0 mx-0">
                                    {det.syllabusList.map((syl, index) => {
                                        return (<>
                                            <div className="col-lg-6 col-md-6 col-12 pr-2 my-2">
                                                <SyllabusCard
                                                    name={syl.name}
                                                    slides={syl.noOfSlides}
                                                    lessonNo={index + 1}
                                                    file={syl.slideDetails}
                                                />
                                            </div>
                                        </>)
                                    })}
                                </div>

                            </div>
                        </>)
                    })}

                </div>
            </>}
            {/* <ConfirmPopup
                open={state.openPop}
                msg={state.msg}
                onClose={() => setState({ ...state, openPop: false })}
                onOk={() => {
                    deleteCourse();
                    setState({ ...state, openPop: false })
                }}
            /> */}
            {(pageLoad || state.load) && <Backdrop />}
        </>
    )
}
