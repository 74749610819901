import * as React from "react";

const SvgLogout = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <defs>
      <clipPath id="Logout_svg__a">
        <path
          data-name="Rectangle 439"
          transform="translate(9272 4573)"
          fill="#fff"
          stroke="#707070"
          d="M0 0h30v30H0z"
        />
      </clipPath>
    </defs>
    <g
      data-name="Mask Group 34"
      transform="translate(-9272 -4573)"
      clipPath="url(#Logout_svg__a)"
    >
      <g data-name="Iconly/Bold/Logout">
        <g data-name="Logout">
          <path
            data-name="Path 4389"
            d="M9285.964 4576a5.174 5.174 0 0 1 5.206 5.128v5.532h-7.051a.89.89 0 1 0 0 1.779h7.051v5.521a5.189 5.189 0 0 1-5.23 5.14h-5.723a5.176 5.176 0 0 1-5.217-5.129v-12.831a5.189 5.189 0 0 1 5.229-5.14h5.734Zm8.136 7.565a.882.882 0 0 1 1.259-.012l3.372 3.361a.882.882 0 0 1 .269.636.856.856 0 0 1-.266.624l-3.372 3.361a.892.892 0 0 1-1.262-1.26l1.848-1.836h-4.779v-1.779h4.781l-1.85-1.836a.892.892 0 0 1 0-1.259Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgLogout;
