import React, { useRef,useState } from 'react'
import { FiXCircle } from "react-icons/fi";
import { toast } from 'react-hot-toast';
import helper from '../../../helper';
import websitesetting from '../../../controller/websitesetting';
import Backdrop from '../../../component/Backdrop';
export default function AddDialects({ onClose, onAdd }) {
    const name = useRef()
    const [load, setLoad] = useState(false)
    const submit = () => {
        const req = {
            "name": name.current.value,
            "admin_id": helper.getid()
        }
        if (!req.name) {
            toast("Please enter dialect")
            return
        }
        setLoad(true)
        websitesetting.addDialect(req).then((res) => {
            if (res.data.status) {
                name.current.value = ''
                onAdd(res.data.result)
            } else {
                toast(res.data.error)
            }
            setLoad(false)
        })
    }
    return (
        <div className="all-center popup-modal">
            <div className="right-popup notify-detail p-fix tp-0 rt-0  sm" >
                <div className="row gx-0 mx-0">
                    <div className="col-10">
                        <p className="f-fred fs-24">Add <span style={{ color: "#089D12" }}> dialect</span></p>
                        <p className="sub-heading">Website management</p>
                    </div>
                    <div className="col-2">
                        <FiXCircle className="pointer float-right " size="2rem" onClick={onClose} style={{ color: "lightgray" }} />

                    </div>
                </div>
                <div className="row gx-0 mx-0 edit-profile f-fred">
                    <div className="col-12 edit-input">
                        <input type="text" placeholder='Dialects' className='text-capitalize' ref={name} style={{ border: "1px solid lightgray" }} />
                    </div>
                    <div className="col-12">
                        <button className="btn btn-update px-2 py-2  col-xs-2" onClick={submit}>Add Dialects</button>
                    </div>
                </div>
            </div>
            {load && <Backdrop />}
        </div>
    )
}
