import React, { useState, useRef } from 'react'
import admin from '../../controller/admin'
import { useEffect } from 'react'
import Backdrop from '../../component/Backdrop'
import moment from 'moment'
import ProfileIcon from '../../components/ProfileIcon';
import { useHistory } from 'react-router-dom';
import CategoryCard from './CategoryCard';
import EditCategory from './EditCategory';
import AddCategory from './AddCategory';
import { FaBell } from 'react-icons/fa'
import searchIcon from '../../static/images/search.svg'
import helper from '../../helper'
export default function CourseCategory() {
    const history = useHistory()
    const [addCourse, setaddCourse] = useState(false);
    const [resData, setresData] = useState([])
    const [searchData, setSearchData] = useState([])
    const qry = useRef()
    const [pageLoad, setpageLoad] = useState(true)
    const [EnCo, setEnCo] = useState({ open: false, id: 0, data: "" });
    const toggleEnCo = (idValue, data) => {
        setEnCo({ ...EnCo, open: !EnCo.open, id: idValue, data: data });
    }
    useEffect(() => {
        apiCall()
        return () => { }
    }, [])
    const apiCall = async () => {
        await admin.getCatList()
            .then((res) => {
                if (res.data.status) {
                    setSearchData(res.data.result)
                    setresData(res.data.result)
                }
                setpageLoad(false)
            })
    }

    const search = () => {
        if (qry.current.value) {
            let regEx = new RegExp(qry.current.value, 'si')
            const res = resData.filter((result) => {

                return result.category_name.match(regEx)
            })
            setSearchData(res)
        }
        else {
            setSearchData(resData)
        }
    }



    return (
        <>
            <div className="mx-1">
                <header className='row gx-0 mx-0 mb-3 py-1'>
                    <div className="col-lg-4 pl-1 col-md-5 col-6 d-flex align-items-center px-1">
                        <button className="btn btn-back  mr-2 fs-14" onClick={() => history.goBack()}>BACK</button>
                        <div>
                            <p className="mb-1 fs-20 f-fred"> <i class="fas fa-bars mr-1 fs-18 pointer d-lg-none  d-md-none d-inline-block"></i>SayDhad <span className='tx-green'>Courses category</span></p>
                            <p className="fs-14 ms-700 lh-15 sub-heading">{moment(new Date).format('ddd, DD MMM YYYY hh:mm A')}</p>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                        <button className="btn btn-update mx-2 d-lg-block d-md-block ms-700 d-none" onClick={() => setaddCourse(true)}>ADD NEW CATEGORY</button>
                        <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                            <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                        </p>
                        <ProfileIcon />
                    </div>
                </header>

                <div className="tutor-library mx-1">
                    <form onSubmit={(e) => { e.preventDefault(); search() }}>
                        <div className="row gx-0 mx-0">
                            <div className="col-lg-3 col-md-4 col-12 px-1 mb-2">
                                <div className='d-flex w-100 align-items-center'>
                                    <input type="search" ref={qry} placeholder="Search category..." className="w-100 pl-5 h-100 input-box px-2 py-2 fs-14 ms-700" />
                                    <img src={searchIcon} className="p-abs lt-0 pl-3" />
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-6 px-1 mb-2">
                                <button type='submit' className="btn btn-login w-100 h-100 wrap">search</button>
                            </div>
                        </div>
                    </form>

                    <section >
                        {!pageLoad && <>
                            {
                                searchData.map((cat) =>
                                    <CategoryCard
                                        id={cat.id}
                                        name={cat.category_name}
                                        date={cat.created_at}
                                        onEdit={() => toggleEnCo(cat.id, cat)}
                                    />
                                )}
                        </>}
                    </section>
                </div>
                {pageLoad && <Backdrop />}
                {addCourse &&
                    <AddCategory
                        CallClose={() => { setaddCourse(false) }}
                        OnAddCourseCat={() => { apiCall() }}
                    />}
                {EnCo.open &&
                    <EditCategory
                        data={EnCo.data}
                        id={EnCo.id}
                        CallClose={() => { toggleEnCo() }}
                        OnEditCat={() => { apiCall() }}
                    />}
            </div>
        </>
    )
}
