import React, { useState, useRef } from 'react'
import admin from '../../controller/admin';
import { useEffect } from 'react';
import Backdrop from '../../component/Backdrop';
import moment from 'moment';
import CouponCard from './CouponCard';
import AddNewCoupon from './AddNewCoupon';
import ProfileIcon from '../../components/ProfileIcon';
import store from '../../redux/AppState'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import searchIcon from '../../static/images/search.svg'
import { FaBell } from 'react-icons/fa';
import helper from '../../helper';
import ReactPaginate from 'react-paginate';
export default function CouponManager() {

    const history = useHistory()
    const storeData = useSelector(state => state)
    const qry = useRef()
    const [addCoupon, setaddCoupon] = useState(false);
    const toggleaddCoupon = () => {
        setaddCoupon(!addCoupon);
    }
    const [pageLoad, setpageLoad] = useState(true)

    const [state, setState] = useState({ list: [], pageNo: 1, limit: 10, total: 0 })


    useEffect(() => {
        apiCall(state.pageNo, state.limit); return () => { }
    }, [])

    const apiCall = async (page, limit) => {
        setpageLoad(true)
        await admin.getCouponList(page, limit,qry.current.value )
            .then((res) => {
                if (res.data.status) {
                    setState({ ...state, list: res.data.result.couponList, total: res.data.result.total_coupon })
                }
                setpageLoad(false)
            })
    }



    return (
        <>
            <div className="mx-1">
                <header className='row gx-0 mx-0 mb-3 py-1'>
                    <div className="col-lg-6 col-md-5 col-6 d-flex flex-column justify-content-center px-1">
                        <p className="mb-1 fs-24 f-fred">
                            <span>
                                <i className="fas fa-bars mr-2 fs-20 hover-shadow pointer d-lg-inline-block d-md-inline-block d-none" onClick={() => store.setShowLeftBar(!storeData.showLeftBar)}></i>
                            </span>
                            <i class="fas fa-bars mr-1 fs-20 pointer d-lg-none  d-md-none d-inline-block"></i>SayDhad coupon <span className='tx-green'>manager</span></p>
                        <p className="lh-15 sub-heading ml-2">{moment(new Date).format('ddd, DD MMM YYYY hh:mm A')}</p>
                    </div>
                    <div className="col-lg-6 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                        <button className="btn btn-edit  d-lg-block  d-md-block d-none mx-2  fs-12 wrap" onClick={toggleaddCoupon}>ADD NEW COUPON</button>
                        <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                            <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                        </p>
                        <ProfileIcon />
                    </div>
                </header>


                <div className="tutor-library mx-1">
                    <form onSubmit={(e) => { e.preventDefault(); apiCall(1,state.limit) }}>
                        <div className="row gx-0 mx-0">
                            <div className="col-lg-3 col-md-4 col-12 px-1 mb-2">
                                <div className='d-flex w-100 align-items-center'>
                                    <input type="search" ref={qry} placeholder="Search coupon code..." className="w-100 pl-5 h-100 input-box px-2 py-2 fs-14 ms-700" />
                                    <img src={searchIcon} className="p-abs lt-0 pl-3" />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-6 px-1 mb-2">
                                <button type='submit' className="btn btn-login w-100 h-100 wrap ">search</button>
                            </div>
                        </div>
                    </form>
                    <section className='row gx-0 mx-0'>
                        <div className="grid-view">
                            <div className="grid-card">
                                {state.list.map((coupon) => {
                                    return (<>
                                        <div key={coupon.id} className="col-12 px-1">
                                            <CouponCard
                                                coupon={coupon}
                                                onClick={() => history.push(`/${helper.getRole()}/dashboard/coupon/${coupon.coupon_code}`)} />
                                        </div>
                                    </>)
                                })}

                            </div>
                        </div>
                        {/* Pagination  */}
                        {state.total !== 0 &&
                            <div className="col-12 px-2 c-pagination">
                                <ReactPaginate
                                    previousLabel={<i class="fas fa-arrow-left"></i>}
                                    nextLabel={<i class="fas fa-arrow-right"></i>}
                                    pageCount={Math.ceil(state.total / 10)}
                                    onPageChange={({ selected }) => {
                                        apiCall(selected + 1, state.limit)
                                        setState({ ...state, pageNo: selected + 1 })
                                    }}
                                    containerClassName={"paginationBttns"}
                                    previousClassName={"previousBttn"}
                                    nextClassName={"nextBttn"}
                                    disabledClassName={"paginationDisabled"}
                                    activeClassName={"paginationActive"}
                                    pageClassName={"eachpage"}
                                />
                            </div>
                        }

                    </section>


                </div>

                {addCoupon &&
                    <AddNewCoupon
                        CallClose={() => { toggleaddCoupon() }}
                        OnAddCoupon={(data) => {
                            let list = [...state.list]
                            list.push(data)
                            setState({ ...state, list, total: state.total + 1 })
                        }}
                    />}

                {/* open Coupon details  */}
                {pageLoad && <Backdrop />}
            </div>

        </>
    )
}
