import axios from "axios";
import helper from "../helper/index";
import { constant } from "../config/Constant";
const BASE_URL = constant.BASE_URL

export const getTutorListForPay = ({ startDate,endDate }) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/admin/tutors/monthly-pay-tutors-list?start_date=${startDate}&end_date=${endDate}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

export const getPaidTutorListForPay = ({ startDate,endDate }) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/admin/tutors/monthly-paid-tutors-list?start_date=${startDate}&end_date=${endDate}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

export const payTutor = (data) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `post`,
            url: `${BASE_URL}/admin/tutors/pay-tutor`,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data
        })
        return res
    }
    catch (err) {
        return err;
    }
}

export const payoutDetails = (id) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/admin/tutors/tutor-transaction-details?id=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

