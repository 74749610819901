import React, { useState, useEffect } from 'react'
import { FiXCircle } from "react-icons/fi";
import Backdrop from '../../../component/Backdrop';
import admin from '../../../controller/admin';
import { Toaster, toast } from 'react-hot-toast';
import TextEditor from '../../../components/TextEditor';
export default function AddFaq({ onClose, onAdd }) {

    const [add, setadd] = useState({ cat: "", que: "", ans: "", role: 2, arabic_que: "", arabic_ans: "" })
    const [pageLoad, setpageLoad] = useState(false)
    const [categoryList, setCategoryList] = useState([])

    useEffect(() => { getCategory() }, [])
    const getCategory = async () => {
        await admin.getKB().then((res) => {
            if (res.data.status) {
                setCategoryList(res.data.result)

            }
        })

    }
    const addFAQ = async () => {
        const reqData = {
            "category": add.cat,
            "question": add.que,
            "answer": add.ans,
            arabic_answer: add.arabic_ans,
            arabic_question: add.arabic_que,
            role: add.role
        }

        if (!reqData.role) {
            toast("Please select role of faq")
            return
        }
        else if (!reqData.category) {
            toast("Please select category")
            return
        }
        else if (!reqData.question) {
            toast("Please enter question")
            return
        }
        else if (!reqData.answer) {
            toast("Please enter answer")
            return
        }
        else if (!reqData.arabic_question) {
            toast("Please enter arabic question")
            return
        }
        else if (!reqData.arabic_answer) {
            toast("Please enter arabic answer")
            return
        }
        setpageLoad(true)
        await admin.addFAQ(reqData).then((res) => {
            if (res.data.status) {
                toast("Faq successfully uploaded")
                setTimeout(() => { onClose(); onAdd(res.data.result, reqData.role); }, 1000)
            }
            else {
                toast(res.data.error)
            }

            setpageLoad(false)
        })
    }
    return (
        <>
            <div className="all-center popup-modal">
                <div className="right-popup notify-detail p-fix tp-0 rt-0 p-5 sm" style={{ width: '60%' }}>
                    <div className="row gx-0 mx-0">
                        <div className="col-10">
                            <p className="f-fred" style={{ fontSize: "24px" }}>Add<span style={{ color: "#089D12" }}> FAQ</span></p>
                            <p className="sub-heading ms-700 " style={{ color: "#434943", fontSize: "14px" }}>SayDhad course category</p>
                        </div>
                        <div className="col-2">
                            <FiXCircle className="pointer float-right " size="2rem" onClick={onClose} style={{ color: "lightgray" }} />
                        </div>
                    </div>
                    <div className="row gx-0 mx-0 edit-profile f-fred mt-2 fs-14 mt-3">
                        <div className="col-lg-12 edit-input">
                            <select className='f-fred  fs-14 px-2' onChange={(e) => { setadd({ ...add, role: e.target.value }) }} style={{ width: "100%", borderRadius: "5px", border: "1px solid #EAEAEA" }}>
                                <option value=''>Select role</option>
                                <option value={2}>Faq for student</option>
                                <option value={3}>Faq for tutor</option>
                            </select>
                        </div>
                        <div className="col-lg-12 edit-input">
                            <select className='f-fred fs-14 px-2' onChange={(e) => { setadd({ ...add, cat: e.target.value }) }} style={{ width: "100%", borderRadius: "5px", border: "1px solid #EAEAEA" }}>
                                <option value=''>Select category</option>
                                {categoryList.map((data) => <option value={data.category_name}>{data.category_name}</option>)}
                            </select>
                        </div>

                        <div className="col-lg-12 edit-input">
                            <input placeholder="Frequently asked question here (English)" type="text" style={{ border: "1px solid lightgray" }} onChange={(e) => { setadd({ ...add, que: e.target.value }) }} />
                        </div>
                        <div className="col-lg-12">
                            <section className='my-3'>
                                {
                                    <TextEditor
                                        onChange={(data) => { setadd({ ...add, ans: data }) }}
                                        data={'<p>Answer here (English)</p>'} />
                                }
                            </section>
                        </div>

                        <div className="col-lg-12 edit-input">
                            <input placeholder="الأسئلة المتداولة هنا (بالعربية)" type="text" style={{ border: "1px solid lightgray" }} onChange={(e) => { setadd({ ...add, arabic_que: e.target.value }) }} />
                        </div>

                       
                        <div className="col-lg-12">
                            <section className='my-3'>
                                {
                                    <TextEditor
                                        onChange={(data) => { setadd({ ...add, arabic_ans: data }) }}
                                        data={'<p>أجب هنا (بالعربية)</p>'} />
                                }
                            </section>
                        </div>

                        {/* <div className="col-lg-12 edit-input">
                            <input placeholder="answer here " type="text" style={{ border: "1px solid lightgray" }} onChange={(e) => { setadd({ ...add, ans: e.target.value }) }} />
                        </div> */}

                        <div className="col-12">
                            <button className="btn btn-update px-2 mr-1 py-2 ms-700  btn-sm col-xs-2 " onClick={addFAQ}>ADD FAQ</button>

                        </div>



                    </div>
                </div>
            </div>
            {pageLoad && <Backdrop />}

        </>
    )
}
