/* eslint-disable */
import React, { useState, useEffect } from 'react'
import ApexChart from '../../components/ApexChart'
import admin from '../../controller/admin';
import Backdrop from '../../component/Backdrop';
import moment from 'moment';
import helper from '../../helper';
import ConfirmPopup from '../../components/ConfirmPopup';
import { useParams, useHistory } from 'react-router-dom';
import ProfileIcon from '../../components/ProfileIcon';
import avatar from '../../static/images/avatar.png'
import TestimonialCard from '../student/TestimonialCard';
import EditTutorRate from './EditTutorRate';
import { Toaster, toast } from 'react-hot-toast';
import ViewCertificate from './ViewCertificate';
import { DateRangePicker } from 'react-dates';
import ReactStars from "react-rating-stars-component";
import { FaBell } from 'react-icons/fa';
import tutor from '../../controller/tutor';
import Payout from './Payout';
export default function TutorProfile() {
    const { userId, page } = useParams()
    const [getData, setgetData] = useState([])
    const [pageLoad, setpageLoad] = useState(true)
    const [loader, setLoader] = useState(false)
    const [getRate, setgetRate] = useState()
    const [EditRate, setEditRate] = useState(false);

    const [isAccess, setIsAccess] = useState(false)
    const [testimonial, setTestimonial] = useState([])
    const [pageNo, setPageNo] = useState(1)

    const [certificate, setCertificate] = useState({
        open: false,
        data: null
    });

    const [state, setState] = useState({
        viewProfile: false,
        openConfirmPop: false,
        msg: '',
        for: 'approve'
    })
    const history = useHistory()

    const [startDate, setStartDate] = useState(moment().subtract(11, 'month'));
    const [endDate, setEndDate] = useState(moment(new Date()));
    const [focusedInput, setFocusedInput] = useState(null);
    const [earning, setEarnings] = useState({ months: [], amount: [], load: true, data: {} })


    useEffect(() => {
        if (!userId || !page) {
            history.push('/')
        }
        setIsAccess(helper.checkModuleAccess({ moduleName: 'Tutor Manager' }))
        getTutorProfile()
        getTutorRate()
        getAnalytics(moment().subtract(11, 'month'), new Date())
        return () => { }
    }, [])

    const getTutorProfile = async () => {
        await admin.getTutorDetail(userId)
            .then((res) => {
                if (res.data.status) {
                    setTestimonial(res.data.result[0].testimonials)
                    setgetData(res.data.result)
                }
                setpageLoad(false)
            })
    }

    const getAnalytics = async (startDate, endDate) => {
        let st = moment(startDate).format('YYYY-MM-DD')
        let en = moment(endDate).format('YYYY-MM-DD')
        await admin.getTutorAnalytics(userId, st, en)
            .then((res) => {
                if (res.data.status) {
                    let months = [], amount = [], prevMonth = [], prevAmount = [], flag = 0
                    res.data.result.earningList.forEach((key) => {
                        if (moment(st).format('MMM') === key.month || flag === 1) {
                            months.push(key.month + " " + moment(st).format('YYYY'))
                            amount.push(parseFloat(key.amount).toFixed(2))
                            flag = 1
                        } else if (moment(st).format('MMM') !== key.month && flag === 0) {
                            prevMonth.push(key.month + " " + moment(en).format('YYYY'))
                            prevAmount.push(parseFloat(key.amount).toFixed(2))
                        }
                    })
                  
                    let newMonth = months.concat(prevMonth)
                    let newAmount = amount.concat(prevAmount)
                 
                    setEarnings({ ...earning, months: newMonth, amount: newAmount, data: res.data.result, load: false })
                }
            }).catch((err) => {
            })
    }

    const onRange = (st, en) => {
        if (st) {
            setStartDate(st)
        }
        if (en) {
            setEndDate(en)
        }
        if (st && en) {
            setEarnings({ ...earning, load: true })
            getAnalytics(st, en)
        }
    }

    const getTutorRate = async () => {
        await admin.getRate(userId)
            .then((res) => {
                if (res.data.status) {
                    setgetRate(res.data.result)
                }

            })
    }

    const approve = async () => {
        const reqData = {
            "id": userId,
            "adminId": helper.getid()
        }
        setLoader(true)
        await admin.approveTutor(reqData)
            .then((res) => {
                if (res.data.status) {
                    toast('Profile successfully approved')
                    getTutorProfile()
                }
                setLoader(false)
            })
    }

    const reject = async () => {
        const reqData = {
            "id": userId,
            "adminId": helper.getid()
        }
        setLoader(true)
        await admin.rejectTutor(reqData)
            .then((res) => {
                if (res.data.status) {
                    toast('profile successfully rejected')
                    getTutorProfile()
                }
                setLoader(false)
            })
    }

    const updateStatus = async (isActive, status) => {
        await tutor.activeInactiveTutor({ id: userId, adminId: helper.getid(), isActive: Boolean(isActive), status }).then((res) => {
            if (res.data.status) {
                getData[0].status = status
                let temp = [...getData]
                setgetData(temp)
            }
        })
    }

    return (
        <>

            {!pageLoad && isAccess && <>
                {getData.map((obj, index) => {
                    return (<>
                        <div key={index} className="mx-1">
                            {<>
                                <header className='row gx-0 mx-0 mb-2 py-1 mb-3'>
                                    <div className="col-lg-4 col-md-6 d-flex align-items-center px-1">
                                        <button className="btn btn-back mr-2" onClick={() => history.goBack()}>BACK</button>
                                        <div>
                                            <p className="f-fred d-lg-block d-md-block d-none fs-24">{obj.name}</p>
                                            <p className="sub-heading d-lg-block d-md-block d-none" style={{ color: "#434943", lineHeight: "8px" }}>Details</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-6 ms-700  col-6 d-flex justify-content-end align-items-center px-1">
                                        {
                                            obj.profileStatus === "Uploaded" &&
                                            <>
                                                <button className="btn btn-update mx-2 d-lg-block d-md-block d-none" onClick={() => { setState({ ...state, openConfirmPop: true, msg: "Do you want to approve tutor profile?", for: 'approve' }) }}>APPROVE TUTOR</button>
                                                <button className="btn btn-clear mx-2 d-lg-block d-md-block d-none" onClick={() => setState({ ...state, openConfirmPop: true, msg: "Do you want to reject tutor profile?", for: 'reject' })}>REJECT TUTOR</button>
                                            </>
                                        }
                                        {obj.profileStatus === "Approved" &&
                                            <>
                                                {obj.status === "Active" ? <button className="btn btn-clear mx-2 d-lg-block ms-700 fs-14 d-md-block d-none" onClick={() => updateStatus(false, "Inactive")}>INACTIVE TUTOR</button>
                                                    : <button className="btn btn-tutor-profile mx-2 d-lg-block ms-700 fs-14 d-md-block d-none" onClick={() => updateStatus(true, "Active")}>ACTIVE TUTOR</button>
                                                }
                                                <button className="btn btn-update mx-2 d-lg-block d-md-block d-none" onClick={() => history.push(`/${helper.getRole()}/dashboard/class-record/${obj.id}`)}>CLASS RECORD</button>
                                            </>

                                        }
                                        <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                                            <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                                        </p>
                                        <ProfileIcon />
                                    </div>
                                </header>

                                <div className='mx-1 tx-lt-black'>
                                    <section className="row gx-0 mx-0 ">
                                        <div className="col-lg-3 col-md-6 px-0 mb-2">
                                            <div className="row gx-0  h-100 quote-card mx-0 mr-2">
                                                <div className="col-6 h-100 py-4">
                                                    <div style={{ order: "1" }}>
                                                        <p className="ms-900 fs-18" >Students</p>
                                                        <p className="sub-heading" style={{ color: "#434943" }}>Enrolled total</p>
                                                    </div>
                                                </div>
                                                <div className="col-6 py-4">
                                                    <h2 className="text-right fs-40 ms-500" style={{ color: "#035D0A" }}>{obj.totalStudent}</h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 px-0 mb-2">
                                            <div className="row quote-card h-100 gx-0 mx-0">
                                                <div className="col-6 h-100 py-4">
                                                    <div style={{ order: "1" }}>
                                                        <p className="ms-900 fs-18">Call</p>
                                                        <p className="ms-700 sub-heading fs-14" >Attended in total</p>
                                                    </div>
                                                </div>
                                                <div className="col-6 py-4">
                                                    <h2 className="text-right fs-40 ms-500" style={{ color: "#035D0A" }}>{obj.total_calls}</h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 "></div>
                                        <div className="col-lg-4 col-12 mb-2" >
                                            <div className="row ms-500 h-100 br-1 gx-0 mx-0 " style={{ backgroundColor: "#FFCA0026", border: "1px solid #FFCA0026" }}>
                                                <div className="col-lg-6 h-100 py-4" >
                                                    <div style={{ order: "1" }}>
                                                        <p className="ms-900 fs-178" style={{ fontWeight: "900" }} >Rate</p>
                                                        <p className="ms-700 sub-heading fs-14" style={{ color: "#434943" }}>Per  minute (SAR)</p>
                                                    </div>
                                                </div>

                                                <div className="col-lg-2 py-4 ">
                                                    <h2 className="text-right ms-500" style={{ color: "#035D0A" }}>{getRate && getRate.rate}<span style={{ fontSize: "16px" }}></span></h2>
                                                </div>
                                                <div className="col-lg-4 py-4 d-flex align-items-center justify-content-end">
                                                    <button className="btn btn-dark " onClick={() => setEditRate(true)}>Edit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <div className="row gx-0 mx-0">
                                        <div className="col-lg-6 col-12 px-2 my-2 ">
                                            <div>
                                                <video height={'300px'} controls width="100%" style={{ borderRadius: "10px" }} src={helper.replaceVideoS3Url(obj.profileVideo)} />
                                            </div>
                                            <button className="btn btn-edit ms-700 fs-14" onClick={() => { setState({ ...state, viewProfile: !state.viewProfile }) }} >{state.viewProfile ? "COLLAPSE" : "VIEW PROFILE"}</button>
                                            {
                                                state.viewProfile &&

                                                <>
                                                    <section className="row gx-0 mx-0">
                                                        <div className="col-lg-12 profile-bg">
                                                            <div className="row gx-0 mx-0">
                                                                <div className="col-lg-10 px-0">
                                                                    <p className="f-fred fs-24" style={{ fontSize: "24px" }}>Profile details</p>
                                                                </div>
                                                                <div className="col-lg-2 px-0 d-flex justify-content-end">
                                                                    <span className="pointer" ><img width="50px" height="50px" className="rounded-circle " src={obj.profilePicture ? helper.replaceS3Url(obj.profilePicture) : avatar} /></span>
                                                                </div>
                                                            </div>
                                                            <div className="row gx-0 mx-0">
                                                                <div className="col-lg-12 px-0 d-flex justify-content-end my-1">
                                                                    <ReactStars
                                                                        edit={false}
                                                                        value={obj.ratings ? parseFloat(obj.ratings).toFixed(1) : 0}
                                                                        count={5}
                                                                        size={15}
                                                                        isHalf={true}
                                                                        emptyIcon={<i className="far fa-star"></i>}
                                                                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                                        fullIcon={<i className="fa fa-star"></i>}
                                                                        classNames="my-0 py-0"
                                                                        activeColor="#ffcb00"
                                                                    />
                                                                    <p className="f-fred ml-2 tx-lt-grey">{obj.ratings ? parseFloat(obj.ratings).toFixed(1) : 0}</p>
                                                                </div>

                                                            </div>

                                                            <div className="row ms-500 mx-0 gx-0">
                                                                <div className="col-lg-6 px-0 ms-500">
                                                                    <p style={{ color: "#434943" }}>Full name</p>
                                                                </div>
                                                                <div className="col-lg-6 text-right px-0 ms-900">
                                                                    <p style={{ color: "#434943" }}>{obj.name}</p>
                                                                </div>

                                                            </div>
                                                            <div className="col-12  border-dot my-1"></div>

                                                            <div className="row ms-500 my-1 mx-0 gx-0">
                                                                <div className="col-lg-4 px-0 px-0 ms-500">
                                                                    <p style={{ fontWeight: "500", fontSize: "16px", color: "#434943" }}>Email address</p>
                                                                </div>
                                                                <div className="col-lg-8 text-right  px-0 ms-900">
                                                                    <p style={{ fontWeight: "900", fontSize: "16px", color: "#434943" }}>{obj.email}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-12  border-dot my-1"></div>
                                                            <div className="row ms-500 my-1 mx-0 gx-0">
                                                                <div className="col-lg-6 px-0 ms-500">
                                                                    <p style={{ fontWeight: "500", fontSize: "16px", color: "#434943" }}>Phone number</p>
                                                                </div>
                                                                <div className="col-lg-6 text-right  px-0 ms-900">
                                                                    <p style={{ fontWeight: "900", fontSize: "16px", color: "#434943" }}>{obj.phoneNo} </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-12  border-dot my-1"></div>
                                                            <div className="row ms-500 my-1 mx-0 gx-0">
                                                                <div className="col-lg-6 px-0 ms-500">
                                                                    <p style={{ color: "#434943" }}>Country</p>
                                                                </div>
                                                                <div className="col-lg-6 text-right  px-0 ms-900">
                                                                    <p style={{ color: "#434943" }}>{obj.from}</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </section>

                                                    <div className="row gx-0 mx-0">
                                                        <div className="col-lg-12 profile-bg ">
                                                            <div className="row gx-0 mx-0">
                                                                <div className="col-lg-12 px-0">
                                                                    <p className="f-fred" style={{ fontSize: "24px" }}>Bank details</p>
                                                                </div>
                                                            </div>
                                                            <div className="row ms-500 my-1 mx-0 gx-0">
                                                                <div className="col-lg-6 px-0 ms-500">
                                                                    <p style={{ fontWeight: "500", fontSize: "16px", color: "#434943" }}>Bank name</p>
                                                                </div>
                                                                <div className="col-lg-6 text-right  px-0 ms-900">
                                                                    <p style={{ fontWeight: "900", fontSize: "16px", color: "#434943" }}>{obj.bankDetails[0] && obj.bankDetails[0].bankName} </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-12  border-dot my-1"></div>
                                                            <div className="row ms-500 my-1 mx-0 gx-0">
                                                                <div className="col-lg-6 px-0 ms-500">
                                                                    <p style={{ fontWeight: "500", fontSize: "16px", color: "#434943" }}>Account no.</p>
                                                                </div>
                                                                <div className="col-lg-6 text-right  px-0 ms-900">
                                                                    <p style={{ fontWeight: "900", fontSize: "16px", color: "#434943" }}>{obj.bankDetails[0] && obj.bankDetails[0].accountNumber} </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-12  border-dot my-1"></div>
                                                            <div className="row ms-500 my-1 mx-0 gx-0">
                                                                <div className="col-lg-6 px-0 ms-500">
                                                                    <p style={{ fontWeight: "500", fontSize: "16px", color: "#434943" }}>BIC no.</p>
                                                                </div>
                                                                <div className="col-lg-6 text-right  px-0 ms-900">
                                                                    <p style={{ fontWeight: "900", fontSize: "16px", color: "#434943" }}>{obj.bankDetails[0] && obj.bankDetails[0].bicNumber}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-12  border-dot my-1"></div>
                                                            <div className="row ms-500 my-1 mx-0 gx-0">
                                                                <div className="col-lg-6 px-0 ms-500">
                                                                    <p style={{ fontWeight: "500", fontSize: "16px", color: "#434943" }}>IBAN</p>
                                                                </div>
                                                                <div className="col-lg-6 text-right  px-0 ms-900">
                                                                    <p style={{ fontWeight: "900", fontSize: "16px", color: "#434943" }}>{obj.bankDetails[0] && obj.bankDetails[0].iban} </p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </>
                                            }

                                            <div className="row gx-0 mx-0">
                                                <div className="col-lg-12 px-1">
                                                    <div className="row gx-0 mx-0">
                                                        <div className="col-lg-12 mt-3 px-0">
                                                            <p className="f-fred fs-24 tx-lt-grey">Testimonials</p>
                                                        </div>

                                                        <div className="col-lg-12 px-0 ">
                                                            <section class="pt-2 pb-2">
                                                                {testimonial.map((data, index) => {
                                                                    if ((index + 1) <= pageNo * 2 && (index + 1) >= (pageNo * 2 - 1)) {
                                                                        return (
                                                                            <TestimonialCard
                                                                                userName={data.name}
                                                                                rate={data.ratings}
                                                                                review={data.review}
                                                                                country={data.country}
                                                                                date={moment(data.createdOn).format('MMM DD, YYYY')}
                                                                            />
                                                                        )
                                                                    }
                                                                })}
                                                                {testimonial.length === 0 && <p className='my-3 text-center '>No Testimonials Found</p>}
                                                                {
                                                                    pageNo > 1 &&
                                                                    <button className='btn btn-warning ml-1 mr-2 d-inline-block fs-14 px-2 py-1' style={{ fontWeight: "bold", color: "white" }}
                                                                        onClick={() => {
                                                                            setPageNo(pageNo - 1);
                                                                        }}><i class="fas fa-arrow-left"></i></button>
                                                                }
                                                                {testimonial.length > pageNo * 2 && <button className='btn btn-warning ml-1 px-2 py-1' style={{ fontWeight: "bold", color: "white" }}
                                                                    onClick={() => {
                                                                        setPageNo(pageNo + 1)
                                                                    }}><i class="fas fa-arrow-right"></i></button>}

                                                            </section>
                                                        </div>


                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-12 px-2 my-2 tx-lt-grey">
                                            <div className="row gx-0 mx-0">
                                                <div className="col-lg-12  px-1">
                                                    <p className="f-fred fs-24">About teaching style & languages</p>
                                                </div>
                                                <div className="col-lg-12 px-4 py-2 my-2 " style={{ border: "1px solid #EAEAEA", borderRadius: "10px" }}>
                                                    <p className="f-fred fs-14">Your introduction</p>

                                                    <p className="ms-300 fs-16 py-2">{obj.introduction}</p>
                                                </div>
                                                <div className="col-lg-12 px-4 py-2 my-2 " style={{ border: "1px solid #EAEAEA", borderRadius: "10px" }}>
                                                    <p className="f-fred fs-14">Teaching style</p>

                                                    <p className="ms-300 py-2" >{obj.teachingStyle}</p>
                                                </div>
                                                <div className="col-lg-12 px-4 py-2 my-2 " style={{ border: "1px solid #EAEAEA", borderRadius: "10px" }}>
                                                    <p className="f-fred fs-14">About you</p>

                                                    <p className="ms-300 fs-16 py-2">{obj.aboutMe}</p>
                                                </div>
                                                <div className="col-lg-12 px-4 py-2 my-2 " style={{ border: "1px solid #EAEAEA", borderRadius: "10px" }}>
                                                    <p className="f-fred fs-14 mb-2">Languages</p>


                                                    {getData[0].language.map((lang, index) => {
                                                        return (
                                                            <div className='ms-500 boxes d-inline-block mr-2 my-1'>
                                                                <span className="tx-lt-black ms-700 mr-2 mb-2">{lang.language}</span>
                                                                <span className="tx-lt-black mr-2 ms-700">({lang.dialect} {lang.fluency})</span>


                                                            </div>
                                                        )
                                                    })}
                                                </div>

                                                {
                                                    state.viewProfile &&
                                                    <>
                                                        <div className="col-lg-12 px-2 py-2">
                                                            <p className="f-fred fs-24" >Experience & Certificates</p>
                                                        </div>


                                                        <div className="col-lg-12 px-4 py-2 my-2 " style={{ border: "1px solid #EAEAEA", borderRadius: "10px" }}>
                                                            <p className="f-fred fs-14">Work Experience</p>

                                                            {
                                                                getData[0].experience.map((exp, index) => {
                                                                    return (
                                                                        <div key={`exp${index}`} className='mb-2'>
                                                                            <p className="ms-900 fs-12 pt-2 mt-2">{exp.title}</p>
                                                                            <p className="ms-500" style={{ fontWeight: "500", fontSize: "12px" }}>{exp.from}</p>
                                                                            <p className="ms-300 py-2 fs-14">{exp.description}</p>
                                                                        </div>
                                                                    )
                                                                })
                                                            }

                                                        </div>


                                                        <div className="col-lg-12 px-4 py-2 my-2 " style={{ border: "1px solid #EAEAEA", borderRadius: "10px" }}>
                                                            <p className="f-fred fs-14">Education</p>
                                                            <p className="ms-500 fs-12">Tutor education</p>
                                                            {
                                                                getData[0].education.map((edu, index) => {
                                                                    return (
                                                                        <div key={`exp${index}`} className='mb-2'>
                                                                            <p className="f-fred fs-12 pt-2 mt-2">{edu.degree}</p>
                                                                            <p className="ms-500" style={{ fontWeight: "500", fontSize: "12px" }}>{edu.from + " - " + edu.to}</p>
                                                                            <p className="ms-500 pt-1 fs-14">{edu.university}</p>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>

                                                        <div className="col-lg-12 px-4 py-2 my-2 " style={{ border: "1px solid #EAEAEA", borderRadius: "10px" }}>
                                                            <p className="f-fred">Certificates</p>
                                                            <p className="ms-500 fs-14 mb-2">Tutor certificates</p>
                                                            {getData[0].certification.map((certi, index) => {
                                                                return (
                                                                    <div className='mb-2' key={`cert${index}`}>
                                                                        <p className="f-fred fs-12">{certi.title}</p>
                                                                        <p className="ms-700" style={{ fontWeight: "500", fontSize: "12px" }}>Issued by:  {certi.issue_authority}</p>
                                                                        <p className="ms-700" style={{ fontWeight: "500", fontSize: "12px" }}>Issued on:  {moment.utc(certi.certificate_date).format('MMM, YYYY')}</p>
                                                                        <a className='link fs-14' href={certi.file_name} target="_blank">View</a>

                                                                        <br />
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </>
                                                }

                                            </div>
                                        </div>

                                    </div>

                                    <div className="row gx-0 mx-0">
                                        <div className="col-lg-5 px-2 my-3 ">
                                            <div className="row gx-0 mx-0">
                                                <div className="col-lg-12 quote-card py-2">
                                                    <div className="row">
                                                        <div className="col-lg-12 " >
                                                            <p className="ms-900 " style={{ fontSize: "18px", color: "#1B221B" }}>Stats & analytics</p>
                                                            <p className="sub-heading">Till now</p>
                                                        </div>
                                                    </div>
                                                    <div className="row gx-0  mx-0 pb-2 pt-4">
                                                        <div className="col-lg-6 px-1 mb-3">
                                                            <div >
                                                                <p className="tx-lt-black-op-5 ms-900" >Total profile views</p>
                                                                <h2 className="ms-500 fs-40">{earning.data.totalProfileViews} </h2>

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 px-1 mb-3">

                                                            <div >
                                                                <p className="tx-lt-black-op-5 ms-900"  >Total earnings (SAR)</p>
                                                                <h2 className="ms-500 fs-40" style={{ fontWeight: "500" }}> {earning.data.totalEarnings} </h2>

                                                            </div>
                                                        </div>


                                                        <div className="col-lg-6 px-1 mb-3">

                                                            <div >
                                                                <p className="tx-lt-black-op-5 ms-900">Monthly increase</p>
                                                                <h2 className="ms-500 fs-40" style={{ fontWeight: "500" }}>{parseFloat(earning.data.monthlyIncrease).toFixed(1)} <span className="stx2">{parseFloat(earning.data.monthlyIncreasePercent).toFixed(1)}%</span></h2>

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 px-1 mb-3">

                                                            <div >
                                                                <p className="tx-lt-black-op-5 ms-900"  >Total comments</p>
                                                                <h2 className="ms-500 fs-40" style={{ fontWeight: "500" }}>{earning.data.totalComments}</h2>

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 px-1 mb-3">

                                                            <div >
                                                                <p className="tx-lt-black-op-5 ms-900"  >Total ratings</p>
                                                                <h2 className="ms-500 fs-40" >{earning.data.rating ? parseFloat(earning.data.rating).toFixed(1) : 0}</h2>

                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>





                                            </div>

                                        </div>
                                        <div className="col-lg-7 my-3 px-2">
                                            <div className="quote-card h-100 all-center cur-pointer bg-smoke border-radius-1">
                                                <div className='w-100 d-flex h-100 py-3 flex-column justify-content-between'>
                                                    <div className='w-100  d-flex justify-content-between align-items-center'>
                                                        <section className='ml-3'>
                                                            <p className='ms-900 fs-18'>Earnings</p>
                                                            <p className='sub-heading'>From 21st Sept 2021 to 21st Dec 2021</p>
                                                        </section>
                                                        <div className="mr-2  ms-700 fs-12 pointer   tx-lt-black w-100 d-lg-flex justify-content-end d-md-flex d-none">
                                                            <DateRangePicker
                                                                showClearDates={true}
                                                                navPrev={null}
                                                                initialStartDate={null}
                                                                disabled={false}
                                                                startDate={startDate}
                                                                isOutsideRange={() => false}
                                                                isDayBlocked={() => false}
                                                                enableOutsideDays={true}
                                                                numberOfMonths={2}
                                                                startDateId="s_id"
                                                                endDate={endDate}
                                                                endDateId="e_id"
                                                                onDatesChange={({ startDate, endDate }) => { onRange(startDate, endDate) }}
                                                                focusedInput={focusedInput}
                                                                onFocusChange={e => setFocusedInput(e)}
                                                                displayFormat="DD/MM/YYYY"
                                                            />
                                                        </div>
                                                    </div>
                                                    {!earning.load && <ApexChart year={`${moment(startDate).format('MMM YYYY')} - ${moment(endDate).format('MMM YYYY')}`} month={earning.months} amount={earning.amount} />}

                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    <Payout />
                                </div>

                            </>}

                        </div>
                    </>)
                })}

            </>}
            {!pageLoad && !isAccess &&
                <p className='my-3 text-center fs-18 ms-700'>Oops! You have not access. please contact your admin</p>
            }

            {/* open confirmbox  */}
            <ConfirmPopup
                open={state.openConfirmPop}
                msg={state.msg}
                onClose={() => setState({ ...state, openConfirmPop: false })}
                onOk={() => {
                    if (state.for === "approve") {
                        setState({ ...state, openConfirmPop: false })
                        approve()
                    } else {
                        setState({ ...state, openConfirmPop: false })
                        reject()
                    }
                }}
            />

            {EditRate &&
                <EditTutorRate
                    id={userId}
                    data={getRate}
                    OnEdit={() => { setEditRate(false); getTutorRate() }}
                    CallClose={() => setEditRate(false)}
                />}

            <ViewCertificate
                open={certificate.open}
                pic={certificate.data}
                onClose={() => setCertificate({ ...certificate, open: false })}

            />

            {(pageLoad || loader) && <Backdrop />}
        </>
    )
}
