import React from 'react';
import moment from 'moment';
export default function CouponCard({ coupon, onClick }) {
    return (<>
        <div className="quote-card cur-pointer bg-smoke border-radius-1 py-2 my-2 ">
            <div className="row fs-14 tx-lt-grey gx-0 mx-0">
            <div className="col-lg-1 col-md-3 col-6 all-center px-1 my-2">
                    <button className={`btn ${coupon.is_active ? ' btn-close' : 'btn-open'} w-100 fs-16 py-1 px-2 ms-700 `}>{coupon.is_active ? "Active" : "Expired"}</button>
                </div>

                <div className="col-lg-1 col-md-3 col-4 px-2 my-1 d-flex justify-content-center flex-column">
                    <p className="f-fred ">{coupon.id}</p>
                    <p className="ms-500 fs-12 ">Coupon ID</p>

                </div>
                <div className="col-lg-2 col-md-3 col-4 px-2 my-1 d-flex justify-content-center flex-column">
                    <p className="f-fred ">{coupon.coupon_code}</p>
                    <p className="ms-500 fs-12 ">Coupon code</p>

                </div>
                <div className="col-lg-1 col-md-3 col-4 px-2 my-1 d-flex justify-content-center flex-column">
                    <p className="f-fred ">{coupon.discount}</p>
                    <p className="ms-500 fs-12 ">Discount(%)</p>

                </div>
                <div className="col-lg-1 col-md-3 col-4 px-2 my-1 d-flex flex-column align-items-lg-end align-items-md-end align-items-start justify-content-center">
                    <p className="f-fred text-right">N/A</p>
                    <p className="ms-500 fs-12 text-right">Students</p>

                </div>
                <div className="col-lg-1 col-md-3 col-4 px-2 my-1 d-flex flex-column align-items-lg-end align-items-start justify-content-center">

                    <p className="f-fred text-right">{moment(coupon.expiry_date).format('DD MMM YYYY')}</p>
                    <p className="ms-500 fs-12 text-right">Expiring on</p>

                </div>
                <div className="col-lg-1 col-md-3 col-4 px-2 my-1 d-flex flex-column align-items-lg-end align-items-start justify-content-center ">
                    <p className="f-fred">{coupon.expiry_time ? moment(coupon.expiry_time,'hh:mm').format('hh : mm a') : 'N/A'}</p>
                    <p className="ms-500 fs-12 ">Time</p>

                </div>
                <div className="col-lg-2 col-md-3 col-4 px-2 my-1 d-flex flex-column align-items-lg-end align-items-start justify-content-center">
                    <p className="f-fred text-right">{moment(coupon.created_at).format('DD MMM YYYY')}</p>
                    <p className="ms-500 fs-12 text-right">Created on</p>

                </div>
                <div className="col-lg-1 col-md-3 col-4 px-2 my-1 d-flex flex-column align-items-lg-end align-items-md-end align-items-start justify-content-center">
                    <p className="f-fred text-right">{moment(coupon.created_at,'HH:mm').format('hh : mm a')}</p>
                    <p className="ms-500 fs-12 text-right ">Time</p>

                </div>
               
                <div className="col-lg-1 col-md-3 col-6 px-2 my-1 all-center">
                    <button className="btn btn-detail w-100 py-1 px-2 f-fred fs-12" onClick={onClick}>Details</button>
                </div>
            </div>
        </div>
    </>)
}
