import React, { useState } from 'react'
import { FiXCircle } from "react-icons/fi";
import admin from '../../controller/admin';
import helper from '../../helper/index';
import { Toaster, toast } from 'react-hot-toast';
import Backdrop from '../../component/Backdrop';
import ConfirmPopup from '../../components/ConfirmPopup';
export default function EditCategory({ CallClose, id, data, OnEditCat }) {
    const [pageload, setPageload] = useState(false)
    const [editCat, seteditCat] = useState()
    const [state, setState] = useState({
        open: false,
        msg: ''
    })
    const deleteCourseCat = async () => {
        setPageload(true)
        await admin.deleteCourseCategory({ adminId: 1, id: id })
            .then((res) => {
                if (res.data.status) {
                    toast('category successfully removed')
                    setTimeout(() => {
                        CallClose()
                        OnEditCat()
                    }, 1500)
                } else {
                    toast(res.data.error)
                }
                setPageload(false)
            })

    }


    const editCourseCat = async () => {
        const reqData = {
            "admin_id": helper.getid(),
            "category_name": editCat,
            "id": id
        }
        if (!reqData.category_name) {
            toast("Please enter category name")
            return
        }
        if (!reqData.id) {
            toast("Invalid category id")
            return
        }

        setPageload(true)
        await admin.editCourseCat(reqData)
            .then((res) => {
                if (res.data.status) {
                    toast("category successfully updated")
                    setTimeout(() => {
                        CallClose()
                        OnEditCat()
                    }, 1500)
                } else {
                    toast(res.data.error)
                }
                setPageload(false)
            })

    }
    return (
        <>
             
            <div className=" all-center popup-modal">
                <div className="right-popup notify-detail p-fix tp-0 rt-0  px-3 py-3 sm" >
                    <div className="row gx-0 mx-0">
                        <div className="col-10">
                            <p className="f-fred fs-24">Edit<span style={{ color: "#089D12" }}> category</span></p>
                            <p className="sub-heading" style={{ color: "#434943" }}>SayDhad course category</p>
                        </div>
                        <div className="col-2">
                            <FiXCircle className="pointer float-right " size="2rem" onClick={CallClose} style={{ color: "lightgray" }} />
                        </div>
                    </div>
                    <div className="row gx-0 mx-0 edit-profile ms-700 mt-3">
                        <div className="col-lg-12 edit-input">
                            <input placeholder="edit category" type="text" style={{ border: "1px solid lightgray" }} defaultValue={data.category_name}
                                onChange={(e) => { seteditCat(e.target.value) }} />
                        </div>
                        <div className="col-6 mb-2">
                            <button className="btn btn-update px-1 mr-1 py-2 ms-700  btn-sm col-xs-2 " onClick={editCourseCat}>UPDATE CATEGORY</button>
                        </div>
                        <div className="col-6 mb-2">
                            <button className="btn btn-clear px-1 py-2  ms-700 btn-sm" onClick={() => setState({ ...state, open: true, msg: 'Do you want to remove the course category' })}>DELETE CATEGORY</button>

                        </div>
                    </div>
                </div>
            </div>
            <ConfirmPopup
                open={state.open}
                msg={state.msg}
                onClose={() => setState({ ...state, open: false })}
                onOk={() => {
                    deleteCourseCat()
                    setState({ ...state, open: false })
                }}
            />
            {pageload && <Backdrop />}
        </>
    )
}
