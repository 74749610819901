import React, { useState, useRef } from 'react'
import { FiXCircle } from "react-icons/fi";
import profile from '../controller/profile';
import toast, { Toaster } from 'react-hot-toast';
import helper from '../helper';
import Backdrop from '../component/Backdrop';
import avatar from "../static/images/avatar.png"
export default function EditAdminProfile({ CallClose, OnEditProfile, data }) {
    const [load, setLoad] = useState(false)
    const [image, setImage] = useState();
    const name = useRef()
    const mobile = useRef()
    const password = useRef()
    const newPassword = useRef()
    const confirmNewPassword = useRef()

    const EditData = async () => {

        //profile
        const profileReq = {
            "name": name.current.value,
            "adminId": helper.getid(),
            "email": data.email,
            "mobileNo": mobile.current.value
        }

        let resp = helper.validate("phno", profileReq.mobileNo)
        if (!resp.isValid) {
            toast(resp.msg)
            return
        }

        //change password
        const passwordReq = {
            "adminId": helper.getid(),
            "oldPassword": password.current.value,
            "newPassword": newPassword.current.value
        }
        if (passwordReq.oldPassword || passwordReq.newPassword) {
            resp = helper.validate("password", passwordReq.oldPassword)
            if (!resp.isValid) {
                toast(resp.msg)
                return
            }
            if (!passwordReq.newPassword) {
                toast('please enter new password')
                return
            }
            resp = helper.validate("password", passwordReq.newPassword)
            if (!resp.isValid) {
                toast(resp.msg)
                return
            }
            if (passwordReq.newPassword !== confirmNewPassword.current.value) {
                toast('password mismatch')
                return
            }
        }

        //profile picture
        const form = new FormData()
        form.append('adminId', helper.getid())
        form.append('file', image)

        setLoad(true)
        await profile.updateAdminProfile(profileReq)
            .then(async (res) => {
                if (res.data.status) {

                    if (image) {
                        await profile.updateProfileImage(form).then((resp) => {
                            if (resp.data.status) {
                            }

                        })
                    }
                    if (passwordReq.oldPassword) {
                        await profile.changePassword(passwordReq).then((resp) => {

                        })
                    }
                }
                toast('profile successfully updated')
                setLoad(false)
                setTimeout(() => { CallClose(); OnEditProfile() }, 1000)
            }).catch((err) => { setLoad(false) })
    }

    return (
        <>

            <div className="all-center popup-modal">
                <div className="right-popup notify-detail p-fix tp-0 rt-0  px-3 py-3 sm" >
                    <div className="row gx-0 mx-0">
                        <div className="col-10">
                            <p className="f-fred" style={{ fontSize: "24px" }}>Edit <span style={{ color: "#089D12" }}> profile</span></p>
                            <p className="sub-heading" style={{ color: "#434943" }}>My profile</p>
                        </div>
                        <div className="col-2">
                            <FiXCircle className="pointer float-right " size="2rem" onClick={CallClose} style={{ color: "lightgray" }} />
                        </div>
                    </div>

                    <div className="row gx-0 mx-0 edit-profile ms-700 mt-3">
                        <div className="col-lg-12 edit-input">
                            <input type="text" ref={name} placeholder="Enter Name" defaultValue={data && data.name} />
                        </div>
                        <div className="col-lg-12 edit-input"> <input type="email" placeholder="Enter email" value={data && data.email} />
                        </div>
                        <div className="col-lg-12 edit-input"> <input type="text" ref={mobile} placeholder="Enter mobile No" maxLength={10} defaultValue={data && data.mobileNo} />
                        </div>
                    </div>

                    <div className="row gx-0 mx-0 " style={{ marginTop: "15px" }}>
                        <div className="col-8 my-2 py-2">
                            <label htmlFor='img' className="btn-replace ms-700 fs-14">Replace profile image</label>
                            <input id="img" type="file" className='d-none' onChange={(e) => setImage(e.target.files[0])} />
                        </div>
                        <div className="col-4 my-2 py-2">
                            <span className="pointer d-flex justify-content-end" >
                                {image ?
                                    <img width="50px" height="50px" className="rounded-circle" src={URL.createObjectURL(image)} alt="" />
                                    : <img width="50px" height="50px" className="rounded-circle" src={data && data.profilePicture ? helper.replaceS3Url(data.profilePicture) : avatar} alt="" />
                                }
                            </span>
                        </div>
                    </div>


                    <div className="row gx-0 mx-0 edit-profile ms-700">
                        <div className="col-lg-12 edit-input">
                            <input ref={password} placeholder="old password" type="password" />
                        </div>
                        <div className="col-lg-12 edit-input">
                            <input ref={newPassword} placeholder="New password" type="password" />

                        </div>
                        <div className="col-lg-12 edit-input">
                            <input ref={confirmNewPassword} placeholder="Repeat new password" type="password" />

                        </div>
                    </div>


                    <div className="row gx-0 mx-0 py-2 my-2">
                        <div className="col-lg-12 ">
                            <button className="btn btn-update ms-700" onClick={EditData}>Update profile</button>
                        </div>
                    </div>


                </div>
            </div>

            {load && <Backdrop />}
        </>
    )
}
