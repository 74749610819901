import React from 'react';
import avatar from '../../static/images/avatar.png'
import moment from 'moment';
import helper from '../../helper';

export default function EarningDetailCard({pic, name, country, amount, cycle, date,subscription,week,min,onClick }) {
    return (<>
        <div className="pointer quote-card cur-pointer bg-smoke border-radius-1 py-2 my-2 " onClick={onClick}>
            <div className="row fs-14 gx-0 mx-0 px-2">
                <div className="col-lg-2 d-flex align-items-center col-md-4 col-6 px-0 my-1">
                    <img className='mr-2 rounded-circle' width={"40px"} height="40px" src={pic ? helper.replaceS3Url(pic) : avatar} alt="" />
                    <div>
                        <p className="f-fred">{name ? name : "N/A"}</p>
                        <p className="ms-500 fs-12">Student</p>
                    </div>
                </div>
                <div className="col-lg-2 col-md-4 col-6 px-0 my-1">
                    <p className="f-fred ">{country ? country  :'N/A'}</p>
                    <p className="ms-500 fs-12 ">Country</p>
                </div>

                <div className="col-lg-1 col-md-4 col-6 px-0 my-1">
                    <p className="f-fred">{subscription ? subscription : 'N/A'}</p>
                    <p className="ms-500 fs-12">Subscription</p>
                </div>
                <div className="col-lg-1 col-md-4 col-6 px-0 my-1">
                    <p className="f-fred ">{min}</p>
                    <p className="ms-500 fs-12 ">Minutes/ day</p>
                </div>
                <div className="col-lg-1 col-md-4 col-6 px-0 my-1">
                    <p className="f-fred ">{week}</p>
                    <p className="ms-500 fs-12 ">Days/ week</p>
                </div>
                <div className="col-lg-2 col-md-4 col-6 px-0 my-1 d-flex flex-column justify-content-center align-items-lg-end">
                    <p className="f-fred ">{amount}</p>
                    <p className="ms-500 fs-12 ">Amount(AED)</p>
                </div>

                <div className="col-lg-1 col-md-4 col-6 d-flex flex-column justify-content-center align-items-lg-end px-0  my-1 ">
                    <p className="f-fred ">{cycle ? cycle : 'N/A'}</p>
                    <p className="ms-500 fs-12 ">Cycle</p>

                </div>

                <div className="col-lg-2 col-md-4 col-6  d-flex flex-column justify-content-center align-items-lg-end px-0 my-1">
                    <p className="f-fred ">{date ? moment(date).format('DD MMM YYYY') : 'N/A'}</p>
                    <p className="ms-500 fs-12 ">Enrolled on</p>

                </div>
            </div>
        </div>
    </>);
}
