import React from 'react';
import moment from 'moment';
export default function CommitmentCard({ plan, onEdit }) {
    return (
        <>
            <div className="quote-card cur-pointer bg-smoke border-radius-1 py-2 my-2 ">
                <div className="row gx-0 mx-0 fs-14 tx-lt-grey">
                    <div className="col-lg-1 all-center px-1 my-2">
                        <button className={`btn ${plan.is_active ? ' btn-close' : 'btn-open'} w-100 fs-16 py-1 px-2 ms-700 `}>{plan.is_active ? "Active" : "Inactive"}</button>
                    </div>
                    <div className="col-lg-1 px-2 my-1">
                        <p className="f-fred ">{plan.id}</p>
                        <p className="ms-500 fs-12 ">Plan ID</p>

                    </div>
                    <div className="col-lg-1 px-2 my-1">
                        <p className="f-fred ">{plan.commitment_level}</p>
                        <p className="ms-500 fs-12 ">Cycle</p>

                    </div>
                    <div className="col-lg-1 px-2 my-1">
                        <p className="f-fred ">{plan.days_per_week}</p>
                        <p className="ms-500 fs-12 ">Days/week</p>

                    </div>
                    <div className="col-lg-1 px-2 my-1">
                        <p className="f-fred ">{plan.minutes_per_day}</p>
                        <p className="ms-500 fs-12 ">Min/Day</p>

                    </div>
                    <div className="col-lg-2 px-2 my-1 ">
                        <p className="f-fred text-right">0</p>
                        <p className="ms-500 fs-12 text-right">Students</p>

                    </div>
                    <div className=" col-lg-1 px-2 my-1">
                        <p className="f-fred text-right">{parseInt(plan.amount).toFixed(0)}</p>
                        <p className="ms-500 fs-12 text-right">Amount(SAR)</p>

                    </div>
                    <div className="col-lg-2 px-2 my-1">
                        <p className="f-fred text-right">{moment(plan.created_at).format('DD MMM YYYY')}</p>
                        <p className="ms-500 fs-12 text-right">created on</p>
                    </div>
                    <div className="col-lg-1 px-2 my-1">
                        <p className="f-fred text-right">{moment(plan.created_at).format("hh : mm a")}</p>
                        <p className="ms-500 fs-12 text-right ">time</p>

                    </div>

                    <div className="col-lg-1 px-2 my-1 all-center">
                        <button className="btn btn-detail w-100 py-1 px-2 f-fred fs-12" onClick={() => onEdit(plan.id, plan)}>Edit</button>

                    </div>
                </div>
            </div>
        </>
    )
}
