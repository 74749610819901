import avatar from '../static/images/avatar.png'
var CryptoJS = require("crypto-js");
var token = "a34@TYUI";
var role = "SMDKR$I";
var userId = "SDIFDSF";
var emailId = "DSAFUSI",
  accessModule = "FSDFSDTER";

function encrypt(data) {
  try {
    let encryptData = CryptoJS.AES.encrypt(
      data,
      "Dhad564h@#TYGD@3UG"
    ).toString();
    return encryptData;
  } catch {}
}
function decrypt(data) {
  try {
    let decryptData = CryptoJS.AES.decrypt(data, "Dhad564h@#TYGD@3UG").toString(
      CryptoJS.enc.Utf8
    );
    return decryptData;
  } catch {}
}

function scroll() {
  window.scroll({
    behavior: "smooth",
    top: 0,
  });
}

function replaceS3Url(url) {
    const invalidUrls = [
        'https://twiker.s3.ap-south-1.amazonaws.com',
        'https://api.saydhad.com'
    ];
    const oldBaseUrl = "https://s3bucketstaqo.s3.amazonaws.com";
    const newBaseUrl = "https://saydhad-new.s3.ap-south-1.amazonaws.com";
  
    // Check if the input is not a valid URL
    try {
        new URL(url);
    } catch (e) {
        // Return the input as is if it's not a valid URL
        return url;
    }

    // Check if the URL contains an invalid URL
    if (url && url !== null && invalidUrls.some(invalidUrl => url.includes(invalidUrl))) {
        return avatar; // assuming avatar is defined elsewhere
    }

    // Check if the URL contains the old base URL
    if (url && url.includes(oldBaseUrl)) {
        const newUrl = url.replace(oldBaseUrl, newBaseUrl);
        let updatedUrl = newUrl.replace(/([^:])\/\//g, '$1/'); // Remove double slashes (except after protocol)
        return updatedUrl;
    }

    return url;
}

function replaceVideoS3Url(url) {
    const invalidUrls = [
        'https://twiker.s3.ap-south-1.amazonaws.com',
        'https://api.saydhad.com'
      ];
    const oldBaseUrl = "https://s3bucketstaqo.s3.amazonaws.com";
    const newBaseUrl = "https://saydhad-new.s3.ap-south-1.amazonaws.com";

    try {
        new URL(url);
    } catch (e) {
        // Return the input as is if it's not a valid URL
        return url;
    }
  
    if(url && url !== null && invalidUrls.some(invalidUrl => url.includes(invalidUrl))){
      return null
    }
  
    // Check if the URL contains the old base URL
    if (url && url !== null && url.includes(oldBaseUrl)) {
      const newUrl = url.replace(oldBaseUrl, newBaseUrl);
      let updatedUrl = newUrl.replace(/([^:])\/\//g, '$1/');
      return updatedUrl;
    }
    return url;
  }


function validate(field, value) {
  if (field === "name") {
    if (value === "") {
      return { isValid: false, msg: "Username is required" };
    } else if (value.toString().trim().length === 0) {
      return { isValid: false, msg: "Do not add white spaces" };
    } else {
      const reg = new RegExp(/^[a-zA-Z ,.-]*$/gi);
      const res = reg.test(value.replace(/\n/g, " "));
      if (res) {
        return { isValid: true };
      } else {
        return { isValid: false, msg: "Please add formated data" };
      }
    }
  }
  if (field === "alphaNum") {
    if (value === "") {
      return { isValid: false, msg: "Please enter valid data" };
    } else if (value.toString().trim().length === 0) {
      return { isValid: false, msg: "Do not add white spaces" };
    } else {
      const reg = new RegExp(/^[a-zA-Z0-9 ,.-]*$/gi);
      const res = reg.test(value.replace(/\n/g, " "));
      if (res) {
        return { isValid: true };
      } else {
        return { isValid: false, msg: "Please enter valid data" };
      }
    }
  } else if (field === "password") {
    if (value === "") {
      return { isValid: false, msg: "Please enter password" };
    } else if (value.length < 8) {
      return {
        isValid: false,
        msg: "Password length should be greater than 8.",
      };
    } else {
      return { isValid: true };
    }
  } else if (field === "otp") {
    if (value === "") {
      return { isValid: false, msg: "otp required" };
    } else if (value.length != 4) {
      return { isValid: false, msg: "Enter Valid Otp" };
    }
    const reg = new RegExp("^[0-9]+$");
    const res = reg.test(value);
    if (!res) {
      return { isValid: false, msg: "Otp have only digits" };
    } else {
      return { isValid: true };
    }
  } else if (field === "phno") {
    if (value === "") {
      return { isValid: false, msg: "phone number required" };
    } else if (value.length != 10) {
      return { isValid: false, msg: "Invalid phone number" };
    }
    const reg = new RegExp("^[0-9]+$");
    const res = reg.test(value);
    if (!res) {
      return { isValid: false, msg: "Mobile Number have only digits" };
    } else {
      return { isValid: true };
    }
  } else if (field === "email") {
    if (value === "") {
      return { isValid: false, msg: "Pleas enter email" };
    }
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const test = reg.test(value.toLowerCase());
    if (!test) {
      return { isValid: false, msg: "Please enter a valid email" };
    } else {
      return { isValid: true };
    }
  } else {
    return { isValid: false, msg: "Invalid field" };
  }
}

function isLoggedIn() {
  try {
    const token = getToken();
    const role = getRole();
    const email = getemail();
    const id = getid();

    if (token && role == "admin" && email && id) {
      return { role: "admin", res: true };
    }
    if (token && role == "manager" && email && id) {
      return { role: "manager", res: true };
    }
    return { role: "anonymous", res: false };
  } catch {
    return { role: "anonymous", res: false };
  }
}

const month = [
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function getDOB(date) {
  const currDate = new Date(date);
  return (
    currDate.getDate() +
    " " +
    month[currDate.getMonth()] +
    " " +
    currDate.getFullYear()
  );
}

function getCuurentDate() {
  const currDate = new Date();

  const date =
    currDate.getUTCDate() +
    " " +
    month[currDate.getUTCMonth()] +
    " " +
    currDate.getUTCFullYear();
  return date;
}
const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

function getDay() {
  const currDate = new Date();
  return days[currDate.getDay()];
}

function getTime() {
  const currDate = new Date();
  const ampm = currDate.getHours() > 12 ? "PM" : "AM";
  const hours =
    currDate.getHours() > 12 ? currDate.getHours() - 12 : currDate.getHours();
  return (
    (hours.toString().length === 1 ? "0" + hours : hours) +
    " : " +
    (currDate.getMinutes().toString().length === 1
      ? "0" + currDate.getMinutes()
      : currDate.getMinutes()) +
    " " +
    ampm
  );
}

function moreText() {
  var points = document.getElementById("points");

  var showMoreText = document.getElementById("moreText");

  var buttonText = document.getElementById("textButton");
  if (points.style.display === "none") {
    showMoreText.style.display = "none";
    points.style.display = "inline";
    buttonText.innerHTML = "Show More";
  }

  // If the hidden portion is revealed,
  // we will change it back to be hidden
  else {
    // Show the text between the
    // span elements
    showMoreText.style.display = "inline";

    // Hide the dots after the text
    points.style.display = "none";

    // Change the text on button
    // to 'Show Less'
    buttonText.innerHTML = "Show Less";
  }
}

const parentContainer = document.querySelector(".read-more-container");
const a = document.getElementById("el");
if (a) {
  parentContainer.addEventListener(
    "click",
    (event) => {
      const current = event.target;
      const isReadMoreBtn = current.className.includes("read-more-btn");

      if (!isReadMoreBtn) return;

      const currentText =
        event.target.parentNode.querySelector(".read-more-text");

      currentText.classList.toggle("read-more-text--show");

      current.textContent = current.textContent.includes("Read more")
        ? "Read less"
        : "Read more";
    },
    false
  );
}

// admin api integration
function getToken() {
  try {
    return decrypt(localStorage.getItem(token));
  } catch (err) {
    return "";
  }
}

function getRole() {
  try {
    return decrypt(localStorage.getItem(role));
  } catch (err) {
    return "";
  }
}
function getAccessModule() {
  try {
    if (getRole() === "manager") {
      let resp = decrypt(localStorage.getItem(accessModule));
      return resp.split(",");
    }
    return [];
  } catch (err) {
    return [];
  }
}

function getid() {
  try {
    return decrypt(localStorage.getItem(userId));
  } catch (err) {
    return "";
  }
}
function getemail() {
  try {
    return decrypt(localStorage.getItem(emailId));
  } catch (err) {
    return "";
  }
}

function saveCredential(adminToken, id, email, userType, access) {
  try {
    localStorage.setItem(token, encrypt(adminToken));
    localStorage.setItem(role, encrypt(userType));
    localStorage.setItem(emailId, encrypt(email));
    localStorage.setItem(userId, encrypt(id.toString()));
    if (access) {
      localStorage.setItem(accessModule, encrypt(access.toString()));
    }
  } catch (err) {}
}

function checkModuleAccess({ moduleName }) {
  try {
    const access = getAccessModule();
    const isAuth = isLoggedIn();
    if (
      isAuth.res &&
      isAuth.role === "manager" &&
      access.includes(moduleName)
    ) {
      return true;
    } else if (isAuth.res && isAuth.role === "admin") {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}

function logOut() {
  try {
    localStorage.removeItem(token);
    localStorage.removeItem(emailId);
    localStorage.removeItem(userId);
    localStorage.removeItem(role);
  } catch {
    return { role: "anonymous", res: false };
  }
}
function checkWindowSize() {
  const resp = window.innerWidth;
  return resp;
}

export default {
  encrypt,
  decrypt,
  getToken,
  scroll,
  validate,
  isLoggedIn,
  getid,
  getAccessModule,
  getDOB,
  logOut,
  getCuurentDate,
  getTime,
  getDay,
  moreText,
  saveCredential,
  getemail,
  getRole,
  checkModuleAccess,
  checkWindowSize,
  replaceS3Url,
  replaceVideoS3Url
};
