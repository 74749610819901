import React, { useState, useRef } from 'react';
import { FiXCircle } from "react-icons/fi";
import moment from 'moment';
import helper from '../../helper';
import { payTutor } from '../../controller/payout';
import toast from 'react-hot-toast';
import Backdrop from '../../component/Backdrop';
export default function ProceedToPay({ open, onClose, data, cb, index }) {
    const [state, setState] = useState({ isLoad: false })
    const formRef = useRef()
    const submit = async (e) => {
        e.preventDefault()
        const form = new FormData(formRef.current)
        /* Create form request */
        form.append("tutor_id", data.tutorId)
        form.append("name", data.name)
        form.append("pay_amount", data.pay_amount)
        form.append("start_date", data.start_date)
        form.append("end_date", data.end_date)
        form.append("status", "Success")
        form.append("rate_per_minute", data.rate)
        form.append("total_calls", data.total_calls)
        form.append("total_minutes", data.call_duration)
        form.append("admin_id", helper.getid())

        if (!form.get('transaction_id')) {
            toast.error("Please enter transactionID")
            return
        }
        let resp = helper.validate("alphaNum",form.get('transaction_id'))
        if(!resp.isValid){
            toast.error("Please enter transactionID")
            return
        }
        setState({ ...state, isLoad: true })
        await payTutor(form).then((res) => {
            if (res.data.status) {
                onClose()
                cb(index)
            } else {
                toast.error(res.data.error)
            }
            setState({ ...state, isLoad: false })
        }).catch((err) => { toast.error(err); setState({ ...state, isLoad: false }) })

    }

    return (<>
        {open &&
            <div className="popup-modal h-100">
                <div className="right-popup notify-detail p-fix tp-0 rt-0 sm">
                    <div className="row gx-0 mx-0">
                        <div className="col-10 px-1">
                            <p className="f-fred fs-24">Pay tutor <span style={{ color: "#089D12" }}> form</span></p>
                            <p className="sub-heading" style={{ color: "#434943" }}>Pay now</p>
                        </div>
                        <div className="col-2 px-1">
                            <FiXCircle className="pointer float-right " size="2rem" onClick={() => onClose()} style={{ color: "lightgray" }} />
                        </div>
                    </div>
                    <form ref={formRef} onSubmit={submit}>
                        <section className='mt-3'>
                            <div className="form-box">
                                <label htmlFor="">Transaction Id</label>
                                <input type="text" maxLength={30} placeholder='Transaction id' className='input-box' name="transaction_id" id="transaction_id" />
                            </div>
                            <div className="form-box">
                                <label htmlFor="">Payable amount(SAR)</label>
                                <input readOnly type="number" placeholder='Payable amount' className='input-box' defaultValue={parseFloat(data.pay_amount).toFixed(2)} />
                            </div>
                            <div className="form-box">
                                <label htmlFor="">Total Duration</label>
                                <input readOnly type="text" className='input-box' defaultValue={data.call_duration} />
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-box">
                                        <label htmlFor="">Start date</label>
                                        <input readOnly type="date" className='input-box' defaultValue={moment(data.start_date).format("YYYY-MM-DD")} />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-box">
                                        <label htmlFor="">End date</label>
                                        <input readOnly type="date" className='input-box' defaultValue={moment(data.end_date).format("YYYY-MM-DD")} />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-box">
                                        <label htmlFor="">Rate per minutes</label>
                                        <input readOnly min={0} type="number" className='input-box' defaultValue={data.rate} />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-box">
                                        <label htmlFor="">Total calls</label>
                                        <input readOnly type="number" className='input-box' defaultValue={data.total_calls} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-box">
                                <label htmlFor="">Invoice</label>
                                <input type="file" name="file_name" id="file_name" />
                            </div>
                        </section>
                        <section>
                            <button type='submit' className='btn btn-login' style={{ width: '200px' }}>Submit</button>
                        </section>
                    </form>
                </div>

                {/* Handle loader */}
                {state.isLoad && <Backdrop />}
            </div>

        }
    </>);
}
