import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ProfileIcon from '../../components/ProfileIcon';
import referral from '../../controller/referral';
import ReferralDetailCard from './ReferralDetailCard';
import Backdrop from '../../component/Backdrop';
import { FaBell } from 'react-icons/fa';
import helper from '../../helper';
export default function ReferralDetail() {
  const history = useHistory()
  const [resData, setResData] = useState([])
  const [pageload, setPageload] = useState(true)
  const { userId } = useParams()
  useEffect(() => { getReferral() }, [])
  const getReferral = async () => {
    await referral.getReferralDetail(userId).then((res) => {
      if (res.data.status) {
        setResData(res.data.result)
      }
      setPageload(false)
    })
  }
  return (<>
    <div className='mx-1'>
      <header className='row gx-0 mx-0 mb-3 py-1'>
        <div className="col-lg-4 col-md-5 col-6 d-flex align-items-center px-1">
          <button className="btn btn-back  mr-2" onClick={() => history.goBack()}>BACK</button>

          <div>  <p className="mb-1 fs-24 f-fred"> <i class="fas fa-bars mr-1 fs-20 pointer d-lg-none  d-md-none d-inline-block"></i>Referral <span className='tx-green'>manager</span></p>
            <p className=" lh-15 sub-heading">Referral management</p>
          </div>
        </div>
        <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
          <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >
            <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
          </p>
          <ProfileIcon />
        </div>
      </header>

      <section>
        {
          resData.map((data) =>
            <ReferralDetailCard
              onClick={() => history.push(`/${helper.getRole()}/dashboard/student/${data.student_id}`)}
              plan={data}
            />)
        }
        {resData.length === 0 && <h6 className='my-3 text-center'>No Successfull referral found</h6>}

      </section>

      {pageload && <Backdrop />}
    </div>
  </>);
}
