import React, { useState, useEffect } from 'react'
import flag from '../../static/images/flag.png'
import admin from '../../controller/admin';
import Backdrop from '../../component/Backdrop';
import helper from '../../helper/index'
import { useHistory, useParams } from 'react-router-dom';
import ProfileIcon from '../../components/ProfileIcon';
import ChatBox from './ChatBox'
import avatar from '../../static/images/avatar.png'
import { FaBell } from 'react-icons/fa';
import moment from 'moment';
import ReactStars from "react-rating-stars-component";
export default function UserTicketDetail() {
    const history = useHistory()
    const { userId } = useParams()
    const [error, setError] = useState('')
    const [supDet, setsupDet] = useState([])
    const [pageLoad, setpageLoad] = useState(true)

    useEffect(() => {
        apiCall()
        return () => {
        }
    }, [])

    const apiCall = async () => {
        await admin.getTicketDetail(userId)
            .then((res) => {
                if (res.data.status) {
                    if (res.data.result.length > 0) {
                        setsupDet(res.data.result[0])
                    } else {
                        setError("Ticket might be removed")
                    }

                }

                setpageLoad(false)

            })
    }
    return (
        <>
            {!pageLoad && !error ? <>
                <section className='mx-1'>
                    <header className='row gx-0 mx-0 mb-3 py-1'>
                        <div className="col-lg-4 col-md-5 col-6 d-flex align-items-center px-1">
                            <button className="btn btn-back mr-2" onClick={() => history.goBack()}>BACK</button>
                            <div>
                                <p className="mb-1 fs-24 f-fred"> <i class="fas fa-bars mr-1 fs-20 pointer d-lg-none  d-md-none d-inline-block"></i>DH-3242</p>
                                <p className="sub-heading lh-15">Support ticket</p>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                            <button className={`btn ${supDet.status == "Close" ? ' btn-close' : 'btn-open'}  fs-16 py-1 px-4 mx-2 ms-700`} >{supDet.status}</button>
                            <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                                <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                            </p>
                            <ProfileIcon />
                        </div>
                    </header>
                </section>
                <div className="row gx-0 mx-0">
                    <div className="col-lg-5 px-2">
                        <section>
                            <div className="quote-card cur-pointer tx-lt-grey bg-smoke border-radius-1 py-2 my-2 ">
                                <div className="row gx-0 mx-0">
                                    <div className="col-2 px-2 my-1 all-center">
                                        <span className="pointer">
                                            <img width="50px" height="50px" className="rounded-circle" src={supDet.profilePicture ? helper.replaceS3Url(supDet.profilePicture) : avatar} /></span>
                                    </div>
                                    <div className="col-8 px-2 my-1">
                                        <p className="f-fred fs-14 ">{supDet.name}</p>
                                        <p className="ms-500 fs-12">{supDet.role} <span className="yr">{supDet.country}</span></p>
                                        {supDet.role === "Tutor" &&
                                            <div>
                                                <ReactStars
                                                    edit={false}
                                                    value={supDet.ratings}
                                                    count={5}
                                                    size={15}
                                                    activeColor="#ffcb00"
                                                />
                                            </div>}
                                    </div>
                                    <div className="col-2 px-2 my-1 d-flex align-items-end justify-content-end">
                                        <span className=" text-right pointer" ><img width="24px" height="24px" className="rounded-circle" src={flag} alt="" /></span>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="my-address py-3 br-1 tx-lt-grey my-2 px-3">
                            <div className="row gx-0 mx-0 f-fred mb-3">
                                <div className="col-12">
                                    <p className="fs-24">Ticket Details</p>
                                </div>
                            </div>
                            <div className="address-body fs-14">
                                <div className="row gx-0 mx-0">
                                    <div className="col-4">
                                        <p className="ms-500 ">Ticket ID</p>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                        <p className="ml-auto ms-900 ml-auto">{supDet.id}</p>
                                    </div>
                                    <div className="col-12 my-1"><div className='border-dot'></div></div>
                                    <div className="col-4">
                                        <p className="ms-500 ">Category</p>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                        <p className="ml-auto ms-900 ml-auto text-capitalize">{supDet.category}</p>
                                    </div>
                                    <div className="col-12 my-1"><div className='border-dot'></div></div>
                                    <div className="col-4">
                                        <p className="ms-500 ">Sub category</p>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                        <p className="ml-auto ms-900 ml-auto text-capitalize">{supDet.sub_category}</p>
                                    </div>
                                    <div className="col-12 my-1"><div className='border-dot'></div></div>
                                    <div className="col-4">
                                        <p className="ms-500 ">Priority level</p>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                        <p className={`ml-auto ms-900 ${supDet.priority_level === "High" ? "tx-error" : supDet.priority_level === "Medium" ? "tx-sky" : "tx-yellow"}`}>{supDet.priority_level ? supDet.priority_level : "Low"}</p>

                                    </div>
                                    <div className="col-12 my-1"><div className='border-dot'></div></div>
                                    <div className="col-4">
                                        <p className="ms-500 ">Created On</p>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-8 d-flex justify-content-center">
                                        <p className="ml-auto ms-900">{moment(supDet.created_at).format('DD MMM YYYY')} {moment(supDet.created_at).format('hh : mm A')}</p>
                                    </div>

                                </div>
                            </div>
                        </section>
                        <section className="my-address py-3 tx-lt-grey br-1 my-2 px-3">
                            <div className="row gx-0 mx-0 f-fred mb-3">
                                <div className="col-12">
                                    <p className="fs-24">Ticket description</p>
                                </div>
                                <div className="col-12">
                                    <p className="ms-500" style={{ fontSize: "16px", color: "#434943", fontWeight: "500" }}>{supDet.description}</p>
                                </div>
                            </div>
                        </section>
                        {supDet.file_name &&
                            <section className="my-address my-3 py-3 px-3 br-1">
                                <a href={supDet.file_name} target={"_blank"}> <img src={helper.replaceS3Url(supDet.file_name)} className="w-100" alt="" /></a>
                            </section>}
                    </div>
                    {supDet.status === "Open" && <div className="col-lg-5 px-2 d-lg-block d-md-block d-none">
                        <ChatBox
                            myId={helper.getid()}
                            roomId={supDet.id}
                            userName={supDet.name}
                        />
                    </div>}
                </div>
                {pageLoad && <Backdrop />}

            </> :
                <p className='my-3 ms-700 text-center'>{error}</p>

            }

        </>
    )
}
