import React, { useState, useRef, useEffect } from 'react'
import store from '../../../redux/AppState'
import { useSelector } from 'react-redux'
import admin from '../../../controller/admin'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import ProfileIcon from '../../../components/ProfileIcon'
import { Toaster, toast } from 'react-hot-toast'
import helper from '../../../helper'
import Backdrop from '../../../component/Backdrop'
import { FaBell } from 'react-icons/fa'
import { fileUploadToS3 } from '../../../helper/fileupload'
export default function EditSupportVideo() {
    const { userId } = useParams()
    const history = useHistory()
    const title = useRef()
    const category = useRef()
    const [resData, setResData] = useState()
    const [pageload, setPageload] = useState(true)
    const [categoryList, setCategoryList] = useState([])


    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedVdo, setSelectedVdo] = useState(null);


    useEffect(() => { apiCall(); getCategory() }, [])

    const getCategory = async () => {
        await admin.getKB().then((res) => {
            if (res.data.status) {
                setCategoryList(res.data.result)
            }
        })

    }

    const apiCall = async () => {
        await admin.getTutorVdo(userId)
            .then((res) => {
                if (res.data.status) {
                    setResData(res.data.result[0])
                    setPageload(false)
                }
            })
    }

    const submit = async () => {

        const formData = new FormData();
        formData.append('title', title.current.value)
        formData.append('category', category.current.value)
        // formData.append('image_file', selectedImage)
        // formData.append('video_file', selectedVdo)
        formData.append('admin_id', helper.getid())
        formData.append('id', userId)

        if (!formData.get('title')) {
            toast("please enter video title")
            return
        }
        if (!formData.get('category')) {
            toast("please select category")
            return
        }
        if (selectedVdo && ((selectedVdo.size / 1024) / 1024) > 25) {
            toast("Video size should not be greater than 25 MB")
            return
        }

        setPageload(true)
        if (selectedVdo) {
            let videoURL = await fileUploadToS3("support_video/", selectedVdo)
            formData.append('video_file', videoURL)

        }
        if (selectedImage) {
            let imgURL = await fileUploadToS3("support_image/", selectedImage)
            formData.append('image_file', imgURL)
        }

        await admin.supportVdo(formData)
            .then((res) => {
                if (res.data.status) {
                    toast(res.data.result)
                }
                else {
                    toast(res.data.error)
                }
                setPageload(false)
            })
    };
    return (
        <>

            <Toaster
                position="top-center"
                reverseOrder={false}
                containerClassName="ms-700"
            />
            <div className="mx-1">
                {!pageload && <>

                    <header className='row gx-0 mx-0 mb-3 py-1'>
                        <div className="col-lg-4 col-md-5 col-6 d-flex align-items-center px-1">
                            <button className="btn btn-back mr-2" style={{ fontWeight: "900" }} onClick={() => history.goBack()}>BACK</button>
                            <div>
                                <p className="mb-1 fs-18 f-fred text-capitalize"> <i class="fas fa-bars mr-1 fs-20 pointer d-lg-none  d-md-none d-inline-block" onClick={() => store.setMenu(true)}></i>Edit support video</p>
                                <p className="sub-heading lh-15 text-capitalize">SayDhad support video</p>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                            <button className="btn btn-update mx-2 d-lg-block d-md-block d-none" onClick={submit}>UPDATE CONTENT</button>

                            <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                                <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                            </p>
                            <ProfileIcon />
                        </div>
                    </header>
                    <div className="mx-1">
                        <div className="row  gx-0 mx-0  ">
                            <div className="col-lg-8 px-0 py-2 ">
                                <div className="row gx-0 mx-0 edit-profile ">
                                    <div className="col-lg-12  edit-input ms-700  px-0">
                                        <input type="text" placeholder="Enter video title" className='text-capitalize' defaultValue={resData.title} style={{ border: "1px solid lightgray" }} ref={title} />
                                    </div>

                                    <div className="col-lg-12 px-0">
                                        <div className="row gx-0 mx-0 f-fred">

                                            <div className="mr-2">
                                                <button className="btn btn-dark px-1 py-1 ms-900 mr-1 ms-900 fs-14" onClick={() => setSelectedVdo()}>Remove</button>

                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-lg-12 d-flex justify-content-center my-2 px-0">
                                        {/* <img width="100%" height="300px"  style={{borderRadius:"10px", display:"block",marginLeft:"auto", marginRight:"auto"}} src="https://www.english.com/blog/wp-content/uploads/2020/04/Teaching-online-FAQs-1132x670.jpg" alt="" />  */}

                                        <input
                                            type="file"
                                            name="myImage"
                                            onChange={(event) => {

                                                setSelectedVdo(event.target.files[0]);
                                            }}
                                        />
                                        <div className="my-4 py-4 ">
                                            {selectedVdo && <video width="100%" height="250px" src={URL.createObjectURL(selectedVdo)} controls> </video>}
                                        </div>


                                    </div>



                                </div>
                            </div>

                            <div className="col-lg-4 px-2 ">
                                <div className="quote-card cur-pointer bg-smoke border-radius-1 py-4 my-4 px-4  filter-cb">
                                    <p className="f-fred edit-profile mb-3" style={{ fontSize: "24px" }}>Select<span style={{ color: "#089D12" }}> category</span></p>
                                    <select className='ms-700 py-2 px-2' ref={category} style={{ width: "100%", borderRadius: "5px", border: "1px solid #EAEAEA" }}>
                                        {categoryList.map((data) => <option defaultValue={resData.category_name} value={data.category_name}>{data.category_name}</option>)}
                                    </select>
                                </div>


                                <div className="quote-card cur-pointer bg-smoke border-radius-1 py-4 my-2 px-4  filter-cb">
                                    <p className="f-fred" style={{ fontSize: "24px" }}>Featured<span style={{ color: "#089D12" }}> image</span></p>

                                    {selectedImage ?
                                        <div>
                                            <img className="my-2" alt="not found" accept=".png, .gif, .jpeg" style={{ maxWidth: '100%' }} height={"180px"} src={URL.createObjectURL(selectedImage)} />
                                            <br />
                                            <div className="my-2">
                                                <button className="btn btn-dark px-1 py-1 ms-900 mr-1 fs-14" onClick={() => setSelectedImage(null)}>Remove</button>

                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <img className="my-2" accept=".png, .gif, .jpeg" style={{ maxWidth: '100%' }} height={"180px"} src={helper.replaceS3Url(resData.featured_image_link)} />
                                            <br />
                                            <div className="my-2">
                                                <button className="btn btn-dark px-1 py-1 ms-900 mr-1 fs-14" onClick={() => setSelectedImage(null)}>Remove</button>

                                            </div>
                                        </div>

                                    }

                                    <input
                                        type="file"
                                        name="myImage"
                                        onChange={(event) => {

                                            setSelectedImage(event.target.files[0]);
                                        }}
                                    />

                                    <p className="py-1" style={{ fontSize: "14px", color: "#434943", fontWeight: "700", lineHeight: "12px" }}>Width: 400px; Height: 160px min</p>


                                </div>
                            </div>
                        </div>
                    </div>
                </>}

                {pageload && <Backdrop />}
            </div>
        </>
    )
}
