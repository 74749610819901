import React from 'react';
import moment from 'moment';
export default function UserManagerCard({ user, onClick }) {
    return (
        <>
            <div className="quote-card cur-pointer bg-smoke border-radius-1 py-2 my-2 ">
                <div className="row fs-14 tx-lt-grey gx-0 mx-0">
                    <div className="col-lg-1 col-md-4 col-12 all-center px-1 my-2">
                        <button className={`btn ${user.isActive ? 'btn-close' : 'btn-open'}  w-100 fs-16 py-1 px-2 ms-700`}>{user.isActive ? 'Active' : 'Inactive'}</button>
                    </div>
                    <div className="col-lg-1 col-md-4 col-6 px-2 my-1">
                        <p className="f-fred ">{user.managerId}</p>
                        <p className="ms-500 fs-12 ">User ID</p>

                    </div>
                    <div className="col-lg-1 col-md-4 col-6 px-2 my-1">
                        <p className="f-fred ">Manager</p>
                        <p className="ms-500 fs-12 ">User role</p>

                    </div>
                    <div className="col-lg-1 col-md-4 col-12 px-2 my-1">
                        <p className="f-fred overflow-elipse">{user.name ? user.name : 'N/A'}</p>
                        <p className="ms-500 fs-12 ">Name</p>

                    </div>
                    <div className="col-lg-3 col-md-4 col-12 px-2 my-1">
                        <p className="f-fred overflow-elipse">{user.email}</p>
                        <p className="ms-500 fs-12 ">Email address</p>

                    </div>
                    <div className="col-lg-1 col-md-4 col-12 px-2 my-1">
                        <p className="f-fred overflow-elipse">{user.mobileNo ? user.mobileNo : 'N/A'}</p>
                        <p className="ms-500 fs-12 ">Phone no</p>

                    </div> 
                    <div className="col-lg-2 col-md-4 d-lg-block d-md-block d-none col-12 px-2 my-1">
                        <p className="f-fred text-right overflow-elipse">{moment(user.createdOn).format('MMM DD YYYY')}</p>
                        <p className="ms-500 fs-12 text-right">Added on</p>

                    </div>
                    <div className="col-lg-1 d-lg-block d-md-block d-none col-md-4 col-12 px-2 my-1">
                        <p className="f-fred text-right">{moment(user.createdOn).format('hh:mm a')}</p>
                        <p className="ms-500 fs-12 text-right ">Time</p>

                    </div>

                    <div className="col-lg-1 col-md-4 col-12 px-2 my-1 all-center">
                        <button className="btn btn-detail w-100 py-1 px-2 f-fred fs-12" onClick={() => { onClick(user.id) }}>Details</button>

                    </div>


                </div>
            </div>
        </>
    )
        ;
}
