import React, { useState, useRef } from 'react'
import store from '../../redux/AppState'
import { useEffect } from 'react';
import admin from '../../controller/admin';
import Backdrop from '../../component/Backdrop';
import moment from 'moment';
import StudentCard from './StudentCard';
import ProfileIcon from '../../components/ProfileIcon';
import { useHistory } from 'react-router-dom';
import student from '../../controller/student';
import Filter from './Filter';
import helper from '../../helper/index'
import { useSelector } from 'react-redux'
import searchIcon from '../../static/images/search.svg'
import { FaBell } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';

export default function AllStudent() {
    const storeData = useSelector(state => state)
    const history = useHistory()
    const [pageLoad, setpageLoad] = useState(true)
    const [page, setPageNo] = useState(1)
    const [openFilter, setOpenFilter] = useState(false)
    const [storeFilterQuery, setStoreFilterQuery] = useState()
    const [queryType, setQueryType] = useState('')
    const [state, setState] = useState({ list: [], total: 0 })

    const query = useRef()
    useEffect(() => {
        apiCall(page)
        return () => { }
    }, [])

    const apiCall = async (pageNo) => {
        setpageLoad(true)
        await admin.getStdntPro(pageNo, 10, '')
            .then((res) => {
                if (res.data.status) {
                    setState({ ...state, list: res.data.result.user_list, total: res.data.result.total_count })
                }
                setpageLoad(false)
            })
    }

    const searchStudent = async (page) => {
        setQueryType('search')
        if (!query.current.value) {
            apiCall(1)
            setQueryType('')
            return
        }
        setpageLoad(true)
        await admin.getStdntPro(page, 10, query.current.value)
            .then((res) => {
                if (res.data.status) {
                    setState({ ...state, list: res.data.result.user_list, total: res.data.result.total_count })
                }
                setpageLoad(false)
            })
    }

    const getFilterResult = async (data, page) => {
        const req = {
            country: data.country,
            planType: data.planType,
            limit: 10,
            page: page
        }
         
        setStoreFilterQuery(req)
        setQueryType('filter')
        setpageLoad(true)
        await student.getFilteredStudent(req).then((res) => {
            if (res.data.status) {
                setState({ ...state, list: res.data.result.user_list, total: res.data.result.total_count })
            }
            setpageLoad(false)
            setOpenFilter(false)
        })
    }

    return (
        <>
            <div className="mx-1 mb-2">
                <header className='row gx-0 mx-0 mb-3 py-1'>
                    <div className="col-lg-4 col-md-5 col-6 d-flex flex-column justify-content-center px-1">
                        <p className="mb-1 fs-24 f-fred">
                            <span>
                                <i className="fas fa-bars mr-2 fs-20 hover-shadow pointer d-lg-inline-block d-md-inline-block d-none" onClick={() => store.setShowLeftBar(!storeData.showLeftBar)}></i>
                            </span>
                            <i class="fas fa-bars mr-1 fs-20 pointer d-lg-none  d-md-none d-inline-block" onClick={() => store.setMenu(true)}></i> SayDhad <span className="tx-green">students</span></p>
                        <p className="sub-heading ml-2">{moment(new Date).format('ddd, DD MMM YYYY hh:mm A')}</p>
                    </div>
                    <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                    <button className="btn btn-update mx-2 d-lg-block d-md-block d-none" onClick={() => history.push(`/${helper.getRole()}/dashboard/incomplete-student-registration`)}>INCOMPLETE REGISTRATION</button>
                  
                        <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                            <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                        </p>
                        <ProfileIcon />
                    </div>
                </header>

                <div className="tutor-library">
                    <form onSubmit={(e) => { e.preventDefault(); searchStudent(1);setPageNo(1) }}>
                        <div className="row gx-0 mx-0">
                            <div className="col-lg-3 col-md-4 col-12 px-1 mb-2">
                                <div className='d-flex w-100 align-items-center'>
                                    <input type="search" ref={query} placeholder="Search student..." className="w-100 pl-5 h-100 input-box px-2 py-2 fs-14 ms-700" />
                                    <img src={searchIcon} className="p-abs lt-0 pl-3" />
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-6 px-1 mb-2">
                                <button type='submit' className="btn btn-login w-100 h-100  wrap">Search</button>
                            </div>
                            <div className="col-lg-2 col-6 mb-2 px-1 mb-2 ">
                                <button type='button' className="btn btn-tutor-profile h-100 w-100  wrap " onClick={() => setOpenFilter(true)}>filter student</button>
                            </div>
                        </div>
                    </form>

                    <section className="grid-view">
                        <div className="grid-card">
                            {
                                state.list.map((result) =>
                                    <StudentCard
                                        key={result.id}
                                        data={result}
                                        onClick={(userId) => history.push(`/${helper.getRole()}/dashboard/student/${userId}`)} />)
                            }
                            
                        </div>
                        {state.list.length === 0 && <p className='my-3 text-center'>No Record Found</p>}
                    </section>

                </div>
                {state.total !== 0 &&
                <div className="col-12 px-2 c-pagination">
                    <ReactPaginate
                        previousLabel={<i class="fas fa-arrow-left"></i>}
                        nextLabel={<i class="fas fa-arrow-right"></i>}
                        pageCount={Math.ceil(state.total / 10)}
                        onPageChange={({ selected }) => {
                            if (queryType === 'search') {
                                searchStudent(selected + 1)
                            }
                            else if (queryType === 'filter') {
                                getFilterResult(storeFilterQuery,selected + 1);
                            } else {
                                apiCall(selected + 1);
                            }
                            setPageNo(selected + 1)
                        }}
                        containerClassName={"paginationBttns"}
                        previousClassName={"previousBttn"}
                        nextClassName={"nextBttn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                        pageClassName={"eachpage"}
                    />
                </div>
}
                {/* pageload compoent  */}
                {pageLoad && <Backdrop />}
                {openFilter && <Filter cb={(data) => { getFilterResult(data, 1);setPageNo(1) }} onClose={() => setOpenFilter(false)} />}

            </div>
        </>
    )
}
