import React, { useState, useEffect } from 'react'
import TextEditor from '../../../components/TextEditor'
import store from '../../../redux/AppState'
import helper from '../../../helper';
import admin from '../../../controller/admin';
import ProfileIcon from '../../../components/ProfileIcon';
import { useHistory } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';
import { FaBell } from 'react-icons/fa';
export default function Aboutus() {

    const history = useHistory()
    const [state, setState] = useState({
        pageload: true,
        data: null
    })
    const [editContent, setEditContent] = useState()

    useEffect(() => { apiCall() }, [])
    const apiCall = async () => {
        await admin.getWebContent()
            .then((res) => {
                if (res.data.status) {
                    setEditContent(res.data.result ? res.data.result.about_us :'')
                    setState({ ...state, data: res.data.result, pageload: false })
                }
            })
    }

    const update = async () => {
        const reqData = {
            "about_us": editContent,
            "admin_id": helper.getid()
        }
        if (!reqData.about_us) {
            toast('About us cannot be blank')
            return
        }
        await admin.editAboutUs(reqData)
            .then((res) => {
                if (res.data.status) {
                    toast('About us successfully updated')
                }
                else {
                    toast(res.data.error)
                }
            })
    }

    return (
        <>
            <Toaster
                position="top-center"
                reverseOrder={false}
                containerClassName="ms-700"
            />
            <div className="mx-1">
                <header className='row gx-0 mx-0 mb-3 py-1'>
                    <div className="col-lg-4 col-md-5 col-6 d-flex align-items-center px-1">
                        <button className="btn btn-back px-2 py-1 fs-14 ms-700 mr-2" style={{ fontWeight: "900" }} onClick={() => history.goBack()}>BACK</button>
                        <div>
                            <p className="mb-1 fs-24 f-fred"> <i class="fas fa-bars mr-1 fs-20 pointer d-lg-none  d-md-none d-inline-block" onClick={() => store.setMenu(true)}></i>Edit <span className="tx-green">content</span></p>
                            <p className="lh-15 sub-heading">About us</p>

                        </div>
                    </div>
                    <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                        <button className="btn btn-update mx-2 d-lg-block d-md-block d-none" onClick={update}>UPDATE CONTENT</button>
                        <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                            <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                        </p>
                        <ProfileIcon />
                    </div>
                </header>
                <section>
                    {
                        !state.pageload && <TextEditor
                            onChange={(data) => setEditContent(data)}
                            data={state.data ? state.data.about_us : '<p>Type here</p>'} />
                    }
                </section>


            </div>

        </>
    )
}
