import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom'
import './static/fonts/MuseoSansRounded100.woff'; 
import './static/fonts/MuseoSansRounded300.woff'; 
import './static/fonts/MuseoSansRounded500.woff'; 
import './static/fonts/MuseoSansRounded700.woff'; 
import './static/fonts/MuseoSansRounded900.woff'; 
import './static/fonts/MuseoSansRounded1000.woff'; 
import './static/css/index.css'
import './static/css/auth.css'
import './static/css/common.css'
import './static/css/admin.css'
import './static/css/pagination.css'
import 'react-multiple-select-dropdown-lite/dist/index.css'
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
