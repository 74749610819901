import React from 'react'
import helper from '../helper'

export default function VideoPop({ file, onClose }) {
    return (
        <div className="popup-modal all-center">
            <div className="popup-modal-body p-rel all-center flex-column p-2 md px-2" style={{ backgroundColor: 'tranparent' }}>
                <div className='py-2 bg-white w-100' >
                    <p className='text-right f-fred pointer mr-4 tx-red' onClick={onClose}>CLOSE</p>
                </div>
                <video controls src={helper.replaceVideoS3Url(file)} style={{ maxWidth: '100%' }}></video>
            </div>
        </div>
    )
}
