import React, {useState} from 'react'
import { FiXCircle } from "react-icons/fi";
import 'react-calendar/dist/Calendar.css';
// import Calendar from 'react-calendar';


export default function CouponManagerEdit(props) {
    const [value, onChange] = useState(new Date());
    
    return (
        
        <>
        
            <div className="  all-center popup-modal">
                <div className="right-popup notify-detail p-fix tp-0 rt-0  px-3 py-3 sm" >
                    <div className="row gx-0 mx-0">
                        <div className="col-lg-10">
                            <p className="f-fred" style={{fontSize:"22px"}}>Edit<span style={{color:"#089D12"}}> coupon</span></p>
                            <p className="f-robo " style={{color:"#434943",fontSize:"14px", fontWeight:"700"}}>SayDhad coupon manager</p>
                        </div>
                        <div className="col-lg-2">
                            <FiXCircle className="pointer float-right " size="2rem" onClick={props.CallClose} style={{color:"lightgray"}} />
                        </div>
                    </div>
                    

                    <div className="row gx-0 mx-0  f-fred">
                        <div className="col-lg-12 col-3 pt-4">
                            <label class="switch">
                                <input type="checkbox"/>
                                <span class="slider round"></span>
                            </label>
                            <span className="pl-2">Active</span>
                        </div>
                    </div>
                   
                    
                    <div className="row  gx-0 mx-0 edit-profile">
                        <div className="col-lg-12 edit-input">
                        <input placeholder="10.00 Discount %" type="text" style={{border:"1px solid lightgray"}} />          
                        </div>
                    </div>
                    
                    <div className="row  gx-0 mx-0 edit-profile ">
                        <div className="col-lg-12 edit-input">
                        <input placeholder="SAYDHAD10 Code" type="text" style={{border:"1px solid lightgray"}} />          
                        </div>
                    </div>

                    <div className="row gx-0 mx-0 edit-profile ">
                       
                        <div className="col-lg-6 pt-4" >
                            
                        <input placeholder="Expiry date" type="date" style={{border:"1px solid lightgray"}}  required />          

                        </div>
                        
                        <div className="col-lg-6  pt-4 text-right ">
                        <input placeholder="Expiry time" type="time" style={{border:"1px solid lightgray"}}  required />          
                            
                        </div>
                    </div>
                    <div className="row  gx-0 mx-0 py-2 my-2">
                        <div className="col-lg-12">
                            <button className="btn btn-update px-2 py-2 f-fred  btn-sm col-xs-2">UPDATE COUPON</button>
                        </div>
                    </div>
                        
                        
                            
                            
                        
                       
                
                   
                </div>
            </div>
           
        </>
    )
}
