import * as React from "react";

const SvgPayout = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <defs>
      <clipPath id="Payout_svg__a">
        <path
          data-name="Rectangle 437"
          transform="translate(9272 4405)"
          fill="#fff"
          stroke="#707070"
          d="M0 0h30v30H0z"
        />
      </clipPath>
    </defs>
    <g
      data-name="Mask Group 36"
      transform="translate(-9272 -4405)"
      clipPath="url(#Payout_svg__a)"
    >
      <g data-name="Iconly/Bold/Activity">
        <path
          data-name="Path 4430"
          d="M9291.14 4410.136a5.688 5.688 0 0 0-.072.9 4.877 4.877 0 0 0 4.872 4.883 5.556 5.556 0 0 0 .888-.083v9.683c0 4.069-2.4 6.481-6.48 6.481h-8.867c-4.081 0-6.481-2.412-6.481-6.481v-8.879c0-4.08 2.4-6.5 6.481-6.5h9.659Zm.241 7.3a.922.922 0 0 0-.841.372l-2.9 3.756-3.328-2.62a.948.948 0 0 0-.684-.192.93.93 0 0 0-.6.359l-3.551 4.621-.077.108a.9.9 0 0 0 .252 1.14 1.008 1.008 0 0 0 .552.18.851.851 0 0 0 .708-.36l3.012-3.877 3.42 2.569.108.071a.9.9 0 0 0 1.14-.252l3.468-4.475-.048.024a.9.9 0 0 0 .1-.9.885.885 0 0 0-.731-.528Zm4.727-9.436a2.892 2.892 0 1 1-2.892 2.892 2.894 2.894 0 0 1 2.892-2.892Z"
          fill="#888c8b"
        />
      </g>
    </g>
  </svg>
);

export default SvgPayout;
