import React, { useState, useRef, useEffect } from 'react'
import { FiXCircle } from "react-icons/fi";
import country from '../../helper/country.json'
import MultiSelect from 'react-multiple-select-dropdown-lite';
export default function Filter({ onClose ,cb}) {
    const [planType, setplanType] = useState([])
    const [countryList, setCountryList] = useState([])
    const [options, setOptions] = useState([])

    useEffect(() => {
        let list = []
        country.forEach((data) => {
            list.push({ label: data.name, value: data.name })
        })
        setOptions(list)
    }, [])


   

    const addPlanType = (status) => {
        let list = planType
        if (list.includes(status)) {
            let index = list.indexOf(status)
            list.splice(index, 1)
        }
        else {
            list.push(status)
        }
        setplanType(list)
    }

    const addCountry = (cn) => {
        setCountryList(cn)
    }

    const submit = () => {
        cb({planType : planType.join(','),country : countryList })
        
    }

    return (
        <>
            <div className="all-center popup-modal">
                <div className="right-popup notify-detail p-fix tp-0 rt-0 tx-lt-grey" >
                    <div className="row gx-0 mx-0">
                        <div className="col-10 px-0">
                            <p className="f-fred" style={{ fontSize: "24px" }}>Filter <span style={{ color: "#089D12" }}> Student</span></p>
                            <p className="sub-heading" style={{ color: "#434943" }}>SayDhad tutors</p>
                        </div>
                        <div className="col-2 px-0 d-flex align-items-center justify-content-end ">
                            <FiXCircle className="pointer " size="2rem" onClick={() => onClose()} style={{ color: "lightgray" }} />
                        </div>
                    </div>

                    <p className='f-fred fs-20 my-3'>Via country</p>
                    <section className='rating-filter ms-700 fs-16'>
                        <MultiSelect
                            className='w-100 fs-20'
                            placeholder="Country"
                            disableChip={false}
                            chipAlternateText="country"
                            options={options}
                            style={{ height: '100%' }}
                            onChange={(data) => { addCountry(data) }}
                        />
                    </section>

                    <p className='f-fred fs-20 my-3'>Via subscription plan</p>
                    <section className='rating-filter ms-500 fs-14'>
                        <div className="d-flex justify-content-between filter-cb align-items-center">
                            <label for="monthly" className='pointer' > Monthly</label>
                            <input className="float-right" type="checkbox" onChange={() => addPlanType('Month')} id="monthly" />
                        </div>
                        <div className="d-flex justify-content-between filter-cb align-items-center">
                            <label for="quarter" className='pointer'> Quarter</label>
                            <input className="float-right" type="checkbox" onChange={() => addPlanType('Quarter')} id="quarter" />
                        </div>
                        <div className="d-flex justify-content-between filter-cb align-items-center">
                            <label for="semester" className='pointer' > Semester</label>
                            <input className="float-right" type="checkbox" onChange={() => addPlanType('Semester')} id="semester" />
                        </div>
                    </section>

                    <div className="row gx-0 mx-0 my-3">
                        <div className="col-lg-5 mx-2">
                            <div><button className="btn btn-update ms-700 btn-apply " onClick={submit}>APPLY FILTERS</button></div>

                        </div>
                        <div className="col-lg-5 mx-3 ">
                            <div><button className="btn btn-clear" onClick={()=>{  cb({planType : '',country :'' })}}>CLEAR FILTERS</button></div>

                        </div>
                    </div>


                </div>
            </div>

        </>
    )
}
