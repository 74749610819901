import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import admin from '../../controller/admin'
import { FiXCircle } from "react-icons/fi";
import helper from '../../helper/index';
import { Toaster, toast } from 'react-hot-toast';
import Backdrop from '../../component/Backdrop';
export default function AddCategory({ CallClose, OnAddCourseCat }) {
    const [resData, setresData] = useState()
    const [pageload, setPageload] = useState(false)
    const addCourseCat = async () => {
        const reqData = {
            "admin_id": helper.getid(),
            "category_name": resData
        }
        if (!reqData.category_name) {
            toast("Please enter category name")
            return
        }
        setPageload(true)
        await admin.addCourseCat(reqData)
            .then((res) => {
                if (res.data.status) {
                    
                    toast(res.data.resul)
                    setTimeout(() => {
                        CallClose()
                        OnAddCourseCat()
                    }, 1000)
                } else {
                    toast(res.data.error)
                }
                setPageload(false)
            })
    }




    return (
        <>
             
            <div className="  all-center popup-modal">
                <div className="right-popup notify-detail p-fix tp-0 rt-0  px-3 py-3 sm" >
                    <div className="row gx-0 mx-0">
                        <div className="col-10">
                            <p className="f-fred fs-24">Add<span style={{ color: "#089D12" }}> course category</span></p>
                            <p className="sub-heading" style={{ color: "#434943" }}>Dhad course manager</p>
                        </div>
                        <div className="col-2">
                            <FiXCircle className="pointer float-right " size="2rem" onClick={CallClose} style={{ color: "lightgray" }} />
                        </div>
                    </div>

                    <div className="row  gx-0 mx-0 edit-profile ms-700 mt-3">
                        <div className="col-lg-12 edit-input">
                            <input className='text-capitalize' placeholder="Type category Name" type="text" style={{ border: "1px solid lightgray" }} onChange={(e) => { setresData(e.target.value) }} required />
                        </div>
                    </div>

                    <div className="row  gx-0 mx-0 py-2 my-2">
                        <div className="col-lg-12">
                            <button className="btn btn-update px-2 py-2 ms-700 fs-14  btn-sm col-xs-2" onClick={addCourseCat}>ADD COURSE CATEGORY</button>
                        </div>
                    </div>
                </div>
            </div>
            {pageload && <Backdrop />}
        </>
    )
}
