/* eslint-disable */
import React, { useState, useEffect } from 'react';
import AttendanceCard from './AttendanceCard';
import 'react-dates/lib/css/_datepicker.css';
import tutor from '../../controller/tutor'
import moment from 'moment'
import ProfileIcon from '../../components/ProfileIcon';
import { useHistory, useParams } from 'react-router-dom';
import { DateRangePicker } from 'react-dates';
import ChatPop from './ChatPop';
import helper from '../../helper';
import { FaBell } from 'react-icons/fa';
import Backdrop from '../../component/Backdrop';
import VideoPop from '../../components/VideoPop';
import { constant } from '../../config/Constant';
export default function TutorAttendance({ }) {
    const { userId } = useParams()
    const history = useHistory()
    const [isAccess, setIsAccess] = useState(false)
    const [pageload, setPageload] = useState(true)
    const [state, setState] = useState({
        chatLogOpen: false,
        chatRoomId: null,
        name: '',
        pic: '',
        file: '',
        videoOpen: false
    })

    const [apiData, setApiData] = useState({
        amount: 0,
        attendanceList: [],
        onlineCalls: [],
        totalTime: 0
    })



    const [startDate, setStartDate] = useState(moment(new Date(new Date().setDate(new Date().getDate() - 30))));
    const [endDate, setEndDate] = useState(moment());
    const [focusedInput, setFocusedInput] = useState(null);


    useEffect(() => {
        setIsAccess(helper.checkModuleAccess({ moduleName: 'Tutor Manager' }))

        apiCall('', endDate)
    }, [])

    const apiCall = async (startDate, endDate) => {
        setPageload(true)
        const date = new Date()
        const nw = moment(new Date(date.setDate(date.getDate() - 30))).format('YYYY-MM-DD')
        const req = {
            tid: userId,
            startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : nw,
            endDate: moment(endDate).format('YYYY-MM-DD')
        }
        await tutor.getAttendaceRecord(req).then((res) => {
            if (res.data.status) {
                let list = []
                list = res.data.result.attendanceList.sort((a, b) => a.date < b.date ? 1 : a.date > b.date ? -1 : 0)
                setApiData({ ...apiData, ...res.data.result, attendanceList: list })
            }

            setPageload(false)
        })
    }



    const onRange = (st, en) => {
        if (st)
            setStartDate(st);
        if (en)
            setEndDate(en)
        if (st && en) {
            apiCall(st, en)
        }
    }

    return (
        <>
            {isAccess &&
                <div className='attendance mx-1' >
                    <header className='row gx-0 mx-0 mb-2 py-1'>
                        <div className="col-lg-4 col-md-5 col-6 d-flex align-items-center  px-1">
                            <button className='btn btn-back mr-2' onClick={() => history.goBack()}>BACK</button>
                            <div>
                                <p className="mb-1 fs-24 f-fred">Class <span className="tx-green">Record</span></p>
                                <p className="fs-14 ms-700 lh-15 sub-heading">{moment(new Date).format('ddd, DD MMM YYYY hh:mm A')}</p>

                            </div>
                        </div>
                        <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                            <button className="btn btn-update mx-2 d-lg-block d-md-block d-none" onClick={()=>history.push(`/${helper.getRole()}/dashboard/chat-logs/${userId}`)}>CHAT LOGS</button>
                            <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >
                                <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                            </p>
                            <ProfileIcon />
                        </div>
                    </header>
                    <section className="row gx-0 mx-0">
                        <div className="col-lg-3 col-md-4  col-12 px-1 my-2">
                            <div className="course-overview h-100 b-grey px-3 py-3 d-flex justify-content-between align-items-center">
                                <div>
                                    <p className="fs-18 f-fred">Online calls</p>
                                    <p className="sub-heading">From {moment(startDate).format('MMM DD, YYYY')} - {moment(endDate).format('MMM DD, YYYY')}</p>
                                </div>
                                <p className="ms-500 fs-40 tx-green">{apiData.onlineCalls}</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-6 px-1 my-2">
                            <div className="course-overview h-100 b-grey px-3 py-3 d-flex justify-content-between align-items-center">
                                <div>
                                    <p className="fs-18 f-fred">Total time</p>
                                    <p className="sub-heading">online calls</p>
                                </div>
                                <p className="ms-500 fs-40 tx-green">{apiData.totalTime}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-6 px-1 my-2">
                            <div className="course-overview h-100 b-grey px-3 py-3 d-flex justify-content-between align-items-center">
                                <div>
                                    <p className="fs-18 f-fred ">Total amount</p>
                                    <p className="sub-heading">SayDhad Fee</p>
                                </div>
                                <p className="ms-500 fs-40 tx-green">{apiData.amount}</p>
                            </div>
                        </div>
                    </section>
                    <section className='p-rel ms-700 fs-14 mx-1'>
                        <DateRangePicker
                            className="w-100 h-100 p-2"
                            showClearDates={false}
                            style={{ height: '100%' }}
                            navPrev={null}
                            initialStartDate={null}
                            disabled={false}
                            startDate={startDate}
                            isOutsideRange={() => false}
                            isDayBlocked={() => false}
                            enableOutsideDays={true}
                            numberOfMonths={2}
                            startDateId="s_id"
                            endDate={endDate}
                            endDateId="e_id"
                            onDatesChange={({ startDate, endDate }) => { onRange(startDate, endDate) }}
                            focusedInput={focusedInput}
                            onFocusChange={e => setFocusedInput(e)}
                            displayFormat="DD/MM/YYYY"
                        />

                    </section>

                    {
                        apiData.attendanceList.map((result) =>
                            <AttendanceCard
                                name={result.name}
                                course={result.title}
                                country={result.country}
                                callDuration={result.duration}
                                date={moment(result.date).format('DD MMM YYYY')}
                                time={moment(result.start_time, 'hh:mm').format('hh : mm A')}
                                img={result.profilePicture}
                                onVideo={() => setState({ ...state, videoOpen: true, file: result.record_url })}
                                onChat={() => setState({ ...state, chatLogOpen: true, name: result.name, pic: result.profilePicture, chatRoomId: result.channel_name })}
                            />
                        )
                    }

                </div>}
            {!isAccess &&
                <p className='my-3 text-center fs-18 ms-700'>Oops! You have not access. please contact your admin</p>
            }


            {state.chatLogOpen &&
                <ChatPop
                    roomId={state.chatRoomId}
                    name={state.name}
                    pic={state.pic}
                    onClose={() => { setState({ ...state, chatLogOpen: false }) }}
                />
            }
            {
                state.videoOpen &&
                <VideoPop
                    onClose={() => setState({ ...state, videoOpen: false })}
                    file={constant.AWS_S3_URL + state.file}
                />
            }
            {pageload && <Backdrop />}
        </>);
}
