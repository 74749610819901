import React, { useState, useRef } from 'react'
import TutorKBCategoryEdit from '../../../adminDash/TutorKBCategoryEdit';
import store from '../../../redux/AppState'
import admin from '../../../controller/admin';
import { useEffect } from 'react';
import moment from 'moment';
import TutorKBCategoryAdd from '../../../adminDash/TutorKBCategoryAdd';
import { useHistory } from 'react-router-dom';
import ProfileIcon from '../../../components/ProfileIcon';
import { FaBell } from 'react-icons/fa';
import helper from '../../../helper';



export default function SupportVideoCategory() {
    const history = useHistory()
    const [Edit, setEdit] = useState({ open: false, id: 0, data: "" });
    const toggleEdit = (idValue, data) => {
        setEdit({ ...Edit, open: !Edit.open, id: idValue, data: data });
    }
    const [AddCat, setAddCat] = useState(false);
    const toggleAddCat = () => {
        setAddCat(!AddCat);
    }
    const [getData, setgetData] = useState([])
    const [searchData, setSearchData] = useState([])
    const qry = useRef()

    useEffect(() => {
        apiCall()
        return () => {

        }
    }, [])

    const apiCall = async () => {
        await admin.getKB()
            .then((res) => {
                if (res.data.status) {
                    setSearchData(res.data.result)
                    setgetData(res.data.result)
                }
            })
    }

    const search = () => {
        if (qry.current.value) {
            let regEx = new RegExp(qry.current.value, 'si')
            const res = getData.filter((result) => {
                return ((result.id.toString()).match(regEx)) || (result.category_name).match(regEx)
            })
            setSearchData(res)
        }
        else {
            setSearchData(getData)
        }
    }
    return (
        <>
            <div className="mx-1">
                <header className='row gx-0 mx-0 mb-3 py-1'>
                    <div className="col-lg-6 col-md-5 col-6 d-flex align-items-center px-1">
                        <button className="btn btn-back   mr-2" style={{ fontWeight: "900" }} onClick={() => history.goBack()}>BACK</button>
                        <div>
                            <p className="mb-1 fs-24 f-fred"> <i class="fas fa-bars mr-1 fs-20 pointer d-lg-none  d-md-none d-inline-block" onClick={() => store.setMenu(true)}></i>Support <span className="tx-green">category</span></p>
                            <p className="lh-15 sub-heading">SayDhad support</p>

                        </div>
                    </div>
                    <div className="col-lg-6 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                        <button className="btn btn-update mx-2 d-lg-block d-md-block d-none" onClick={toggleAddCat}>ADD NEW CATEGORY</button>

                        <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                            <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                        </p>
                        <ProfileIcon />
                    </div>
                </header>

                <div className="tutor-library mx-1">
                    <form onSubmit={(e) => { e.preventDefault(); search() }}>
                        <div className="row gx-0 mx-0">
                            <div className="col-lg-3 col-md-4 col-12 px-1 mb-2">
                                <input type="search" ref={qry} placeholder="Type name/id" className="w-100 h-100 input-box px-2 py-2 fs-14 ms-700" />
                                {/* { <i type='button' className="fas fa-times p-abs rt-0 mr-2 my-2 pointer" ></i>} */}
                            </div>
                            <div className="col-lg-2 col-md-4 col-6 px-1 mb-2">
                                <button type='submit' className="btn btn-login w-100 h-100 wrap">search</button>
                            </div>
                        </div>
                    </form>



                    {searchData.map((cat, index) => {
                        return (<>
                            <div className="col-lg-12 col-md-12 col-12 px-0">
                                <div className="quote-card cur-pointer fs-14 bg-smoke border-radius-1 py-2 my-2 ">
                                    <div className="row gx-0 mx-0 tx-lt-grey">
                                        <div className="col-lg-2">
                                            <p className="f-fred ">{cat.id}</p>
                                            <p className="ms-500 fs-12">Category ID</p>

                                        </div>
                                        <div className="col-lg-4">
                                            <p className="f-fred ">{cat.category_name}</p>
                                            <p className="ms-500 fs-12">Category</p>

                                        </div>


                                        <div className="col-lg-2 px-4 my-1">
                                            <p className="f-fred text-right">{moment.utc(cat.created_at).format('MMM DD YYYY')}</p>
                                            <p className="ms-500 fs-12 text-right">Added on</p>

                                        </div>
                                        <div className="col-lg-2 px-4 my-1">
                                            <p className="f-fred text-right">{moment.utc(cat.created_at).format('HH:MM a')}</p>
                                            <p className="ms-500 fs-12 text-right ">Time</p>

                                        </div>
                                        <div className="col-lg-2">
                                            <button className="btn btn-detail w-100 py-1 px-2 f-fred fs-14" onClick={() => { toggleEdit(cat.id, cat) }}>Edit</button>

                                        </div>



                                    </div>
                                </div>
                            </div>
                        </>)
                    })}


                </div>

                {Edit.open &&
                    <TutorKBCategoryEdit
                        id={Edit.id}
                        data={Edit.data}
                        CallClose={() => { toggleEdit() }}
                        OnEdit={() => { apiCall() }}
                    />}

                {AddCat &&
                    <TutorKBCategoryAdd
                        CallClose={toggleAddCat}
                        OnAdd={(data) => {
                            let list = [...getData]
                            list.push(data)
                            setSearchData(list)
                            setgetData(list)
                        }}
                    />}
            </div>
        </>
    )
}
