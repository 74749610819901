import React, { useState, useEffect } from 'react'
import store from '../../redux/AppState'
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import ProfileIcon from '../../components/ProfileIcon';
import PayoutDetailCard from './PayoutDetailCard';
import { FaBell } from 'react-icons/fa';
import helper from '../../helper';
import { payoutDetails } from '../../controller/payout';
import Backdrop from '../../component/Backdrop';
import { DateRangePicker } from 'react-dates';
export default function PayoutDetails() {
    const history = useHistory()
    const { userId } = useParams()
    const [startDate, setStartDate] = useState(moment(new Date(new Date().setDate(new Date().getDate() - 30))));
    const [endDate, setEndDate] = useState(moment(new Date()));
    const [focusedInput, setFocusedInput] = useState(null);

    const [state, setState] = useState({
        classessList: [],
        filterdList: [],
        end_date: new Date(),
        onlineCalls: 0,
        start_date: new Date(),
        total_amount: 0,
        total_time: "",
        isLoad: true
    })

    /* Fetch Data On Rendering */
    useEffect(() => {
        async function getPayDetails() {
            await payoutDetails(userId).then((res) => {
                if (res.data.status) {
                    setStartDate(moment(res.data.result.start_date))
                    setEndDate(moment(res.data.result.end_date))
                    setState({ ...res.data.result, isLoad: false, filterdList: res.data.result.classessList })
                } else { }
            }).catch((err) => { })
        }
        getPayDetails()

    }, [])

    const onRange = (st, en) => {
        setStartDate(st);
        setEndDate(en)
        if (st && en) {
            const res = state.classessList.filter((result) => {
                return (moment(result.date).format('YYYY-MM-DD') >= moment(st).format('YYYY-MM-DD') && moment(result.date).format('YYYY-MM-DD') <= moment(en).format('YYYY-MM-DD'))
            })
              setState({ ...state, filterdList:res })
        }
    }

        return (
            <>
                <div className="mx-1">
                    <header className='row gx-0 mx-0 mb-3 py-1'>
                        <div className="col-lg-4 col-md-5 px-1 col-6 d-flex align-items-center px-1">
                            <button className="btn btn-back fs-14 mr-2" onClick={() => history.goBack()}>BACK</button>
                            <div>
                                <p className="mb-1 fs-24 f-fred"> <i class="fas fa-bars mr-1 fs-20 pointer d-lg-none  d-md-none d-inline-block" onClick={() => store.setMenu(true)}></i>Payout<span className="tx-green"> details</span></p>
                                <p className="sub-heading">Payout manager</p>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                            <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >
                                <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                            </p>
                            <ProfileIcon />
                        </div>
                    </header>
                    <div className="">
                        <section className="row gx-0 mx-0 px-2">
                            <div className="col-lg-4 pay" >
                                <div className="row gx-0 mx-0 payout h-100 ">
                                    <div className="col-lg-7 px-1">
                                        <div style={{ order: "1" }}>
                                            <p className='ms-900 fs-18 tx-lt-grey'>Online calls</p>
                                            <p className='ms-700 fs-14'>{moment(state.start_date).format("DD MMM")}- {moment(state.end_date).format("DD MMM YYYY")}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 px-1">
                                        <h2 className='ms-500 fs-30 my-3' style={{ color: "#035D0A" }}>{state.onlineCalls}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 pay" >
                                <div className="row gx-0 mx-0 payout h-100">
                                    <div className="col-lg-7 px-1">
                                        <div style={{ order: "1" }}>
                                            <p className='ms-900 fs-18'>Total time</p>
                                            <p className='ms-700 fs-14'>{state.onlineCalls || "0"} online calls</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 px-1">
                                        <h2 className='ms-500 fs-30 my-3' style={{ color: "#035D0A" }}>{state.total_time}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 pay">
                                <div className="row gx-0 mx-0 payout h-100">
                                    <div className="col-lg-6 px-1">
                                        <div style={{ order: "1" }}>
                                            <p className='ms-900 fs-18'>Total amount</p>
                                            <p className='ms-700 fs-14'>Excl. SayDhad Fee</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 px-1">
                                        <h2 className='ms-500 fs-30 my-3' style={{ color: "#035D0A" }}>{state.total_amount}</h2>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <div className="row gx-0 mx-0 px-2 my-3">
                            <div className="col-4 px-1">
                                <p className='ms-900 fs-24' style={{ color: "#1B221B" }}>Online calls</p>
                                <p className='ms-700 fs-14'>Details</p>
                            </div>
                            <div className="col-8 d-flex justify-content-end align-items-end ">
                                <div className="mr-2 ms-700 fs-14 pointer  tx-lt-black icon-border d-lg-block d-md-block d-none">
                                    <DateRangePicker
                                       
                                        maxDate={moment()}
                                        showClearDates={true}
                                        navPrev={null}
                                        initialStartDate={null}
                                        disabled={false}
                                        startDate={startDate}
                                        isOutsideRange={() => false}
                                        isDayBlocked={() => false}
                                        enableOutsideDays={true}
                                        numberOfMonths={2}
                                        startDateId="s_id"
                                        endDate={endDate}
                                        endDateId="e_id"
                                        onDatesChange={({ startDate, endDate }) => { onRange(startDate, endDate) }}
                                        focusedInput={focusedInput}
                                        onFocusChange={e => setFocusedInput(e)}
                                        displayFormat="DD/MM/YYYY"
                                    />
                                </div>
                            </div>
                        </div>

                        <section className='mx-1'>
                            <div className="grid-view">
                                <div className="grid-card">
                                    {
                                        state.filterdList.map((object) => <PayoutDetailCard
                                            userImg={object.student_prof_pic}
                                            tutorImg={object.tutor_prof_pic}
                                            userName={object.student_name}
                                            tutorName={object.tutor_name}
                                            rate={object.tutor_rate}
                                            amount={parseFloat(object.amount).toFixed(2)}
                                            callOn={object.date}
                                            time={object.start_time}
                                            callDuration={object.call_duration}

                                        />)}
                                </div>
                            </div>
                        </section>

                        {state.isLoad && <Backdrop />}
                    </div>


                </div>
            </>
        )
    }
