import axios from "axios";
import helper from "../helper/index";
import { constant } from "../config/Constant";
const BASE_URL = constant.BASE_URL

const getReferralList = () => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/admin/student/referral-minutes-list`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

const getReferralDetail = (sid) => {
    try {
        const token = helper.getToken()
        const res = axios({
            method: `get`,
            url: `${BASE_URL}/admin/student/share-referral-list?student_id=${sid}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    }
    catch (err) {
        return err;
    }
}

export default {
    getReferralList,
    getReferralDetail
}