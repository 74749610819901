import React from 'react'
import { Route, Redirect } from "react-router-dom";
import helper from '../helper/index';
export default function PrivateRoute({ component: Component, restricted, ...rest }) {
    return (
        <Route {...rest} render={props => (
            (helper.isLoggedIn().res && helper.isLoggedIn().role === "manager") && restricted ?
            <Component {...props} />
            :
            <Redirect to='/' />
        )} />
    )
}