import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import ProfileIcon from '../../components/ProfileIcon';
import PayoutCard from './PayoutCard';
import TutorCard from '../tutor/TutorCard';
import PayTutorCard from './PayTutorCard';
import ProceedToPay from './ProceedToPay';
import { getTutorListForPay } from '../../controller/payout';
import { FaBell } from 'react-icons/fa';
import Backdrop from '../../component/Backdrop';

export default function PayTutor() {
    const history = useHistory()
    const [startDate, setStartDate] = useState(moment(new Date(new Date().setDate(new Date().getDate() - 30))));
    const [endDate, setEndDate] = useState(moment(new Date()));
    const [focusedInput, setFocusedInput] = useState(null);

    const [state, setState] = useState({
        data: [],
        load: true,
        error: false
    })

    const [proceedToPay, setProceedToPay] = useState({
        open: false,
        data: null,
        index: -1
    })


    const onRange = (st, en) => {
        setStartDate(st);
        setEndDate(en)
        if (st && en) {
            getTutor(moment(st).format('YYYY-MM-DD'), moment(en).format('YYYY-MM-DD')
            )
        }
    }

    useEffect(() => {
        getTutor(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
    }, [])

    const getTutor = async (start, end) => {
        setState({ ...state, data: [], load: true })
        await getTutorListForPay({ startDate: start, endDate: end }).then((res) => {
            if (res.data.status) {
                setState({ ...state, data: res.data.result, load: false })
            } else {
                setState({ ...state, data: [], load: true })
            }
        })
    }

    const handleCallBack = (index) => {
        let list = [...state.data]
        list.splice(index, 1)
        setState({ ...state, data: list })
    }

    return (<>
        <div className='pay-tutor mx-1'>
            <header className='row gx-0 mx-0 mb-3 py-1'>
                <div className="col-lg-4 col-md-5 px-1 col-6 d-flex align-items-center">
                    <button className="btn btn-back mr-2" onClick={() => history.goBack()}>BACK</button>

                    <div>
                        <p className="mb-1 fs-24 f-fred">Pay <span className="tx-green">tutor</span></p>
                        <p className="lh-15 sub-heading">Payout manager</p>
                    </div>
                </div>
                <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                    <div className="mr-2 ms-700 fs-12 pointer  tx-lt-black icon-border d-lg-block d-md-block d-none">
                        <DateRangePicker
                            showClearDates={true}
                            navPrev={null}
                            initialStartDate={null}
                            disabled={false}
                            startDate={startDate}
                            isOutsideRange={() => false}
                            isDayBlocked={() => false}
                            enableOutsideDays={true}
                            numberOfMonths={2}
                            startDateId="s_id"
                            endDate={endDate}
                            endDateId="e_id"
                            onDatesChange={({ startDate, endDate }) => { onRange(startDate, endDate) }}
                            focusedInput={focusedInput}
                            onFocusChange={e => setFocusedInput(e)}
                            displayFormat="DD/MM/YYYY"
                        />
                    </div>
                    <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push('/admin/dashboard/notification')} >
                        <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                    </p>
                    <ProfileIcon />
                </div>
            </header>

            <section className='mx-1'>
                {state.data.map((data, index) =>
                    <PayTutorCard
                        name={data.name}
                        amount={data.pay_amount}
                        ratings={data.rate}
                        rate={data.rate}
                        pic={data.profilePicture}
                        from={data.from}
                        duration={data.call_duration}
                        calls={data.total_calls}
                        isPaid={false}
                        onClick={() => setProceedToPay({ ...proceedToPay, open: true, data, index: index })}
                    />)}
            </section>

            <ProceedToPay
                open={proceedToPay.open}
                data={proceedToPay.data}
                index={proceedToPay.index}
                cb={(index) => handleCallBack(index)}
                onClose={() => setProceedToPay({ ...proceedToPay, open: false })}
            />

            {state.load && <Backdrop />}
        </div>
    </>);
}
