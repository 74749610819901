import { useState, useEffect } from 'react';
import { payoutListing } from '../../controller/tutor';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import helper from '../../helper';
export default function Payout() {
    const { userId } = useParams()
    const history = useHistory()

    /* maintain listing state */
    const [state, setState] = useState({ paidTutors: [], payout_analytics: {}, pageload: true, error: null })

    /* Calendar state */
    const [startDate, setStartDate] = useState(moment(new Date(new Date().setDate(new Date().getDate() - 30))));
    const [endDate, setEndDate] = useState(moment());
    const [focusedInput, setFocusedInput] = useState(null);

    /* Get Data Payout listing on before render */
    useEffect(() => {
        getPayout(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
    }, [])

    const getPayout = async (startDate, endDate) => {
        setState({ ...state, pageload: true, error: null })
        await payoutListing(userId, startDate, endDate).then((res) => {
            if (res.data.status) {
                setState({ ...res.data.result, pageload: false, error: null })
            } else {
                setState({ ...state, pageload: false, error: res.data.error })
            }
           
        }).catch((err) => { setState({ ...state, pageload: false, error: err }) })
    }

    /* Handle calendar */
    const onRange = (st, en) => {
        if (st) {
            setStartDate(st);
        }
        if (en) {
            setEndDate(en)
        }
        if (st && en) {
            getPayout(st.format('YYYY-MM-DD'), en.format('YYYY-MM-DD'))
        }
    }


    return (
        <div className="payout">
            <div className="row gx-0 mx-0">
                <div className="col-lg-6 px-2 py-2 my-2 d-flex align-items-center">
                    <p className="f-fred fs-24 tx-lt-grey">Payouts summary</p>
                </div>
                <div className="col-lg-6 d-flex justify-content-end align-items-center">
                    <div className="ms-700 fs-12 pointer tx-lt-black">
                        <DateRangePicker
                            showClearDates={false}
                            navPrev={null}
                            initialStartDate={null}
                            disabled={false}
                            startDate={startDate}
                            isOutsideRange={() => false}
                            isDayBlocked={() => false}
                            enableOutsideDays={true}
                            numberOfMonths={2}
                            startDateId="s_id"
                            endDate={endDate}
                            endDateId="e_id"
                            onDatesChange={({ startDate, endDate }) => { onRange(startDate, endDate) }}
                            focusedInput={focusedInput}
                            onFocusChange={e => setFocusedInput(e)}
                            displayFormat="DD/MM/YYYY"
                        />
                    </div>
                </div>
            </div>
            <div className="grid-view">
                <div className="grid-card">
                    {state.paidTutors.map((data) => {
                        return (<>
                            <div key={data.id} className="row gx-0 mx-0 ">
                                <div className="col-lg-12 col-md-12 col-12 px-1">
                                    <div className="quote-card cur-pointer bg-smoke border-radius-1 py-2 my-2 ">
                                        <div className="row gx-0 mx-0 fs-14 tx-lt-grey">
                                            <div className="col-lg-2 my-1">
                                                <p className="f-fred ">{data.transaction_id}</p>
                                                <p className="ms-500 fs-12">Transaction ID</p>
                                            </div>
                                            <div className="col-lg-1">
                                                <p className="f-fred ">{data.total_calls}</p>
                                                <p className="ms-500 fs-12">Online calls</p>
                                            </div>
                                            <div className="col-lg-1">
                                                <p className="f-fred ">{data.total_minutes || "0"}</p>
                                                <p className="ms-500 fs-12">Total time</p>
                                            </div>
                                            <div className="col-lg-2">
                                                <p className="f-fred ">{data.pay_amount}</p>
                                                <p className="ms-500 fs-12">Amount (SAR)</p>
                                            </div>


                                            <div className="col-lg-2 px-2 my-1 ">
                                                <p className="f-fred ">{moment(data.start_date).format('DD MMM')} - {moment(data.end_dat).format('DD MMM YYYY')}</p>
                                                <p className="ms-500 fs-12">Billing Cycle</p>

                                            </div>
                                            <div className="col-lg-2 px-2 my-1">
                                                <p className="f-fred ">{moment(data.created_at).format('DD MMM YYYY')}</p>
                                                <p className="ms-500 fs-12">Created on</p>

                                            </div>
                                            <div className="col-lg-1 px-2 my-1">
                                                <p className="f-fred ">{moment(data.created_at).format('hh:mm a')}</p>
                                                <p className="ms-500 fs-12">Time</p>

                                            </div>
                                            <div className="col-lg-1 px-2 my-1">
                                                <button className="btn btn-detail w-100 py-1 px-2 f-fred fs-14" onClick={() => history.push(`/${helper.getRole()}/dashboard/tutor-payout/${data.id}`)}>Details</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>)
                    })}
                    {state.paidTutors.length === 0 &&<p className='my-3 text-center '>No Payout Found</p> }
                                                            
                </div>
            </div>
        </div>
    )
}
