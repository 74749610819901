import moment from 'moment';
import React from 'react';
import avatar from '../../static/images/avatar.png'
import helper from '../../helper';
export default function CouponDetailCard({ data }) {
    return (<>
        <div className="quote-card cur-pointer bg-smoke border-radius-1 py-2 my-2 ">
            <div className="row gx-0 mx-0 fs-14 tx-lt-grey">
                <div className="col-lg-2 col-md-3 col-4 px-2 my-1 d-flex align-items-center">
                    <span className="pointer ">
                        <img width="40px" height="40px" className="rounded-circle mr-2" src={data.profilePicture ? helper.replaceS3Url(data.profilePicture) : avatar} alt="" /></span>
                    <div>
                        <p className="f-fred ">{data.name ? data.name : 'N/A'}</p>
                        <p className="ms-500 fs-12 ">Name</p>
                    </div>
                </div>
                <div className="col-lg-2 col-md-3 col-4  px-2 my-1">
                    <p className="f-fred ">{data.country ? data.country : 'N/A'}</p>
                    <p className="ms-500 fs-12 ">Country</p>
                </div>
                <div className=" col-lg-3 col-md-3 col-4 px-2 my-1">
                    <div className="">
                        <p className="f-fred">{data.discount ? data.discount : 'N/A'}</p>
                        <p className="ms-500 fs-12 ">Discount (%)</p>
                    </div>
                </div>
                <div className=" col-lg-1 col-md-3 col-4 px-2 my-1 d-flex flex-column align-items-lg-end align-items-md-end align-items-start justify-content-center">
                    <div className="">
                        <p className="f-fred ">{data.amount ? data.amount : 0}</p>
                        <p className="ms-500 fs-12 ">Amount</p>

                    </div>
                </div>
               
                <div className="col-lg-2 col-md-3 col-4 px-2 my-1 d-flex flex-column align-items-lg-end align-items-start justify-content-center">
                    <div className="">
                        <p className="f-fred ">{moment(data.created_at).format('MMM DD, YYYY')}</p>
                        <p className="ms-500 fs-12 ">created on</p>
                    </div>
                </div>
                <div className="col-lg-2 col-md-3 col-4 px-2 my-1 d-lg-flex d-md-flex d-none flex-column align-items-lg-end align-items-start justify-content-center">
                <div className="">
                    <p className="f-fred">{moment(data.created_at, 'HH:mm').format('hh : mm a')}</p>
                    <p className="ms-500 fs-12 ">Time</p>
                </div>
                </div>



            </div>
        </div>
    </>);
}
