import React, { useState, useEffect } from 'react'
import helper from '../../../helper';
import ProfileIcon from '../../../components/ProfileIcon';
import { useHistory } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { FaBell } from 'react-icons/fa';
import store from '../../../redux/AppState'
import AddCategory from './AddCategory';
import websitesetting from '../../../controller/websitesetting';
import AddSubcategory from './AddSubcategory';
import { FaTrashAlt, FaEdit } from "react-icons/fa";
import Backdrop from '../../../component/Backdrop';
export default function SupportCategory() {
    const history = useHistory()
    const [load, setLoad] = useState(true)
    const [state, setState] = useState({
        addCategory: false,
        addSubcategory: false,
        data: []
    })
    const [selectedCategory, setSelectedCategory] = useState({ open: false, data: null, index: 0 })

    useEffect(() => {
        async function getCategory() {
            await websitesetting.getAllSupportCategory(1).then((res) => {
                setLoad(false)
                if (res.data.status) {
                   
                    setState({ ...state, data: res.data.result })
                }
            })
        }
        getCategory()
        return () => { setState({}); setLoad() }
    }, [])

    const remove = async (id, index) => {
        setLoad(true)
        await websitesetting.removeSupportCategory(id).then((res) => {
            if (res.data.status) {
                let copy = state.data
                copy.splice(index, 1)
                setState({ ...state, data: copy })
            }
            setLoad(false)
        })
    }

    return (
        <div className="support-category mx-1">
            <Toaster
                position="top-center"
                reverseOrder={false}
                containerClassName="ms-700"
            />
            <header className='row gx-0 mx-0 mb-3 py-1'>
                <div className="col-lg-4 col-md-5 col-6 d-flex align-items-center px-1">
                    <button className="btn btn-back px-2 py-1 fs-14 ms-700 mr-2" style={{ fontWeight: "900" }} onClick={() => history.goBack()}>BACK</button>
                    <div>
                        <p className="mb-1 fs-24 f-fred"> <i class="fas fa-bars mr-1 fs-20 pointer d-lg-none  d-md-none d-inline-block" onClick={() => store.setMenu(true)}></i>Edit <span className="tx-green">Category</span></p>
                        <p className="lh-15 sub-heading">About us</p>

                    </div>
                </div>
                <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                    <button className="btn btn-update mx-2 d-lg-block d-md-block d-none" onClick={() => { setState({ ...state, addCategory: true }) }}>ADD CATEGORY</button>
                    <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >
                        <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                    </p>
                    <ProfileIcon />
                </div>
            </header>


            <section>
                {
                    state.data.map((data, index) =>
                        <div key={data.id} className="quote-card cur-pointer bg-smoke border-radius-1 py-2 my-2">
                            <div className="row gx-0 mx-0">
                                <div className="col-lg-9  px-4 my-1">
                                    <p className="f-fred fs-14">{data.category_name}</p>
                                    <p className="ms-500 fs-12">Category</p>
                                </div>
                                <div className="col-lg-3  my-1 d-flex justify-content-end align-items-center">
                                    <FaEdit className="pointer float-right tx-lt-grey mx-3" size={24} onClick={() => { setSelectedCategory({ ...selectedCategory, open: true, data, index }) }} />
                                    <FaTrashAlt className="pointer float-right tx-red mx-3" size={20} onClick={() => remove(data.id, index)} />
                                </div>
                            </div>
                        </div>

                    )
                }
            </section>

            {/* Add side popup */}
            {state.addCategory && <AddCategory
                cb={(data) => {
                    let copy = state.data
                    copy.push({ category_name: data.category_name, id: data.id, sub_category: [], priority_level: data.priority_level, role: data.role })
                    setState({ ...state, data: copy, addCategory: false })
                }}
                onClose={() => { setState({ ...state, addCategory: false }) }} />}

            {selectedCategory.open && <AddSubcategory
                data={selectedCategory.data}
                onAdd={
                    (data) => {
                        let copy = state.data
                        copy[selectedCategory.index].sub_category.push(data)
                        setState({ ...state, data: copy })
                    }
                }
                onRemove={(index) => {
                    let copy = state.data
                    copy[selectedCategory.index].sub_category.splice(index, 1)
                    setState({ ...state, data: copy })
                }}
                onClose={() => { setSelectedCategory({ ...selectedCategory, open: false }) }} />}

            {load && <Backdrop />}
        </div>
    )
}
