import React, { useState, useEffect } from 'react'
import student from '../../controller/student'
import Backdrop from '../../component/Backdrop'
import SyllabusCard from './SyllabusCard'
import helper from '../../helper'
import { useHistory, useParams } from 'react-router-dom'
import ReadMore from '../../component/ReadMore'
// import CourseCertificate from './CourseCertificate'
export default function EnrolledCourseDetails() {
    const { courseId,studentId } = useParams()
    const history = useHistory()
    const [pageload, setPageload] = useState(true)
    const [resData, setResData] = useState()
    const [state, setState] = useState({
        openConfirm: false,
        openAlert: false,
        type: 'ok',
        msg: ""
    })

    useEffect(() => {
        apiCall()
        return () => { }
    }, [])

    const apiCall = async () => {
        await student.getEnrolledCoursesDeatails({ cid: courseId, sid: studentId })
            .then((res) => {
                if (res.data.result) {
                    setResData(res.data.result[0].course[0])
                }
               
                setPageload(false)
            })
    }
    return (
        <>
            {
                !pageload ?
                    <div className="user-course-details px-2">
                        <button className="btn btn-back  mr-3" onClick={() => history.goBack()}>Back</button>
                        <section className="row gx-0 mx-0 mt-2">
                            <div className="col-lg-3 col-md-4 col-6 px-1 my-2">
                                <div className="course-overview h-100 b-grey px-3 py-3 d-flex justify-content-between align-items-center">
                                    <div>
                                        <p className="fs-18 ms-900 lh-15">Total</p>
                                        <p className="sub-heading">Lessons</p>
                                    </div>
                                    <p className="ms-500 fs-40 tx-green">{resData.totalLessons}</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6 px-1 my-2">
                                <div className="course-overview h-100 b-grey px-3 py-3 d-flex justify-content-between align-items-center">
                                    <div>
                                        <p className="fs-18 ms-900 lh-15">Total</p>
                                        <p className="sub-heading">Slides</p>
                                    </div>
                                    <p className="ms-500 fs-40 tx-green">{resData.totalSlide}</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6 px-1 my-2">
                                <div className="course-overview h-100 b-grey px-3 py-3 d-flex justify-content-between align-items-center">
                                    <div>
                                        <p className="fs-18 ms-900 lh-15">Total Enrolled</p>
                                        <p className="sub-heading">Student</p>
                                    </div>
                                    <p className="ms-500 fs-40 tx-green">{resData.totEnrolledStudent}</p>
                                </div>
                            </div>
                        </section>
                        <div className="row tx-lt-grey">
                            <div className="col-lg-7 col-md-8 col-8">
                                <p className="f-fred text-capitalize mt-2  fs-24">{resData.title}</p>
                                <p className="ms-500 fs-14 text-capitalize">{resData.subTitle}</p>
                            </div>
            
                        </div>
                        <div className="border-dot my-2 mb-3"></div>
                        <section className="row gx-0 mx-0 fs-14  ms-500 text-capitalize">
                            <div className="col-lg-2 col-md-4 col-6 pl-0 mb-1">
                                <p className="ms-900  mb-1">Experience Level </p>
                                <span className="px-2 py-1 b-grey mt-1 rounded w-100">{resData.experienceLevel}</span>
                            </div>
                            <div className="col-lg-2 col-md-4 col-6 mb-1">
                                <p className="ms-900 mb-1">Course Length</p>
                                <span className="px-2 py-1 b-grey mt-1 rounded w-100">{resData.totalLessons} Lessons</span>
                            </div>
                            <div className="col-lg-2 col-md-4 col-6 mb-1">
                                <p className="ms-900  mb-1">Slides</p>
                                <span className="px-2 py-1 b-grey mt-1 rounded w-100">{resData.totalSlide} Slides</span>
                            </div>
                        </section>
                        <p className="my-3 f-fred fs-24" style={{ color: '#C3C3C3' }}>Overview</p>


                        <section className="row gx-0 mx-0">
                            <div className="col-lg-4 mb-2 px-1">
                                <p className="ms-900 fs-14">What will you be able to do?</p>
                                <ReadMore
                                    text={resData.description}
                                    initialNoOfWords={30}
                                />

                            </div>
                            <div className="col-lg-4 mb-2 px-1">
                                <p className="ms-900 fs-14">Why take this course?</p>
                                <ReadMore
                                    text={resData.descriptionCourse}
                                    initialNoOfWords={30}
                                />
                            </div>

                        </section>
                        <div className="border-dot mt-2 mb-3"></div>
                        <p className="my-3 f-fred fs-24" style={{ color: '#C3C3C3' }}>Syllabus</p>

                        <section className="row gx-0 mx-0 ">
                            {
                                resData.syllabusList.map((syllabus, index) => {
                                    return (
                                        <div key={`syllabus${index}`} className="col-lg-6 col-md-6 col-12 pl-0 pr-2 my-2">
                                            <SyllabusCard
                                                syllabusId={syllabus.id}
                                                name={syllabus.name}
                                                slides={syllabus.noOfSlides}
                                                lessonNo={index + 1}
                                                id={studentId}
                                                data={syllabus}
                                                isEnrolled={resData.enrolled_courses}
                                                previousCompletedSlide={index != 0 ? resData.syllabusList[index - 1].total_completed_slide : 0}
                                                previousTotalSlide={index != 0 ? resData.syllabusList[index - 1].noOfSlides : 0}
                                            />
                                        </div>
                                    )
                                })
                            }

                        </section>


                        {/* <section className="mt-2 ">
                            <h4 className="mt-3 f-fred  mb-2" style={{ color: '#C3C3C3' }}>Certificates</h4>
                            <CourseCertificate
                                title={resData.title}
                                courseId={resData.id}
                                isDownload={resData.total_slide_complete === resData.totalSlide}
                            />
                        </section> */}


                    </div> : <Backdrop />}


        </>
    )
}

