import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { Provider } from 'react-redux'
import reduxstore from './redux/AppState'
import AdminLogin from "./auth/AdminLogin"
import AdminForgotPassword from "./auth/AdminForgotPassword";
import Dashboard from "./module/dashboard/Dashboard";
import ManagerLogin from "./auth/ManagerLogin";
import ManagerFogotPassword from "./auth/ManagerForgotPassword";
import ManagerResetPassword from "./auth/ManagerResetPassword";
import AdminResetPassword from "./auth/AdminResetPassword";
import PrivateRoute from "./route/PrivateRoute";
import ManagerPrivateRoute from "./route/ManagerRoute";
import { useClearCache } from 'react-clear-cache';

import { Toaster } from "react-hot-toast";
function App() {
  const location = useLocation()
  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  useEffect(() => {
    !isLatestVersion && emptyCacheStorage();
  }, [])

  useEffect(() => {
    setTimeout(() => {
      sayDhadTextReplace()
    }, 1000)
  }, [location])


  const sayDhadTextReplace = () => {
    try {
      let pTag = document.getElementsByTagName("p")
      let enReg = /(SayDhad|Say-Dhad|SAYDHAD|Saydhad)/i
    
      for (let i = 0; i < pTag.length; i++) {
        pTag[i].innerHTML = pTag[i].innerHTML.replace(enReg, "<span class='tx-green'>Say</span><span class='tx-yellow'>Dhad</span>");

      }
    } catch (err) { }
  }

  return (
    <Provider store={reduxstore.store}>
      <Toaster
        position="top-left"
        reverseOrder={false}
        containerClassName="ms-700"
      />
      <Switch>
        {/* authentication routes  */}
        <Route exact path="/" component={AdminLogin} />
        <Route exact path="/manager" component={ManagerLogin} />
        <Route exact path="/admin/forgot-password" component={AdminForgotPassword} />
        <Route exact path="/admin/reset-password/link" component={AdminResetPassword} />
        <Route exact path="/manager/forgot-password" component={ManagerFogotPassword} />
        <Route exact path="/manager/reset-password/link" component={ManagerResetPassword} />

        {/* Authorized admin routes  */}
        <PrivateRoute restricted={true} exact path="/admin/dashboard/:page" component={Dashboard} />
        <PrivateRoute restricted={true} exact path="/admin/dashboard/:page/:userId" component={Dashboard} />
        <PrivateRoute restricted={true} exact path="/admin/dashboard/setting/:user/:page/:id" component={Dashboard} />
        <PrivateRoute restricted={true} exact path="/admin/dashboard/setting/:user/:page" component={Dashboard} />
        <PrivateRoute restricted={true} exact path="/admin/dashboard/setting/:page" component={Dashboard} />
        <PrivateRoute restricted={true} exact path="/admin/dashboard/enrolled-course-detail/:courseId/:studentId" component={Dashboard} />


        {/* Authorized Manager routes  */}
        <ManagerPrivateRoute restricted={true} exact path="/manager/dashboard/:page" component={Dashboard} />
        <ManagerPrivateRoute restricted={true} exact path="/manager/dashboard/:page/:userId" component={Dashboard} />
        <ManagerPrivateRoute restricted={true} exact path="/manager/dashboard/setting/:user/:page/:id" component={Dashboard} />
        <ManagerPrivateRoute restricted={true} exact path="/manager/dashboard/setting/:user/:page" component={Dashboard} />
        <ManagerPrivateRoute restricted={true} exact path="/manager/dashboard/setting/:page" component={Dashboard} />
      </Switch>

    </Provider>
  );
}

export default App;
