import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, ContentState, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { fileUploadToS3 } from "../helper/fileupload";
import htmlToDraft from 'html-to-draftjs';

export default function TextEditor({ data, onChange }) {
    const contentDataState = ContentState.createFromBlockArray(htmlToDraft(data));
    const editorDataState = EditorState.createWithContent(contentDataState);
    const [editorState, setEditorState] = useState(editorDataState);
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(
        rawContentState
    );
  
    const changeHandle = (data) => {
        setEditorState(data)
        onChange(markup)
    }

    const uploadCallback = (file, callback) => {
        return new Promise((resolve, reject) => {
            const reader = new window.FileReader();
            reader.onloadend = async () => {
                const form_data = new FormData();
                form_data.append("file", file);
                const url = await fileUploadToS3("faqs", file)
                resolve({ data: { link: url } });
            };
            reader.readAsDataURL(file);
        });
    };

    let toolbar = {
        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
        image: {
            urlEnabled: true,
            uploadEnabled: true,
            alignmentEnabled: true,
            uploadCallback: uploadCallback,
            previewImage: true,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            alt: { present: false, mandatory: false },
            defaultSize: {
                height: 300,
                width: 600,
                maxWidth: '100%'
            },
        },
    }
    return (
        <div >

            <div className="py-2 px-4 ms-500" style={{ border: "1px solid lightgray", minHeight: '400px', color: "black", borderRadius: "10px" }}>
                <Editor
                    placeholder="Type here..."
                    editorState={editorState}
                    onEditorStateChange={(data) => changeHandle(data)}
                    toolbar={toolbar}
                />
            </div>
        </div>
    );
}