import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import store from '../../redux/AppState'
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import ProfileIcon from '../../components/ProfileIcon';
import PayoutCard from './PayoutCard';
import { FaBell } from 'react-icons/fa';
import helper from '../../helper';
import { getPaidTutorListForPay } from '../../controller/payout';
import Backdrop from '../../component/Backdrop';

export default function AllPayout() {
    const history = useHistory()
    const storeData = useSelector(state => state)
    const [startDate, setStartDate] = useState(moment(new Date(new Date().setDate(new Date().getDate() - 30))));
    const [endDate, setEndDate] = useState(moment(new Date()));
    const [focusedInput, setFocusedInput] = useState(null);
    const [state, setState] = useState({
        paidTutors: [],
        payout_analytics : {},
        load: true,
        error: false
    })

    const onRange = (st, en) => {
        setStartDate(st);
        setEndDate(en)
        if (st && en) {
            getPayout(moment(st).format('YYYY-MM-DD'), moment(en).format('YYYY-MM-DD')
            )
        }
    }

    useEffect(() => {
        getPayout(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
    }, [])

    const getPayout = async (start, end) => {
        setState({ ...state, data: [], load: true })
        await getPaidTutorListForPay({ startDate: start, endDate: end }).then((res) => {
            if (res.data.status) {
                setState({ ...state, paidTutors: res.data.result.paidTutors,payout_analytics:res.data.result.payout_analytics, load: false })
            } else {
                setState({ ...state, data: [], load: true })
            }
        })
    }

    return (
        <>
            <div className="mx-1">
                <header className='row gx-0 mx-0 mb-3 py-1'>
                    <div className="col-lg-5 col-md-5 px-1 col-6 d-flex flex-column justify-content-center px-1">
                        <p className="mb-1 fs-24 f-fred">
                            <span>
                                <i className="fas fa-bars mr-2 fs-20 hover-shadow pointer d-lg-inline-block d-md-inline-block d-none" onClick={() => store.setShowLeftBar(!storeData.showLeftBar)}></i>
                            </span>
                            <i class="fas fa-bars mr-1 fs-24 pointer d-lg-none  d-md-none d-inline-block" onClick={() => store.setMenu(true)}></i> SayDhad <span className="tx-green">payout manager</span></p>
                        <p className="sub-heading ml-2">{moment().format('ddd, DD MMM YYYY hh:mm A')}</p>
                    </div>
                    <div className="col-lg-7 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                        <button className='btn btn-update fs-14 px-3 mr-2' style={{ height: '39px' }} onClick={() => history.push(`/${helper.getRole()}/dashboard/pay-tutor`)}>PAY NOW</button>
                        <div className="mr-2 ms-700 fs-14 pointer  tx-lt-black icon-border d-lg-block d-md-block d-none">
                            <DateRangePicker
                            maxDate={moment()}
                                showClearDates={true}
                                navPrev={null}
                                initialStartDate={null}
                                disabled={false}
                                startDate={startDate}
                                isOutsideRange={() => false}
                                isDayBlocked={() => false}
                                enableOutsideDays={true}
                                numberOfMonths={2}
                                startDateId="s_id"
                                endDate={endDate}
                                endDateId="e_id"
                                onDatesChange={({ startDate, endDate }) => { onRange(startDate, endDate) }}
                                focusedInput={focusedInput}
                                onFocusChange={e => setFocusedInput(e)}
                                displayFormat="DD/MM/YYYY"
                            />
                        </div>
                        <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                            <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                        </p>
                        <ProfileIcon />
                    </div>
                </header>

                <div className="mx-1">
                    <section className="row gx-0 mx-0 tx-lt-black">
                        <div className="col-lg-4 pay" >
                            <div className="row gx-0 mx-0">
                                <div className="col-lg-12 payout">
                                    <div style={{ order: "1" }}>
                                        <p className='ms-900 fs-18' >Total payouts (SAR)</p>
                                        <p className='sub-heading'>From {moment(startDate).format('MMM DD, YYYY')} to {moment(endDate).format('MMM DD, YYYY')}</p>
                                        <h2 className='ms-500 fs-40 my-3'>{state.payout_analytics.tot_amount} <span className="stx">{parseFloat(state.payout_analytics.amount_percentage).toFixed(2)}%</span></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 pay">
                            <div className="row gx-0 mx-0">
                                <div className="col-lg-12 payout">
                                    <div style={{ order: "1" }}>
                                        <p className='ms-900 fs-18' >Total tutors</p>
                                        <p className='ms-700 fs-14'>From {moment(startDate).format('MMM DD, YYYY')} to {moment(endDate).format('MMM DD, YYYY')}</p>
                                        <h2 className='ms-500 fs-40 my-3'>{state.payout_analytics.total_tutors} <span className="stx">{parseFloat(state.payout_analytics.tut_percentage).toFixed(2)}%</span></h2>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4 pay" style={{ order: "1" }}>
                            <div className="row gx-0 mx-0">
                                <div className="col-lg-12 payout">
                                    <div style={{ order: "1" }}>
                                        <p className='ms-900 fs-18' >Total calls made</p>
                                        <p className='ms-700 fs-14'>From {moment(startDate).format('MMM DD, YYYY')} to {moment(endDate).format('MMM DD, YYYY')}</p>
                                        <h2 className='ms-500 fs-40 my-3'>{state.payout_analytics.total_calls} </h2>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                    <div className="grid-view">
                        <div className="grid-card">
                            {
                                state.paidTutors.map((pay) =>
                                    <PayoutCard
                                        key={pay.id}
                                        tid={pay.transaction_id}
                                        calls={pay.total_calls}
                                        time={pay.total_minutes}
                                        amount={pay.pay_amount}
                                        rate={pay.rate_per_minute}
                                        billFrom={moment(pay.start_date).format("DD MMM YYYY")}
                                        billTo={moment(pay.end_date).format("DD MMM YYYY")}
                                        date={moment(pay.created_at).format("YYYY-MM-DD hh:mm:ss")}
                                        onClick={() => history.push(`/${helper.getRole()}/dashboard/payout-manager/${pay.id}`)}
                                    />)
                            }
                            {state.paidTutors.length === 0 && <p className='my-3 ms-700 text-center'>No Result Found</p>}
                        </div>
                    </div>
                                {state.load && <Backdrop />}
                </div>
            </div>

        </>
    )
}
