import React, { useState, useEffect } from 'react'
import store from '../../../redux/AppState'
import admin from '../../../controller/admin'
import Backdrop from '../../../component/Backdrop';
import ProfileIcon from '../../../components/ProfileIcon';
import { useHistory } from 'react-router-dom';
import { FaBell } from 'react-icons/fa';
import helper from '../../../helper';

export default function WebsiteContent() {
    const history = useHistory()

    const [webC, setwebC] = useState()
    const [pageLoad, setpageLoad] = useState(true)
    useEffect(() => {
        apiCall()
        return () => { }
    }, [])

    const apiCall = async () => {
        await admin.getWebContent()
            .then((res) => {
                if (res.data.status) {
                    setwebC(res.data.result)
                }
                setpageLoad(false)
            })
    }

    return (
        <>
            {!pageLoad && <>
                <div className="mx-1">
                    <header className='row gx-0 mx-0 mb-3 py-1'>
                        <div className="col-lg-4 col-md-5 col-6 d-flex align-items-center px-1">
                            <button className="btn btn-back px-2 py-1 fs-14 ms-700 mr-2" style={{ fontWeight: "900" }} onClick={() => history.goBack()}>BACK</button>
                            <div>
                                <p className="mb-1 fs-24 f-fred"> <i class="fas fa-bars mr-1 fs-20 pointer d-lg-none  d-md-none d-inline-block" onClick={() => store.setMenu(true)}></i>Website <span className="tx-green">content</span></p>
                                <p className="sub-heading lh-15">SayDhad general setting</p>

                            </div>
                        </div>
                        <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                            <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                                <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                            </p>
                            <ProfileIcon />
                        </div>
                    </header>
                    <div className="mx-2 tx-lt-grey">

                        {/* <div className="quote-card cur-pointer bg-smoke border-radius-1 py-2 my-2 ">
                            <div className="row gx-0 mx-0">
                                <div className="col-lg-11 col-md-10 col-9 px-4 my-1">
                                    <p className="f-fred fs-14 ">About us</p>
                                    <p className="ms-500 fs-12 " style={{ fontWeight: "500" }}>Global content</p>
                                </div>
                                <div className="col-lg-1 col-md-2 col-3  my-1 all-center">
                                    <button className="btn btn-dark w-100 py-1 px-2 f-fred fs-14" onClick={() => history.push(`/${helper.getRole()}/dashboard/web-content/aboutus`)}>Edit</button>
                                </div>
                            </div>
                        </div> */}

                        <div className="quote-card cur-pointer bg-smoke border-radius-1 py-2 my-2 ">
                            <div className="row gx-0 mx-0">
                                <div className="col-lg-11 col-md-10 col-9 px-4 my-1">
                                    <p className="f-fred fs-14 ">Support category</p>
                                    <p className="ms-500 fs-12 " style={{ fontWeight: "500" }}>Global content</p>
                                </div>
                                <div className="col-lg-1 col-md-2 col-3  my-1 all-center">
                                    <button className="btn btn-dark w-100 py-1 px-2 f-fred fs-14" onClick={() => history.push(`/${helper.getRole()}/dashboard/web-content/support-category`)}>Edit</button>
                                </div>
                            </div>
                        </div>
                       
                        <div className="quote-card cur-pointer bg-smoke border-radius-1 py-2 my-2 ">
                            <div className="row gx-0 mx-0">
                                <div className="col-lg-11 col-md-10 col-9 px-4 my-1">
                                    <p className="f-fred fs-14 ">Languages</p>
                                    <p className="ms-500 fs-12 ms-500">Global content</p>
                                </div>
                                <div className="col-lg-1 col-md-2 col-3  my-1 all-center">
                                    <button className="btn btn-dark w-100 py-1 px-2 f-fred fs-14"  onClick={() => history.push(`/${helper.getRole()}/dashboard/web-content/tutor-languages`)}>Edit</button>
                                </div>
                            </div>
                        </div>
                        <div className="quote-card cur-pointer bg-smoke border-radius-1 py-2 my-2 ">
                            <div className="row gx-0 mx-0">
                                <div className="col-lg-11 col-md-10 col-9 px-4 my-1">
                                    <p className="f-fred fs-14 ">Dialects</p>
                                    <p className="ms-500 fs-12 " style={{ fontWeight: "500" }}>Global content</p>
                                </div>
                                <div className="col-lg-1 col-md-2 col-3  my-1 all-center">
                                    <button className="btn btn-dark w-100 py-1 px-2 f-fred fs-14"  onClick={() => history.push(`/${helper.getRole()}/dashboard/web-content/tutor-dialects`)}>Edit</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>}
            {pageLoad && <Backdrop />}

        </>
    )
}
