import React, { useState } from 'react'
import { FiXCircle } from "react-icons/fi";
import admin from '../../controller/admin';
import {toast } from 'react-hot-toast';
import helper from '../../helper/index';
import Backdrop from '../../component/Backdrop';
import moment from 'moment';

export default function AddNewCoupon({ CallClose, OnAddCoupon }) {

    const [openData, setopenData] = useState({ discount: "", code: "", date: "", time: "", is_active: false })
    const [pageload,setPageload] = useState(false)
    const addCoupon = async () => {
        const requestData = {
            "discount": openData.discount,
            "coupon_code": openData.code,
            "expiry_date": openData.date,
            "expiry_time": openData.time,
            "is_active": openData.is_active,
            "is_admin": helper.getid()
        }
        if(!requestData.coupon_code){
            toast.error("Please generate coupon code")
            return
        }
        if (!requestData.discount || isNaN(requestData.discount) || requestData.discount < 0 || requestData.discount > 100) {
            toast.error("Please enter valid discount")
            return
        }
        if(!requestData.expiry_date){
            toast.error("Please enter expiry date")
            return
        }
        if(!requestData.expiry_time){
            toast.error("Please enter expiry time")
            return
        }

        setPageload(true)
        await admin.addCoupon(requestData)
            .then((res) => {
                if (res.data.status) {
                    toast("Coupon successfully added")
                        CallClose()
                        OnAddCoupon(res.data.result)
                }
                setPageload(false)
            })
    }

    const getCode = async ()=>{
        await admin.getCouponCode().then((res)=>{
            setopenData({...openData,code  :res.data.result.coupon_code})
        }).catch((err)=>{})
    }

    return (
        <>
             
            <div className="  all-center popup-modal">
                <div className="right-popup notify-detail p-fix tp-0 rt-0  px-3 py-3 sm" >
                    <div className="row gx-0 mx-0">
                        <div className="col-10">
                            <p className="f-fred" style={{ fontSize: "22px" }}>Add<span style={{ color: "#089D12" }}> coupon</span></p>
                            <p className="ms-500 sub-heading" style={{  fontSize: "14px" }}>SayDhad coupon manager</p>
                        </div>
                        <div className="col-2">
                            <FiXCircle className="pointer float-right " size="2rem" onClick={CallClose} style={{ color: "lightgray" }} />
                        </div>
                    </div>


                    <div className="row gx-0 mx-0  ms-700">
                        <div className="col-12 pt-4">
                            <label class="switch">
                                <input type="checkbox" onChange={(e) => { setopenData({ ...openData, is_active: e.target.checked }) }} />
                                <span class="slider round"></span>
                            </label>
                            <span className="pl-2">{openData.is_active ? "Active" : "Inactive"}</span>
                        </div>
                    </div>
                    <div className="row  gx-0 mx-0  ms-700 ">
                        <div className="col-8 pr-1 edit-profile ">
                           <div className='edit-input'>
                           <input placeholder="Coupon Code" type="text" style={{ border: "1px solid lightgray" }} value={openData.code} />
         
                           </div>
                           
                                          </div>
                        <div className="col-4 pl-0 my-2 ">
                            <button className='btn bg-sky text-white w-100 h-100  fs-14 ms-700 mt-1 py-2 px-2' onClick={getCode}>GET CODE</button>
                        </div>
                    </div>
                    <div className="row  gx-0 mx-0 edit-profile ms-700">
                        <div className="col-12 edit-input">
                            <input placeholder="Discount %" type="text" style={{ border: "1px solid lightgray" }} onChange={(e) => { setopenData({ ...openData, discount: e.target.value }) }} required />
                        </div>
                    </div>

                    <div className="row gx-0 mx-0 edit-profile ms-700">
                        <div className="col-6 pt-4" >
                            <input min={moment().format('YYYY-MM-DD')} type="date" className='px-2 py-1' style={{ border: "1px solid lightgray" }} onChange={(e) => { setopenData({ ...openData, date: e.target.value }) }} />
                        </div>

                        <div className="col-6  pt-4 text-right">
                            <input type="time" className='px-2 py-1' style={{ border: "1px solid lightgray" }} onChange={(e) => { setopenData({ ...openData, time: e.target.value }) }} />
                        </div>
                    </div>


                    <div className="row  gx-0 mx-0 py-2 my-2">
                        <div className="col-12">
                            <button className="btn btn-update px-2 py-2 ms-700 fs-14  btn-sm col-xs-2" onClick={addCoupon}>ADD COUPON</button>
                        </div>
                    </div>

                </div>
            </div>

            {pageload && <Backdrop />}

        </>
    )
}
