import React, { useState, useRef } from 'react'
import { useEffect } from 'react';
import admin from '../../controller/admin';
import Backdrop from '../../component/Backdrop';
import { useHistory } from 'react-router-dom';
import ProfileIcon from '../../components/ProfileIcon';
import moment from 'moment';
import Filter from './Filter';
import CourseCard from './CourseCard';
import { useSelector } from 'react-redux'
import store from '../../redux/AppState'
import { FaBell } from 'react-icons/fa';
import searchIcon from '../../static/images/search.svg'
import helper from '../../helper';
export default function AllCourses() {
    const storeData = useSelector(state => state)
    const history = useHistory()
    const [resData, setresData] = useState([])
    const [searchData, setSearchData] = useState([])
    const [pageLoad, setpageLoad] = useState(true)
    const [categoryList, setcategoryList] = useState([])
    const [openFilter, setOpenFilter] = useState(false)
    const qry = useRef()

    useEffect(() => {
        apiCall()
        return () => { }
    }, [])

    const apiCall = async () => {
        await admin.getCourse(10)
            .then((res) => {
                const list = []
                if (res.data.status) {
                    res.data.result.forEach((data) => {
                        list.push(data.courseCategory)
                    })
                    setresData(res.data.result)
                    setSearchData(res.data.result)
                    setcategoryList(list)
                }
              
                setpageLoad(false)
            }).catch((err) => {
                setpageLoad(false)
            })
    }


    
    const search = () => {
        if (qry.current.value) {
            let regEx = new RegExp(qry.current.value, 'si')
            let list = []
            resData.forEach((result) => {
                let course = [], find = false
                result.course.some((data) => {
                    if (data.title && (data.title).match(regEx) || (result.courseCategory).match(regEx)) {
                        course.push(data)
                        find = true
                        return true
                    }
                })
                if(find){
                    list.push({
                        course: course,
                        courseCategory: result.courseCategory
                    })
                }
            })
            setSearchData(list)
        }
        else {
            setSearchData(resData)
        }
    }

    const filters = (data) => {
        const res = resData.filter((result) => {
            if (data.level.length !== 0 && data.categories.length != 0) {
                let find = false
                result.course.some((courses) => {
                    if (data.level.includes(courses.experienceLevel)) {
                        find = true
                        return true
                    }
                })
                if (find && data.categories.includes(result.courseCategory)) {
                    return true
                }
            }
            else if (data.categories.length === 0 && data.level.length != 0) {
                let find = false
                result.course.some((courses) => {

                    if (data.level.includes(courses.experienceLevel)) {
                        find = true
                        return true
                    }
                })
                return find
            }
            else if (data.level.length === 0 && data.categories.length !== 0) {
                return data.categories.includes(result.courseCategory)
            }
            else {
                return true
            }
        })
        setSearchData(res)
    }
    return (
        <>
            {!pageLoad && <>
                <div className="mx-1">
                    <header className='row gx-0 mx-0 mb-3 py-1'>
                        <div className="col-lg-4 col-md-5 col-6 d-flex flex-column justify-content-center px-1">
                            <p className="mb-1 fs-24 f-fred">
                                <span>
                                    <i className="fas fa-bars mr-2 fs-20 hover-shadow pointer d-lg-inline-block d-md-inline-block d-none" onClick={() => store.setShowLeftBar(!storeData.showLeftBar)}></i>
                                </span>
                                <i class="fas fa-bars mr-1 fs-20 pointer d-lg-none  d-md-none d-inline-block"></i>SayDhad <span className='tx-green'>Courses</span></p>
                            <p className="sub-heading ml-2">{moment().format('ddd, DD MMM YYYY hh:mm A')}</p>
                        </div>
                        <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                            <button  className="btn btn-green  mx-2 d-lg-block d-md-block ms-700 d-none" onClick={() => history.push(`/${helper.getRole()}/dashboard/add-course`)}>ADD NEW COURSE</button>
                            <button className="btn  btn-update mx-2 d-lg-block d-md-block ms-700 d-none" onClick={() => history.push(`/${helper.getRole()}/dashboard/course-category`)}>VIEW CATEGORIES</button>
                            <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                                <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                            </p>
                            <ProfileIcon />
                        </div>
                    </header>

                    <div className="tutor-library">

                        <form onSubmit={(e) => { e.preventDefault(); search() }}>
                            <div className="row gx-0 mx-0">
                                <div className="col-lg-3 col-md-4 col-12 px-1 mb-2">
                                    <div className='d-flex w-100 align-items-center'>
                                        <input type="search" ref={qry} placeholder="Type here course title..." className="w-100 pl-5 h-100 input-box px-2 py-2 fs-14 ms-700" />
                                        <img src={searchIcon} className="p-abs lt-0 pl-3" />
                                    </div>
                                </div>

                                <div className="col-lg-2 col-md-4 col-6 px-1 mb-2">
                                    <button type='submit' className="btn btn-login w-100 h-100 fs-12 wrap">browse library</button>
                                </div>
                                <div className="col-lg-2 col-6 mb-2 px-1 mb-2 ">
                                    <button type='button' className="btn btn-tutor-profile h-100 w-100 fs-12 wrap " onClick={() => setOpenFilter(true)}>filter courses</button>
                                </div>
                            </div>
                        </form>


                        <section className="row gx-0 mx-0">
                            {
                                searchData.map((cat, index) => {
                                    return (
                                        <>
                                            <div key={`category${index}`} className="col-12 px-0"><p className="f-fred mb-2 mt-3 fs-24 tx-lt-grey px-1 text-capitalize">{cat.courseCategory}</p> </div>
                                            {
                                                cat.course.map((courses, index1) => {

                                                    return (
                                                        <div key={`course+${index1}`} className="col-lg-3 col-md-4 col-12 px-1 mb-3">
                                                            <CourseCard
                                                                name={courses.title}
                                                                subTitle={courses.subTitle}
                                                                lesson={courses.totalLessons}
                                                                slides={courses.totalSlide}
                                                                isRemove={false}
                                                                img={courses.imgName}
                                                                onChange={() => { history.push(`/${helper.getRole()}/dashboard/course-detail/${courses.id}`) }}
                                                            />
                                                        </div>
                                                    )

                                                })
                                            }
                                        </>
                                    )
                                })
                            }

                        </section>


                    </div>

                </div>
            </>}

            {openFilter &&
                <Filter
                    categories={categoryList}
                    CallClose={() => setOpenFilter(false)}
                    cb={(data) => {
                        setOpenFilter(false)
                        filters(data)
                    }}
                    clear={() => setSearchData(resData)}

                />}
            {pageLoad && <Backdrop />}
        </>
    )
}
