/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import store from '../../redux/AppState'
import admin from '../../controller/admin';
import Backdrop from '../../component/Backdrop';
import moment from 'moment';
import UserManagerCard from './UserManagerCard';
import UserDetail from './UserDetails';
import ProfileIcon from '../../components/ProfileIcon';
import { useHistory } from 'react-router-dom';
import AddManager from './AddManager';
import { FaBell } from 'react-icons/fa';
import searchIcon from '../../static/images/search.svg'
import helper from '../../helper';

export default function UserManager() {
    const history = useHistory()
    const storeData = useSelector(state => state)
    const [addManager, setAddManager] = useState(false)
    const query = useRef()
    const [Detail, setDetail] = useState({ open: false, id: 0, data: "" });
    const toggleDetail = (idValue, data) => {
        setDetail({ ...Detail, open: !Detail.open, id: idValue, data: data });
    }

    const [user, setuser] = useState([])
    const [pageLoad, setpageLoad] = useState(true)
    useEffect(() => {
        apiCall('')
        return () => { }
    }, [])

    const apiCall = async (qry) => {
        setpageLoad(true)
        await admin.getUserList(qry)
            .then((res) => {
                if (res.data.status) {
                    setuser(res.data.result)
                }
                setpageLoad(false)
            })
    }
    const search = () => {
        apiCall(query.current.value)
    }

    return (
        <>


            <section>
                <header className='row gx-0 mx-0 mb-3 py-1'>
                    <div className="col-lg-4 col-md-5 col-6 d-flex flex-column justify-content-center px-1">
                        <p className="mb-1 fs-24 f-fred">
                            <span>
                                <i className="fas fa-bars mr-2 fs-20 hover-shadow pointer d-lg-inline-block d-md-inline-block d-none" onClick={() => store.setShowLeftBar(!storeData.showLeftBar)}></i>
                            </span>
                            <i class="fas fa-bars mr-1  pointer d-lg-none  d-md-none d-inline-block"></i>SayDhad <span className="tx-green">user manager</span></p>
                        <p className="lh-15 sub-heading ml-2">{moment(new Date).format('ddd, DD MMM YYYY hh:mm A')}</p>
                    </div>
                    <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                        <button className=" btn  btn-edit  d-lg-block  d-md-block d-none mx-2  fs-14 wrap" onClick={() => setAddManager(true)}>ADD MANAGER</button>
                        <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                            <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                        </p>
                        <ProfileIcon />
                    </div>
                </header>
            </section>

            <div className="tutor-library mx-2">
                <form onSubmit={(e) => { e.preventDefault(); search() }}>
                    <div className="row gx-0 mx-0">
                        <div className="col-lg-3 col-md-4 col-12 px-1 mb-2">
                            <div className='d-flex w-100 align-items-center'>
                                <input type="search" ref={query} placeholder="Type here name..." className="w-100 pl-5 h-100 input-box px-2 py-2 fs-14 ms-700" />
                                <img src={searchIcon} className="p-abs lt-0 pl-3" />
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-6 px-1 mb-2">
                            <button className="btn btn-login w-100 h-100 wrap">search</button>
                        </div>
                    </div>
                </form>
                <div className="grid-view">
                    <div className="grid-card">
                    {!pageLoad && <>
                        {user.map((result, index) => {
                            return (
                                <div className="">
                                    <UserManagerCard
                                        user={result}
                                        onClick={(userId) => toggleDetail(userId, result)}
                                    />
                                </div>
                            )
                        })}
                    </>}
                </div>
            </div>


            {pageLoad && <Backdrop />}

            {/* open user details  */}
            {Detail.open &&
                <UserDetail
                    id={Detail.id}
                    data={Detail.data}
                    CallClose={() => { toggleDetail() }}
                    onEdit={() => { apiCall('') }}
                />}

            {addManager && <AddManager onAdd={(data) => {
                let list = [...user]
                list.push(data)
                setuser(list)
            }} onClose={() => { setAddManager(false) }} />}
        </div>
        </>
    )
}
