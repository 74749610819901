import React from 'react'
import happy from '../static/images/Logo.svg'
export default function ConfirmPopup({ open, msg, onClose, onOk }) {
    return (
        <>
            {open &&
                <div className="popup-modal h-100 all-center">
                    <div className="popup-modal-body sm">
                        <div className='my-3'>
                            <img className='d-block mx-auto' style={{ width: '200px' }} src={happy} alt="parrot" />
                        </div>
                        <p className='ms-500 text-center my-4 px-'>{msg}</p>

                        <div className='all-center'>
                            <div>
                                <button className='btn btn-login text-white fs-14 mb-3 mx-2' style={{ width: '150px',height:'39px' }} onClick={onOk}>OKAY</button>
                                <button className='btn btn-clear text-white py-2  fs-14 mb-3 mx-2' style={{ width: '150px',height:'39px' }} onClick={onClose}>CANCEL</button>
                            </div>
                        </div>

                    </div>
                </div>
            }
        </>
    )

}
