/* eslint-disable */
import { createStore } from 'redux'
const initialState = {
    isAuth: false,
    notificationActive: false,
    menuOpen: false,
    navigationTab: "home",
    showLeftBar: false,
    profilePic : null
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case 'auth':
            return { ...state, isAuth: action.payload }
        case 'notificationActive':
            return { ...state, notificationActive: action.payload }
        case 'sideMenu':
            return { ...state, menuOpen: action.payload }
        case 'navigationTab':
            return { ...state, navigationTab: action.payload }
            case 'showLeftBar':
                return { ...state, showLeftBar: action.payload }
                case 'profilePic':
                return { ...state, profilePic: action.payload }
        default:
            return state;
    }
}

const store = createStore(reducer)

function setIsAuth(value) {
    store.dispatch({ type: 'auth', payload: value })
}
function setNotificationActive(value) {
    store.dispatch({ type: 'notificationActive', payload: value })
}

function setMenu(value) {
    store.dispatch({ type: 'sideMenu', payload: value })
}

function setNavigationTab(value) {
    store.dispatch({ type: 'navigationTab', payload: value })
}
function setPic(value) {
    store.dispatch({ type: 'profilePic', payload: value })
}
function setShowLeftBar(value) {
    store.dispatch({
        type: 'showLeftBar',
        payload: value
    })
}
export default {
    setIsAuth, store,
    setNotificationActive, setMenu, setNavigationTab,setShowLeftBar,
    setPic
}