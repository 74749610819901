import React from 'react';
import moment from 'moment';
export default function SupportCard({ tic, onClick }) {
    return (
        <>
            <div className="quote-card cur-pointer bg-smoke border-radius-1 py-2 my-2 ">
                <div className="row gx-0 fs-14 tx-lt-grey mx-0">
                    <div className="col-lg-1 all-center px-2 my-2">
                        <button className={`btn ${tic.status === "Open" ? 'btn-open' : 'btn-close'} w-100 fs-16 py-1 px-2 ms-700 `}>{tic.status === "Open" ? "Open" : "Close"}</button>

                    </div>
                    <div className="col-lg-1 px-2 my-1">
                        <p className="f-fred ">{tic.id}</p>
                        <p className="ms-500 fs-12 ">Ticket ID</p>

                    </div>
                    <div className="col-lg-1 px-2 my-1">
                        <p className="f-fred ">{tic.role}</p>
                        <p className="ms-500 fs-12 ">User</p>

                    </div>
                    <div className="col-lg-1 px-2 my-1">
                        <p className="f-fred text-capitalize overflow-elipse">{tic.name ? tic.name : 'NA'}</p>
                        <p className="ms-500 fs-12 ">Name</p>

                    </div>
                    <div className="col-lg-1 px-2 my-1 ">
                        <p className="f-fred text-capitalize overflow-elipse">{tic.category}</p>
                        <p className="ms-500 fs-12 ">Category</p>

                    </div>
                    <div className=" col-lg-3 px-2 my-1">
                        <p className="f-fred text-capitalize overflow-elipse">{tic.sub_category}</p>
                        <p className="ms-500 fs-12 ">Sub category</p>

                    </div>
                    <div className="col-lg-2 px-2 my-1">
                        <p className="f-fred text-right">{moment(tic.created_at).format('DD MMM YYYY')}</p>
                        <p className="ms-500 fs-12 text-right">Added on</p>

                    </div>
                    <div className="col-lg-1 px-2 my-1">
                        <p className="f-fred text-right">{moment(tic.created_at).format('hh:mm a')}</p>
                        <p className="ms-500 fs-12 text-right ">Time</p>

                    </div>

                    <div className="col-lg-1 px-2 my-1 all-center">
                        <button className="btn btn-detail w-100 py-1 px-2 f-fred fs-12" onClick={() => { onClick(tic.id) }}>Details</button>
                    </div>
                </div>
            </div>
        </>
    )
}
