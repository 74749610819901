import React from 'react';
import avatar from '../../static/images/avatar.png'
import helper from '../../helper';
export default function PayTutorCard({ onClick, pic, name, rate, from, amount, date, calls, duration, isPaid, isSelected }) {
    return (
        <>
            <div className="quote-card cur-pointer tx-lt-grey f-fred fs-14 bg-smoke border-radius-1 py-2 my-2 ">
                <div className="row gx-0 mx-0">
                    <div className="col-lg-2 col-md-4 col-6 px-2 my-2 d-flex align-items-center">
                        <span className="pointer" ><img width="40px" height="40px" className="rounded-circle" src={pic ? helper.replaceS3Url(pic) : avatar} /></span>
                        <div className="px-2">
                            <p className="">{name}</p>
                            <p className="ms-500 fs-12">Tutor</p>
                        </div>
                    </div>
                    <div className="col-lg-1 col-md-4 col-6 d-flex my-2 align-items-center ">
                        <div className="px-2">
                            <p className="">{from ? from : 'NA'}</p>
                            <p className="ms-500 fs-12">Country</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-12 col-12 my-2 d-flex align-items-center justify-content-lg-start  justify-content-between ">
                        <div className="px-2">
                            <p className="">{rate}</p>
                            <p className="ms-500  fs-12">Rate/min(SAR)</p>
                        </div>
                        <div className="px-2">
                            <p className="">{calls}</p>
                            <p className="ms-500  fs-12">calls</p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-12 col-12 my-2 d-flex align-items-center justify-content-lg-start  justify-content-between ">
                        <div className="px-2">
                            <p className="">{duration}</p>
                            <p className="ms-500  fs-12">Call duration</p>
                        </div>

                    </div>
                    <div className="col-lg-3 col-12 my-2 px-0 d-flex align-items-lg-end align-items-start flex-column">
                        <div className="px-2 d-flex align-items-lg-end align-items-start flex-column">
                            <p className="">{parseFloat(amount).toFixed(2)}</p>
                            <p className="ms-500 fs-12">Payable(SAR)</p>
                        </div>
                    </div>
                    <div className="col-lg-1 px-1 my-2 d-flex align-items-center">
                        <div className="my-1 w-100">
                            <button className="btn  btn-detail  w-100 py-1 px-2 ms-900 fs-14" onClick={onClick}>Pay now</button>
                        </div>
                    </div>
                </div>
            </div>
        </>);
}
