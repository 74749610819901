import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment'
import { useHistory } from 'react-router-dom';
import ProfileIcon from '../../components/ProfileIcon';
import referral from '../../controller/referral';
import ReferralCard from './ReferralCard';
import Backdrop from '../../component/Backdrop';
import { useSelector } from 'react-redux';
import store from '../../redux/AppState'
import searchIcon from '../../static/images/search.svg'
import { FaBell } from 'react-icons/fa';
import helper from '../../helper';
export default function AllReferral() {
    const history = useHistory()
    const qry = useRef()
    const storeData = useSelector(state => state)

    const [resData, setResData] = useState([])
    const [searchData, setSearchData] = useState([])
    const [pageload, setPageload] = useState(true)
    useEffect(() => { getReferral() }, [])
    const getReferral = async () => {
        await referral.getReferralList().then((res) => {
            if (res.data.status) {
                setResData(res.data.result)
                setSearchData(res.data.result)
            }
            setPageload(false)
        })
    }

    const search = () => {
        if (qry.current.value) {
            let regEx = new RegExp(qry.current.value, 'si')
            const res = resData.filter((result) => {
                return ((result.student_id.toString()).match(regEx)) || result.name ? (result.name).match(regEx) : true
            })
            setSearchData(res)
        }
        else {
            setSearchData(resData)
        }
    }
    return (
        <>
            <div className='referral mx-1'>

                <header className='row gx-0 mx-0 mb-3 py-1'>
                    <div className="col-lg-5 col-md-5 col-6 d-flex flex-column justify-content-center px-1">
                        <p className="mb-1 fs-24 f-fred">
                            <span>
                                <i className="fas fa-bars mr-2 fs-20 hover-shadow pointer d-lg-inline-block d-md-inline-block d-none" onClick={() => store.setShowLeftBar(!storeData.showLeftBar)}></i>
                            </span>
                            <i class="fas fa-bars mr-1 fs-20 pointer d-lg-none  d-md-none d-inline-block"></i>SayDhad Referral <span className='tx-green'>manager</span></p>
                        <p className="lh-15 sub-heading">{moment(new Date).format('ddd, DD MMM YYYY hh:mm A')}</p>
                    </div>
                    <div className="col-lg-7 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">

                        {/* <button className="btn btn-edit fs-12 mx-2 d-lg-block d-md-block ms-700 d-none" onClick={toggleaddPlan} >ADD NEW PLAN</button> */}
                        <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                            <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                        </p>
                        <ProfileIcon />
                    </div>
                </header>

                <form onSubmit={(e) => { e.preventDefault(); search() }}>
                    <div className="row tutor-library gx-0 mx-0">
                        <div className="col-lg-3 col-md-4 col-12 px-1 mb-2">
                            <div className='d-flex w-100 align-items-center'>
                                <input type="search" ref={qry} placeholder="Search by student id/name" className="w-100 pl-5 h-100 input-box px-2 py-2 fs-14 ms-700" />
                                <img src={searchIcon} className="p-abs lt-0 pl-3" />
                            </div>

                        </div>
                        <div className="col-lg-2 col-md-4 col-6 px-1 mb-2">
                            <button type='submit' className="btn btn-login w-100 h-100 wrap">search</button>
                        </div>
                    </div>
                </form>
                <section className='mx-1'>
                    <div className="grid-view">
                        <div className="grid-card">
                            {
                                searchData.map((data) =>
                                    <ReferralCard
                                        plan={data}
                                        onClick={() => history.push(`/${helper.getRole()}/dashboard/referral/${data.student_id}`)}
                                    />)
                            }
                        </div>
                    </div>
                </section>

            </div>
            {pageload && <Backdrop />}
        </>);
}
