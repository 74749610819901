import React, { useState, useEffect } from 'react'
import EarningDetail from '../../adminDash/EarningDetail'
import store from '../../redux/AppState'
import admin from '../../controller/admin';
import Backdrop from '../../component/Backdrop';
import ProfileIcon from '../../components/ProfileIcon';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { DateRangePicker } from 'react-dates';
import EarningStudentCard from './EarningStudentCard';
import { useSelector } from 'react-redux'
import { FaBell } from 'react-icons/fa';
import helper from '../../helper';
import ReactPaginate from 'react-paginate';
export default function EarningManagement() {
    const storeData = useSelector(state => state)
    const [startDate, setStartDate] = useState(moment(new Date(new Date().setDate(new Date().getDate() - 30))));
    const [endDate, setEndDate] = useState(moment(new Date()));
    const [focusedInput, setFocusedInput] = useState(null);
    const [page, setPage] = useState(1)
    const history = useHistory()
    const [earning, setearning] = useState({list : [],total_amount:0,total_count : 0})
    const [pageLoad, setpageLoad] = useState(true)

    const onRange = (st, en) => {
        if(st)
        setStartDate(st);
        if(en)
        setEndDate(en)
        if (st && en) {
            apiCall(moment(st).format('YYYY-MM-DD'), moment(en).format('YYYY-MM-DD'), page)
        }
    }

    useEffect(() => {
        const date = new Date()
        const startDate = moment(new Date(date.setDate(date.getDate() - 30))).format('YYYY-MM-DD')
        apiCall(startDate, moment().format('YYYY-MM-DD'), page)
        return () => { }
    }, [])

    const apiCall = async (startDate, endDate, page) => {
        setpageLoad(true)
        await admin.getEarning(startDate, endDate, page, 10)
            .then((res) => {
                if (res.data.status) {
                    setearning({...res.data.result})
                }
                setpageLoad(false)
            })
    }

    return (
        <>
            <div className="mx-1">
                <header className='row gx-0 mx-0 mb-3 py-1'>
                    <div className="col-lg-4 col-md-5 col-6 d-flex flex-column justify-content-center px-1">
                        <p className="mb-1 fs-24 f-fred">
                            <span>
                                <i className="fas fa-bars mr-2 fs-20 hover-shadow pointer d-lg-inline-block d-md-inline-block d-none" onClick={() => store.setShowLeftBar(!storeData.showLeftBar)}></i>
                            </span>
                            <i class="fas fa-bars mr-1 fs-20 pointer d-lg-none  d-md-none d-inline-block" onClick={() => store.setMenu(true)}></i> SayDhad <span className="tx-green">earning/sales</span></p>
                        <p className="fs-14 ms-700 lh-15 sub-heading ml-2">{moment(new Date).format('ddd, DD MMM YYYY hh:mm A')}</p>
                    </div>
                    <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                        <div className="mr-2 ms-700 fs-12 pointer  tx-lt-black icon-border d-lg-block d-md-block d-none">
                            <DateRangePicker
                                showClearDates={true}
                                navPrev={null}
                                initialStartDate={null}
                                disabled={false}
                                startDate={startDate}
                                isOutsideRange={() => false}
                                isDayBlocked={() => false}
                                enableOutsideDays={true}
                                numberOfMonths={2}
                                startDateId="s_id"
                                endDate={endDate}
                                endDateId="e_id"
                                onDatesChange={({ startDate, endDate }) => { onRange(startDate, endDate) }}
                                focusedInput={focusedInput}
                                onFocusChange={e => setFocusedInput(e)}
                                displayFormat="DD/MM/YYYY"
                            />
                        </div>
                        <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                            <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                        </p>
                        <ProfileIcon />
                    </div>
                </header>

                <div className="">
                    <section className="row gx-0 mx-0 " style={{ display: "flex", flexDirection: "row" }}>
                        <div className="col-lg-3 col-md-6 col-12 pay mb-2">
                            <div className="row gx-0 mx-0 h-100">
                                <div className="col-lg-12 h-100 payout left-dash">
                                    <div style={{ order: "1" }}>
                                        <p className='ms-900 fs-18' >Total earnings (SAR)</p>
                                        <p className='ms-700 fs-14'>From {moment(startDate).format('MMM DD, YYYY')} to {moment(endDate).format('MMM DD, YYYY')}</p>
                                        <div className='overlay-cover  p-rel'>
                                            <h2 className='ms-500 fs-40 my-3'>
                                                {earning.total_amount > 1000 ? earning.total_amount.toString().substring(0, 3) + "K" : (parseInt(earning.total_amount)).toFixed(0)} </h2>

                                            <div style={{ backgroundColor: 'whitesmoke' }} className="p-abs  br-1 tx-lt-grey overlay-text p-abs tp-0 rt-0  px-2 py-1" >

                                                <p className='ms-700 fs-16 tx-lt-grey py-2'>SAR {earning.total_amount}</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12 pay mb-2">
                            <div className="row gx-0 mx-0 h-100">
                                <div className="col-lg-12 h-100 payout" >
                                    <div style={{ order: "1" }}>
                                        <p className='ms-900 fs-18' >Total students enrolled</p>
                                        <p className='ms-700 fs-14'>From {moment(startDate).format('MMM DD, YYYY')} to {moment(endDate).format('MMM DD, YYYY')}</p>
                                        <h2 className='ms-500 fs-40 my-3'>{earning.total_enrolled} </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="grid-view">
                        <div className="grid-card">
                            <section className='mx-1'>
                              
                                    {earning.list.map((data) =>
                                        <EarningStudentCard
                                            pic={data.profilePicture}
                                            name={data.name}
                                            country={data.country}
                                            amount={data.amount}
                                            cycle={data.commitment_level}
                                            date={data.created_at}
                                            onClick={() => history.push(`/${helper.getRole()}/dashboard/earning-manager/${data.user_id}`)}
                                        />)}
                              
                            </section>
                        </div>
                        
                    </div>
                     {/* Pagination  */}
                     {earning.total_count !== 0 &&
                            <div className="col-12 px-2 c-pagination">
                                <ReactPaginate
                                    previousLabel={<i class="fas fa-arrow-left"></i>}
                                    nextLabel={<i class="fas fa-arrow-right"></i>}
                                    pageCount={Math.ceil(earning.total_count / 10)}
                                    onPageChange={({ selected }) => {
                                        apiCall(startDate,endDate,selected + 1)
                                        setPage(selected+1)
                                    }}
                                    containerClassName={"paginationBttns"}
                                    previousClassName={"previousBttn"}
                                    nextClassName={"nextBttn"}
                                    disabledClassName={"paginationDisabled"}
                                    activeClassName={"paginationActive"}
                                    pageClassName={"eachpage"}
                                />
                            </div>
                        }


                    {earning.list.length === 0 && <p className='my-3 ms-700 text-center'>No Data found</p>}


                </div>
                {pageLoad && <Backdrop />}
            </div>
        </>
    )
}
