import React, { useState} from 'react'
import NewSlideShow from './NewSlideShow'
export default function NewSyllabusCard({ name, slides, file }) {
    const [open, setOpen] = useState(false)
    return (
        <>
            <div className="syllabus b-grey px-2 py-3">
                <div className="row gx-0 mx-0 ms-500 fs-14">
                    <div className="col-12 mb-2 px-1">
                        <p className="f-fred lh-18 fs-14 text-capitalize">{name}</p>
                        <p className="tx-lt-grey lh-15">Name of lesson</p>
                    </div>

                    <div className="col-4 mb-2 px-1">
                        <p className="f-fred lh-18 fs-14">{slides}</p>
                        <p className="tx-lt-grey lh-15">Slides</p>
                    </div>
                    <div className="col-8 mb-2 px-1">
                        <button className="btn btn-detail ms-700 w-100 py-1 text-white fs-14" onClick={() => setOpen(true)}>View Slide</button>
                    </div>
                </div>
                {open && 
                <NewSlideShow
                    data={file}
                    cb={() => setOpen(false)}
                    startIndex={0}
                />}
            </div>
        </>
    )
}
