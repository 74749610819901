import axios from 'axios'
import helper from '../helper/index'
import { constant } from "../config/Constant";
const URL = constant.BASE_URL

const getPagesCount = async () => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'get',
            url: `${URL}/admin/general-page-contents`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return res;
    }
    catch (err) {
        return err;
    }
}

const addSupportCategory = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'post',
            url: `${URL}/admin/support-ticket-categories`,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data  :data
        });
        return res;
    }
    catch (err) {
        return err;
    }
}

const addSupportSubCategory = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'post',
            url: `${URL}/admin/support-ticket-sub-categories`,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data : data
        });
        return res;
    }
    catch (err) {
        return err;
    }
}

const getAllSupportCategory = async (role) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'get',
            url: `${URL}/support-ticket-categories-list?role=${role}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return res;
    }
    catch (err) {
        return err;
    }
}

const removeSupportCategory = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'delete',
            url: `${URL}/admin/support-ticket-categories?id=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return res;
    }
    catch (err) {
        return err;
    }
}

const removeSupportSubCategory = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'delete',
            url: `${URL}/admin/support-ticket-sub-categories?id=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return res;
    }
    catch (err) {
        return err;
    }
}

const addLanguage = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'post',
            url: `${URL}/admin/language`,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data
        });
        return res;
    }
    catch (err) {
        return err;
    }
}

const getLanguage = async () => {
    try {
       
        const res = await axios({
            method: 'get',
            url: `${URL}/languages`,
           
        });
        return res;
    }
    catch (err) {
        return err;
    }
}

const removeLanguage = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'delete',
            url: `${URL}/admin/language?id=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return res;
    }
    catch (err) {
        return err;
    }
}

const addDialect = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'post',
            url: `${URL}/admin/dialect`,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data
        });
        return res;
    }
    catch (err) {
        return err;
    }
}

const getDialect = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'delete',
            url: `${URL}/admin/support-ticket-sub-categories?category_id=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return res;
    }
    catch (err) {
        return err;
    }
}

const removeDialect = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'delete',
            url: `${URL}/admin/dialect?id=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return res;
    }
    catch (err) {
        return err;
    }
}

export default {
    getPagesCount,
    addSupportCategory,
    addSupportSubCategory,
    getAllSupportCategory,
    removeSupportCategory,
    removeSupportSubCategory,
    addLanguage,
    getLanguage,
    removeLanguage,
    addDialect,
    getDialect,
    removeDialect

}