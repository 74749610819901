import React, { useState, useRef } from 'react'
import { FiXCircle } from "react-icons/fi";
import { FaTrashAlt } from "react-icons/fa";
import { toast } from 'react-hot-toast';
import Backdrop from '../../../component/Backdrop';
import websitesetting from '../../../controller/websitesetting';

export default function AddSubcategory({ onClose, onAdd,onRemove, data }) {
  const [load, setLoad] = useState(false)
  const category = useRef()
  const role = useRef()

  const submit = async () => {
    const req = {
      "sub_category_name": category.current.value,
      "category_id": data.id
    }
    if (!req.sub_category_name) {
      toast("Please enter sub-category name")
      return
    }

    setLoad(true)
    await websitesetting.addSupportSubCategory(req).then((res) => {
      if (res.data.status) {
        category.current.value = ''
        onAdd(res.data.result)
      }
      setLoad(false)
     
    })
  }

  const remove = async (id,index) => {
    setLoad(true)
    await websitesetting.removeSupportSubCategory(id).then((res) => {
      setLoad(false)
      if (res.data.status) {
        onRemove(index)
      }
      
    })
  }

  return (
    <>
      <div className="all-center popup-modal">
        <div className="right-popup notify-detail p-fix tp-0 rt-0  sm" >
          <div className="row gx-0 mx-0">
            <div className="col-10">
              <p className="f-fred fs-24">Edit <span style={{ color: "#089D12" }}> sub-category</span></p>
              <p className="sub-heading">{data.category_name}</p>
            </div>
            <div className="col-2">
              <FiXCircle className="pointer float-right " size="2rem" onClick={onClose} style={{ color: "lightgray" }} />

            </div>
          </div>


          <div className="row gx-0 mx-0 edit-profile f-fred">
            <div className="col-12 edit-input">
              <input type="text" placeholder='sub-category name' ref={category} style={{ border: "1px solid lightgray" }} />
            </div>

            <section className='col-12'>
              <h5 className='mt-3 mb-1'>Sub categories</h5>
              {data.sub_category.map((data, index) => <div className='ms-700 fs-14 py-2'>
                {data.sub_category_name}
                <FaTrashAlt className="pointer float-right tx-red" size={18} onClick={() => remove(data.id,index)} />
              </div>)}

            </section>

            <div className="col-12">
              <button className="btn btn-update px-2 py-2  col-xs-2" onClick={submit}>Add Sub-category</button>
            </div>

          </div>
        </div>
      </div>
      {load && <Backdrop />}
    </>
  )
}
