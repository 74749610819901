import React, { useState, useEffect } from 'react'
import TutorKnowledgeBaseEdit from '../../../adminDash/TutorKnowledgeBaseEdit';
import store from '../../../redux/AppState'
import { useSelector } from 'react-redux'
import admin from '../../../controller/admin'
import ArticleCard from '../../../components/ArticleCard';
import helper from '../../../helper';
import ProfileIcon from '../../../components/ProfileIcon';
import { useHistory, useParams } from 'react-router-dom';
import { FaBell } from 'react-icons/fa';
export default function ArticleDetail({ data, id, resData, category, CallClose }) {

    const { user } = useParams()
    const history = useHistory()
    const [Callpopup, setCallpopup] = useState(false);
    const [state, setState] = useState({
        data: data,
        id: id
    })
    const [relatedArticle, setRelatedArticle] = useState([])
    const [Backpopup, setBackpopup] = useState(false);
    const [Edit, setEdit] = useState(false);
    useEffect(() => {
        let list = []
        resData.forEach((result) => {
            if (result.category == category && result.id != id) {
                list.push(result)
            }
        })
        setRelatedArticle(list)

    }, [])

    const setArticle = (data, id) => {
        setState({
            ...state,
            data: data,
            id: id
        })

        let list = []
        resData.forEach((result) => {
            if (result.category == category && result.id != id) {
                list.push(result)
            }
        })
        setRelatedArticle(list)
        helper.scroll()
    }

    const DeleteArticle = async () => {

        const conf = window.confirm("Do you want to delete this artile?")
        if (!conf) {
            return
        }
        await admin.deleteArticle({ adminId: helper.getid(), id: state.id })
            .then((res) => {
                if (res.data.status) {
                    CallClose()
                }
            })
    }
    return (
        <>

            <div className="mx-1">
                {!Backpopup && <>
                    {!Edit && <>

                        <header className='row gx-0 mx-0 mb-3 py-1'>
                            <div className="col-lg-5 col-md-5 col-6 d-flex align-items-center px-1">
                                <button className="btn btn-back px-2 py-1 fs-14 ms-700 mr-2" style={{ fontWeight: "900" }} onClick={() => CallClose()}>BACK</button>
                                <div>
                                    <p className="mb-1 fs-24 f-fred overflow-elipse"> <i class="fas fa-bars mr-1 fs-24 pointer d-lg-none  d-md-none d-inline-block" onClick={() => store.setMenu(true)}></i>{state.data.title}</p>
                                    <p className="fs-14 ms-700 lh-15 sub-heading text-capitalize">{user} knowledgebase</p>

                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                                <button className="btn btn-update fs-12 mx-2 d-lg-block d-md-block d-none" onClick={() => history.push(`/${helper.getRole()}/dashboard/update-support-article/${state.id}`)}>UPDATE CONTENT</button>

                                <button className="btn btn-clear mx-2 d-lg-block d-md-block d-none" onClick={DeleteArticle}>DELETE ARTICLE</button>
                                <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                                    <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                                </p>
                                <ProfileIcon />
                            </div>
                        </header>



                        <div className="mx-1">
                            {!Callpopup && <>

                                <div className="row gx-0 mx-0 ">
                                    <div className="col-lg-8 mx-auto">
                                        <div className='mb-2'>
                                            <img height="350px" className='d-block mx-auto'
                                                style={{ borderRadius: "20px", display: "block", marginLeft: "auto", marginRight: "auto" }} src={helper.replaceS3Url(state.data.featured_image_link)} alt="" />

                                        </div>
                                        <section className='all-center'>
                                            <div className="d-flex">
                                                <div className="px-2 my-1 ">
                                                    <span className="  pointer" ><img width="40px" height="40px" className="rounded-circle" src={helper.replaceS3Url(data.featured_image_link)} /></span>
                                                </div>
                                                <div className="px-2 my-1">
                                                    <p className="f-fred ">{state.data.title}</p>
                                                    <p className="ms-500 fs-12 sub-heading">SayDhad {user} Support</p>
                                                </div>
                                            </div>
                                        </section>

                                        <section>
                                            <p className="f-fred py-2" style={{ fontSize: "24px" }}>{state.data.title}</p>
                                            <div className="ms-500 text-justify fs-18" style={{ color: "#434943" }} dangerouslySetInnerHTML={{ __html: state.data.description }}>
                                            </div>
                                        </section>
                                    </div>
                                </div>


                                <p className="plan my-2 px-2">Related articles</p>


                                <div class="row gx-0 mx-0">

                                    {relatedArticle.map((result, index) => {
                                        return (
                                            <div key={`rel-article${index}`} className="col-lg-3 mb-2 col-md-4 col-12">
                                                <ArticleCard
                                                    img={result.featured_image_link}
                                                    title={result.title}

                                                    onChange={() => setArticle(result, result.id)} />


                                            </div>
                                        )


                                    })}
                                    {relatedArticle.length === 0 && <div className="col-12">
                                        <p className='ms-700 my-3 text-center'>No Related article found</p>
                                    </div>}

                                </div>
                            </>}

                        </div>
                    </>}
                    {Edit && <TutorKnowledgeBaseEdit />}
                </>}

            </div>
        </>
    )
}
