const managerList = [
    {
        label: "Dashboard Manager",
        value: "Dashboard Manager"
    },
    {
        label: "Student Manager",
        value: "Student Manager"
    },
    {
        label: "Tutor Manager",
        value: "Tutor Manager"
    },
    {
        label: "Course Manager",
        value: "Course Manager"
    },
    {
        label: "Payout Manager",
        value: "Payout Manager"
    },
    {
        label: "Earning Manager",
        value: "Earning Manager"
    },
    {
        label: "Cost Manager",
        value: "Cost Manager"
    },
    {
        label: "Referral Manager",
        value: "Referral Manager"
    },
    {
        label: "Coupon Manager",
        value: "Coupon Manager"
    },
    {
        label: "Support Manager",
        value: "Support Manager"
    },
    {
        label: "General Setting",
        value: "General Setting"
    },
    {
        label: "Website Manager",
        value: "Website Manager"
    },
    {
        label: "Article Manager",
        value: "Article Manager"
    },
    {
        label: "Support Video Manager",
        value: "Support Video Manager"
    },
    {
        label: "Faq Manager",
        value: "Faq Manager"
    }
]

const permissionAccess = {
    dashboardManagement: "Dashboard Manager",
    tutorManagement: "Tutor Manager",
    studentManagement: "Student Manager",
    courseManagement: "Course Manager",

    payoutManagement: "Payout Manager",
    earningManagement: "Earning Manager",
    costManagement: "Cost Manager",


    referralManagement: "Referral Manager",
    couponManagement: "Coupon Manager",
    websiteManagement: "Website Manager",

    supportManagement: "Support Manager",
    articleManagement: "Article Manager",

    supportVideoManagement: "Support Video Manager",
    faqManagement: "Faq Manager",
    generalManagement: "General Setting",


}

export { managerList, permissionAccess }