import React, { useEffect } from 'react'
import '../../static/css/userDash.css'
import parot from '../../static/images/parrot-1.png'
import logo from '../../static/images/Logo.svg'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import CheckValidRoute from '../../route/CheckValidRoute'
import helper from '../../helper'
import admin from '../../controller/admin'
import store from '../../redux/AppState'
import manager from '../../controller/manager'
import { SvgDashboard, SvgLibrary, SvgSupport, SvgStudents, SvgPayout, SvgLogout } from '../../Icons'
import { permissionAccess } from '../../config/permissions'

export default function Dashboard() {
    const history = useHistory()
    const storeData = useSelector(state => state)
    const { page } = useParams()

    const logout = () => {
        window.localStorage.clear();
        history.push('/')
    }
    useEffect(() => {
        getProfile()
    }, [])

    const getProfile = async () => {
        if (helper.getRole() === "admin") {
            await admin.getAdminProfile({ id: helper.getid() })
                .then((res) => {
                    if (res.data.status) {
                        store.setPic(res.data.result ? res.data.result.profilePicture : null)
                    }
                })
        } else if (helper.getRole() === "manager") {
            await manager.getManagerDetails(helper.getid()).then((res) => {
                if (res.data.status) {
                    store.setPic(res.data.result ? res.data.result.profilePicture : null)
                }
            })
        }
    }

    return (
        <>
            <div className="userdash">
                <div className='my-0 py-0 d-flex' style={{width:'100vw'}}>
                        {
                            storeData.showLeftBar ?
                                <div className='left-icon-sm-strip d-lg-block d-md-block d-none' width={'80px'}>
                                    <section className='left-dash hide-scroll '>
                                        <div className='scroll-hide'>
                                            <div className="all-center  overlay-cover my-2">
                                                <img width="30px" className="pointer" src={parot} alt="" />

                                            </div>

                                            <ul className="mt-3 list-unstyled">
                                                {helper.checkModuleAccess({ moduleName: permissionAccess.dashboardManagement }) && <li onClick={() => { history.push(`/${helper.getRole()}/dashboard/home`) }} className={`${page === "home" && "active-tab-sm"}  overlay-cover `}>
                                                    <p className="f-fred text-upper mx-2">
                                                        <span className='icon-width d-flex align-items-center'>

                                                            <SvgDashboard className={`${page === "home" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                        </span>
                                                    </p>
                                                    <div className="p-abs overlay-text px-2 py-1" >
                                                        Dashboard
                                                    </div>
                                                </li>}
                                                {helper.checkModuleAccess({ moduleName: permissionAccess.tutorManagement }) &&
                                                    <li onClick={() => { history.push(`/${helper.getRole()}/dashboard/tutor`) }} className={`${page === "tutor" && "active-tab-sm"} overlay-cover`}>

                                                        <p className="f-fred text-upper mx-2">                    <span className='icon-width d-flex align-items-center'>

                                                            <SvgStudents className={`${page === "tutor" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                        </span></p>
                                                        <div className="p-abs overlay-text px-2 py-1" >
                                                            Tutors
                                                        </div>

                                                    </li>
                                                }
                                                {helper.checkModuleAccess({ moduleName: permissionAccess.studentManagement }) &&
                                                    <li onClick={() => { history.push(`/${helper.getRole()}/dashboard/student`) }} className={`${page === "student" && "active-tab-sm"} overlay-cover`}>
                                                        <p className="f-fred text-upper mx-2">                    <span className='icon-width d-flex align-items-center'>

                                                            <SvgStudents className={`${page === "student" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                        </span></p>
                                                        <div className="p-abs overlay-text px-2 py-1" >
                                                            Students
                                                        </div>

                                                    </li>
                                                }
                                                {helper.checkModuleAccess({ moduleName: permissionAccess.courseManagement }) &&

                                                    <li onClick={() => { history.push(`/${helper.getRole()}/dashboard/course-manager`) }} className={`${page === "course-manager" && "active-tab-sm"} overlay-cover`}>
                                                        <p className="f-fred text-upper mx-2"> <span className='icon-width d-flex align-items-center'>

                                                            <SvgLibrary className={`${page === "course-manager" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                        </span></p>
                                                        <div className="p-abs overlay-text px-2 py-1" >
                                                            Course Manager
                                                        </div>

                                                    </li>
                                                }
                                                {helper.checkModuleAccess({ moduleName: permissionAccess.payoutManagement }) &&
                                                    <li onClick={() => { history.push(`/${helper.getRole()}/dashboard/payout-manager`) }} className={`${page === "payout-manager" && "active-tab-sm"} overlay-cover`}>
                                                        <p className="f-fred text-upper mx-2">  <span className='icon-width d-flex align-items-center'>

                                                            <SvgPayout className={`${page === "payout-manager" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                        </span></p>
                                                        <div className="p-abs overlay-text px-2 py-1" >
                                                            Payout Manager
                                                        </div>

                                                    </li>
                                                }                                            {helper.checkModuleAccess({ moduleName: permissionAccess.earningManagement }) &&
                                                    <li onClick={() => { history.push(`/${helper.getRole()}/dashboard/earning-manager`) }} className={`${page === "earning-manager" && "active-tab-sm"} overlay-cover`}>
                                                        <p className="f-fred text-upper mx-2">  <span className='icon-width d-flex align-items-center'>

                                                            <SvgSupport className={`${page === "earning-manager" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                        </span></p>
                                                        <div className="p-abs overlay-text px-2 py-1" >
                                                            Earning Manager
                                                        </div>

                                                    </li>
                                                }
                                                {helper.checkModuleAccess({ moduleName: permissionAccess.costManagement }) &&
                                                    <li onClick={() => { history.push(`/${helper.getRole()}/dashboard/cost-manager`) }} className={`${page === "cost-manager" && "active-tab-sm"} overlay-cover`}>
                                                        <p className="f-fred text-upper mx-2">  <span className='icon-width d-flex align-items-center'>

                                                            <SvgSupport className={`${page === "earning-manager" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                        </span></p>
                                                        <div className="p-abs overlay-text px-2 py-1" >
                                                            Cost Manager
                                                        </div>

                                                    </li>
                                                }                                            {helper.checkModuleAccess({ moduleName: permissionAccess.referralManagement }) &&
                                                    <li onClick={() => { history.push(`/${helper.getRole()}/dashboard/referral`) }} className={`${page === "referral" && "active-tab-sm"} overlay-cover`}>
                                                        <p className="f-fred text-upper mx-2">  <span className='icon-width d-flex align-items-center'>

                                                            <SvgSupport className={`${page === "earning-manager" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                        </span></p>
                                                        <div className="p-abs overlay-text px-2 py-1" >
                                                            Referral Manager
                                                        </div>
                                                    </li>
                                                }
                                                {helper.checkModuleAccess({ moduleName: permissionAccess.couponManagement }) &&
                                                    <li onClick={() => { history.push(`/${helper.getRole()}/dashboard/coupon-manager`) }} className={`${page === "coupon-manager" && "active-tab-sm"} overlay-cover`}>
                                                        <p className="f-fred text-upper mx-2">  <span className='icon-width d-flex align-items-center'>

                                                            <SvgSupport className={`${page === "earning-manager" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                        </span></p>
                                                        <div className="p-abs overlay-text px-2 py-1" >
                                                            Coupon Manager
                                                        </div>

                                                    </li>
                                                }
                                                {helper.checkModuleAccess({ moduleName: permissionAccess.supportManagement }) &&
                                                    <li onClick={() => { history.push(`/${helper.getRole()}/dashboard/user-support`) }} className={`${(page === "user-support" || page === "tutor-support") && "active-tab-sm"} overlay-cover`}>
                                                        <p className="f-fred text-upper mx-2">  <span className='icon-width d-flex align-items-center'>

                                                            <SvgSupport className={`${page === "earning-manager" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                        </span></p>
                                                        <div className="p-abs overlay-text px-2 py-1" >
                                                            SayDhad Support
                                                        </div>

                                                    </li>}
                                                {helper.checkModuleAccess({ moduleName: '' }) &&

                                                    <li onClick={() => { history.push(`/${helper.getRole()}/dashboard/user-manager`) }} className={`${page === "user-manager" && "active-tab-sm"} overlay-cover`}>
                                                        <p className="f-fred text-upper mx-2">  <span className='icon-width d-flex align-items-center'>

                                                            <SvgSupport className={`${page === "earning-manager" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                        </span></p>
                                                        <div className="p-abs overlay-text px-2 py-1" >
                                                            User Manager
                                                        </div>

                                                    </li>}
                                                {helper.checkModuleAccess({ moduleName: permissionAccess.generalManagement }) &&
                                                    <li onClick={() => { history.push(`/${helper.getRole()}/dashboard/setting`) }} className={`${page === "setting" && "active-tab-sm"} overlay-cover`}>
                                                        <p className="f-fred text-upper mx-2">  <span className='icon-width d-flex align-items-center'>

                                                            <SvgSupport className={`${page === "earning-manager" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                        </span></p>
                                                        <div className="p-abs overlay-text px-2 py-1" >
                                                            Setting
                                                        </div>
                                                    </li>
                                                }

                                                <li onClick={() => { logout(); history.push('/') }} className="overlay-cover">
                                                    <p className="f-fred text-upper mx-2"> <SvgLogout className="inactive-icon-color" /></p>
                                                    <div className="p-abs overlay-text px-2 py-1" >
                                                        Logout
                                                    </div>

                                                </li>

                                            </ul>
                                        </div>
                                    </section>
                                </div>
                                :
                                <div className='left-icon-lg-strip'>
                                    <section className='left-dash d-flex flex-column justify-content-between left-icon-lg-strip px-2 hide-scroll' style={{ width: '270px' }}>
                                        <div >
                                            <div className="all-center flex-column overlay-cover py-3 p-sticky tp-0 bg-white" style={{ zIndex: 1 }}>
                                                <img width="190px" height={'68px'} className="pointer" src={logo} alt="" />
                                                <p className='tx-green f-fred mt-2 text-upper'>{helper.getRole()} PANEL</p>

                                            </div>

                                            <ul className="mt-3 list-unstyled">

                                                {helper.checkModuleAccess({ moduleName: permissionAccess.dashboardManagement }) && <li onClick={() => { history.push(`/${helper.getRole()}/dashboard/home`) }} className={`${page === "home" && "active-tab"}`}>
                                                    <div className="f-fred text-upper  d-flex align-items-center">
                                                        <span className='icon-width d-flex align-items-center'>

                                                            <SvgDashboard className={`${page === "home" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                        </span>
                                                        <p>Dashboard</p>
                                                    </div>
                                                </li>}
                                                {helper.checkModuleAccess({ moduleName: permissionAccess.tutorManagement }) &&
                                                    <li onClick={() => { history.push(`/${helper.getRole()}/dashboard/tutor`) }} className={`${page === "tutor" && "active-tab"}`}>

                                                        <div className="f-fred text-upper  d-flex align-items-center">
                                                            <span className='icon-width d-flex align-items-center'>

                                                                <SvgStudents className={`${page === "tutor" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                            </span>
                                                            <p>Tutors</p>
                                                        </div>

                                                    </li>
                                                }
                                                {helper.checkModuleAccess({ moduleName: permissionAccess.studentManagement }) &&
                                                    <li onClick={() => { history.push(`/${helper.getRole()}/dashboard/student`) }} className={`${page === "student" && "active-tab "}`}>
                                                        <div className="f-fred text-upper  d-flex align-items-center">
                                                            <span className='icon-width d-flex align-items-center'>

                                                                <SvgStudents className={`${page === "student" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                            </span>
                                                            <p>Students</p>
                                                        </div>

                                                    </li>
                                                }

                                                {helper.checkModuleAccess({ moduleName: permissionAccess.courseManagement }) &&
                                                    <li onClick={() => { history.push(`/${helper.getRole()}/dashboard/course-manager`) }} className={`${page === "course-manager" && "active-tab "}`}>
                                                        <div className="f-fred text-upper  d-flex align-items-center">
                                                            <span className='icon-width d-flex align-items-center'>

                                                                <SvgLibrary className={`${page === "course-manager" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                            </span>
                                                            <p>Course Manager</p>
                                                        </div>
                                                    </li>
                                                }
                                                {helper.checkModuleAccess({ moduleName: permissionAccess.payoutManagement }) &&
                                                    <li onClick={() => { history.push(`/${helper.getRole()}/dashboard/payout-manager`) }} className={`${page === "payout-manager" && "active-tab "}`}>
                                                        <div className="f-fred text-upper  d-flex align-items-center">
                                                            <span className='icon-width d-flex align-items-center'>

                                                                <SvgPayout className={`${page === "payout-manager" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                            </span>
                                                            <p>Payouts Manager</p>
                                                        </div>
                                                    </li>
                                                }
                                                {helper.checkModuleAccess({ moduleName: permissionAccess.earningManagement }) &&
                                                    <li onClick={() => { history.push(`/${helper.getRole()}/dashboard/earning-manager`) }} className={`${page === "earning-manager" && "active-tab "}`}>
                                                        <div className="f-fred text-upper  d-flex align-items-center">
                                                            <span className='icon-width d-flex align-items-center'>

                                                                <SvgSupport className={`${page === "earning-manager" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                            </span>
                                                            <p>Earning / Sales</p>
                                                        </div>

                                                    </li>
                                                }
                                                {helper.checkModuleAccess({ moduleName: permissionAccess.costManagement }) &&
                                                    <li onClick={() => { history.push(`/${helper.getRole()}/dashboard/cost-manager`) }} className={`${page === "cost-manager" && "active-tab "}`}>
                                                        <div className="f-fred text-upper  d-flex align-items-center">
                                                            <span className='icon-width d-flex align-items-center'>

                                                                <SvgSupport className={`${page === "cost-manager" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                            </span>
                                                            <p>Cost Manager</p>
                                                        </div>

                                                    </li>
                                                }
                                                {helper.checkModuleAccess({ moduleName: permissionAccess.referralManagement }) &&
                                                    <li onClick={() => { history.push(`/${helper.getRole()}/dashboard/referral`) }} className={`${page === "referral" && "active-tab"}`}>
                                                        <div className="f-fred text-upper  d-flex align-items-center">
                                                            <span className='icon-width d-flex align-items-center'>

                                                                <SvgSupport className={`${page === "referral" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                            </span>
                                                            <p>Referral</p>
                                                        </div>
                                                    </li>
                                                }
                                                {helper.checkModuleAccess({ moduleName: permissionAccess.couponManagement }) &&
                                                    <li onClick={() => { history.push(`/${helper.getRole()}/dashboard/coupon-manager`) }} className={`${page === "coupon-manager" && "active-tab "}`}>
                                                        <div className="f-fred text-upper  d-flex align-items-center">
                                                            <span className='icon-width d-flex align-items-center'>

                                                                <SvgSupport className={`${page === "coupon-manager" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                            </span>
                                                            <p>Coupon Manager</p>
                                                        </div>

                                                    </li>
                                                }
                                                {helper.checkModuleAccess({ moduleName: permissionAccess.supportManagement }) &&
                                                    <li onClick={() => { history.push(`/${helper.getRole()}/dashboard/user-support`) }} className={`${(page === "user-support" || page === "tutor-support") && "active-tab "}`}>
                                                        <div className="f-fred text-upper  d-flex align-items-center">
                                                            <span className='icon-width d-flex align-items-center'>

                                                                <SvgSupport className={`${page === "user-support" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                            </span>
                                                            <p>Support</p>
                                                        </div>
                                                    </li>
                                                }
                                                {helper.checkModuleAccess({ moduleName: '' }) &&
                                                    <li onClick={() => { history.push(`/${helper.getRole()}/dashboard/user-manager`) }} className={`${page === "user-manager" && "active-tab "}`}>
                                                        <div className="f-fred text-upper  d-flex align-items-center">
                                                            <span className='icon-width d-flex align-items-center'>

                                                                <SvgSupport className={`${page === "user-manager" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                            </span>
                                                            <p>User Manager</p>
                                                        </div>

                                                    </li>}
                                                {helper.checkModuleAccess({ moduleName: permissionAccess.generalManagement }) &&
                                                    <li onClick={() => { history.push(`/${helper.getRole()}/dashboard/setting`) }} className={`${page === "setting" && "active-tab "}`}>
                                                        <div className="f-fred text-upper  d-flex align-items-center">
                                                            <span className='icon-width d-flex align-items-center'>

                                                                <SvgSupport className={`${page === "setting" ? "active-icon-color" : 'inactive-icon-color'}`} />
                                                            </span>
                                                            <p>General Settings</p>
                                                        </div>
                                                    </li>
                                                }
                                                <li onClick={() => logout()}>
                                                    <div className="f-fred text-upper  d-flex align-items-center">
                                                        <span className='icon-width d-flex align-items-center'>

                                                            <SvgLogout className="inactive-icon-color" />
                                                        </span>
                                                        <p>Logout</p>
                                                    </div>
                                                </li>
                                            </ul>

                                        </div>
                                        <div className='pb-3 pl-3 p-sticky bt-0 bg-white'>
                                            <p className="footer ms-700 mt-4" style={{ opacity: '1' }}>@2021 SayDhad. All Rights Reserved</p>
                                            <p className="footer tx-grey ms-500" style={{ opacity: '1' }}>App Version 1.0.0</p>

                                        </div>
                                    </section>
                                </div>}
                        <div className='w-100 my-0 py-0' style={{overflow:'auto'}}>
                            <section className='right-dash hide-scroll'>
                                <div className='scroll-hide '>
                                    <CheckValidRoute />
                                </div>
                            </section>
                        </div>
                </div>
            </div>
        </>
    )
}
