import React from 'react';

export default function FullStatPop({ open, data, close, type }) {
    return (<>
        {open &&
            <div className='popup-modal all-center'>

                <section className='popup-modal-body sm pt-3'>
                    <p className='fs-24 ms-900 text-center my-2'>Global {type === "tutor" ? "Tutors" : "Students"}</p>
                    {data.map((result, index) => {
                        return (
                            <section key={'user${index'} className=''>
                                <div className='break-line my-2 mx-3'></div>
                                <div className="row  gx-0 mx-0 pb-2">
                                    <div className="col-lg-8 col-md-2 col-3 px-3 d-flex align-items-center">
                                        <span className='mr-2'>
                                            <img width="24px" height="16px" className="" src={result.flag_uri} alt="countryflaf" /></span>
                                            <p className="ms-500 " style={{ fontSize: "14px" }}>{result.country}</p>
                                
                                    </div>
                                   
                                    <div className="col-4 px-3 d-flex align-items-center justify-content-end">
                                        <p className="ms-700 fs-14 text-right" style={{ fontSize: "12px" }}>{type === "tutor" ? result.totalTutors : result.totalStudents}</p>
                                    </div>
                                </div>
                               
                            </section>
                        )
                    })}
                    <button className='btn bg-sky py-1 d-block mx-auto px-3 my-3 ms-700 fs-14 text-white' onClick={close}>Close</button>

                </section>


            </div>
        }
    </>);
}
