import React, { useState } from 'react'
import { FiXCircle } from 'react-icons/fi'
import admin from '../controller/admin'
import { Toaster, toast } from 'react-hot-toast'
import helper from '../helper/index'
import Backdrop from '../component/Backdrop'
export default function TutorKBCategoryEdit({ CallClose, OnAdd }) {

    const [load, setLoad] = useState(false)
    const [addKB, setaddKB] = useState()

    const addKnowBase = async () => {
        const reqData = {
            "admin_id": helper.getid(),
            "category_name": addKB
        }
        if (!reqData.category_name) {
            toast('Please enter category name')
            return
        }
        await admin.addKB(reqData)
            .then((res) => {
                if (res.data.status) {
                    toast('Successfully added')
                    setTimeout(() => {
                        CallClose()
                        OnAdd(res.data.result)
                    }, [])
                }
               
                setLoad(false)
            })
    }
    return (
        <>
         
            <div className="  all-center popup-modal">
                <div className="right-popup notify-detail p-fix tp-0 rt-0  px-3 py-3 sm" >
                    <div className="row gx-0 mx-0">
                        <div className="col-10">
                            <p className="f-fred" style={{ fontSize: "24px" }}>Add<span style={{ color: "#089D12" }}> Category</span></p>
                            <p className="sub-heading ms-700 " style={{ color: "#434943", fontSize: "14px" }}>SayDhad  knowledgebase</p>
                        </div>
                        <div className="col-2">
                            <FiXCircle className="pointer float-right " size="2rem" onClick={CallClose} style={{ color: "lightgray" }} />

                        </div>
                    </div>


                    <div className="row gx-0 mx-0 edit-profile f-fred">

                        <div className="col-lg-12 edit-input my-2 ms-700 px-1">
                            <input placeholder="Category name" type="text" style={{ border: "1px solid lightgray" }}  onChange={(e) => { setaddKB(e.target.value) }} />
                        </div>
                        <div className="col-12 px-1">
                            <button className="btn btn-update px-2 mr-1 py-2 ms-700  btn-sm col-xs-2 " onClick={addKnowBase}>ADD CATEGORY</button>

                        </div>



                    </div>
                </div>
            </div>

            {load && <Backdrop />}
        </>
    )
}
