import axios from 'axios'
import { constant } from "../config/Constant";
const URL_AUTH = constant.BASE_URL

const tutorRegister = async (data)=>{
    try{
        const res = await axios.post(`${URL_AUTH}/auth-tutor/registration`,data);
        return res;
    }
    catch(err){
        return err;
    }
}


const tutorVerify = async (data)=>{
    try{
        const res = await axios.post(`${URL_AUTH}/tutor/verify-account`,data);
        return res;
    }
    catch(err){
        return err;
    }
}

const tutorLogin = async (data)=>{
    try{
        const res = await axios.post(`${URL_AUTH}/tutor/login`,data);
        return res;
    }
    catch(err){
        return err;
    }
}


const tutorForgot = async (data)=>{
    try{
        const res = await axios.post(`${URL_AUTH}/tutor/forgot-password/link`,data);
        return res;
    }
    catch(err){
        return err;
    }
}

// admin panel ui integration
const adminLogin = async (data)=>{
    try{
        const res = await axios.post(`${URL_AUTH}/admin/login`,data);
        return res;
    }
    catch(err){
        return err;
    }
}
const adminForgotPassword = async (data)=>{
    try{
        const res = await axios.post(`${URL_AUTH}/admin/forgot-password/link`,data);
        return res;
    }
    catch(err){
        return err;
    }
}

const adminResetPassword = async (data)=>{
    try{
        const res = await axios.post(`${URL_AUTH}/admin/reset-password`,data);
        return res;
    }
    catch(err){
        return err;
    }
}

const managerLogin = async (data)=>{
    try{
        const res = await axios.post(`${URL_AUTH}/manager/login`,data);
        return res;
    }
    catch(err){
        return err;
    }
}

const managerForgotPassword = async (data)=>{
    try{
        const res = await axios.post(`${URL_AUTH}/manager/forgot-password/link`,data);
        return res;
    }
    catch(err){
        return err;
    }
}

const managerResetPassword = async (data)=>{
    try{
        const res = await axios.post(`${URL_AUTH}/manager/reset-password`,data);
        return res;
    }
    catch(err){
        return err;
    }
}


export default {
    tutorRegister,tutorVerify,tutorLogin,tutorForgot, adminLogin,managerLogin,
    adminForgotPassword,adminResetPassword,managerForgotPassword,managerResetPassword
}