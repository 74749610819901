import axios from "axios";
import helper from "../helper/index";
import { constant } from "../config/Constant";
const ADMIN_URL = constant.ADMIN_URL
const BASE_URL = constant.BASE_URL

const addManager = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'post',
            url: `${ADMIN_URL}/add-user-manager`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}


const getManagerDetails = async (id) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'get',
            url: `${ADMIN_URL}/manager-details-by-Id?managerId=${id}`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}

const editManagerDetails = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'put',
            url: `${ADMIN_URL}/edit-manager-details-by-Id`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}


const changePassword = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios({
            method: 'post',
            url: `${BASE_URL}/manager/change-password`,
            data: data,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res;
    }
    catch (err) {
        return err
    }
}

export default {
addManager,editManagerDetails,getManagerDetails,changePassword
};


