import React, { useState } from 'react'
import store from '../../../redux/AppState'
import { useSelector } from 'react-redux'
import admin from '../../../controller/admin';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ProfileIcon from '../../../components/ProfileIcon'
import { FaBell } from 'react-icons/fa';
import helper from '../../../helper';

export default function PolicyPages() {
    const history = useHistory()

    const [pageLOad, setpageLOad] = useState(true)

    const [getData, setgetData] = useState()
    useEffect(() => {
        apiCall()
        return () => {
        }
    }, [])
    const apiCall = async () => {

        await admin.getPolicyPages()

            .then((res) => {

                if (res.data.status) {
                    setgetData(res.data.result)
                    setpageLOad(false)
                }

            })
    }
    return (
        <>





            <section className='mx-1'>
                <header className='row gx-0 mx-0 py-1 mb-3'>
                    <div className="col-lg-4 col-md-5 col-6 d-flex  px-1 align-items-center">
                        <button className="btn btn-back  mr-2" onClick={() => history.goBack()}>BACK</button>
                        <div>
                            <p className="mb-1 fs-24 f-fred">

                                <i class="fas fa-bars mr-1 fs-20 pointer d-lg-none  d-md-none d-inline-block px-1" onClick={() => store.setMenu(true)}></i> Website  <span className="tx-green">content</span></p>
                            <p className="lh-15 sub-heading">SayDhad general setting</p>

                        </div>

                    </div>
                    <div className="col-lg-8 col-md-7 col-6 px-1 d-flex justify-content-end align-items-center">
                        <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                            <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                        </p>

                        <ProfileIcon />
                    </div>
                </header>
            </section>

            <section className='mx-2 tx-lt-grey'>
                <div className="quote-card cur-pointer bg-smoke border-radius-1 py-2 my-2 ">
                    <div className="row gx-0 mx-0">
                        <div className="col-lg-11 col-10 px-2 my-1">
                            <p className="f-fred fs-14">Terms & conditions</p>
                            <p className='ms-500 fs-14'>Global content</p>
                        </div>
                        <div className="col-lg-1 col-2  my-1">
                            <button className="btn btn-dark w-100 py-1 px-2 f-fred fs-14 " onClick={() => history.push(`/${helper.getRole()}/dashboard/policy-pages/terms-condition`)}>Edit</button>
                        </div>
                    </div>
                </div>

                <div className="quote-card cur-pointer bg-smoke border-radius-1 py-2 my-2 ">
                    <div className="row gx-0 mx-0">

                        <div className="col-lg-11 col-10 px-2 my-1">
                            <p className="f-fred fs-14">Privacy policy</p>
                            <p className='ms-500 fs-14'>Global content</p>

                        </div>
                        <div className="col-lg-1 col-2  my-1">

                            <button className="btn btn-dark w-100 py-1 px-2 f-fred fs-14 " onClick={() => history.push(`/${helper.getRole()}/dashboard/policy-pages/privacy-policy`)}>Edit</button>

                        </div>

                    </div>
                </div>

                <div className="quote-card cur-pointer bg-smoke border-radius-1 py-2 my-2 ">
                    <div className="row gx-0 mx-0">


                        <div className="col-lg-11 col-10 px-2 my-1">
                            <p className="f-fred fs-14">Refunds policy</p>
                            <p className='ms-500 fs-14'>Global content</p>

                        </div>
                        <div className="col-lg-1 col-2  my-1">
                            <button className="btn btn-dark w-100 py-1 px-2 f-fred fs-14" onClick={() => history.push(`/${helper.getRole()}/dashboard/policy-pages/refund-policy`)}>Edit</button>
                        </div>
                    </div>
                </div>


                <div className="quote-card cur-pointer bg-smoke border-radius-1 py-2 my-2 ">
                    <div className="row gx-0 mx-0">

                        <div className="col-lg-11 col-10 px-2 my-1">
                            <p className="f-fred fs-14">Disclaimer</p>
                            <p className='ms-500 fs-14'>Global content</p>

                        </div>
                        <div className="col-lg-1 col-2  my-1">
                            <button className="btn btn-dark w-100 py-1 px-2 f-fred fs-14" onClick={() => history.push(`/${helper.getRole()}/dashboard/policy-pages/disclaimer`)}>Edit</button>
                        </div>


                    </div>
                </div>



            </section>


        </>
    )
}
