import React from 'react'
import parrot from '../static/images/parrot-1.png'
const styles = {
    zIndex: 5,
    backgroundColor: 'rgba(233, 232, 229, 0.4)',
    height: '100%',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    imgstyle: {

    }
}

export default function Backdrop() {
    return (
        <div className="custom-backdrop all-center" style={styles}>
            <img className="p-anim" width="80px" src={parrot} alt="" />
        </div>
    )
}
