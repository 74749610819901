import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import store from '../../redux/AppState'
import admin from '../../controller/admin';
import moment from 'moment';
import ProfileIcon from '../../components/ProfileIcon';
import { useHistory } from 'react-router-dom';
import SupportCard from './SupportCard';
import Backdrop from '../../component/Backdrop';
import helper from '../../helper';
import { FaBell } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
export default function TutorSupport() {
    const storeData = useSelector(state => state)
    const history = useHistory();
    const [pageLoad, setpageLoad] = useState(true)
    const query = useRef()
    const [state, setState] = useState({ list: [], pageNo: 1, limit: 10, total: 0 })

    useEffect(() => {
        apiCall(query.current.value, state.pageNo)
        return () => { }
    }, [])

    const apiCall = async (qry, pageNo) => {
        setpageLoad(true)
        await admin.getTutorSupportTicket(qry, pageNo, 10)
            .then((res) => {
                if (res.data.status) {
                    setState({ ...state, list: res.data.result.ticket_list, total: res.data.result.total_count })
                }
                setpageLoad(false)
            })
    }

    return (
        <>
            <div className="mx-1">
                <header className='row gx-0 mx-0 mb-3 py-1'>
                    <div className="col-lg-4 col-md-5 col-6 d-flex flex-column justify-content-center px-1">
                        <p className="mb-1 fs-24 f-fred">
                            <span>
                                <i className="fas fa-bars mr-2 fs-20 hover-shadow pointer d-lg-inline-block d-md-inline-block d-none" onClick={() => store.setShowLeftBar(!storeData.showLeftBar)}></i>
                            </span>
                            <i class="fas fa-bars mr-1 fs-20 pointer d-lg-none  d-md-none d-inline-block"></i>Tutor<span className='tx-green'> support</span></p>
                        <p className="fs-14 ms-700 lh-15 sub-heading ml-2">{moment(new Date).format('ddd, DD MMM YYYY hh:mm A')}</p>
                    </div>
                    <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                        <button className="btn btn-edit ms-700 fs-14  d-lg-block  d-md-block d-none mx-2  fs-12 wrap" onClick={() => history.push(`/${helper.getRole()}/dashboard/user-support`)}>VIEW USER SUPPORT</button>
                        <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                            <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                        </p>
                        <ProfileIcon />
                    </div>
                </header>

                <div className="tutor-library mx-1">
                    <form onSubmit={(e) => { e.preventDefault(); apiCall(query.current.value, 1) }}>
                        <div className="row gx-0 mx-0">
                            <div className="col-lg-3 col-md-4 col-12 px-1 mb-2">
                                <input ref={query} type="search" placeholder="Type here category/ticketId" className="w-100 h-100 input-box px-2 py-2 fs-14 ms-700" />
                            </div>
                            <div className="col-lg-2 col-md-4 col-6 px-1 mb-2">
                                <button type='submit' className="btn btn-login w-100 h-100 wrap">search</button>
                            </div>
                        </div>
                    </form>
                    <div className="grid-view">
                        <div className="grid-card">
                            {state.list.map((data) => <div key={data.id} className="">
                                <SupportCard
                                    tic={data}
                                    onClick={(ticketId) => { history.push(`/${helper.getRole()}/dashboard/user-support/${ticketId}`) }}
                                />
                            </div>)}
                        </div>

                        {state.list.length === 0 && <p className='my-3 text-center'>No Record Found</p>}
                    </div>

                    {state.total !== 0 &&
                        <div className="col-12 px-2 c-pagination">
                            <ReactPaginate
                                previousLabel={<i class="fas fa-arrow-left"></i>}
                                nextLabel={<i class="fas fa-arrow-right"></i>}
                                pageCount={Math.ceil(state.total / 10)}
                                onPageChange={({ selected }) => {
                                    apiCall(query.current.value, selected + 1)
                                    setState({ ...state, pageNo: selected + 1 })
                                }}
                                containerClassName={"paginationBttns"}
                                previousClassName={"previousBttn"}
                                nextClassName={"nextBttn"}
                                disabledClassName={"paginationDisabled"}
                                activeClassName={"paginationActive"}
                                pageClassName={"eachpage"}
                            />
                        </div>
                    }


                </div>


            </div>

            {pageLoad && <Backdrop />}
        </>
    )
}
