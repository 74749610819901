import React, { useRef } from 'react'
import '../static/css/threeDot.css'

export default function ThreeDot({onVideo,onChat}) {

    const dotRef = useRef()
    const handleToggle = () => {
        let classess = dotRef.current.className.split(" ")
        if (classess.includes("d-block")) {
            let index = classess.indexOf("d-block")
            classess.splice(index, 1)
            dotRef.current.className = classess.join(" ") + " d-none "
        } else {
            let index = classess.indexOf("d-none")
            classess.splice(index, 1)
            dotRef.current.className = classess.join(" ") + " d-block "
        }
    }

    return (
        <section className='p-rel three-dot-box'>
            <i className="fas fa-ellipsis-v three-dot-icon pointer ml-2 hover-shadow " onClick={handleToggle}></i>
            <div ref={dotRef} className='p-abs  three-dot-body bg-sky  fs-16  ms-700 bg-white'
            >
                <ul style={{ listStyle: 'none' }}>
                    <li onClick={()=>{onVideo();handleToggle()}}><label htmlFor="drop-iconc" >Video</label></li>
                    <li onClick={()=>{onChat();handleToggle()}} ><label htmlFor="drop-icon" >Chat</label></li>
                </ul>
            </div>
        </section>
    )
}
