import React, { useState, useEffect, useRef } from 'react'
import admin from '../../controller/admin';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import NotificationCard from './NotificationCard';
import ProfileIcon from '../../components/ProfileIcon';
import { useSelector } from 'react-redux';
import store from '../../redux/AppState'
import Backdrop from '../../component/Backdrop';
import NotificationDetail from './NotificationDetails';
import { FaBell } from 'react-icons/fa'
import helper from '../../helper';
import ReactPaginate from 'react-paginate';
export default function AllNotification() {
    const storeData = useSelector(state => state)
    const history = useHistory()
    const [Callpopup, setCallpopup] = useState({ open: false, id: 0 });
    const [notify, setnotify] = useState({ list: [], total: 0 })
    const [pageNo, setPageNo] = useState(1)
    const query = useRef()
    const [pageload, setPageload] = useState(true)

    useEffect(() => {
        apiCall(1)
        return () => { }
    }, [])

    const apiCall = async (pageNo) => {
        setPageload(true)
        await admin.getNotification(pageNo, 10, '')
            .then((res) => {
                if (res.data.status) {
                    setnotify({ ...res.data.result })
                }
                setPageload(false)
            })
    }

    const search = async (pageNo) => {
        setPageload(true)
        await admin.getNotification(pageNo, 10, query.current.value)
            .then((res) => {
                if (res.data.status) {
                    setnotify(res.data.result)
                }
                setPageload(false)
            })
    }

    const markRead = async (notifyId, status, index) => {
        if (status) {
            return
        }
        await admin.markAsRead(helper.getid(), notifyId)
            .then((res) => {
                if (res.data.status) {
                    let list = [...notify.list]
                    list[index] = res.data.result
                    setnotify({ list, total: notify.total })
                }
            })
    }

    const checkNew = (date, status) => {
        const today = new Date()
        if (moment(today).format('YYYY MM DD') == moment(date).format('YYYY MM DD') && !status) {
            return "New"
        }
        else if (status) {
            return "Read"
        }
        else {
            return "Unread"
        }
    }
    return (
        <>
            <div className="tutor-library mx-1 mb-2">
                <header className='row gx-0 mx-0 mb-3 py-1'>
                    <div className="col-lg-4 col-md-5 col-6 d-flex flex-column justify-content-center px-1">
                        <p className="mb-1 fs-24 f-fred">
                            <span>
                                <i className="fas fa-bars mr-2 fs-20 hover-shadow pointer d-lg-inline-block d-md-inline-block d-none" onClick={() => store.setShowLeftBar(!storeData.showLeftBar)}></i>
                            </span>
                            <i class="fas fa-bars mr-1 fs-20 pointer d-lg-none  d-md-none d-inline-block"></i>Notifications</p>
                        <p className="ml-2 lh-15 sub-heading">{moment(new Date).format('ddd, DD MMM YYYY hh:mm A')}</p>
                    </div>
                    <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                        <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer" onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >
                            <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />

                        </p>
                        <ProfileIcon />
                    </div>
                </header>

                <form onSubmit={(e) => { e.preventDefault(); search(1); setPageNo(1) }}>
                    <section className="row gx-0  mx-0">
                        <div className="col-lg-3 col-md-4 col-12 px-2 mb-2">
                            <input maxLength={100} ref={query} type="search" placeholder="Type here title" className="w-100 h-100 ms-700 input-box px-2 py-2 fs-14" />
                        </div>
                        <div className="col-lg-2 col-md-4 col-6 px-1 mb-2">
                            <button type='submit' className="btn btn-login w-100 h-100 wrap">search</button>
                        </div>
                    </section>
                </form>
                <section className='w-100 mx-1'>
                    {notify.list.map((data, index) => {
                        return (
                            <div key={`notification${index}`} className="">
                                <NotificationCard
                                    btn={checkNew(data.created_at, data.read_as) === "New" ? "New" : checkNew(data.created_at, data.read_as)}
                                    data={data}
                                    border={checkNew(data.created_at, data.read_as) === "New" && "orange"}
                                    name={checkNew(data.created_at, data.read_as) === "New" ? "New" : checkNew(data.created_at, data.read_as)}

                                    onClick={(id) => { markRead(data.id, data.read_as, index); setCallpopup({ ...Callpopup, open: true, id: data.id }) }}
                                />
                            </div>
                        )
                    })}
                    <div className="col-12 px-2 c-pagination">
                        <ReactPaginate
                            previousLabel={<i class="fas fa-arrow-left"></i>}
                            nextLabel={<i class="fas fa-arrow-right"></i>}
                            pageCount={Math.ceil(notify.total / 10)}
                            onPageChange={({ selected }) => {
                                apiCall(selected + 1, query.current.value,);
                                setPageNo(selected + 1)
                            }}
                            containerClassName={"paginationBttns"}
                            previousClassName={"previousBttn"}
                            nextClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                            pageClassName={"eachpage"}
                        />
                    </div>
                </section>
            </div>

            {Callpopup.open &&
                <NotificationDetail
                    id={Callpopup.id}
                    CallClose={() => setCallpopup({ ...Callpopup, open: false })}
                />}
            {pageload && <Backdrop />}

        </>
    )
}
