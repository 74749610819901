import * as React from "react";

const SvgLibrary = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <defs>
      <clipPath id="Library_svg__a">
        <path
          data-name="Rectangle 434"
          transform="translate(9272 4160)"
          fill="#fff"
          stroke="#707070"
          d="M0 0h30v30H0z"
        />
      </clipPath>
    </defs>
    <g
      data-name="Mask Group 39"
      transform="translate(-9272 -4160)"
      clipPath="url(#Library_svg__a)"
    >
      <g data-name="Iconly/Bold/Paper">
        <path
          data-name="Path 4390"
          d="M9288.7 4163a.548.548 0 0 1 .547.552v3.864a4.019 4.019 0 0 0 3.968 4.008c.9 0 1.616.012 2.162.012h.2c.365 0 .857-.011 1.283-.011a.535.535 0 0 1 .535.54v9.648a5.361 5.361 0 0 1-5.33 5.387h-9.457a5.636 5.636 0 0 1-5.608-5.652v-12.936a5.394 5.394 0 0 1 5.358-5.412h6.342Zm1.271 15.48h-6.46a.892.892 0 0 0-.891.888.9.9 0 0 0 .891.9h6.463a.9.9 0 0 0 .891-.9.892.892 0 0 0-.891-.888Zm-2.448-6h-4.012a.9.9 0 0 0-.891.9.892.892 0 0 0 .891.888h4.016a.892.892 0 0 0 .891-.888.9.9 0 0 0-.891-.9Zm3.454-8.393a.567.567 0 0 1 .977-.4l4.783 5.024a.572.572 0 0 1-.408.968c-.977 0-2.128 0-2.956-.008a2.421 2.421 0 0 1-2.4-2.42Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgLibrary;
