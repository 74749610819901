import React, { useState, useEffect, useRef } from 'react'
import flag from '../../static/images/flag.png'
import { FiXCircle } from "react-icons/fi";
import ReactStars from "react-rating-stars-component";
import avatar from '../../static/images/avatar.png'
import student from '../../controller/student';
import { useHistory } from 'react-router-dom';
import helper from '../../helper';
export default function EngagedTutor({ CallClose, name, userId }) {
    const [state, setState] = useState({
        load: true,
        data: []
    })
    const history = useHistory()
    const qry = useRef()

    useEffect(() => { getEngagedTutor() }, [])

    const getEngagedTutor = async () => {
        await student.getEngagedTutor({ id: userId, name: qry.current.value }).then((res) => {
            if (res.data.status) {
                setState({ ...state, data: res.data.result })
               
            }
        })
    }
    return (
        <>
            <div className="all-center popup-modal">
                <div className="right-popup notify-detail p-fix tp-0 rt-0  px-3 py-3 sm" >
                    <form onSubmit={(e) => { e.preventDefault(); getEngagedTutor() }}>
                        <div className="row gx-0 mx-0">
                            <div className="col-10">
                                <p className="f-fred" style={{ fontSize: "24px" }}>Engaged <span style={{ color: "#089D12" }}> tutors</span></p>
                                <p className="sub-heading" style={{ color: "#434943", fontSize: "14px" }}>{name}</p>
                            </div>
                            <div className="col-2">
                                <FiXCircle className="pointer float-right " size="2rem" onClick={() => CallClose()} style={{ color: "lightgray" }} />
                            </div>
                        </div>
                        <div className="row gx-0 mx-0  f-fred mb-2 px-2">
                            <div className="col-8 px-0 edit-profile ">
                                <div className='edit-input'>
                                    <input ref={qry} type="text" className='ms-700' placeholder="Search by tutor name" style={{ border: "1px solid lightgray", fontSize: "14px" }} />

                                </div>   </div>
                            <div className="col-4 py-1 mt-2 px-2">
                                <button type='submit' className='btn w-100 bg-sky fs-14 my-0 py-0 f-fred ls h-100 text-white'>SUBMIT</button>
                            </div>
                        </div>
                    </form>
                    <section className='px-2 mt-3'>
                        {state.data.map((result) => {
                            return (

                                <div className={`d-flex b-grey mb-2 pointer`} onClick={() => history.push(`/${helper.getRole()}/dashboard/tutor/${result.tutorId}`)}>
                                    <div className="all-center my-2">
                                        <div className='p-rel'>
                                            <img style={{ height: '40px', width: '40px' }} className="rounded-circle mx-1" src={result.profilePicture ? helper.replaceS3Url(result.profilePicture) : avatar} alt="tutor" />
                                            {/* <div className={`${data.isOnline ? "online-tag" : "offline-tag"} p-abs bt-0 rt-0`}></div> */}
                                        </div>
                                    </div>
                                    <div className='w-100 d-flex justify-content-between'>
                                        <div className="my-2 pl-2">
                                            <p className="lh-18 text-capitalize fs-14 f-fred">{result.name}</p>
                                            <p className="lh-18 fs-12 ms-500">Tutor from <span className='ms-900 text-capitalize'>{result.from}</span></p>
                                            <div className="d-flex align-items-center">
                                                <div className="d-inline-block py-0 my-0 mr-1">
                                                    <ReactStars
                                                        edit={false}
                                                        value={result.ratings}
                                                        count={5}
                                                        size={15}
                                                        classNames="my-0 py-0"
                                                        activeColor="#ffcb00"
                                                    />
                                                </div>
                                                <span className="tx-black ms-700 fs-14">{result.ratings}</span>
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-end justify-content-end p-rel">
                                                                                 <span className="p-2">
                                                <img width="20px" height="20px" className="rounded-circle" src={helper.replaceS3Url(result.flag_uri)}
                                                    alt="" />

                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </section>
                </div>
            </div>

        </>
    )
}
