import React, { useState } from 'react'
import parot from '../../static/images/parrot-1.png'
import StudentSlideShow from './StudentSlideShow'
function UserSyllabusCard({ name, slides, lessonNo, isEnrolled, data, previousCompletedSlide, previousTotalSlide }) {
    const [slideData, setSlideData] = useState({ open: false, data: data })
    return (
        <>
            <div className="syllabus b-grey px-2 py-3">
                <div className="row gx-0 mx-0 fs-12 ">
                    <div className="col-lg-6 col-8  d-flex">
                        <div className="mb-2 mr-3">
                            <p className="f-fred lh-15 fs-14">{lessonNo}</p>
                            <p className="tx-lt-grey ms-500 fs-12">Lesson</p>
                        </div>
                        <div className=" mb-2">
                            <p className="f-fred lh-15 fs-14 text-capitalize">{name}</p>
                            <p className="tx-lt-grey ms-500 fs-12">Lesson title</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-4 mb-2 d-flex justify-content-end align-items-center px-2 ">
                        {
                            isEnrolled ? <>
                                {previousCompletedSlide != previousTotalSlide && lessonNo != 1 ?
                                    <>
                                        <div className='d-flex align-items-center mr-2'>
                                            <img src={parot} width="25px" className='mr-0' alt="" />
                                            <p className='ms-500 alert tx-lt-grey fs-14 px-1'>Complete previous lesson</p>
                                        </div>
                                        <div className="mr-2">
                                            <p className="f-fred lh-15 fs-14 ">{data.total_completed_slide + "/" + slides}</p>
                                            <p className="tx-lt-grey ms-500 fs-12 lh-15 d-flex justify-content-center">Slides</p>
                                        </div>

                                        {data.noOfSlides === data.total_completed_slide ?
                                            <div className='check-out all-center'>
                                                <i class="fas fa-check check-tik"></i>
                                            </div> :
                                            <div className='check-incomplete-out all-center'>

                                            </div>
                                        }
                                    </>
                                    :
                                    <>
                                        <button style={{ backgroundColor: '#089D12' }} className='btn ms-900 fs-14 text-white py-1 mx-2  px-2 br-1' onClick={() => setSlideData({ ...slideData, open: true, data: data })}>View Slide</button>
                                        <div className="mr-2">
                                            <p className="f-fred lh-15 fs-14 ">{data.total_completed_slide + "/" + slides}</p>
                                            <p className="tx-lt-grey ms-500 fs-12 lh-15 d-flex justify-content-center">Slides</p>
                                        </div>
                                        {data.noOfSlides === data.total_completed_slide ?
                                            <div className='check-out all-center'>
                                                <i class="fas fa-check check-tik"></i>
                                            </div> :
                                            <div className='check-incomplete-out all-center'>

                                            </div>
                                        }
                                    </>
                                }
                            </>
                                :
                                <>
                                    <button style={{ backgroundColor: '#089D12' }} className='btn ms-900 fs-14 text-white py-1 mx-2  px-2 br-1' onClick={() => setSlideData({ ...slideData, open: true, data: data })}>Preview</button>
                                    <div className="mr-2">
                                        <p className="f-fred lh-15 fs-14 ">{slides}</p>
                                        <p className="tx-lt-grey ms-500 fs-12 lh-15 d-flex justify-content-center">Slides</p>
                                    </div>
                                </>

                        }




                    </div>
                </div>
            </div>

            {slideData.open &&
                <StudentSlideShow
                    name={name}
                    lesson={lessonNo}
                    startIndex={0}
                    data={data.slideDetails}
                    cb={() => setSlideData({ ...slideData, open: false, data: data })}
                />
            }


        </>
    )
}

export default UserSyllabusCard
