import React from 'react';
import moment from 'moment';
export default function NotificationCard({ btn, border, name, onClick, data }) {

    return (<>
        <div className="quote-card cur-pointer bg-smoke border-radius-1 py-2 my-2 ">
            <div className="row fs-14 tx-lt-grey gx-0 mx-0">
                <div className="col-lg-1 all-center px-1 my-2">
                    <button className={`btn ${btn == "New" ? "btn-new-notify" : btn == "Read" ? "btn-read" : btn == "Unread" ? "btn-unread" : ""} w-100 fs-16 br-2 py-1 ms-700 tx-lt-black`}>{name}</button>
                </div>
                <div className="col-lg-2 px-2 my-1">
                    <p className="f-fred ">{data.type.length > 25 ? (data.type.toString()).substring(0, 25) + "..." : data.type}</p>
                    <div className='overflow-elipse'>
                        <p className="ms-500 fs-12">Notification</p>
                    </div>
                </div>
                <div className="col-lg-3 px-2 my-1">
                    <p className="f-fred ">{data.description.length > 25 ? (data.description.toString()).substring(0, 25) + "..." : data.description}</p>
                    <div>
                        <p className="ms-500 fs-12">Subject</p>
                    </div>
                </div>
                <div className="col-lg-4 px-2 my-1">
                    <p className="f-fred text-right">{moment(data.created_at).format('DD MMM YYYY')}</p>
                    <p className="ms-500 fs-12 text-right">Date</p>

                </div>
                <div className="col-lg-1 px-2 my-1">
                    <p className="f-fred text-right">{moment(data.created_at).format('hh:mm a')}</p>
                    <p className="ms-500 fs-12 text-right ">Time</p>

                </div>

                <div className="col-lg-1 px-2 my-1 all-center">
                    <button className="btn btn-detail w-100 py-1 px-2 f-fred fs-12" onClick={() => { onClick() }}>Details</button>

                </div>

            </div>
        </div>
    </>);
}
