import React, { useState, useEffect } from 'react'
import TextEditor from '../../../components/TextEditor'
import store from '../../../redux/AppState'
import admin from '../../../controller/admin';
import ProfileIcon from '../../../components/ProfileIcon';
import { useHistory } from 'react-router-dom';
import helper from '../../../helper/index';
import { Toaster, toast } from 'react-hot-toast';
import Backdrop from '../../../component/Backdrop';
import { FaBell } from 'react-icons/fa';
export default function PrivacyPolicy() {
    const history = useHistory()
    const [editData, seteditData] = useState()
    const [state, setState] = useState({
        load: false,
        pageload: true,
        data: null
    })

    useEffect(() => {
        apiCall();
        return () => { };
    }, []);


    const apiCall = async () => {
        await admin.getPolicyPages().then((res) => {
            seteditData(res.data.result ? res.data.result.privacy_policy :'')
            setState({ ...state, load: false, pageload: false, data: res.data.result ? res.data.result.privacy_policy :''})
        }).catch((err) => {
            setState({ ...state, load: false, pageload: false, data: null })
        })
    }

    const updateData = async () => {
        const reqData = {
            "admin_id": helper.getid(),
            "privacy_policy": editData
        }

        setState({ ...state, load: true, pageload: false })
        await admin.editPrivacyPolicy(reqData)
            .then((res) => {
                if (res.data.status) {
                    toast(res.data.result)
                } else {
                    toast(res.data.error)
                }
                setState({ ...state, load: false, pageload: false })
            })

    }
    return (
        <>
            <div className="mx-1">
                 
                <header className='row gx-0 mx-0 py-1 mb-3'>
                    <div className="col-lg-4 col-md-5 col-6 d-flex  px-1 align-items-center">
                        <button className="btn btn-back  mr-2" onClick={() => history.goBack()}>BACK</button>
                        <div>
                            <p className="mb-1 fs-24 f-fred">
                                <i class="fas fa-bars mr-1 fs-20 pointer d-lg-none  d-md-none d-inline-block px-1" onClick={() => store.setMenu(true)}></i> Edit  <span className="tx-green">content</span></p>
                            <p className="lh-15 sub-heading">Privacy policy</p>

                        </div>

                    </div>
                    <div className="col-lg-8 col-md-7 col-6 px-1 d-flex justify-content-end align-items-center">
                        <button className="btn btn-update mx-2 d-lg-block d-md-block d-none" onClick={updateData}>UPDATE CONTENT</button>
                        <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                            <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                        </p>
                        <ProfileIcon />
                    </div>
                </header>

                <section className='mx-1'>
                    {
                        !state.pageload && <TextEditor
                            onChange={(data) => seteditData(data)}
                            data={state.data ? state.data :'<p>Type here</p>'} />
                    }
                </section>


                {state.pageload || state.load && <Backdrop />}
            </div>
        </>
    )
}
