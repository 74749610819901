import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import AllTutor from '../module/tutor/AllTutors'
import AllStudent from '../module/student/AllStudent'
import AllCourses from '../module/courses/AllCourses'
import CostManager from '../module/costmanager/CostManager'
import CouponManager from '../module/couponmanager/CouponManager'
import Support from '../module/support/Support'
import UserManager from '../module/usermanager/UserManager'
import Setting from '../module/setting/Setting'
import AllNotification from '../module/notification/AllNotification'
import UploadCourse from '../module/courses/UploadCourse'
import UploadSyllabus from '../module/courses/UploadSyllabus'
import TutorProfile from '../module/tutor/TutorProfile'
import StudentProfile from '../module/student/StudentProfile'
import Analytic from '../module/dashboard/Analytic'
import CourseCategory from '../module/courses/CourseCategory'
import TutorAttendance from '../module/tutor/TutorAttendance'
import PayoutDetails from '../module/payout/PayoutDetails'
import CourseDetails from '../module/courses/CourseDetails'
import TutorSupport from '../module/support/TutorSupport'
import UserTicketDetail from '../module/support/UserTicketDetail'
import TutorSupportVideo from '../module/setting/tutor/TutorSupportVideo'
import SupportVideoCategory from '../module/setting/tutor/SupportVideoCategory'
import EditSupportVideo from '../module/setting/tutor/EditSupportVideo'
import TutorFaqs from '../module/setting/faq/TutorFaqs'
import PolicyPages from '../module/setting/policypages/PolicyPages'
import EarningManagement from '../module/earningmanager/EarningManagement'
import EarningDetail from '../module/earningmanager/EarningDetails'
import AllPayout from '../module/payout/AllPayout'
import PayTutor from '../module/payout/PayTutor'
import AllReferral from '../module/referral/AllReferral'
import ReferralDetail from '../module/referral/ReferralDetail'
import CouponDetails from '../module/couponmanager/CouponDetails'
import PrivacyPolicy from '../module/setting/policypages/PrivacyPolicy'
import TermsCondition from '../module/setting/policypages/TermsCondition'
import RefundPolicy from '../module/setting/policypages/RefundPolicy'
import Disclaimer from '../module/setting/policypages/Disclaimer'
import UserSupportVideo from '../module/setting/tutor/UserSupportVideo'
import StudentFaqs from '../module/setting/faq/StudentFaqs'
import TutorArticles from '../module/setting/articles/TutorArticles'
import AddTutorArticle from '../module/setting/articles/AddTutorArticle'
import AddStudentArticle from '../module/setting/articles/AddStudentArticle'
import StudentArticles from '../module/setting/articles/StudentArticles'
import EditArticle from '../module/setting/articles/EditArticle'
import AddTutorSupportVideo from '../module/setting/tutor/AddTutorSupportVideo'
import AddUserSupportVideo from '../module/setting/tutor/AddUserSupportVideo'
import WebsiteContent from '../module/setting/website/Website'
import Aboutus from '../module/setting/website/Aboutus'
import helper from '../helper'
import EnrolledCourses from '../module/student/EnrolledCourses'
import EnrolledCourseDetail from '../module/student/EnrolledCourseDetails'
import { permissionAccess } from '../config/permissions'
import SupportCategory from '../module/setting/website/SupportCategory'
import Languages from '../module/setting/website/Languages'
import Dialects from '../module/setting/website/Dialects'
import EditCourse from '../module/courses/EditCourse'
import ChatLogs from '../module/tutor/ChatLogs'
import IncompleteRegistration from '../module/tutor/IncompleteRegistration'
import IncompleteStudentRegistration from '../module/student/IncompleteStudentRegistration'
export default function CheckValidRoute() {
    const history = useHistory()

    const { page, userId, user, courseId, studentId } = useParams()
    return (
        <section className='px-2 py-2'>
            {page === "home" ? (
                <Analytic />
            ) : // Tutors
                page === "tutor" && userId && helper.checkModuleAccess({ moduleName: permissionAccess.tutorManagement }) ? (
                    <TutorProfile />
                ) : page === "tutor" && helper.checkModuleAccess({ moduleName: permissionAccess.tutorManagement }) ? (
                    <AllTutor />
                    ) : page === "incomplete-tutor-registration" && helper.checkModuleAccess({ moduleName: permissionAccess.tutorManagement }) ? (
                        <IncompleteRegistration />
                 
                ) : page === "class-record" && userId && helper.checkModuleAccess({ moduleName: permissionAccess.tutorManagement }) ? (
                    <TutorAttendance />
                ) : page === "tutor-payout" && userId && helper.checkModuleAccess({ moduleName: permissionAccess.tutorManagement }) ? (
                    <PayoutDetails />
                ) : page === "chat-logs" && userId && helper.checkModuleAccess({ moduleName: permissionAccess.tutorManagement }) ? (
                    <ChatLogs /> ): 
         

                    //Student
                    page === "student" && userId && helper.checkModuleAccess({ moduleName: permissionAccess.studentManagement }) ? (
                        <StudentProfile />
                    ) : page === "student" && helper.checkModuleAccess({ moduleName: permissionAccess.studentManagement }) ? (
                        <AllStudent />
                    ) :
                     page === "incomplete-student-registration" && helper.checkModuleAccess({ moduleName: permissionAccess.studentManagement }) ? (
                        <IncompleteStudentRegistration />
                    ) :
                        page === "enrolled-courses" && userId && helper.checkModuleAccess({ moduleName: permissionAccess.studentManagement }) ? <EnrolledCourses /> :
                            courseId && studentId && helper.checkModuleAccess({ moduleName: permissionAccess.studentManagement }) ? <EnrolledCourseDetail /> :

                                //Course
                                page === "course-manager" && helper.checkModuleAccess({ moduleName: permissionAccess.courseManagement }) ? (
                                    <AllCourses />
                                ) : page === "course-detail" && userId && helper.checkModuleAccess({ moduleName: permissionAccess.courseManagement }) ? (
                                    <CourseDetails />
                                ) : page === "add-course" && helper.checkModuleAccess({ moduleName: permissionAccess.courseManagement }) ? (
                                    <UploadCourse />
                                ) : page === "edit-course" && userId && helper.checkModuleAccess({ moduleName: permissionAccess.courseManagement }) ? (
                                    <EditCourse />
                                ) : page === "add-syllabus" && helper.checkModuleAccess({ moduleName: permissionAccess.courseManagement }) ? (
                                    <UploadSyllabus />
                                ) : page === "course-category" && helper.checkModuleAccess({ moduleName: permissionAccess.courseManagement }) ? (
                                    <CourseCategory />
                                    //payout 
                                ) : page === "payout-manager" && userId && helper.checkModuleAccess({ moduleName: permissionAccess.payoutManagement }) ? (
                                    <PayoutDetails />
                                ) : page === "payout-manager" && helper.checkModuleAccess({ moduleName: permissionAccess.payoutManagement }) ? (
                                    <AllPayout />
                                ) : page === "pay-tutor" && helper.checkModuleAccess({ moduleName: permissionAccess.payoutManagement }) ? (
                                    <PayTutor />
                                    // earning 
                                ) : page === "earning-manager" && userId && helper.checkModuleAccess({ moduleName: permissionAccess.earningManagement }) ? (
                                    <EarningDetail />
                                ) : page === "earning-manager" && helper.checkModuleAccess({ moduleName: permissionAccess.earningManagement }) ? (
                                    <EarningManagement />
                                    //cost manager 
                                ) : page === "cost-manager" && helper.checkModuleAccess({ moduleName: permissionAccess.costManagement }) ? (
                                    <CostManager />
                                ) : page === "referral" && userId && helper.checkModuleAccess({ moduleName: permissionAccess.referralManagement }) ? (
                                    <ReferralDetail />
                                ) : page === "referral" && helper.checkModuleAccess({ moduleName: permissionAccess.referralManagement }) ? (
                                    <AllReferral />
                                ) : page === "coupon" && userId && helper.checkModuleAccess({ moduleName: permissionAccess.couponManagement }) ? (
                                    <CouponDetails />
                                ) : page === "coupon-manager" && helper.checkModuleAccess({ moduleName: permissionAccess.couponManagement }) ? (
                                    <CouponManager />
                                ) : //support
                                    page === "user-support" && userId && helper.checkModuleAccess({ moduleName: permissionAccess.supportManagement }) ? (
                                        <UserTicketDetail />
                                    ) : page === "user-support" && helper.checkModuleAccess({ moduleName: permissionAccess.supportManagement }) ? (
                                        <Support />
                                        // ) : page === "tutor-support" && userId && helper.checkModuleAccess({ moduleName: permissionAccess.supportManagement }) ? (
                                        //     <TutorTicketDetail />
                                    ) : page === "tutor-support" && helper.checkModuleAccess({ moduleName: permissionAccess.supportManagement }) ? (
                                        <TutorSupport />
                                    ) : page === "user-manager" && helper.getRole() === "admin" ? (
                                        <UserManager />
                                    ) : // General setting
                                        user === "tutor" && page === "article" && helper.checkModuleAccess({ moduleName: permissionAccess.articleManagement }) ? (
                                            <TutorArticles />
                                        ) : user === "student" && page === "article" && helper.checkModuleAccess({ moduleName: permissionAccess.articleManagement }) ? (
                                            <StudentArticles />
                                        ) : page === "update-support-article" && userId && helper.checkModuleAccess({ moduleName: permissionAccess.articleManagement }) ? (
                                            <EditArticle />
                                        ) : user === "tutor" && page === "video" && helper.checkModuleAccess({ moduleName: permissionAccess.supportVideoManagement }) ? (
                                            <TutorSupportVideo />
                                        ) : user === "student" && page === "video" && helper.checkModuleAccess({ moduleName: permissionAccess.supportVideoManagement }) ? (
                                            <UserSupportVideo />
                                        ) : page === "add-tutor-support-video" && helper.checkModuleAccess({ moduleName: permissionAccess.supportVideoManagement }) ? (
                                            <AddTutorSupportVideo />
                                        ) : page === "add-user-support-video" && helper.checkModuleAccess({ moduleName: permissionAccess.supportVideoManagement }) ? (
                                            <AddUserSupportVideo />
                                        ) : page === "add-tutor-support-article" && helper.checkModuleAccess({ moduleName: permissionAccess.articleManagement }) ? (
                                            <AddTutorArticle />
                                        ) : page === "add-user-support-article" && helper.checkModuleAccess({ moduleName: permissionAccess.articleManagement }) ? (
                                            <AddStudentArticle />
                                        ) : page === "update-support-video" && userId && helper.checkModuleAccess({ moduleName: permissionAccess.supportVideoManagement }) ? (
                                            <EditSupportVideo />
                                        ) : page === "support-category" ? (
                                            <SupportVideoCategory />
                                        ) : user === "tutor" && page === "faqs" && helper.checkModuleAccess({ moduleName: permissionAccess.faqManagement }) ? (
                                            <TutorFaqs />
                                        ) : user === "student" && page === "faqs" && helper.checkModuleAccess({ moduleName: permissionAccess.faqManagement }) ? (
                                            <StudentFaqs />
                                        ) : page === "policy-pages" && userId == "privacy-policy" && helper.getRole() == "admin" ? (
                                            <PrivacyPolicy />
                                        ) : page === "policy-pages" && userId == "terms-condition" && helper.getRole() == "admin" ? (
                                            <TermsCondition />
                                        ) : page === "policy-pages" && userId == "refund-policy" && helper.getRole() == "admin" ? (
                                            <RefundPolicy />
                                        ) : page === "policy-pages" && userId == "disclaimer" && helper.getRole() == "admin" ? (
                                            <Disclaimer />
                                        ) : page === "policy-pages" ? (
                                            <PolicyPages />
                                        ) : page === "web-content" && userId == "aboutus" ? (
                                            <Aboutus />)
                                            :
                                            page === "web-content" && userId == "support-category" ? (
                                                <SupportCategory />)
                                                :
                                                page === "web-content" && userId == "tutor-languages" ? (
                                                    <Languages />)
                                                    : page === "web-content" && userId == "tutor-dialects" ? (
                                                        <Dialects />

                                                    ) : page === "web-content" ? (
                                                        <WebsiteContent />
                                                    ) : page === "setting" ? (
                                                        <Setting />
                                                    ) : page === "notification" ? (
                                                        <AllNotification />
                                                    ) : (
                                                        <div style={{ height: '100vh' }} className="all-center flex-column">
                                                            <p className='text-center ms-700 my-3'>Oops! You have not access. Please contact your admin</p>
                                                            <button className='btn bg-sky f-fred fs-14 text-white ls' onClick={() => history.goBack()}>RETURN BACK</button>
                                                        </div>
                                                    )}
        </section>
    )
}
