import axios from "axios";
import helper from "../helper/index";
import { constant } from "../config/Constant";
const ADMIN_URL = constant.ADMIN_URL
const BASE_URL = constant.BASE_URL
const updateAdminProfile = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios.put(`${BASE_URL}/admin/edit-admin-profile`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}
const updateProfileImage = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios.put(`${BASE_URL}/admin/edit-profile-picture`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}

const changePassword = async (data) => {
    try {
        const token = helper.getToken()
        const res = await axios.put(`${BASE_URL}/admin/change-password`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return res
    } catch (err) {
        return err
    }
}

export default {updateAdminProfile,updateProfileImage,changePassword}
