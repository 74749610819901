import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyB_opZ0V3VzcjLV-Su0yk6CAtg8WgpmOAM",
  authDomain: "saydhad-99aa5.firebaseapp.com",
  databaseURL: "https://saydhad-99aa5-default-rtdb.firebaseio.com",
  projectId: "saydhad-99aa5",
  storageBucket: "saydhad-99aa5.appspot.com",
  messagingSenderId: "5049449942",
  appId: "1:5049449942:web:6b236d1ac157b015573877",
  measurementId: "G-00SN5SE9LT"
};

firebase.initializeApp(firebaseConfig);

const database = getDatabase();

export default { database };
