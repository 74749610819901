/* eslint-disable */
import React, { useState } from 'react'
import { FiXCircle } from "react-icons/fi";
import avatar from '../../static/images/avatar.png'
import EditManagerDetails from './EditDetails';
import helper from '../../helper';
export default function UserDetail({ CallClose, data, onEdit }) {
    const [Callpopup, setCallpopup] = useState({ open: false, id: 0, data: "" });
    const toggleCallpopup = (idValue, data) => {
        setCallpopup({ ...Callpopup, open: !Callpopup.open, id: idValue, data: data });
    }

    return (
        <>
            <div className=" all-center popup-modal">
                <div className="right-popup notify-detail p-fix tp-0 rt-0  px-3 py-3 sm" >
                    <div className="row gx-0 mx-0">
                        <div className="col-10">
                            <p className="f-fred" style={{ fontSize: "24px" }}>Manager<span style={{ color: "#089D12" }}> details</span></p>
                            <p className="sub-heading" style={{ color: "#434943" }}>SayDhad manager</p>
                        </div>
                        <div className="col-2">
                            {/* <i class="far fa-times-circle float-right tx-lt-black pt-3"style={{fontSize:"24px"}}></i> */}
                            <FiXCircle className="pointer float-right " size="2rem" onClick={() => { CallClose() }} style={{ color: "lightgray" }} />
                        </div>
                    </div>


                    <section className="row gx-0 mx-0 px-2">
                        <div className="col-lg-12 profile-bg">
                            <div className="row gx-0 mx-0">
                                <div className="col-lg-2 px-0 d-flex ">
                                    <span className="pointer" ><img width="50px" height="50px" className="rounded-circle" src={data.profilePicture ? helper.replaceS3Url(data.profilePicture) : avatar} /></span>
                                </div>
                            </div>
                            <div className="row ms-500 mt-2 mx-0 gx-0">
                                <div className="col-6 px-0 ms-500">
                                    <p style={{ color: "#434943" }}>Full name</p>
                                </div>
                                <div className="col-6 wrap text-right px-0 ms-900">
                                    <p style={{ color: "#434943" }}>{data.name}</p>
                                </div>
                            </div>
                            <div className="col-12  border-dot my-1"></div>
                            <div className="row ms-500 my-1 mx-0 gx-0">
                                <div className="col-6 px-0 px-0 ms-500">
                                    <p className='ms-500' style={{ color: "#434943" }}>Email address</p>
                                </div>
                                <div className="col-6 overflow-elipse  px-0 ms-900">
                                    <p className='wrap' style={{ color: "#434943" }}>{data.email}</p>
                                </div>
                            </div>
                            <div className="col-12  border-dot my-1"></div>
                            <div className="row ms-500 my-1 mx-0 gx-0">
                                <div className="col-6 px-0 ms-500">
                                    <p style={{ fontWeight: "500", fontSize: "16px", color: "#434943" }}>Phone number</p>
                                </div>
                                <div className="col-6 text-right  px-0 ms-900">
                                    <p style={{ fontWeight: "900", fontSize: "16px", color: "#434943" }}>{data.mobileNo} </p>
                                </div>
                            </div>
                            <div className="col-12  border-dot my-1"></div>
                            <div className="row ms-500 my-1 mx-0 gx-0">
                                <div className="col-6 px-0 ms-500">
                                    <p style={{ color: "#434943" }}>Manager ID</p>
                                </div>
                                <div className="col-6 text-right  px-0 ms-900">
                                    <p style={{ color: "#434943" }}>{data.managerId}</p>
                                </div>
                            </div>
                            <div className="col-12  border-dot my-1"></div>
                            <div className="row ms-500 my-1 mx-0 gx-0">
                                <div className="col-6 px-0 ms-500">
                                    <p style={{ color: "#434943" }}>Role</p>
                                </div>
                                <div className="col-6 text-right  px-0 ms-900">
                                    <p style={{ color: "#434943" }}>Manager</p>
                                </div>
                            </div>

                        </div>
                    </section>
                    <div className="row">
                        <div className="col-lg-12 d-flex justify-content-center align-content-center pt-2 mt-2">
                            <button className="btn btn-edit" onClick={() => { toggleCallpopup(data.id, data) }}>Edit profile</button>
                            {Callpopup.open &&
                                <EditManagerDetails
                                    id={Callpopup.id}
                                    data={Callpopup.data}
                                    CallClose={() => { toggleCallpopup() }}
                                    onUpdate={(data)=>{onEdit(data);CallClose()}}

                                />}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
