import React, { useEffect, useRef } from 'react'
import fb from '../config/firebase';
import helper from '../helper/index';
import { ref, set, push, onChildAdded, increment } from "firebase/database";
import moment from 'moment';
import { FaTelegramPlane } from 'react-icons/fa';

const db = fb.database
let prevDate = "Today"
let condition = 0
let rootRoomid = 0
let studentId = 0
let unsubscribe = ''

const adduserDiv = (message, time) => {
    try {
        const userDiv = document.getElementById("chat-body")
        if (condition === 0 || (prevDate.toString() !== moment(time).format('MMMM DD, YYYY').toString())) {
            prevDate = moment(time).format('MMMM DD, YYYY')
            const chatDayBox = document.createElement('div')
            const chatDay = document.createElement('div')
            chatDayBox.classList.add("chat-day-box")
            chatDay.classList.add("chat-day")
            chatDayBox.append(chatDay)
            chatDay.innerHTML = moment(time).format('MMMM DD, YYYY')
            userDiv.append(chatDayBox)
            condition = 1;
        }

        const ob = document.createElement('div')
        const chatDivContainer = document.createElement('div')
        const chatbox = document.createElement('div')
        const timeDiv = document.createElement('div')
        const clearDiv = document.createElement('div')
        chatDivContainer.classList.add("user-chat-box-container")
        chatbox.classList.add("user-chat-box-body")
        timeDiv.classList.add("chat-time")
        chatDivContainer.append(chatbox)
        chatDivContainer.append(timeDiv)
        chatbox.innerHTML = message
        timeDiv.innerHTML = moment(time).format('hh : mm a')
        clearDiv.classList.add("clear")
        ob.classList.add("p-rel")
        ob.append(chatDivContainer)
        ob.append(clearDiv)
        userDiv.append(ob)
        userDiv.scrollTop = userDiv.scrollHeight;
    }
    catch (err) {

    }
}

const addRemoteDiv = (message) => {
    try {
        const remoteDiv = document.getElementById("chat-body")
        const ob = document.createElement('div')
        const chatDiv = document.createElement('div')
        const clearDiv = document.createElement('div')
        chatDiv.innerHTML = message
        chatDiv.classList.add("remote-chat-box")
        clearDiv.classList.add("clear")
        ob.classList.add("p-rel")
        ob.append(chatDiv)
        ob.append(clearDiv)
        remoteDiv.append(ob)
        remoteDiv.scrollTop = remoteDiv.scrollHeight;

    }
    catch (err) {

    }
}

const enable = () => {
    unsubscribe = onChildAdded(ref(db, `userChat/${rootRoomid}/messages`), (data, index) => {
        const val = data.val()
        if (val.createdBy == studentId) {
            adduserDiv(val.message, val.createdAt)
        }
        else {
            addRemoteDiv(val.message)
        }
    })
}

export default function ChatBox({ myId, roomId, userName, remoteUserId }) {
    let msg = useRef()
    useEffect(() => {
        try {
            const userDiv = document.getElementById("chat-body")
            userDiv.innerHTML = ""
            rootRoomid = roomId
            studentId = myId
        }
        catch (err) {

        }
        enable()
        return () => {
            try {
                unsubscribe()
            }
            catch { }
        }
    }, [roomId])

    return (
        <div className="chat-box mx-auto px-3" style={{ maxWidth: '600px', minWidth: '350px' }}>
            <div className="chat-header py-3 px-2">
                <p className="ms-900 fs-24 tx-lt-black ">Chatting with {userName ? userName : "Anonymous"}</p>
            </div>
            <div id="chat-body" className="chat-room-body-read chat-body">

            </div>
        </div>
    )
}
