import moment from 'moment';
import React from 'react';

export default function PayoutCard({ onClick, tid, calls, time, amount, rate, billFrom, billTo, date }) {
    return (<>
        <div className="quote-card cur-pointer tx-lt-grey bg-smoke border-radius-1 py-2 my-2 ">
            <div className="row gx-0 mx-0 fs-14">
                <div className="col-lg-2 ">
                    <p className="f-fred ">{tid}</p>
                    <p className="ms-500 fs-12">Transaction ID</p>

                </div>

                <div className="col-lg-3 d-flex">
                    <div className="px-2 mr-2 my-1">
                        <p className="f-fred ">{calls}</p>
                        <p className="ms-500 fs-12">Online calls</p>
                    </div>
                    <div className=" px-2 mr-2 my-1">
                        <p className="f-fred ">{time || 0}</p>
                        <p className="ms-500 fs-12">Total time</p>

                    </div>
                    <div className=" px-2 mr-2 my-1">
                        <p className="f-fred ">{amount}</p>
                        <p className="ms-500 fs-12">Amount (SAR)</p>

                    </div>
                </div>


                <div className="col-lg-1 px-2 my-1 ">
                    <p className="f-fred ">{rate}</p>
                    <p className="ms-500 fs-12">Rate (SAR)</p>

                </div>
                <div className="col-lg-2">
                    <p className="f-fred text-right">{billFrom} - {billTo}</p>
                    <p className="ms-500 fs-12 text-right">Billing Cycle</p>

                </div>
                <div className="col-lg-2">
                    <p className="f-fred text-right">{moment(date).format('MMM DD, YYYY')}</p>
                    <p className="ms-500 fs-12 text-right">Created on</p>

                </div>
                <div className="col-lg-1">
                    <p className="f-fred text-right">{moment(date).format('HH:mm A')}</p>
                    <p className="ms-500 fs-12 text-right ">Time</p>

                </div>
                <div className="col-lg-1 px-2 my-1 all-center">
                    <button className="btn btn-detail w-100 py-1 px-2 f-fred fs-14" onClick={onClick}>Details</button>
                </div>
            </div>
        </div>
    </>);
}
