import React, { useState } from 'react'
import { FiXCircle } from "react-icons/fi";
export default function Filter({ CallClose, categories, cb }) {

    const [category, setCategory] = useState([])
    const [level, setLevel] = useState([])

    const select = (data, type) => {
        if (type === "cat") {
            const list = [...category]
            if (!list.includes(data)) {
                list.push(data)
            } else {
                let index = list.indexOf(data);
                list.splice(index, 1)
            }
            setCategory(list)
        }
        else if (type === "level") {
            const list = [...level]
            if (!list.includes(data)) {
                list.push(data)
            } else {
                let index = list.indexOf(data);
                list.splice(index, 1)
            }

            setLevel(list)
        }
    }

   
    return (
        <>
            <div className="all-center popup-modal">
                <div className="right-popup notify-detail tx-lt-grey p-fix tp-0 rt-0 px-3 py-3 sm" >
                    <div className="row gx-0 mx-0">
                        <div className="col-10 px-0">
                            <p className="f-fred" style={{ fontSize: "24px" }}>Filter <span style={{ color: "#089D12" }}> Course</span></p>
                            <p className="ms-500 fs-14" style={{ color: "#434943" }}>SayDhad courses</p>
                        </div>
                        <div className="col-2 px-0 d-flex align-items-center justify-content-end ">
                            <FiXCircle className="pointer " size="2rem" onClick={() => CallClose()} style={{ color: "lightgray" }} />
                        </div>
                    </div>

                    <h6 className='f-fred fs-20 my-3'>Via categories</h6>

                    <section className='ms-500 fs-14'>
                        {categories.map((data, index) => {
                            return (
                                <div key={`cat-filter-${index}`} className='d-flex justify-content-between'>
                                    <label htmlFor={`cat-${index}`} className='pointer'>{data}</label>
                                    <input type="checkbox" defaultChecked={category.includes(data)} onChange={(e) => { select(data, "cat") }} id={`cat-${index}`} />
                                </div>
                            )
                        })}
                    </section>

                    <h6 className='f-fred fs-20 my-3'>Via course level</h6>

                    <section className='ms-500 fs-14 filter-cb'>

                        <div className='d-flex justify-content-between'>
                            <label htmlFor={`level-1`} className='pointer'>Basic</label>
                            <input type="checkbox" defaultChecked={level.includes("Basic")} onChange={(e) => { select("Basic", "level") }} id={`level-1`} />
                        </div>
                        <div className='d-flex justify-content-between'>
                            <label htmlFor={`level-2`} className='pointer'>Intermediate</label>
                            <input type="checkbox" defaultChecked={level.includes("Intermediate")} onChange={(e) => { select("Intermediate", "level") }} id={`level-2`} />
                        </div>
                        <div className='d-flex justify-content-between'>
                            <label htmlFor={`level-3`} className='pointer'>Advanced</label>
                            <input type="checkbox" defaultChecked={level.includes("Advance")} onChange={(e) => { select("Advance", "level") }} id={`level-3`} />
                        </div>

                    </section>
                    <div className="row gx-0 mx-0 my-3">

                        <div className="col-lg-6  mb-2">
                            <button className='btn btn-update ms-700 w-100 fs-14 btn-apply px-2' style={{ width: '200px' }} onClick={() => cb({
                                categories: category,
                                level: level
                            })}>GET RESULT</button>

                        </div>
                        <div className="col-lg-6 mb-2 ">
                            <div><button className="btn btn-clear ms-700 fs-14" onClick={()=>{
                                cb({
                                    categories: [],
                                    level: []
                                })
                            }}>CLEAR FILTERS</button></div>

                        </div>
                    </div>


                </div>
            </div>

        </>
    )
}
