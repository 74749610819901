import React from 'react';
import moment from 'moment';
export default function CategoryCard({ onEdit, id, name, date }) {
    return (<>
        <div className="b-grey  tx-lt-grey fs-14 cur-pointer bg-smoke border-radius-1 py-2 my-2 ">
            <div className="row gx-0 mx-0">
                <div className="col-lg-2">
                    <p className="f-fred ">{id}</p>
                    <p className="ms-500 fs-12">Category ID</p>

                </div>
                <div className="col-lg-3">
                    <p className="f-fred ">{name}</p>
                    <p className="ms-500 fs-12">Category</p>

                </div>
                <div className="col-3">
                    <p className="f-fred text-right">{moment.utc(date).format('MMM DD YYYY')}</p>
                    <p className="ms-500 fs-12 text-right">Added on</p>

                </div>
                <div className="col-2">
                    <p className="f-fred text-right">{moment(date).format('hh : mm A')}</p>
                    <p className="ms-500 fs-12 text-right ">Time</p>
                </div>
                <div className="col-2">
                    <button className="btn btn-detail w-100 py-1 px-2 f-fred fs-14" onClick={() => onEdit()}>Edit</button>

                </div>

            </div>
        </div>
    </>);
}
