import React, { useState } from 'react'
import EditPlan from './EditPlan'
import { useSelector } from 'react-redux';
import store from '../../redux/AppState'
import { useEffect } from 'react';
import admin from '../../controller/admin';
import Backdrop from '../../component/Backdrop';
import CommitmentCard from './CommitmentCard';
import AddNewPlan from './AddNewPlan';
import { useHistory } from 'react-router-dom';
import ProfileIcon from '../../components/ProfileIcon';
import { FaBell } from 'react-icons/fa';
import helper from '../../helper';
import moment from 'moment';
import ReactPaginate from 'react-paginate';

export default function CostManager() {
    const history = useHistory()
    const storeData = useSelector(state => state)

    /* Handling popup details */
    const [Sb, setSb] = useState({ open: false, id: 0, data: "", index: 0 });
    const toggleSb = (idValue, data, index) => {
        setSb({ ...Sb, open: !Sb.open, id: idValue, data: data, index });
    }
    const [addPlan, setaddPlan] = useState(false);
    const toggleaddPlan = () => {
        setaddPlan(!addPlan);
    }

    /* State Handler */
    const [pageLoad, setpageLoad] = useState(true)
    const [qry, setQry] = useState("")
    const [state, setState] = useState({ list: [], pageNo: 1, limit: 10, total: 0 })


    useEffect(() => {
        apiCall(state.pageNo, state.limit); return () => { }
    }, [])

    const apiCall = async (page, limit) => {
        setpageLoad(true)
        await admin.getComPlan(page, limit, qry)
            .then((res) => {
                if (res.data.status) {
                    setState({ ...state, list: res.data.result.commitmentList, total: res.data.result.total_count })
                }
                setpageLoad(false)
            })
    }

    return (
        <>
            <div className='mx-1'>
                <header className='row gx-0 mx-0 mb-3 py-1'>
                    <div className="col-lg-4 col-md-5 col-6 d-flex flex-column justify-content-center px-1">
                        <p className="mb-1 fs-24 f-fred">
                            <span>
                                <i className="fas fa-bars mr-2 fs-20 hover-shadow pointer d-lg-inline-block d-md-inline-block d-none" onClick={() => store.setShowLeftBar(!storeData.showLeftBar)}></i>
                            </span>
                            <i class="fas fa-bars mr-1 fs-20 pointer d-lg-none  d-md-none d-inline-block"></i>SayDhad cost <span className='tx-green'>manager</span></p>
                        <p className="fs-14 ms-700 lh-15 sub-heading">{moment().format('ddd, DD MMM YYYY hh:mm A')}</p>
                    </div>
                    <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                        <button className="btn btn-edit fs-12 mx-2 d-lg-block d-md-block ms-700 d-none" onClick={toggleaddPlan} >ADD NEW PLAN</button>
                        <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >
                            <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                        </p>
                        <ProfileIcon />
                    </div>
                </header>
            </div>

            <div className="tutor-library mx-1">

                {/* Handle search */}
                <form onSubmit={(e) => { e.preventDefault(); apiCall(1, state.limit) }}>
                    <div className="row gx-0 mx-0">
                        <div className="col-lg-3 col-md-4 col-12 px-1 mb-2 d-flex align-items-center w-100">
                            <input type="search" placeholder="Type commitment level/id" className="w-100 h-100 input-box px-2 py-2 fs-14 ms-700" onChange={(e) => setQry(e.target.value)} />
                        </div>
                        <div className="col-lg-2 col-md-4 col-6 px-1 mb-2">
                            <button type='submit' className="btn btn-login w-100 h-100 wrap">search</button>
                        </div>
                    </div>
                </form>

                <p className="plan px-2 my-3">Commitment plans</p>
                <section className="row gx-0 mx-0">
                    {state.list.map((plan, index) => {
                        return (
                            <>
                                <div key={plan.id} className="col-lg-12 col-md-12 col-12 px-1">
                                    <CommitmentCard
                                        plan={plan}
                                        onEdit={(planId, plan) => toggleSb(planId, plan, index)}
                                    />
                                </div>
                            </>)
                    })}

                    {/* Pagination  */}
                    {state.total !== 0 &&
                        <div className="col-12 px-2 c-pagination">
                            <ReactPaginate
                                previousLabel={<i class="fas fa-arrow-left"></i>}
                                nextLabel={<i class="fas fa-arrow-right"></i>}
                                pageCount={Math.ceil(state.total / 10)}
                                onPageChange={({ selected }) => {
                                    apiCall(selected + 1, state.limit)
                                    setState({ ...state, pageNo: selected + 1 })
                                }}
                                containerClassName={"paginationBttns"}
                                previousClassName={"previousBttn"}
                                nextClassName={"nextBttn"}
                                disabledClassName={"paginationDisabled"}
                                activeClassName={"paginationActive"}
                                pageClassName={"eachpage"}
                            />
                        </div>
                    }
                </section>



                {pageLoad && <Backdrop />}

                {/* edit plan details  */}
                {Sb.open &&
                    <EditPlan
                        id={Sb.id}
                        data={Sb.data}
                        CallClose={() => { toggleSb() }}
                        OnEditComm={(data) => {
                            let list = [...state.list]
                            list[Sb.index] = data
                            setState({ ...state, list })
                        }}
                    />}

                {addPlan &&
                    <AddNewPlan
                        CallClose={toggleaddPlan}
                        OnAddPlan={(data) => {
                            let list = [...state.list]
                            list.push(data)
                            setState({ ...state, list, total: state.total })
                        }}
                    />}

            </div>

        </>
    )
}
