import React, { useRef, useState } from 'react'
import eye from '../static/images/eye.svg'
import { FaEyeSlash, FaEye } from 'react-icons/fa'

export default function ToggleHidePassword({ onChange, name, placeholder, className, inputClassName }) {
    const passRef = useRef()
    const [show, setshow] = useState(false)
    const toggle = () => {
        if (passRef.current.type == "password") {
            setshow(true)
            passRef.current.type = "text"
        } else {
            setshow(false)
            passRef.current.type = "password"
        }
    }
    return (
        <div className={`p-rel d-flex w-100 pt-0 align-items-center mb-1 ${className ? className : "input-box"}`}>
            <input br ref={passRef} name={name} type="password" placeholder={placeholder ? placeholder : "password"} className={`px-2 py-2 ${inputClassName} ms-500`} onChange={(e) => { onChange && onChange(e) }} />
            {
                show ?
                    <FaEye className='p-abs rt-0 mr-2  pointer' style={{ color: '#159D18',fontSize:18 }} onClick={toggle} />
                    : <FaEyeSlash className='p-abs rt-0 mr-2  pointer' style={{ color: '#159D18',fontSize:18 }} onClick={toggle} />}
        </div>
    )
}
