import React from 'react';
import moment from 'moment';
import avatar from '../../static/images/avatar.png'
export default function IncompleteRegisterCard({ tut }) {
    return (

        <>
            <div className="quote-card cur-pointer f-fred fs-14 bg-smoke border-radius-1 py-2 my-2 ">
                <div className="row gx-0 mx-0 tx-lt-grey">
                    <div className="col-lg-1 col-md-4 col-12 all-center wrap px-1 my-2">
                        <button className={`btn ${tut.profileStatus === "Approved" ? ' btn-close' : 'btn-open'} w-100  fs-16 py-1 px-2 ms-700 `}>{tut.profileStatus}</button>
                    </div>
                    <div className="col-lg-4 col-md-12 col-12 my-2 d-flex align-items-center justify-content-lg-start  justify-content-between ">
                        <div className=" px-2">
                            <p className="">{tut.email}</p>
                            <p className="ms-500 fs-12 ">Email</p>
                        </div>

                    </div>
                    <div className="col-lg-3 col-12 my-2 px-0 align-items-center d-lg-flex d-md-flex justify-content-md-between d-none">

                        <div className=" px-2 d-flex align-items-lg-end align-items-start flex-column">
                            <p className="">{moment(tut.createdOn).format('DD MMM YYYY')}</p>
                            <p className="ms-500 fs-12">Added on</p>

                        </div>
                        <div className=" px-2 d-flex align-items-lg-end align-items-between flex-column ">
                            <p className="">{moment(tut.createdOn).format("hh:mm a")}</p>
                            <p className="ms-500 fs-12">Time</p>

                        </div>
                    </div>

                </div>
            </div>
        </>);
}
