import React, { useState, useRef } from 'react'
import { FiXCircle } from "react-icons/fi";
import admin from '../../controller/admin';
import helper from '../../helper/index';
import { toast } from 'react-hot-toast';
import Backdrop from '../../component/Backdrop';
export default function EditPlan({ CallClose, id, data, OnEditComm }) {

    const [isActive, setIsActive] = useState(data.is_active)
    const [pageload, setPageload] = useState(false)

    const price = useRef()
    const plan_type = useRef()
    const minutes = useRef()
    const week = useRef()

    const editComPlan = async () => {
        const reqData = {
            "admin_id": helper.getid(),
            "amount": price.current.value,
            "is_active": isActive,
            "commitment_level": plan_type.current.value,
            "minutes_per_day": minutes.current.value,
            "days_per_week": week.current.value,
            "id": id
        }
        if (!reqData.amount || isNaN(reqData.amount)) {
            toast.error("Please add valid price")
            return
        }
        if (!reqData.minutes_per_day || isNaN(reqData.minutes_per_day)) {
            toast.error("Please add valid minutes")
            return
        }
        if (!reqData.days_per_week || isNaN(reqData.days_per_week) || reqData.days_per_week > 7) {
            toast.error("Please add valid days per week")
            return
        }
        setPageload(true)
        await admin.editComPlan(reqData)
            .then((res) => {
                if (res.data.status) {
                    toast.success("Plan Updated Successfully")
                        CallClose()
                        OnEditComm(res.data.result)
                } else {
                    toast.error(res.data.error)
                }
                setPageload(false)
            })

    }

    return (
        <>
             
            <div className="all-center popup-modal">
                <div className="right-popup notify-detail p-fix tp-0 rt-0  px-3 py-3 sm" >
                    <div className="row gx-0 mx-0">
                        <div className="col-10">
                            <p className="f-fred" style={{ fontSize: "22px" }}>Edit<span style={{ color: "#089D12" }}> commitment plans</span></p>
                            <p className="sub-heading" style={{ color: "#434943", fontSize: "14px", fontWeight: "700" }}>SayDhad cost manager</p>
                        </div>
                        <div className="col-2">
                            <FiXCircle className="pointer float-right " size="2rem" onClick={() => CallClose()} style={{ color: "lightgray" }} />
                        </div>
                    </div>
                    <div className="row gx-0 mx-0 ms-700">
                        <div className="col-lg-12 col-3 py-2 my-2">
                            <label class="switch">
                                <input type="checkbox" defaultChecked={isActive} onChange={(e) => { setIsActive(e.target.checked) }} />
                                <span class="slider round"></span>
                            </label>
                            <span className="pl-2">{isActive ? 'Active' : 'Inactive'}</span>
                        </div>
                    </div>

                    <div className="row gx-0 mx-0 pb-4">
                        <div className="col-lg-12 col-3 add-course">
                            <select className="add-course-select ms-700 fs-14" defaultValue={data.commitment_level} ref={plan_type} >
                                <option value="Month">Monthly</option>
                                <option value="Quarter">Quarterly</option>
                                <option value="Semester">Half yearly</option>
                                <option value="Annual">Annually</option>
                            </select>
                        </div>

                    </div>
                    <div className="row gx-0 mx-0  ">
                        <div className="col-lg-12 col-3 add-course">
                            <select className="add-course-select ms-700 fs-14" defaultValue={data.minutes_per_day} ref={minutes}>
                                <option value="">Select minutes per day</option>
                                <option value="15">15 minutes</option>
                                <option value="30">30 minutes</option>
                                <option value="60">60 minutes</option>
                              
                            </select>
                        </div>
                    </div>
                    <div className="row  gx-0 mx-0 edit-profile">
                        <div className="col-lg-12 edit-input ms-700 fs-14">
                            <input placeholder="Plan price per month" type="number" defaultValue={parseInt(data.amount).toFixed(0)} ref={price} style={{ border: "1px solid lightgray" }} />
                        </div>
                        <div className="col-lg-12 edit-input ms-700 fs-14">
                            <input placeholder="days per week" type="number" ref={week} defaultValue={data.days_per_week} style={{ border: "1px solid lightgray" }} />
                        </div>
                    </div>

                    <div className="row  gx-0 mx-0 py-2 my-2">
                        <div className="col-lg-12">
                            <button className="btn btn-update px-2 py-2 f-fred  btn-sm col-xs-2" onClick={editComPlan}>UPDATE PLAN</button>
                        </div>
                    </div>
                </div>
            </div>
            {pageload && <Backdrop />}
        </>
    )
}
