import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import store from '../../redux/AppState'
import SettingCard from './SettingCard';
import { useHistory } from 'react-router-dom';
import ProfileIcon from '../../components/ProfileIcon';
import moment from 'moment';
import websitesetting from '../../controller/websitesetting';
import Backdrop from '../../component/Backdrop';
import { FaBell } from 'react-icons/fa';
import banner from '../../static/images/banner.png'
import helper from '../../helper';
export default function Setting() {
    const [state, setState] = useState({
        pageload: true,
        error: '',
        data: []
    })
    const history = useHistory()
    const storeData = useSelector(state => state)


    useEffect(() => getCount(), [])

    const getCount = async () => {
        await websitesetting.getPagesCount().then((res) => {
            if (res.data.status) {
                setState({ ...state, pageload: false, data: res.data.result })
            }
        }).catch((err) => {
            setState({ ...state, pageload: false, data: null, error: err })
        })
    }
    return (
        <>
            <div>
                <header className='row gx-0 mx-0 py-1 mb-3'>
                    <div className="col-lg-6 col-md-5 col-6 d-flex flex-column justify-content-center px-1 ">
                        <p className="mb-1 fs-24 f-fred">
                            <span>
                                <i className="fas fa-bars mr-2 fs-20 hover-shadow pointer d-lg-inline-block d-md-inline-block d-none" onClick={() => store.setShowLeftBar(!storeData.showLeftBar)}></i>
                            </span>
                            <i class="fas fa-bars mr-1  pointer d-lg-none  d-md-none d-inline-block px-1" onClick={() => store.setMenu(true)}></i> SayDhad <span className="tx-green">general settings</span></p>
                        <p className="lh-15 sub-heading ml-2">{moment(new Date).format('ddd, DD MMM YYYY hh:mm A')}</p>
                    </div>
                    <div className="col-lg-6 col-md-7 col-6 d-flex justify-content-end align-items-center">
                        <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                            <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                        </p>

                        <ProfileIcon />
                    </div>
                </header>

                <div className=" mx-2">
                 
                    
                    <section className="banner-box-settings  row gx-0 mx-0 tx-lt-black">
                        <div className="col-lg-7 col-md-7 col-12 py-4 px-4 d-flex flex-column justify-content-center">
                            <h5 className="f-fred fs-32">General pages & content</h5>
                            <p className="mb-3 mt-2 fs-18 lh-21 tx-lt-grey ms-500">
                            From this panel you will be able to control the entire website’s & applications content. Browse the section below to select the category and you’ll find more pages associated with the respective category.                </p>
                          
                        </div>
                        <div className="col-lg-5 d-lg-block h-100 d-md-block d-none col-md-5 col-12 px-0">
                            <img className="w-100 h-100" style={{ borderRadius: '140px 20px 20px 140px', maxHeight: '100%' }} src={banner} alt="" />
                        </div>
                    </section>
                    <br />
                    {!state.pageload && !state.error &&
                        <div className="row gx-0 mx-0 ">
                            <div className="col-lg-4 pay px-1" >
                                <SettingCard
                                    title="Tutor knowledgebase"
                                    subTitle={"SayDhad tutor support"}
                                    count={state.data.tutorSupportArticles}
                                    onClick={() => history.push(`/${helper.getRole()}/dashboard/setting/tutor/article`)}
                                />
                            </div>
                            <div className="col-lg-4 pay px-1" >
                                <SettingCard
                                    title="Student knowledgebase"
                                    subTitle={"SayDhad student support"}
                                    count={state.data.studentSupportArticles}
                                    onClick={() => history.push(`/${helper.getRole()}/dashboard/setting/student/article`)}
                                />
                            </div>

                            <div className="col-lg-4 pay" >

                                <SettingCard
                                    title="Website content"
                                    subTitle={"about, contact, etc"}
                                    count={3}
                                    onClick={() => history.push(`/${helper.getRole()}/dashboard/web-content`)}
                                />

                            </div>
                            <div className="col-lg-4 pay" >
                                <SettingCard
                                    title="Policy page"
                                    subTitle={"terms, privacy, etc"}
                                    count={state.data.totalpolicyPages}
                                    onClick={() => history.push(`/${helper.getRole()}/dashboard/policy-pages`)}
                                />
                            </div>

                            <div className="col-lg-4 pay" >
                                <SettingCard
                                    title="Tutor help videos"
                                    subTitle={"SayDhad tutor support"}
                                    count={state.data.tutorHelpVideo}
                                    onClick={() => history.push(`/${helper.getRole()}/dashboard/setting/tutor/video`)}
                                />
                            </div>

                            <div className="col-lg-4 pay" >
                                <SettingCard
                                    title="Student help videos"
                                    subTitle={"SayDhad student support"}
                                    count={state.data.studentHelpVideo}
                                    onClick={() => history.push(`/${helper.getRole()}/dashboard/setting/student/video`)}
                                />
                            </div>

                            <div className="col-lg-4 pay" >
                                <SettingCard
                                    title="Tutor FAQ"
                                    subTitle={"SayDhad tutor support"}
                                    count={state.data.tutorFaqCategories}
                                    onClick={() => history.push(`/${helper.getRole()}/dashboard/setting/tutor/faqs`)}
                                />
                            </div>

                            <div className="col-lg-4 pay" >
                                <SettingCard
                                    title="Student FAQ"
                                    subTitle={"SayDhad student support"}
                                    count={state.data.studentFaqCategories}
                                    onClick={() => history.push(`/${helper.getRole()}/dashboard/setting/student/faqs`)}
                                />
                            </div>
                        </div>
                    }



                </div>

                {/* {WebCont && <WebsiteContent />} */}
                {state.pageload && <Backdrop />}
            </div>

        </>
    )
}
