import * as React from "react";

const SvgSupport = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <defs>
      <clipPath id="Support_svg__a">
        <path
          data-name="Rectangle 438"
          transform="translate(9272 4489)"
          fill="#fff"
          stroke="#707070"
          d="M0 0h30v30H0z"
        />
      </clipPath>
    </defs>
    <g
      data-name="Mask Group 35"
      transform="translate(-9272 -4489)"
      clipPath="url(#Support_svg__a)"
    >
      <g data-name="Iconly/Bold/Shield-Done">
        <path
          data-name="Path 4388"
          d="m9287.885 4492.119 7.965 2.673a2.171 2.171 0 0 1 1.5 2.035l.05 7.973a9.894 9.894 0 0 1-2.478 6.594 10.131 10.131 0 0 1-2.907 2.206l-4.281 2.289a.939.939 0 0 1-.434.111.916.916 0 0 1-.43-.1l-4.318-2.239a10.157 10.157 0 0 1-2.936-2.18 9.9 9.9 0 0 1-2.565-6.569l-.051-7.963a2.175 2.175 0 0 1 1.474-2.049l7.935-2.772a2.266 2.266 0 0 1 1.476-.009Zm3.809 8.543a.924.924 0 0 0-1.282.007l-4.042 3.985-1.655-1.591a.924.924 0 0 0-1.282.008.865.865 0 0 0 .008 1.247l2.3 2.212a.924.924 0 0 0 1.282-.008l4.677-4.612a.866.866 0 0 0-.006-1.248Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgSupport;
