import React, { useState, useEffect } from 'react'
import student from '../../controller/student';
import { useHistory, useParams } from 'react-router-dom';
import ProfileIcon from '../../components/ProfileIcon';
import CourseCard from '../courses/CourseCard';
import { FaBell } from 'react-icons/fa';
import helper from '../../helper';
export default function EnrolledCourses() {

    const { userId } = useParams()
    const history = useHistory()
    const [state, setState] = useState({
        pageload: true,
        data: []
    })

    useEffect(() => {
        getCourses()
    }, [])

    const getCourses = async () => {
        await student.getEnrolledCourses(userId)
            .then((res) => {
                if (res.data.status) {
                    if (res.data.result.length >= 1) {
                        let list = []
                        res.data.result.forEach((data) => {
                            data.course.forEach((course) => {
                                list.push(course)
                            })
                        })
                        setState({ ...state, data: list, pageload: false })
                    } else {
                        setState({ ...state, pageload: false })
                    }
                } else {
                    setState({ ...state, pageload: false })
                }

            }).catch((err) => {
                setState({ ...state, pageload: false })
            })
    }

    return (
        <>
            <div className='mx-1'>
                <header className='row gx-0 mx-0 mb-3 py-1'>
                    <div className="col-lg-4 col-md-5 col-6 d-flex align-items-center px-1 ">
                        <button className="btn btn-back mr-2 " onClick={() => history.goBack()}>BACK</button>
                        <div>
                            <p className="mb-1 fs-24 f-fred"> <i class="fas fa-bars mr-1 fs-20 pointer d-lg-none  d-md-none d-inline-block"></i>Enrolled courses</p>
                            <p className="sub-heading lh-15">Details</p>

                        </div>
                    </div>
                    <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                    <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

<FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
</p>
                            <ProfileIcon />
                    </div>
                </header>
            </div>


            <section className='row gx-0 mx-0'>
                {state.data.map((courses, index) => {
                    return (

                        <div className="col-lg-3 col-md-4 col-12 px-1 mb-3">
                            <CourseCard
                                name={courses.title}
                                subTitle={courses.subTitle}
                                lesson={courses.totalLessons}
                                slides={courses.totalSlide}
                                isRemove={false}
                                img={courses.imgName}
                                onChange={() => { history.push(`/${helper.getRole()}/dashboard/enrolled-course-detail/${courses.id}/${userId}`) }}
                            />
                        </div>
                    )
                })
                }
                {state.data.length === 0 &&
                    <p className='my-3 w-100 ms-700 text-center'>
                        No enrolled courses found</p>}
            </section>

        </>
    )
}
