import React, { useState, useEffect } from 'react'

import store from '../../../redux/AppState'
import { useSelector } from 'react-redux'
import admin from '../../../controller/admin'

import { useHistory } from 'react-router-dom';
import ProfileIcon from '../../../components/ProfileIcon';
import HowToVideosCard from '../../../components/HowToVideoCard';
import helper from '../../../helper';
import toast from 'react-hot-toast';
import Backdrop from '../../../component/Backdrop';
import { FaBell } from 'react-icons/fa';
export default function TutorHelpVideoDetail({ data, id, resData, category, CallClose }) {
    const history = useHistory()

    const [Callpopup, setCallpopup] = useState(false);

    const [state, setState] = useState({
        data: data,
        id: id
    })
    const [relatedVideo, setRelatedVideo] = useState([])
    const [pageload, setPageload] = useState(false)
    useEffect(() => {
        let list = []
        resData.forEach((result) => {
            if (result.category == category && result.id != id) {
                list.push(result)
            }
        })
        setRelatedVideo(list)

    }, [])

    const DeleteVdo = async () => {
        const conf = window.confirm('Do you want to delete the video?')
        if (!conf) {
            return
        }

        setPageload(true)
        await admin.deleteSupportVdo({ adminId: helper.getid(), id: state.id })
            .then((res) => {
                if (res.data.status) {
                    toast('Successfully deleted')
                    setTimeout(() => { CallClose() }, 1000)
                } else {
                    toast(res.data.erro)

                }
                setPageload(false)

            })
    }

    const setVideo = (data, id) => {
        setState({
            ...state,
            data: data,
            id: id
        })

        let list = []
        resData.forEach((result) => {
            if (result.category == category && result.id != id) {
                list.push(result)
            }
        })
        setRelatedVideo(list)
        helper.scroll()
    }

    return (
        <>
            <div className="mx-1">
                <header className='row gx-0 mx-0 mb-3 py-1'>
                    <div className="col-lg-6 col-md-5 col-6 d-flex align-items-center px-1">
                        <button className="btn btn-back mr-2" style={{ fontWeight: "900" }} onClick={CallClose}>BACK</button>
                        <div>
                            <p className="mb-1 fs-18 f-fred text-capitalize overflow-elipse"> <i class="fas fa-bars mr-1 fs-20 pointer d-lg-none  d-md-none d-inline-block" onClick={() => store.setMenu(true)}></i>{state.data.title}</p>
                            <p className="fs-14 ms-700 lh-15 sub-heading text-capitalize">{data.category}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">

                        <button className="btn btn-update fs-14 ms-700 mx-2 d-lg-block d-md-block d-none" onClick={() => history.push(`/${helper.getRole()}/dashboard/update-support-video/${state.id}`)}>UPDATE</button>
                        <button className="btn btn-clear mx-2 fs-14 ms-700 d-lg-block d-md-block d-none" onClick={DeleteVdo}>DELETE</button>
                        <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                            <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                        </p>
                        <ProfileIcon />
                    </div>
                </header>
                <div className="mx-1">
                    {!Callpopup && <>

                        <div className="mt-2 row gx-0 mx-0 ">

                            <div className="col-lg-12 d-flex justify-content-center">
                                <video controls width="60%" height="350px" className='br-2' style={{ display: "block", marginLeft: "auto", margin: "auto" }} src={helper.replaceVideoS3Url(state.data.video_link)} alt="" />

                            </div>

                        </div>

                        <section className="genCont">
                            <div className="row gx-0 mx-0 my-2 ">
                                <div className="px-2 my-1 ">
                                    <span className="  pointer" ><img width="40px" height="40px" className="rounded-circle" src={helper.replaceS3Url(state.data.featured_image_link)} /></span>
                                </div>
                                <div className="px-2 my-1">
                                    <p className="f-fred text-capitalize">{state.data.title}</p>
                                    <p className="ms-500 fs-14">SayDhad Tutor Support</p>
                                </div>
                            </div>
                        </section>


                        <div className="row gx-0 mx-0">
                            <p className="plan my-2 px-2">Related videos</p>
                        </div>

                        <div class="row gx-0 mx-0">
                            {relatedVideo.map((result, index) => {
                                return (
                                    <div key={`rel${index}`} className="col-lg-3 px-1 mb-2">
                                        <HowToVideosCard
                                            img={result.featured_image_link}
                                            title={result.title}
                                            onChange={() => setVideo(result, result.id)}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                        <div className="col-12 ">
                            {relatedVideo.length === 0 && <p className='text-center ms-700 my-3'>No Related video found.</p>}
                        </div>
                    </>}

                </div>
                {pageload && <Backdrop />}
            </div>
        </>
    )
}
