export const constant = {
    // BASE_URL: 'https://api.saydhad.com/v1',
    // TUTOR_URL: 'https://api.saydhad.com/v1/tutor',
    // ADMIN_URL: 'https://api.saydhad.com/v1/admin',
    // AGORA_URL: 'https://api.agora.io/v1/apps',
    // DOMAIN : 'api.saydhad.com',

    BASE_URL: process.env.REACT_APP_API_BASE_URL,
    TUTOR_URL: process.env.REACT_APP_API_TUTOR_URL,
    ADMIN_URL: process.env.REACT_APP_API_ADMIN_URL,
    DOMAIN : process.env.REACT_APP_DOMAIN,

    
    AGORA_URL: process.env.REACT_APP_AGORA_URL,    
    AGORA_CUSTOMER_KEY: process.env.REACT_APP_AGORA_CUSTOMER_KEY,
    AGORA_CUSTOMER_SECRET_KEY: process.env.REACT_APP_AGORA_CUSTOMER_SECRET_KEY,

    AWS_BUCKET_NAME: process.env.REACT_APP_AWS_BUCKET_NAME,
    AWS_ACCESS_KEY: process.env.REACT_APP_AWS_ACCESS_KEY,
    AWS_SECRET_KEY: process.env.REACT_APP_AWS_SECRET_KEY,
    AWS_REGION: process.env.REACT_APP_AWS_REGION,
    AWS_S3_URL: process.env.REACT_APP_AWS_S3
}