import React, { useState, useRef, useEffect } from 'react'
import store from '../../redux/AppState'
import admin from '../../controller/admin';
import helper from '../../helper/index';
import { useHistory, useParams } from 'react-router-dom';
import ProfileIcon from '../../components/ProfileIcon';
import {  toast } from 'react-hot-toast';
import Backdrop from '../../component/Backdrop';
import { FaBell } from 'react-icons/fa';
import { fileUploadToS3 } from '../../helper/fileupload';

export default function EditCourse() {
    const history = useHistory()
    const { userId } = useParams()
    const [pageLoad, setpageLoad] = useState(true)
    const [selectedImage, setSelectedImage] = useState(null);
    const [courseCategories, setCourseCategories] = useState([])
    const [courseData, setCourseData] = useState(null);

    const title = useRef()
    const description = useRef()
    const experienceLevel = useRef()
    const courseCategory = useRef()
    const subTitle = useRef()
    const descriptionCourse = useRef()


    useEffect(() => {
        getCourseCategory();
        apiCall()
        return () => { };
    }, []);

    const apiCall = async () => {
        await admin.getCourseById(userId)
            .then((res) => {
                if (res.data.status) {
                    setCourseData(res.data.result.length > 0 ? res.data.result[0].course[0] : null)
                }
                setpageLoad(false)
            })
    }

    const getCourseCategory = async () => {
        await admin.getCatList()
            .then((res) => {
                if (res.data.status) {
                    setCourseCategories(res.data.result)
                }
            })
    }

    const submit = async () => {
        const formData = new FormData()
        if (!title.current.value) {
            toast("Please add course title")
            return
        }
        if (!subTitle.current.value) {
            toast("Please add course sub title")
            return
        }
        if (!experienceLevel.current.value) {
            toast("Please add course experience level")
            return
        }


        if (!description.current.value) {
            toast("Please add 'What will you be able to do?'")
            return
        }
        if (!descriptionCourse.current.value) {
            toast("Please add 'Why take these courses?'")
            return
        }
        if (!courseCategory.current.value) {
            toast("Please add course category")
            return
        }


        formData.append('id', userId)
        formData.append('title', title.current.value)
        formData.append('description', description.current.value)
        formData.append('experienceLevel', experienceLevel.current.value)
        formData.append('courseCategory', courseCategory.current.value)
        formData.append('subTitle', subTitle.current.value)

        formData.append('admin_id', helper.getid())
        formData.append('descriptionCourse', descriptionCourse.current.value)
        formData.append('preRequisites', "none")
        formData.append('courseType', "Paid")

        setpageLoad(true)
        if (selectedImage) {
            let imgURL = await fileUploadToS3("course_prev_image/", selectedImage)
            formData.append('imgName', imgURL)
        }

        await admin.updateCourse(formData)
            .then((res) => {
                if (res.data.status) {
                    toast(res.data.result)
                }else {
                    toast(res.data.error)
                }
                setpageLoad(false);
            }).catch((err)=>{
                
            })
    };


    return (
        <>

            <div className="mx-1">

                <header className='row gx-0 mx-0 mb-3 py-1'>
                    <div className="col-lg-4 pl-1 col-md-5 col-6 d-flex align-items-center">
                        <div className='mr-2'>
                            <button className="btn btn-back  fs-12" onClick={() => history.goBack()}>BACK</button>
                        </div>
                        <div className='d-flex flex-column'>
                            <p className="mb-1 fs-20 f-fred"> <i class="fas fa-bars mr-1 fs-20 pointer d-lg-none  d-md-none d-inline-block" onClick={() => store.setMenu(true)}></i> Edit <span className="tx-green">Course</span></p>
                            <p className="fs-14 ms-700 lh-15 sub-heading">{courseData && courseData.title}</p>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                        <button className='btn btn-update ms-900' onClick={() => submit()}>PUBLISH COURSE</button>

                        <button className="btn btn-update mx-2 d-lg-block d-md-block d-none " onClick={() => { history.push(`/${helper.getRole()}/dashboard/add-syllabus?id=${userId}`) }}>ADD SYLLABUS</button>

                        <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                            <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                        </p>
                        <ProfileIcon />
                    </div>
                </header>

                <div className=''>
                    <div className="row gx-0 mx-0">
                        <div className="col-lg-8 col-12 ms-700 px-1">
                            <div className="row gx-0 mx-0 edit-profile ">
                                <div className="col-lg-6 col-md-6 col-12 pl-0 edit-input  ">
                                    <input type="text" className='text-capitalize' placeholder="Course title" style={{ border: "1px solid lightgray" }} ref={title} defaultValue={courseData && courseData.title} />
                                </div>
                                <div className="col-lg-6 col-md-6 col-12 edit-input px-2">
                                    <input type="text" className='text-capitalize' placeholder="Course subtitle" style={{ border: "1px solid lightgray" }} ref={subTitle} defaultValue={courseData && courseData.subTitle} />
                                </div>
                            </div>
                            <div className="row  gx-0 mx-0 py-2 my-2 ">
                                <div className="col-lg-6 col-md-6 col-12 pl-0 add-course ">
                                    <select className="add-course-select" placeholder="Course level" name="" id="" ref={experienceLevel} defaultValue={courseData?.experienceLevel}>
                                        <option value="">Course level</option>
                                        <option selected={courseData?.experienceLevel === "Basic" ? true : false} value="Basic">Basic</option>
                                        <option selected={courseData?.experienceLevel === "Advanced" ? true : false} value="Advanced">Advanced</option>
                                        <option selected={courseData?.experienceLevel === "Intermediate" ? true : false} value="Intermediate">Intermediate</option>
                                    </select>
                                </div>

                            </div>

                            <div className="row  gx-0 mx-0 ">

                                <div className="col-lg-12 py-2 mb-2 pl-0">
                                    <textarea className='px-2 py-1 w-100' rows="6" style={{ border: "1px solid #EAEAEA", borderRadius: "10px" }} placeholder='What will you be able to do?' ref={description} defaultValue={courseData && courseData.description} required></textarea>

                                </div>

                                <div className="col-12 py-2 mb-2 pl-0">
                                    <textarea className='px-2 py-1 w-100' rows="6" style={{ border: "1px solid #EAEAEA", borderRadius: "10px" }} placeholder='Why take these courses?' ref={descriptionCourse} defaultValue={courseData && courseData.descriptionCourse} required></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4  col-12">
                            <div className="quote-card py-3 cur-pointer bg-smoke border-radius-1 py-2 my-2 px-4  filter-cb">
                                <p className="ms-900 pb-2 fs-20 mb-3">Select<span style={{ color: "#089D12" }}> category</span></p>
                                {courseData?.courseCategory && <select defaultValue={courseData?.courseCategory} className='edit-input text-dark ms-700 py-2 fs-16 py-1 px-1' style={{ width: "100%", border: "1px solid #EAEAEA", borderRadius: "8px" }} ref={courseCategory}>
                                    <option value="">Select course category</option>
                                    {courseCategories.map((data) => <option selected={courseData?.courseCategory === data.category_name} value={data.category_name}>{data.category_name}</option>)}
                                </select>}
                            </div>
                            <div className="quote-card py-3 cur-pointer bg-smoke border-radius-1 py-2 my-2 px-4  filter-cb">
                                <p className="ms-900 fs-20 mb-3">Featured<span style={{ color: "#089D12" }}> image</span></p>
                                {selectedImage ? (
                                    <div>
                                        <img className="my-2" style={{ maxHeight: '200px' }} width={'100%'} alt="not found" accept=".png, .gif, .jpeg" src={URL.createObjectURL(selectedImage)} required />
                                        <br />
                                        <div className="my-2">
                                            <button className="btn btn-dark px-1 py-1 ms-900 mr-1 fs-14" onClick={() => setSelectedImage(null)}>CHANGE IMAGE</button>
                                        </div>
                                    </div>
                                )
                                    :
                                    <div>
                                        <img className="my-2" style={{ maxHeight: '200px' }} width={'100%'} alt="not found" accept=".png, .gif, .jpeg" src={courseData && helper.replaceS3Url(courseData.imgName)} required />

                                    </div>
                                }

                                <input
                                    type="file" name="myImage" onChange={(event) => {
                                        setSelectedImage(event.target.files[0]);
                                    }}
                                />
                                <p className="py-2" style={{ fontSize: "14px", color: "#434943", fontWeight: "700", lineHeight: "12px" }}>Width: 400px; Height: 200px min</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {pageLoad && <Backdrop />}
        </>
    )
}

