import * as React from "react";

const SvgStudents = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <defs>
      <clipPath id="Students_svg__a">
        <path
          data-name="Rectangle 435"
          transform="translate(9272 4241)"
          fill="#fff"
          stroke="#707070"
          d="M0 0h30v30H0z"
        />
      </clipPath>
    </defs>
    <g
      data-name="Mask Group 38"
      transform="translate(-9272 -4241)"
      clipPath="url(#Students_svg__a)"
    >
      <g data-name="Iconly/Bold/3-User">
        <g data-name="3-User">
          <path
            data-name="3-Friends"
            d="M9286.944 4258.5c3.743 0 6.939.593 6.939 2.968s-3.176 2.989-6.939 2.989c-3.743 0-6.939-.593-6.939-2.967s3.176-2.99 6.939-2.99Zm5.985-1.43a16.969 16.969 0 0 1 3.538.309 3.008 3.008 0 0 1 2.325 1.421 2.1 2.1 0 0 1 0 1.822c-.5 1.09-2.121 1.439-2.75 1.53a.2.2 0 0 1-.221-.224 4.856 4.856 0 0 0-2.9-4.78.049.049 0 0 1-.031-.051.041.041 0 0 1 .039-.029Zm-12.127 0h.269a.04.04 0 0 1 .034.028.05.05 0 0 1-.03.052 4.854 4.854 0 0 0-2.9 4.779.2.2 0 0 1-.22.225c-.629-.09-2.248-.44-2.75-1.53a2.091 2.091 0 0 1 0-1.822 3.009 3.009 0 0 1 2.324-1.424 17.064 17.064 0 0 1 3.539-.308Zm6.142-10.07a4.588 4.588 0 0 1 4.592 4.618 4.592 4.592 0 1 1-9.184 0 4.588 4.588 0 0 1 4.592-4.618Zm6.234.77a3.9 3.9 0 0 1 3.736 4.924 3.833 3.833 0 0 1-3.846 2.86 3.705 3.705 0 0 1-.527-.051.173.173 0 0 1-.116-.266 6.373 6.373 0 0 0-.092-7.213.113.113 0 0 1-.017-.124.141.141 0 0 1 .09-.054 3.824 3.824 0 0 1 .772-.076Zm-12.357 0a3.841 3.841 0 0 1 .774.08.148.148 0 0 1 .09.05.115.115 0 0 1-.018.12 6.373 6.373 0 0 0-.092 7.213.173.173 0 0 1-.116.266 3.581 3.581 0 0 1-.527.051 3.833 3.833 0 0 1-3.846-2.86 3.9 3.9 0 0 1 3.735-4.92Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgStudents;
