import React from 'react';

export default function SettingCard({ title, subTitle, count, onClick }) {
    return (<>
        <div className="row payout pointer gx-0 mx-0" onClick={onClick}>
            <div className="col-lg-9 px-1">
                <div>
                    <p className='ms-900 fs-18 tx-lt-black'>{title}</p>
                    <p className="sub-heading">{subTitle}</p>
                </div>
            </div>
            <div className="col-lg-3 px-1">
                <h2 className="text-right  fs-40 ms-500" style={{ color: "#099d14"}}>{count}</h2>
            </div>
        </div>
    </>);
}
