import React, { useState } from 'react'
import ApexChart from '../ApexChart'
import store from '../../redux/AppState'
import admin from '../../controller/admin'
import { useEffect } from 'react'
import Backdrop from '../../component/Backdrop'
import ProfileIcon from '../../components/ProfileIcon'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import GlobalUserCard from './GlobalUserCard'
import TopUserCard from './TopUserCard'
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import { useSelector } from 'react-redux'
import { FaBell } from 'react-icons/fa'
import helper from '../../helper'
export default function Analytic() {
    const history = useHistory()
    const [resData, setResData] = useState()
    const [pageLoad, setpageLoad] = useState(true)
    const storeData = useSelector(state => state)
    const [startDate, setStartDate] = useState(moment().subtract(11, 'month'));
    const [endDate, setEndDate] = useState(moment());
    const [focusedInput, setFocusedInput] = useState(null);
    const [earning, setEarnings] = useState({ months: [], amount: [], load: true })

    useEffect(() => {
        statCall(startDate, endDate)
        return () => { }
    }, [])


    const onRange = (st, en) => {
        if (st) {
            setStartDate(st)
        }
        if (en) {
            setEndDate(en)
        }
        if (st && en) {
            setEarnings({ ...earning, load: true })
            statCall(st, en)
        }
    }

    const statCall = async (st, en) => {
        let startDate = moment(st).format('YYYY-MM-DD')
        let endDate = moment(en).format('YYYY-MM-DD')
        await admin.getStats(startDate, endDate)
            .then((res) => {
                if (res.data.status) {
                    setResData(res.data.result)
                    let months = [], amount = [], prevMonth = [], prevAmount = [], flag = 0

                    /* Formating Data for Chart */
                    res.data.result.earningList.forEach((key) => {
                        if (moment(startDate).format('MMM') === key.month || flag === 1) {
                            months.push(key.month + " " + moment(st).format('YYYY'))
                            amount.push(parseFloat(key.amount).toFixed(2))
                            flag = 1
                        } else if (moment(endDate).format('MMM') !== key.month && flag === 0) {
                            prevMonth.push(key.month + " " + moment(en).format('YYYY'))
                            prevAmount.push(parseFloat(key.amount).toFixed(2))
                        }
                    })

                    let newMonth = months.concat(prevMonth)
                    let newAmount = amount.concat(prevAmount)
                    setEarnings({ ...earning, months: newMonth, amount: newAmount, load: false })
                }
                setpageLoad(false)
            })
    }

    return (
        <>
            {!pageLoad && <>
                <header className='row gx-0 mx-0 mb-2 py-1'>
                    <div className="col-lg-4 col-md-5 col-6 d-flex flex-column justify-content-center px-1">
                        <p className="mb-1 fs-24 f-fred">
                            <span>
                                <i className="fas fa-bars ml-1 mr-2 fs-20 hover-shadow pointer d-lg-inline-block d-md-inline-block d-none" onClick={() => store.setShowLeftBar(!storeData.showLeftBar)}></i>
                            </span>
                            <i class="fas fa-bars mr-1 fs-20  pointer d-lg-none  d-md-none d-inline-block" onClick={() => store.setMenu(true)}></i>Your <span className="tx-green">dashboard</span></p>
                        <p className="fs-14 ms-700 lh-15 sub-heading ml-2">{moment().format('ddd, DD MMM YYYY hh:mm A')}</p>
                    </div>
                    <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                        <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >
                            <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                        </p>
                        <ProfileIcon />
                    </div>
                </header>

                <div className="tutor-library mx-2">
                    <section className="row gx-0 mx-0 ">
                        <div className="col-lg-12 px-0">
                            <div className="row gx-0 mx-0">
                                <div className="col-lg-12 pb-2 px-0 boxShadow">
                                    <div className="row gx-0 mx-0">
                                        <div className="col-lg-12 ms-900 mb-3 mt-4 fs-18 tx-lt-black">Global tutors and students map</div>
                                    </div>
                                    <div className="row gx-0 mx-0">
                                        <div className="col-lg-4 pr-2">
                                            <GlobalUserCard
                                                data={resData.tutorsGlobal}
                                                type={"tutor"}
                                            />
                                        </div>
                                        <div className="col-lg-4">
                                            <GlobalUserCard
                                                data={resData.studentsGlobal}
                                                type={"student"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="col-lg-12 px-0 col-md-12 col-12">
                        <div className="row gx-0 mx-0">
                            <div className="col-lg-7 px-0 my-3">
                                <div className="quote-card all-center h-100 cur-pointer bg-smoke border-radius-1">
                                    <div className='w-100 d-flex h-100 py-3 flex-column justify-content-between'>
                                        <div className='w-100  d-flex justify-content-between align-items-center'>
                                            <section className='ml-3'>
                                                <p className='ms-900 fs-18'>Earnings</p>
                                                <p className='sub-heading'>From {startDate.format("DD MMM YYYY")} to {endDate.format("DD MMM YYYY")} </p>
                                            </section>
                                            <div className="mr-2  ms-700 fs-12 pointer   tx-lt-black w-100 d-lg-flex justify-content-end d-md-flex d-none">
                                                <DateRangePicker
                                                    maxDate={moment()}
                                                    monthFormat='MMM YYYY'
                                                    showClearDates={false}
                                                    navPrev={null}
                                                    initialStartDate={null}
                                                    disabled={false}
                                                    startDate={startDate}
                                                    isOutsideRange={() => false}
                                                    isDayBlocked={() => false}
                                                    enableOutsideDays={true}
                                                    numberOfMonths={2}
                                                    startDateId="s_id"
                                                    endDate={endDate}
                                                    endDateId="e_id"
                                                    onDatesChange={({ startDate, endDate }) => { onRange(startDate, endDate) }}
                                                    focusedInput={focusedInput}
                                                    onFocusChange={e => setFocusedInput(e)}
                                                    displayFormat="DD/MM/YYYY"
                                                />
                                            </div>
                                        </div>
                                        {!earning.load && <ApexChart year={`${moment(startDate).format('MMM YYYY')} - ${moment(endDate).format('MMM YYYY')}`} month={earning.months} amount={earning.amount} />}

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 my-3 h-100">
                                <section className="row gx-0 mx-0">
                                    <div className="col-lg-12 home">
                                        <div className="row gx-0 mx-0">
                                            <div className="col-lg-12 px-1" >
                                                <p className="ms-900" style={{ fontSize: "18px", fontWeight: "900", color: "#1B221B" }}>Stats & analytics</p>
                                                <p className="sub-heading">Till now</p>
                                            </div>
                                        </div>
                                        <div className="row gx-0 mx-0 pb-4 pt-4">
                                            <div className="col-lg-6 px-1 mb-3">
                                                <div className="row gx-0 mx-0">
                                                    <div className="col-lg-12 px-1 ">
                                                        <div>
                                                            <p className="tx-lt-black-op-5 ms-900">Total tutors</p>
                                                            <p className="ms-500 fs-40" >{resData.totTutor.count} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 px-1 mb-3">
                                                <div className="row gx-0 mx-0">
                                                    <div className="col-lg-12 px-1">
                                                        <div >
                                                            <p className="tx-lt-black-op-5 ms-900" style={{ fontWeight: "700", fontSize: "16px" }} >Total earnings(in SAR)</p>
                                                            <h2 className="ms-500 fs-40" style={{ fontWeight: "500" }}>{resData.total_earnings} <span className="stx">{resData.earning_percentage}%</span></h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 px-1 mb-3">
                                                <div className="row gx-0 mx-0">
                                                    <div className="col-lg-12 px-1">
                                                        <div >
                                                            <p className="tx-lt-black-op-5 ms-900" style={{ fontWeight: "700", fontSize: "16px" }} >Total students</p>
                                                            <h2 className="ms-500 fs-40" >{resData.totStudent.count}

                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 px-1 mb-2">
                                                <div className="row gx-0 mx-0">
                                                    <div className="col-lg-12 px-1">
                                                        <div >
                                                            <p className="tx-lt-black-op-5 ms-900" style={{ fontWeight: "700", fontSize: "16px" }} >Total courses</p>
                                                            <h2 className="ms-500 fs-40">{resData.totCourses.count}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 px-1 mb-2">
                                                <div className="row gx-0 mx-0">
                                                    <div className="col-lg-12 px-1">
                                                        <div >
                                                            <p className="tx-lt-black-op-5 ms-900" style={{ fontWeight: "700", fontSize: "16px" }} >Total payouts(in SAR)</p>
                                                            <h2 className="ms-500 fs-40" style={{ fontWeight: "500" }}>0</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 px-1">
                                                <div className="row gx-0 mx-0">
                                                    <div className="col-lg-12 px-1">
                                                        <div >
                                                            <p className="tx-lt-black-op-5 ms-900" style={{ fontWeight: "700", fontSize: "16px" }} >Total app download</p>
                                                            <h2 className="ms-500 fs-40" style={{ fontWeight: "500" }}>0</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>

                        <div className="row gx-0 mx-0">
                            <div className="col-lg-4 px-0">
                                <div className='b-grey px-2 pt-3'>
                                    <div className="row gx-0 mx-0">
                                        <div className="col-lg-12 px-0" >
                                            <p className="ms-900" style={{ fontSize: "18px", color: "#1B221B" }}>Top {resData.topTutor.length <= 5 ? resData.topTutor.length : 5} Tutor</p>
                                            <p className='sub-heading ms-700' style={{ fontWeight: "700", fontSize: "14px" }}>Till now</p>
                                        </div>
                                    </div>

                                    {
                                        resData.topTutor.slice(0, 5).map((result) =>
                                            <TopUserCard
                                                name={result.name}
                                                flag={result.flag_uri}
                                                pic={result.profilePicture}
                                                rating={result.rating}
                                                onClick={() => history.push(`/${helper.getRole()}/dashboard/tutor/${result.tutor_id}`)}


                                            />)}

                                </div>
                            </div>

                            <div className="col-lg-4 px-2">
                                <div className='b-grey px-2 pt-3'>
                                    <div className="row gx-0 mx-0">
                                        <div className="col-lg-12 px-0" >
                                            <p className="ms-900" style={{ fontSize: "18px", fontWeight: "900", color: "#1B221B" }}>Top {resData.topStudent.length <= 5 ? resData.topStudent.length : 5} Student</p>
                                            <p className='sub-heading ms-700' style={{ fontWeight: "700", fontSize: "14px" }}>Till now</p>
                                        </div>
                                    </div>
                                    {resData.topStudent.slice(0, 5).map((result) =>
                                        <TopUserCard
                                            name={result.name}
                                            flag={result.flag_uri}
                                            pic={result.profilePicture}
                                            rating={result.rating}
                                            courses={result.enroll_courses}
                                            onClick={() => history.push(`/${helper.getRole()}/dashboard/student/${result.student_id}`)}
                                        />)}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>}
            {pageLoad && <Backdrop />}
        </>
    )
}
