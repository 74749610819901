import React, { useState } from 'react'
import NewSlideShow from '../module/courses/NewSlideShow'
export default function SyllabusCard({ name, slides, lessonNo, file }) {
    const [open, setOpen] = useState(false)
    return (
        <>
            <div className="syllabus b-grey px-2 py-3">
                <div className="row gx-0 mx-0 fs-12">
                    <div className="col-lg-2 col-md-2 col-3 mb-2">
                        <p className="f-fred lh-15 fs-14">{lessonNo}</p>
                        <p className="tx-lt-grey ms-500 fs-12">Lesson</p>
                    </div>
                    <div className="col-lg-5 col-md-6 col-5 mb-2">
                        <p className="f-fred lh-15 fs-14 text-capitalize">{name}</p>
                        <p className="tx-lt-grey ms-500 fs-12">Title of lesson</p>
                    </div>
                    <div className="col-lg-2 col-md-4 col-4 mb-2 d-flex flex-column align-items-end px-2">
                        <p className="f-fred lh-15 fs-14">{slides}</p>
                        <p className="tx-lt-grey ms-500 fs-12 ">Slides</p>
                    </div>
                    <div className="col-lg-3 col-md-4 col-6 px-0 ">
                        <button className="btn btn-detail f-fred w-100 py-1 text-white fs-14" onClick={() => setOpen(true)}>View Slide</button>
                    </div>
                </div>
                {
                    open && <NewSlideShow
                        data={file}
                        startIndex={0}
                        cb={() => setOpen(false)}
                    />}
            </div>
        </>
    )
}
