import React, { useState } from 'react'
import AddFAQ from './AddFaq'
import store from '../../../redux/AppState'
import { useSelector } from 'react-redux'
import admin from '../../../controller/admin';
import { useEffect } from 'react';
import Backdrop from '../../../component/Backdrop';
import { useHistory } from 'react-router-dom';
import ProfileIcon from '../../../components/ProfileIcon';
import EditFaq from './EditFaq';
import { FaBell } from 'react-icons/fa';
import helper from '../../../helper';

export default function TutorFaqs() {
    const storeData = useSelector(state => state)
    const history = useHistory()
    const [Callpopup, setCallpopup] = useState(false);


    const [Backpopup, setBackpopup] = useState(false);


    const [faqEdit, setfaqEdit] = useState({ open: false, id: 0, data: "" });
    const togglefaqEdit = (idValue, data) => {
        setfaqEdit({ ...faqEdit, open: !faqEdit.open, id: idValue, data: data });
    }
    const [Add, setAdd] = useState(false);
    const toggleAdd = () => {
        setAdd(!Add);
    }

    const [getData, setgetData] = useState([])
    const [categoryList, setcategoryList] = useState([])
    const [pageLoad, setpageLoad] = useState(true)
    const [view, setView] = useState(0)

    useEffect(() => {
        apiCall()
        return () => {

        }
    }, [])

    const apiCall = async () => {
        await admin.getFAQById()
            .then((res) => {
                if (res.data.status) {
                    let filtered = []
                    res.data.result.forEach((data) => {
                        if (data.role == 3) {
                            filtered.push(data)
                        }
                    })

                    const list = []
                    for (let i = 0; i < filtered.length; i++) {
                        if (!list.includes(filtered[i].category)) {
                            list.push(filtered[i].category)
                        }
                    }
                    setcategoryList(list)
                    setgetData(filtered)

                }
                setpageLoad(false)
            })
    }

    return (
        <>
            <div className="mx-1">
                {!Backpopup && <>

                    <header className='row gx-0 mx-0 mb-3 py-1'>
                        <div className="col-lg-4 col-md-5 col-6 d-flex align-items-center px-1">
                            <button className="btn btn-back mr-2" style={{ fontWeight: "900" }} onClick={() => history.goBack()}>BACK</button>
                            <div>
                                <p className="mb-1 fs-20 f-fred"> <i class="fas fa-bars mr-1 fs-20 pointer d-lg-none  d-md-none d-inline-block" onClick={() => store.setMenu(true)}></i>SayDhad Faqs <span className="tx-green"></span></p>
                                <p className="fs-14 ms-700 lh-15 sub-heading">SayDhad tutor support</p>

                            </div>
                        </div>
                        <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                            <button className="btn btn-green fs-12 mx-2 d-lg-block d-md-block d-none" onClick={toggleAdd}>ADD NEW FAQ</button>
                            <button className="btn btn-update mx-2 d-lg-block d-md-block d-none" onClick={() => history.push(`/${helper.getRole()}/dashboard/support-category`)}>VIEW CATEGORIES</button>
                            <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                                <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                            </p>
                            <ProfileIcon />
                        </div>
                    </header>

                    <div className="mx-2">
                        {!Callpopup && <>
                            {!pageLoad && <>

                                {categoryList.map((cat, index) => {
                                    return (
                                        <>
                                            <p className="fs-24 ms-900 mt-2 ml-3 mt-4 tx-lt-grey text-capitalize">{cat}</p>
                                            {getData.map((faq, index) => {
                                                if (faq.category == cat) {
                                                    return (<>
                                                        <div className="col-lg-12 col-md-12 col-12">
                                                            <div className="quote-card cur-pointer bg-smoke border-radius-1 py-2 my-2 ">
                                                                <div className="row gx-0 mx-0">

                                                                    <div className="col-lg-8 col-12 my-1">
                                                                        <p className="f-fred text-capitalize fs-14 ">{faq.question}</p>
                                                                        <p className="ms-500 fs-12">{cat}</p>
                                                                    </div>
                                                                    <div className="col-lg-4 d-flex justify-content-end col-12">
                                                                        <div className="px-2 my-1 all-center ">
                                                                            <button className="btn btn-dark h-100 br-1 w-100 py-1 px-4 f-fred fs-14" onClick={() => { togglefaqEdit(faq.id, faq) }}>Edit</button>
                                                                        </div>
                                                                        <div className=" px-2 my-1 all-center">
                                                                            <button className="btn btn-detail w-100 py-1 px-2 f-fred fs-14" onClick={() => setView(index)}>Details</button>
                                                                        </div>
                                                                    </div>
                                                                    {view === index &&
                                                                        <div className="col-12 mt-2">
                                                                            <div className="ms-300 fs-18"
                                                                                style={{ color: "#434943" }}
                                                                                dangerouslySetInnerHTML={{ __html: faq.answer }}>
                                                                            </div>

                                                                            <hr className='my-3' />
                                                                            
                                                                            <div className="ms-300 fs-18"
                                                                                style={{ color: "#434943" }}
                                                                                dangerouslySetInnerHTML={{ __html: faq.arabic_answer }}>
                                                                            </div>

                                                                            {/* <p className='fs-18 ms-300'>{faq.answer}</p> */}
                                                                        </div>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </>)
                                                }
                                            })}
                                        </>)
                                })}

                            </>}
                            {pageLoad && <Backdrop />}
                            {faqEdit.open &&
                                <EditFaq
                                    id={faqEdit.id}
                                    data={faqEdit.data}
                                    CallClose={() => { togglefaqEdit() }}
                                    OnEdit={() => { apiCall() }}
                                />}

                        </>}

                    </div>
                </>}

                {Add && <AddFAQ onClose={toggleAdd} onAdd={(data, role) => {
                    if (role == 3) {
                        let list = [...getData]
                        list.push(data)
                        setgetData(list)
                    }
                }} />}


            </div>
        </>
    )
}
