import React, { useState } from 'react'
import { FiXCircle } from 'react-icons/fi'
import admin from '../controller/admin'
import { Toaster, toast } from 'react-hot-toast'
import helper from '../helper/index'
import Backdrop from '../component/Backdrop'
export default function TutorKBCategoryEdit({ CallClose, id, data, OnEdit }) {

    const [load, setLoad] = useState(false)
    const [editKB, seteditKB] = useState()
    const editKnowBase = async () => {
        const reqData = {
            "admin_id": helper.getid(),
            "category_name": editKB,
            "id": id
        }
        if (!reqData.category_name) {
            toast('Please enter category name')
            return
        }
        setLoad(true)
        await admin.editKB(reqData)
            .then((res) => {
                if (res.data.status) {
                    toast('Successfully updated')
                    setTimeout(() => {
                        CallClose()
                        OnEdit()
                    }, [])
                }
               
                setLoad(false)

            })
    }

    const deleteKnowBase = async () => {
        setLoad(true)
        await admin.deleteKB({ adminId: 1, id: id })
            .then((res) => {
                if (res.data.status) {
                    toast('Successfully deleted')
                    setTimeout(() => {
                        CallClose()
                        OnEdit()
                    }, [])
                }
              
                setLoad(false)
            })
    }
    return (
        <>
        
            <div className=" all-center popup-modal">
                <div className="right-popup notify-detail p-fix tp-0 rt-0  px-3 py-3 sm" >
                    <div className="row gx-0 mx-0">
                        <div className="col-10">
                            <p className="f-fred" style={{ fontSize: "24px" }}>Edit<span style={{ color: "#089D12" }}> Category</span></p>
                            <p className="sub-heading" style={{ color: "#434943" }}>SayDhad tutor knowledgebase</p>
                        </div>
                        <div className="col-2">
                            <FiXCircle className="pointer float-right " size="2rem" onClick={CallClose} style={{ color: "lightgray" }} />

                        </div>
                    </div>


                    <div className="row gx-0 mx-0 edit-profile">

                        <div className="col-lg-12 edit-input my-2 ms-700 px-1">
                            <input placeholder="Category name" type="text" className='text-capitalize' style={{ border: "1px solid lightgray" }} defaultValue={data.category_name} onChange={(e) => { seteditKB(e.target.value) }} />
                        </div>
                        <div className="col-6 px-1">
                            <button className="btn btn-update py-2 f-fred  btn-sm" onClick={editKnowBase}>UPDATE CATEGORY</button>

                        </div>
                        <div className="col-6 px-1">
                            <button className="btn btn-clear py-2  f-fred btn-sm " onClick={deleteKnowBase}>DELETE CATEGORY</button>
                        </div>
                    </div>
                </div>
            </div>
            {load && <Backdrop />}

        </>
    )
}
