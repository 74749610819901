import React, { useState, useRef, useEffect } from 'react'
import { FiXCircle } from "react-icons/fi";
import country from '../../helper/country.json'
import MultiSelect from 'react-multiple-select-dropdown-lite';

export default function Filter({ CallClose, cb }) {
    const [rateList, setRateList] = useState([])
    const [statusList, setStatusList] = useState([])
    const [countryList, setCountryList] = useState([])
    const [options, setOptions] = useState([])

    useEffect(() => {
        let list = []
        country.forEach((data) => {
            list.push({ label: data.name, value:  data.name })
        })
        setOptions(list)
    }, [])


    const addRate = (rate) => {
        let list = rateList
        if (list.includes(rate)) {
            let index = list.indexOf(rate)
            list.splice(index, 1)
        }
        else {
            list.push(rate)
        }
        setRateList(list)
    }

    const addStatus = (status) => {
        let list = statusList
        if (list.includes(status)) {
            let index = list.indexOf(status)
            list.splice(index, 1)
        }
        else {
            list.push(status)
        }
        setStatusList(list)
    }

    const addCountry = (cn) => {
        setCountryList(cn)
    }

    const submit = () => {
        cb({rating : rateList.join(','),status : statusList.join(','),country : countryList })
    }

    return (
        <>
            <div className="all-center popup-modal">
                <div className="right-popup notify-detail tx-lt-grey p-fix tp-0 rt-0" >
                    <div className="row gx-0 mx-0">
                        <div className="col-10 px-0">
                            <p className="f-fred tx-lt-black" style={{ fontSize: "24px" }}>Filter <span style={{ color: "#089D12" }}> tutors</span></p>
                            <p className="sub-heading">SayDhad tutors</p>
                        </div>
                        <div className="col-2 px-0 d-flex align-items-center justify-content-end ">
                            <FiXCircle className="pointer " size="2rem" onClick={() => CallClose()} style={{ color: "lightgray" }} />
                        </div>
                    </div>

                    <p className='f-fred fs-20 my-3'>Via rating</p>
                    <section className='rating-filter ms-500 fs-14'>
                        <div className="d-flex justify-content-between filter-cb align-items-center">
                            <label for="star5" className='pointer' > 5 stars</label>
                            <input className="float-right" onChange={() => addRate(5)} type="checkbox" id="star5" />
                        </div>
                        <div className="d-flex justify-content-between filter-cb align-items-center">
                            <label for="star4" className='pointer' > 4 stars</label>
                            <input className="float-right" type="checkbox" onChange={() => addRate(4)} id="star4" />
                        </div>
                        <div className="d-flex justify-content-between filter-cb align-items-center">
                            <label for="star3" className='pointer'> 3 stars</label>
                            <input className="float-right" type="checkbox" onChange={() => addRate(3)} id="star3" />
                        </div>
                        <div className="d-flex justify-content-between filter-cb align-items-center">
                            <label for="star2" className='pointer' > 2 stars</label>
                            <input className="float-right" type="checkbox" onChange={() => addRate(2)} id="star2" />
                        </div>
                        <div className="d-flex justify-content-between filter-cb align-items-center">
                            <label for="star1" className='pointer' > 1 stars</label>
                            <input className="float-right" type="checkbox" onChange={() => addRate(1)} id="star1" />
                        </div>
                        <div className="d-flex justify-content-between filter-cb align-items-center">
                            <label for="star0" className='pointer' > 0 stars</label>
                            <input className="float-right" onChange={() => addRate(1)} type="checkbox" id="star0" />
                        </div>
                    </section>

                    <p className='f-fred fs-20 my-3'>Via country</p>
                    <section className='rating-filter ms-500 fs-14'>
                        <MultiSelect
                            className='w-100 fs-20'
                            placeholder="Country"
                            disableChip={false}
                            chipAlternateText="country"
                            options={options}
                            style={{ height: '100%' }}
                            onChange={(data) => { addCountry(data) }}
                        />
                     
                    </section>

                    <p className='f-fred fs-20 my-3'>Via status</p>
                    <section className='rating-filter ms-500 fs-14'>
                        <div className="d-flex justify-content-between filter-cb align-items-center">
                            <label for="active" className='pointer' > Approved</label>
                            <input className="float-right" type="checkbox" onChange={() => addStatus('Approved')} id="active" />
                        </div>
                        <div className="d-flex justify-content-between filter-cb align-items-center">
                            <label for="inactive" className='pointer'> Rejected</label>
                            <input className="float-right" type="checkbox" onChange={() => addStatus('Rejected')} id="inactive" />
                        </div>
                        <div className="d-flex justify-content-between filter-cb align-items-center">
                            <label for="pending" className='pointer' > Pending</label>
                            <input className="float-right" type="checkbox" onChange={() => addStatus('Uploaded')} id="pending" />
                        </div>
                    </section>

                    <div className="row gx-0 mx-0 my-3">
                        <div className="col-lg-5 mx-2">
                            <div><button className="btn btn-update btn-apply " onClick={submit}>APPLY FILTERS</button></div>

                        </div>
                        <div className="col-lg-5 mx-3 ">
                            <div><button className="btn btn-clear ms-700 fs-14" onClick={()=>{ cb({rating : '',status : '',country : '' })}}>CLEAR FILTERS</button></div>

                        </div>
                    </div>


                </div>
            </div>

        </>
    )
}
