import React, { useState, useEffect } from 'react'
import CouponManagerEdit from '../../adminDash/CouponManagerEdit'
import { useHistory, useParams } from 'react-router-dom';
import ProfileIcon from '../../components/ProfileIcon';
import admin from '../../controller/admin';
import CouponDetailCard from './CouponDetailCard';
import { FaBell } from 'react-icons/fa';
import helper from '../../helper';
import Backdrop from '../../component/Backdrop';
export default function CouponDetails() {
    const { userId } = useParams()
    const history = useHistory()
    const [Sb, setSb] = useState(false);
    const toggleSb = () => {
        setSb(!Sb);
    }
    const [resData, setresData] = useState([])
    const [pageLoad, setpageLoad] = useState(true)
    useEffect(() => apiCall(), [])
    const apiCall = async () => {
        await admin.getCouponByCode(1,10,userId)
            .then((res) => {
                if (res.data.status) {
                    setresData(res.data.result)
                }
             setpageLoad(false)
            })
    }
    return (
        <>
            <div className="mx-1">
                <header className='row gx-0 mx-0 mb-3 py-1'>
                    <div className="col-lg-4 col-md-5 col-6 d-flex align-items-center px-1">
                        <button className="btn btn-back mr-2 " style={{ fontWeight: "900" }} onClick={() => history.goBack()}>BACK</button>
                        <div>
                            <p className="mb-1 fs-24 f-fred">
                                <i class="fas fa-bars mr-1 fs-20 pointer d-lg-none  d-md-none d-inline-block"></i>Coupon <span className='tx-green'>code</span></p>
                            <p className="lh-15 sub-heading">{userId}</p>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-7 col-6 d-flex justify-content-end align-items-center px-1">
                             <p className="mr-2 all-center icon-box icon-border px-2 py-1 pointer " onClick={() => history.push(`/${helper.getRole()}/dashboard/notification`)} >

                            <FaBell style={{ width: '25px', height: '20px' }} className="inactive-icon-color" />
                        </p>
                        <ProfileIcon />
                    </div>
                </header>

                <div className="tutor-library mx-1">
                    {resData.map((data) => <CouponDetailCard data={data} />)}
                    {resData.length === 0 && <p className='text-center my-2 ms-500'>No Record Found</p>}
                </div>

                {Sb &&
                    <CouponManagerEdit
                        CallClose={toggleSb}
                    />}
                    {pageLoad && <Backdrop />}
            </div>

        </>
    )
}
